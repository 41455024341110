<template>
  <div class="flex flex-col h-full">
    <!-- SUB HEADER -->
    <div class="flex-shrink-0 text-center">
      <!-- SUB TITLE -->
      <div class="text-base text-gray-600 mb-7">
        {{ t('modelCreate.subTitle.periodsForm') }}
      </div>
      <!-- NOTES -->
      <div class="text-sm text-gray-600" :class="{ 'mb-8': notes.length }">
        {{ notes }}
      </div>
    </div>

    <div class="flex flex-row flex-wrap">
      <div v-for="period in sortedCriteriaMatchedPeriods" :key="period.Id" class="px-5 basis-1/4 mb-7">
        <tx-input
          v-model="periodForm[period.Period]"
          type="number"
          :label="period.Period"
          step="1"
          :min="0"
          :disabled="requestArticles && requestArticles.length > 0"
          :errors="v$[period.Period]?.$errors"
          @input="quantityUpdated"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useVuelidate from '@vuelidate/core'
import type { ComputedRef } from 'vue'
import { computed, reactive, ref, watch } from 'vue'
import { helpers } from '@vuelidate/validators'
import { useDebounceFn } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import utils from '@/services/utils'
import TxInput from '@/shared/components/TxInput.vue'
import type CatalogShipmentWindowRange from '@/models/catalogShipmentWindowRange'
import { appConstants } from '@/models/constants'
import type MyArticle from '@/models/myArticle'

const props = defineProps<{
  createType: StyleCreateType
  retailWindowCriteriaAttributeValue: any | -999
  sortedShipmentWindowRange: CatalogShipmentWindowRange[]
  visible: boolean
  requestArticles?: MyArticle[]
}>()

const emit = defineEmits<{
  (e: 'formUpdated', periodForm: Record<string, any>): void
}>()

const { t } = useI18n()
const periodForm = reactive<Record<string, number>>({})
const notes = ref('')
let formInitialized = false
const debouncedFormUpdated = useDebounceFn(() => {
  emit('formUpdated', periodForm)
}, appConstants.debounce.input)

// COMPUTED
const sortedCriteriaMatchedPeriods: ComputedRef<CatalogShipmentWindowRange[]> = computed(() => {
  let sortedCriteriaMatchedPeriods = props.sortedShipmentWindowRange

  if (props.retailWindowCriteriaAttributeValue !== -999) {
    sortedCriteriaMatchedPeriods = props.sortedShipmentWindowRange.filter(period => utils.haveEqualStringValue(period.CriteriaAttributeValue, props.retailWindowCriteriaAttributeValue))
  }
  return Object.values(sortedCriteriaMatchedPeriods.reduce((acu, cur) => {
    const periodLower = cur.Period != null && cur.Period.toString().trim().length ? cur.Period.toString().toLowerCase().trim() : null
    if (periodLower && !acu.hasOwnProperty(periodLower)) {
      acu[periodLower] = cur
    }
    return acu
  }, {}))
})

const validations = computed(() => {
  const result: Record<string, any> = {}
  sortedCriteriaMatchedPeriods.value.forEach((period) => {
    result[period.Period] = {
      alLeastOne: helpers.withMessage(t('validations.atleast'), alLeastOneFormRule),
    }
  })
  return result
})
// COMPUTED-ENDS

// WATCHERS
watch(() => props.createType, () => {
  formInitialized = false
})

watch(() => props.visible, (visible) => {
  if (visible && !formInitialized) {
    initForm()
    formInitialized = true
  }
})
// WATCHERS-ENDS

const v$ = useVuelidate(validations, periodForm)

// FUNCTIONS
function initForm() {
  if (props.requestArticles && props.requestArticles.length) {
    props.requestArticles.forEach((requestArticle) => {
      if (requestArticle.Period) {
        periodForm[requestArticle.Period] = (periodForm[requestArticle.Period] || 0) + 1
      }
    })
  }
  const oldValues = { ...periodForm }
  // periodForm = {} this prevent vuelidate to work properly
  for (const key in periodForm) {
    delete periodForm[key]
  }
  sortedCriteriaMatchedPeriods.value.forEach((period) => {
    periodForm[period.Period] = oldValues[period.Period] || 0
  })
  return periodForm
}

function quantityUpdated() {
  debouncedFormUpdated()
}

function alLeastOneFormRule() {
  let valid = false
  for (const period in periodForm) {
    const periodQuantity = periodForm[period]
    if (periodQuantity && periodQuantity > 0) {
      valid = true
    }
  }
  return valid
}
// FUNCTIONS-ENDS

defineExpose({
  v$,
})
</script>
