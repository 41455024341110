<template>
  <div
    class="flex items-center justify-center w-full"
    :class="{ 'border-blue-500 border-opacity-100': dragging }"
    @dragover.prevent="dragging = true"
    @dragleave.prevent="dragging = false"
    @drop.prevent="onFileDrop"
  >
    <label for="file-upload" class="flex flex-col w-full p-2 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      <div v-if="modelValue">
        <div class="flex flex-row">
          <div class="text-lg mt-2 w-9/12">
            <span>{{ modelValue.name }}</span>
            <span class="text-gray-500">{{ `(${utils.formatBytes(modelValue.size)})` }}</span>
          </div>
          <div>
            <tx-button type="cancel" :text="t('general.change')" @click="fileInputRef?.click()" />
          </div>
        </div>
      </div>
      <div v-else class="flex flex-col items-center justify-center pt-5 pb-6">
        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" /></svg>
        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
          <span class="font-semibold">{{ t('general.clickToBrowse') }}</span> or {{ t('general.dragAndDrop') }}
        </p>
        <p class="text-xs text-gray-500 dark:text-gray-400">{{ fileFormatHint }}</p>
      </div>
      <input id="file-upload" ref="fileInputRef" type="file" class="hidden" :accept="acceptFormat" @change="onFileChange">
    </label>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import useErrorMessage from '../composables/errorMessage'
import TxButton from '@/shared/components/TxButton.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import utils from '@/services/utils'

interface IProps {
  modelValue?: File | null
  fileFormatHint?: string
  acceptFormat?: string
}
const props = withDefaults(defineProps<IProps>(), { fileFormatHint: '', acceptFormat: '' })

const emit = defineEmits<{
  (e: 'update:modelValue', val: File | null): void
  (e: 'change', val: File | null): void
}>()

const { t } = useI18n()
const { errorMessage, hasError } = useErrorMessage()

const dragging = ref(false)
const fileInputRef = ref<HTMLInputElement>()

function onFileDrop(event: DragEvent) {
  event.preventDefault()
  dragging.value = false

  const files = event.dataTransfer?.files
  if (files && files.length > 0 && isValidFile(files[0])) {
    emit('update:modelValue', files[0])
    emit('change', files[0])
  }
}

function onFileChange(event: Event) {
  const files = (event.target as HTMLInputElement).files
  if (files && files.length > 0 && isValidFile(files[0])) {
    emit('update:modelValue', files[0])
    emit('change', files[0])
  }
}

function isValidFile(file: File) {
  errorMessage.value = ''

  const validFormats = props.acceptFormat.split(',').map(item => item.replace('.', '').trim().toLowerCase())
  const fileExtension = file.name.split('.').pop()?.toLowerCase()
  const isValid = utils.isDefined(fileExtension) && validFormats.includes(fileExtension)

  if (!isValid) {
    errorMessage.value = `${t('general.unsupportedFile', { fileName: file.name })}. ${t('general.allowedFileFormats', { fileFormats: validFormats.join(', ') })}`
  }

  return isValid
}
</script>
