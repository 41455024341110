<template>
  <div
    class="relative grid select-none items-center whitespace-nowrap rounded-lg py-1.5 px-3 font-sans text-xs font-bold uppercase text-white"
    :class="bgClasses"
  >
    <span class="">{{ text }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface IProps {
  type?: 'info' | 'error' | 'success' | 'warning' | 'primary'
  text: string
}

const props = withDefaults(defineProps<IProps>(), { type: 'primary' })

const bgClasses = computed(() => {
  switch (props.type) {
    case 'info':
      return 'bg-gray-500 dark:bg-gray-700'
    case 'error':
      return 'bg-red-500 dark:bg-red-600'
    case 'success':
      return 'bg-green-500 dark:bg-green-600'
    case 'warning':
      return 'bg-yellow-500 dark:bg-yellow-600'
    case 'primary':
      return 'bg-blue-500 dark:bg-blue-600'
    default:
      return ''
  }
})
</script>
