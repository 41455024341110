export default class SellerDetails {
  AccountId: number
  AccountName: string
  AccountType: string
  AccountTypeId: number
  Address: string
  AllowSeasonlessAttributes: number
  AllowSeasonlessModelAttributes: number
  AutomaticNumbering: number
  City: string
  ConsolidationPluginIds: Array<number>
  Country: string
  CountryCode: string
  CreatedBy: number
  CreatedDate: Date
  Domain: string
  Email: string
  IsSizeScaleByStyle: number
  ModelNameLength: number
  OrganizationId: number
  OrganizationName: string
  ParentAccountId: number
  ProcessQueue: string
  State: string
  Status: number
  UpdatedBy: number
  UpdatedDate: Date
  UseConfirmOrder: unknown
  UseOrderType: null
  Website: string
  Zip: number

  constructor(
    {
      AccountId,
      AccountName,
      AccountType,
      AccountTypeId,
      Address,
      AllowSeasonlessAttributes,
      AllowSeasonlessModelAttributes,
      AutomaticNumbering,
      City,
      ConsolidationPluginIds,
      Country,
      CountryCode,
      CreatedBy,
      CreatedDate,
      Domain,
      Email,
      IsSizeScaleByStyle,
      ModelNameLength,
      OrganizationId,
      OrganizationName,
      ParentAccountId,
      ProcessQueue,
      State,
      Status,
      UpdatedBy,
      UpdatedDate,
      UseConfirmOrder,
      UseOrderType,
      Website,
      Zip,
    }: SellerDetails,
  ) {
    this.AccountId = AccountId
    this.AccountName = AccountName
    this.AccountType = AccountType
    this.AccountTypeId = AccountTypeId
    this.Address = Address
    this.AllowSeasonlessAttributes = AllowSeasonlessAttributes
    this.AllowSeasonlessModelAttributes = AllowSeasonlessModelAttributes
    this.AutomaticNumbering = AutomaticNumbering
    this.City = City
    this.ConsolidationPluginIds = ConsolidationPluginIds
    this.Country = Country
    this.CountryCode = CountryCode
    this.CreatedBy = CreatedBy
    this.CreatedDate = CreatedDate
    this.Domain = Domain
    this.Email = Email
    this.IsSizeScaleByStyle = IsSizeScaleByStyle
    this.ModelNameLength = ModelNameLength
    this.OrganizationId = OrganizationId
    this.OrganizationName = OrganizationName
    this.ParentAccountId = ParentAccountId
    this.ProcessQueue = ProcessQueue
    this.State = State
    this.Status = Status
    this.UpdatedBy = UpdatedBy
    this.UpdatedDate = UpdatedDate
    this.UseConfirmOrder = UseConfirmOrder
    this.UseOrderType = UseOrderType
    this.Website = Website
    this.Zip = Zip
  }
}
