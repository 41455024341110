<template>
  <div
    class="w-[190px] h-[170px] cursor-pointer m-5 shrink-0 bg-card shadow-card rounded-md"
    @click.right.stop="doContext($event, id)"
  >
    <div
      v-if="!thumbnail || thumbnail.length === 0" class="overflow-hidden " :class="{ 'w-[100px]': owner === 'new' }"
      @click="doClick(id)"
    >
      <font-awesome-icon v-if="owner === 'new'" class="w-[100px] h-[100px]" icon="fa-light fa-plus" />
      <span v-else v-html="WhiteboardPlaceholderSvg" />
    </div>
    <div v-else class="w-full h-[100px] shadow-md rounded-md bg-white" @click="doClick(id)">
      <img :src="thumbnail">
    </div>
    <div class="w-full px-1 flex ">
      <div class="flex-grow" @click="doClick(id)">
        <div class="px-1 pt-3 overflow-hidden text-sm font-medium line-clamp-1" v-text="name" />
        <div v-show="owner !== 'new'" class="px-1 pt-1 overflow-hidden text-sm text-grey line-clamp-1" v-text="owner" />
      </div>
      <div class="flex-grow-0 m-auto">
        <tx-dropdown
          v-if="menu" :items="menu" :value-prop="valueProp" :display-prop="displayProp"
          faicon="fa-light fa-ellipsis-vertical" @select="onMenu"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import WhiteboardPlaceholderSvg from '@/assets/svg/whiteboard-placeholder.svg?raw'
import TxDropdown from '@/shared/components/TxDropdown.vue'

const props = defineProps<{
  id: number
  name: string
  menu?: any[]
  displayProp?: string
  valueProp?: string
  owner?: string
  thumbnail?: string
}>()

const emits = defineEmits<{
  (e: 'click', id: number): void
  (e: 'context', data: { evt: MouseEvent, id: number }): void
  (e: 'menu', data: { item: any, id: number }): void
}>()

function doClick(id: number) {
  emits('click', id)
}
function doContext(evt: MouseEvent, id: number) {
  emits('context', { evt, id })
}
function onMenu(item: any) {
  emits('menu', { item, id: props.id })
}
</script>
