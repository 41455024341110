<template>
  <div class="grid items-center grid-cols-4 gap-4">
    <label class="text-xs tracking-wide uppercase label required" v-text="t('generateFrameDialog.steps.mapping.sheet')" />
    <tx-select
      v-if="excelWorkbook" v-model="localModelValue.sheetId" class="col-span-3" :data="excelWorkbook.worksheets"
      value-prop="id" display-prop="name" :sort-list="false" :errors="v$.sheetId?.$errors" @change="onChange('sheetId')"
    />

    <template v-for="mapping in selectedTemplateFileMapping" :key="mapping.name">
      <label class="text-xs tracking-wide uppercase label" :class="{ required: mapping.required }" v-text="t(mapping.label)" />
      <tx-select
        v-model="localModelValue[mapping.name]" class="col-span-3" :data="excelColumns"
        :sort-list="false" clearable filterable :multiple-values="mapping.multiple" :multiple-limit="mapping.multipleLimit"
        :errors="v$[mapping.name]?.$errors" @change="onChange(mapping.name)"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Workbook } from 'exceljs'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, helpers, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useUserStore } from '@/store/userData'
import templates from '@/modules/whiteboard/services/templates'
import merchTemplates from '@/modules/merch/services/templates'
import type { IWhiteboardTemplateFileMapping } from '@/modules/whiteboard/services/templates/IWhiteboardTemplate'
import type { IMerchTemplateFileMapping } from '@/modules/merch/services/templates/IMerchTemplate'
import TxSelect from '@/shared/components/TxSelect.vue'

const props = defineProps<{
  templateId: number
  excelWorkbook?: Workbook
  modelValue: Record<string, any>
  currentStep?: number
  validationStep?: number
  isMerch?: boolean
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', val: Record<string, any>): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const withI18nMessage = createI18nMessage({ t })

const localModelValue = computed({
  get: () => props.modelValue,
  set: modelValue => emit('update:modelValue', modelValue),
})

const selectedTemplateFileMapping = computed<IWhiteboardTemplateFileMapping[] | IMerchTemplateFileMapping[]>(() => {
  let mapping: IWhiteboardTemplateFileMapping[] = []
  const currentTemplate = props.isMerch ? merchTemplates : templates
  if (currentTemplate[props.templateId] && userStore.activeCatalog && userStore.myAttributes) {
    mapping = currentTemplate[props.templateId].getFileMapping()
  }
  return mapping
})

const excelColumns = computed(() => {
  const cols: string[] = []
  if (props.excelWorkbook && props.modelValue.sheetId) {
    const worksheet = props.excelWorkbook.getWorksheet(props.modelValue.sheetId)
    if (worksheet) {
      for (let i = 1; i <= worksheet.columnCount; i++) {
        const cell = worksheet.getCell(1, i)
        if (cell && cell.value) {
          cols.push(cell.value.toString())
        }
      }
      setMappingBasedOnAutoMap(cols)
    }
  }
  return cols
})

function setMappingBasedOnAutoMap(cols: string[]) {
  for (const itm of selectedTemplateFileMapping.value) {
    if (itm.multiple) { continue }

    const mapped = cols.find(c => itm.autoMap.includes(c.toLowerCase()))
    localModelValue.value[itm.name] = mapped || null
  }
  emit('update:modelValue', localModelValue.value)
}

const rules = computed(() => {
  const result: Record<string, any> = {}
  if (props.currentStep === props.validationStep) {
    result.sheetId = { required: withI18nMessage(required) }
    for (const mapping of selectedTemplateFileMapping.value) {
      if (mapping.required) {
        result[mapping.name] = { required: helpers.withMessage(t('validations.required', { property: mapping.name === 'articleNumber' ? t(`general.${mapping.name}`) : mapping.name }), required) }
      }
    }
  }
  return result
})

const v$ = useVuelidate(rules, props.modelValue)

function reset() {
  if (excelColumns.value && excelColumns.value.length) {
    setMappingBasedOnAutoMap(excelColumns.value)
  }
}

function onChange(mappingName: string) {
  if (v$.value[mappingName]) {
    v$.value[mappingName].$touch()
  }
  emit('update:modelValue', localModelValue.value)
}

defineExpose({
  selectedTemplateFileMapping,
  excelColumns,
  reset,
})
</script>
