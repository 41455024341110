<template>
  <div v-resize="onResizeContainer" class="relative w-full" @mousewheel="onWheelScroll">
    <div
      ref="refContainer" class="container h-full overflow-hidden" :style="{ height: `${contentHeight + 20}px` }"
      @scroll="refreshPaddles"
    >
      <div ref="refContent" v-resize="onResizeContent" class="box-border mx-4 flex w-[1000px]">
        <slot />
      </div>
      <font-awesome-icon
        v-show="leftPaddleVisible" class="absolute top-1/2 left-0 bg-white h-8 hover:text-primary-500 z-[1000]"
        icon="fa-light fa-chevron-left" @click="scrollLeft"
      />
      <font-awesome-icon
        v-show="rightPaddleVisible" class="absolute top-1/2 right-0 bg-white h-8 hover:text-primary-500 z-[1000]"
        icon="fa-light fa-chevron-right" @click="scrollRight"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
// NOTE: Important - Make sure the child components are styles with inline-block
import { useThrottleFn } from '@vueuse/core'
import { onBeforeUnmount, onMounted, ref } from 'vue'

interface IProps {
  scrollStep?: number
}
const props = withDefaults(defineProps<IProps>(), { scrollStep: 150 })

const refContainer = ref<HTMLElement | undefined>()
const refContent = ref<HTMLElement | undefined>()
const containerWidth = ref(0)
const containerHeight = ref()
const contentWidth = ref(0)
const contentHeight = ref(0)
const leftPaddleVisible = ref(true)
const rightPaddleVisible = ref(true)
const paddleMargin = 20
let interval

const refreshPaddles = useThrottleFn(() => {
  if (refContent.value && refContainer.value) {
    const contentPosition = refContainer.value.scrollLeft
    const contentInvisibleSize = contentWidth.value - containerWidth.value
    const contentEndOffset = contentInvisibleSize - paddleMargin

    if (contentWidth.value <= containerWidth.value) {
      leftPaddleVisible.value = false
      rightPaddleVisible.value = false
    }
    else if (contentPosition <= paddleMargin) {
      leftPaddleVisible.value = false
      rightPaddleVisible.value = true
    }
    else if (contentPosition < contentEndOffset) {
      leftPaddleVisible.value = true
      rightPaddleVisible.value = true
    }
    else if (contentPosition >= contentEndOffset) {
      leftPaddleVisible.value = true
      rightPaddleVisible.value = false
    }
  }
})

function onResizeContainer({ width, height }) {
  containerWidth.value = width
  containerHeight.value = height

  refreshPaddles()
}

function onResizeContent({ width, height }) {
  contentWidth.value = width
  contentHeight.value = height

  refreshPaddles()
}

function scrollLeft() {
  if (!refContainer.value) { return }
  refContainer.value.scrollLeft -= props.scrollStep
}

function scrollRight() {
  if (!refContainer.value) { return }
  refContainer.value.scrollLeft += props.scrollStep
}

function onWheelScroll(event: WheelEvent) {
  if (refContainer.value) {
    refContainer.value.scrollLeft -= event.deltaY
    event.preventDefault()
  }
}

onMounted(() => {
  // NOTE: Found an easy way to support drag scrolling, simply keep the scroll bar visible (overflow-visible)
  //       but make the control bigger than its container so that the scrollbars are not visible

  if (refContent.value) {
    contentWidth.value = refContent.value.clientWidth
  }
  if (refContainer.value) {
    contentHeight.value = (refContainer.value.parentElement?.clientHeight || 0) + 8 // 8 is the scroll bar height

    containerHeight.value = refContainer.value.clientHeight
    containerWidth.value = refContainer.value.clientWidth
  }

  interval = setInterval(() => refreshPaddles(), 1000)
  refreshPaddles()
})

onBeforeUnmount(() => {
  clearInterval(interval)
})
</script>

<style lang="scss" scoped>
.container {
  scroll-behavior: smooth;
}

.content {
  width: fit-content;
}
</style>
