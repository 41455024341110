<template>
  <div class="">
    <div
      v-show="toolbarVisible" ref="toolbarMerchRef"
      class="absolute z-[1000] flex bg-grey-light rounded-lg shadow-toolbar" :style="{ top: `${toolbarPos.y}px`, left: `${toolbarPos.x}px` }"
    >
      <div v-if="lockProp" class="flex items-center">
        <div v-tooltip="'Unlock'" class="w-6 h-6 m-1.5 cursor-pointer">
          <font-awesome-icon class="w-6 h-6" icon="fa-light fa-lock" @click="onLockPropChange(!lockProp)" />
        </div>
        <!-- <div v-if="editableProps['addComment']" class="w-6 h-6 m-1.5 cursor-pointer" v-tooltip="editableProps['addComment'].name">
            <font-awesome-icon class="w-6 h-6" icon="fa-light fa-comment" @click="onAddComment" />
          </div> -->
      </div>
      <div v-for="p in editableProps" v-else :key="p.type" class="flex items-center">
        <div
          v-if="p.type === 'border'" v-tooltip="p.name"
          class="rounded-full w-6 h-6 m-1.5 cursor-pointer border-solid" :style="{ borderColor: borderPropColor, borderWidth: `${borderPropThickness}px` }"
          @click="onOpenSelection($event, p.type)"
        />
        <div
          v-else-if="p.type === 'color'" v-tooltip="p.name"
          class="rounded-full w-6 h-6 m-1.5 cursor-pointer" :style="{ backgroundColor: colorProp }" @click="onOpenSelection($event, p.type)"
        />
        <div
          v-else-if="p.type === 'font'" v-tooltip="p.name"
          class="w-auto m-1.5 text-base font-bold cursor-pointer" @click="onOpenSelection($event, p.type)" v-text="fontProp"
        />
        <div
          v-else-if="p.type === 'fontSize'" v-tooltip="p.name"
          class="w-auto m-1.5 text-base font-bold cursor-pointer" @click="onOpenSelection($event, p.type)"
          v-text="availableFontSizes.find(data => data.key === fontSizeProp)?.value || fontSizeProp"
        />
        <div
          v-else-if="p.type === 'textColor'"
          v-tooltip="p.name" class="w-6 text-center text-base font-bold m-1.5 cursor-pointer"
          :style="{ borderBottom: `5px solid ${textColorProp}` }" @click="onOpenSelection($event, p.type)"
        >
          A
        </div>
        <div v-else-if="p.type === 'textAlign'" v-tooltip="p.name" class="w-6 h-6 m-1.5 cursor-pointer">
          <font-awesome-icon class="w-6 h-6" :icon="availableTextAligns[textAlignProp]" @click="onOpenSelection($event, p.type)" />
        </div>
        <div
          v-else-if="p.type === 'fontStyle'"
          v-tooltip="p.name"
          class="w-6 border-b-4 border-black italic text-center text-base font-bold m-1.5 cursor-pointer" @click="onOpenSelection($event, p.type)"
        >
          B
        </div>
        <div
          v-else-if="p.type === 'size'" v-tooltip="p.name"
          class="w-auto m-1.5 text-base font-bold cursor-pointer" @click="onOpenSelection($event, p.type)" v-text="sizeProp"
        />
        <div
          v-else-if="p.type === 'shapeType'" v-tooltip="p.name" class="w-6 h-6 m-1.5 cursor-pointer"
          @click="onOpenSelection($event, p.type)"
        >
          <!-- <svg viewBox="0 0 50 50">
              <path :style="{ fill: 'none', stroke: '#000000', strokeWidth: '3px' }" :d="availableShapes[shapeTypeProp]" />
            </svg> -->
        </div>
        <!-- <div v-else-if="p.type === 'frameSize'" class="w-auto m-1.5 text-base font-bold cursor-pointer"
            v-tooltip="p.name" v-text="utils.capitalizeFirstLetter(frameSizeProp)"
            @click="onOpenSelection($event, p.type)" /> -->
        <div v-else-if="p.type === 'lock'" v-tooltip="p.name" class="w-6 h-6 m-1.5 cursor-pointer">
          <font-awesome-icon class="w-6 h-6" icon="fa-light fa-lock-open" @click="onLockPropChange(!lockProp)" />
        </div>
        <div
          v-else-if="p.type === 'imageType'" v-tooltip="p.name"
          class="w-auto m-1.5 text-base font-bold cursor-pointer" @click="onOpenSelection($event, p.type)" v-text="assetKeyProp || 'Type'"
        />
        <div
          v-else-if="p.type === 'scale'" v-tooltip="p.name"
          class="w-auto m-1.5 text-base font-bold cursor-pointer" @click="onOpenSelection($event, p.type)" v-text="`${scaleProp}%`"
        />
        <!-- <div v-else-if="p.type === 'addModelDetails'" class="w-6 h-6 m-1.5 cursor-pointer" v-tooltip="p.name">
            <font-awesome-icon class="w-6 h-6" icon="fa-light fa-plus" @click="onAddModelDetails" />
          </div> -->
        <div v-else-if="p.type === 'addArticleDetails'" v-tooltip="p.name" class="w-6 h-6 m-1.5 cursor-pointer">
          <font-awesome-icon class="w-6 h-6" icon="fa-light fa-plus" @click="onOpenSelection($event, p.type)" />
        </div>
        <div
          v-else-if="p.type === 'showLabels'" class="m-1.5 text-base cursor-pointer" :class="{ 'text-primary-500': showLabelsProp }"
          @click="onShowLabelsPropChange(!showLabelsProp)" v-text="p.name"
        />
        <div v-else-if="p.type === 'attributes'" class="w-40 mx-1.5">
          <tx-select
            v-model="attributesProp" :data="availableAttributes" :placeholder="p.name" value-prop="SystemName"
            display-prop="DisplayName" filterable clearable multiple-values show-one @change="onAttributePropChange"
          />
        </div>
        <!-- <div v-else-if="p.type === 'addComment'" class="w-6 h-6 m-1.5 cursor-pointer" v-tooltip="p.name">
            <font-awesome-icon class="w-6 h-6" icon="fa-light fa-comment" @click="onAddComment" />
          </div> -->
      </div>

      <!-- MORE OPTIONS -->
      <tx-button
        v-show="!isEmpty(selectedObjectSubMenu)" v-tooltip="t('general.more').toLowerCase()" type="icon" faicon="fa-light fa-ellipsis-stroke-vertical"
        class="float-right" @click="onOpenSelection($event as MouseEvent, 'subMenu')"
      />
    </div>
    <div v-if="!toolbarVisible && props.objectProps && Object.keys(props.objectProps).length" class="flex overflow-y-auto flex-wrap">
      <div v-for="key in Object.keys(props.objectProps!)" :key="key" class="w-[45%] my-2 mx-2">
        <div v-if="key !== 'addArticleDetails'" class="flex flex-row flex-nowrap">
          <tx-checkbox v-model="checkedObjectsProps[key]" class="w-[20px]" @change="onChangeObjectPropCheckbox(key)" />
          <tx-select
            v-if="props.objectProps![key].type === 'scale'" v-model="objectPropsModel[key]"
            :label="t('merch.dialog.modify.objectProperties.scale')" :data="availableScales" @change="onChangeObjectProp(key)"
          />
          <tx-select
            v-if="props.objectProps![key].type === 'imageType'" v-model="objectPropsModel[key]"
            :label="t('merch.dialog.modify.objectProperties.imageType')" :data="merchConstants.defaultImageType" value-prop="key"
            display-prop="value" @change="onChangeObjectProp(key)"
          />
          <tx-input-color-picker
            v-if="props.objectProps![key].type === 'textColor' || props.objectProps![key].type === 'color' || props.objectProps![key].type === 'border'" v-model="objectPropsModel[key]"
            :label="t(`merch.dialog.modify.objectProperties.${props.objectProps![key].type}`)"
            @input="onChangeObjectProp(key)"
            @change="onChangeObjectProp(key)"
          />
          <tx-select
            v-if="props.objectProps![key].type === 'font'" v-model="objectPropsModel[key]"
            :label="t('merch.dialog.modify.objectProperties.font')" :data="availableFonts" @change="onChangeObjectProp(key)"
          />
          <tx-select
            v-if="props.objectProps![key].type === 'fontSize'" v-model="objectPropsModel[key]" value-prop="key" display-prop="value"
            :label="t('merch.dialog.modify.objectProperties.fontSize')" :data="availableFontSizes" @change="onChangeObjectProp(key)"
          />
          <tx-select
            v-if="props.objectProps![key].type === 'textAlign'" v-model="objectPropsModel[key]"
            :label="t('merch.dialog.modify.objectProperties.textAlign')" :data="availableTextAlignsOptions" @change="onChangeObjectProp(key)"
          />
          <tx-select
            v-if="props.objectProps![key].type === 'attributes'" v-model="objectPropsModel[key]" :multiple-values="true"
            :label="t('merch.dialog.modify.objectProperties.attributes')" :data="availableAttributes" value-prop="SystemName"
            display-prop="DisplayName" @change="onChangeObjectProp(key)"
          />
          <tx-select
            v-if="props.objectProps![key].type === 'fontStyle'" v-model="objectPropsModel[key]"
            :label="t('merch.dialog.modify.objectProperties.fontStyle')" :data="fontStyleOptions" @change="onChangeObjectProp(key)"
          />
          <tx-switch v-if="props.objectProps![key].type === 'lock' || props.objectProps![key].type === 'showLabels'" v-model="objectPropsModel[key]" :label="t(`merch.dialog.modify.objectProperties.${props.objectProps![key].type}`)" @change="onChangeObjectProp(key)" />
        </div>
      </div>
    </div>

    <!-- DROPDOWNS -->
    <div
      v-show="dropdownVisible === 'border'"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-40 p-2 text-center"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <input
        v-model="borderPropThickness" type="range" class="w-100" min="1" max="10"
        @change="onBorderPropChange(borderPropColor, borderPropThickness)"
      >
      <div
        v-for="color in availableColors" :key="color" class="rounded-full w-6 h-6 float-left m-1.5 cursor-pointer"
        :style="{ backgroundColor: color }"
        :class="{ 'outline outline-blue-50 outline-2 outline-offset-1': borderPropColor === color }"
        @click="onBorderPropChange(color, borderPropThickness)"
      />
    </div>

    <div
      v-show="dropdownVisible === 'color'" class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-40 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-for="color in availableColors" :key="color" class="rounded-full w-6 h-6 float-left m-1.5 cursor-pointer"
        :style="{ backgroundColor: color }"
        :class="{ 'outline outline-blue-50 outline-2 outline-offset-1': colorProp === color }"
        @click="onColorPropChange(color)"
      />
    </div>

    <div
      v-show="dropdownVisible === 'font'" class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-40 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-for="font in availableFonts" :key="font" class="text-sm h-6 my-auto m-1.5 cursor-pointer"
        :class="{ 'font-bold': fontProp === font }" @click="onFontPropChange(font)" v-text="font"
      />
    </div>

    <div
      v-show="dropdownVisible === 'fontSize'"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-20 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <input v-model="fontSizeProp" type="number" min="1" max="100" @change="onFontSizePropChange(fontSizeProp)">
      <div
        v-for="fontSize in availableFontSizes" :key="fontSize.key" class="text-sm h-6 my-auto m-1.5 cursor-pointer"
        :class="{ 'font-bold': fontSizeProp === fontSize.key }" @click="onFontSizePropChange(fontSize.key)" v-text="fontSize.value"
      />
    </div>

    <div
      v-show="dropdownVisible === 'textColor'"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-40 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-for="textColor in availableColors" :key="textColor" class="rounded-full w-6 h-6 float-left m-1.5 cursor-pointer"
        :style="{ backgroundColor: textColor }"
        :class="{ 'outline outline-blue-50 outline-2 outline-offset-1': textColorProp === textColor }"
        @click="onTextColorPropChange(textColor)"
      />
    </div>

    <div
      v-show="dropdownVisible === 'textAlign'"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-30 p-2"
      :style="{ left: `${propDropdownPos.x - 50}px`, top: `${propDropdownPos.y}px` }"
    >
      <font-awesome-icon
        v-for="(icon, alignType) in availableTextAligns" :key="alignType" class="rounded-full w-6 h-6 float-left m-1.5 cursor-pointer"
        :icon="icon" @click="onTextAlignPropChange(alignType)"
      />
    </div>

    <div
      v-show="dropdownVisible === 'fontStyle'"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-30 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-tooltip="t('general.bold')" class="float-left m-1.5 text-base cursor-pointer"
        :class="{ 'text-primary-500': boldProp }" @click="onBoldPropChange(!boldProp)"
      >
        B
      </div>
      <div
        v-tooltip="t('general.italic')" class="float-left m-1.5 text-base italic cursor-pointer"
        :class="{ 'text-primary-500': italicProp }" @click="onItalicPropChange(!italicProp)"
      >
        I
      </div>
    </div>

    <div
      v-show="dropdownVisible === 'size'" class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-8 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-for="size in availableSizes" :key="size" class="text-sm h-6 my-auto m-1.5 cursor-pointer"
        :class="{ 'font-bold': sizeProp === size }" @click="onSizePropChange(size)" v-text="size"
      />
    </div>

    <div
      v-show="dropdownVisible === 'imageType'"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-40 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-for="asset in availableAssets" :key="asset.key" class="text-sm h-6 my-auto m-1.5 cursor-pointer"
        :class="{ 'font-bold': assetKeyProp === asset.value }" @click="onAssetPropChange(asset)" v-text="asset.value"
      />
    </div>

    <div
      v-show="dropdownVisible === 'scale'" class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-20 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <input v-model="scaleProp" type="number" min="1" max="100" @change="onScalePropChange(scaleProp)">
      <div
        v-for="scale in availableScales" :key="scale" class="text-sm h-6 my-auto m-1.5 cursor-pointer"
        :class="{ 'font-bold': scaleProp === scale }" @click="onScalePropChange(scale)" v-text="`${scale}%`"
      />
    </div>
    <div
      v-show="dropdownVisible === 'addArticleDetails'"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-40 p-2 max-h-[180px] overflow-scroll"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <tx-switch v-model="addArticleDetailsGroupProp" class="w-100" label="Group" />
      <div class="text-sm m-1.5">
        Position
      </div>
      <div
        v-for="position in availablePositions" :key="position.key" class="text-xs h-6 my-auto m-2 cursor-pointer"
        @click="onAddArticleDetails(position.key)" v-text="position.value"
      />
    </div>
    <!--
    <div
      v-show="selectedObj?.type === whiteboardConstants.objectTypes.discussion"
      class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar w-60 p-2"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <manage-discussion v-if="selectedObj?.type === whiteboardConstants.objectTypes.discussion"
          v-model="discussionIdProp" :whiteboard-id="props.whiteboardId" @update:model-value="OnDiscussionIdChange"
          @delete="onDeleteDiscussion" />
    </div> -->

    <div
      v-show="dropdownVisible === 'subMenu'" class="absolute z-[1000] bg-grey-light rounded-sm shadow-toolbar w-60 p-2 h-[250px] overflow-auto"
      :style="{ left: `${propDropdownPos.x}px`, top: `${propDropdownPos.y}px` }"
    >
      <div
        v-for="subMenu in selectedObjectSubMenu"
        :key="subMenu.action" class="flex items-center px-1 text-base leading-9 truncate transition rounded-sm cursor-pointer hover:bg-gray-200 motion-reduce:transition-none motion-reduce:hover:transform-none"
        @click="onSelectedObjectSubMenuClick(subMenu.action)"
      >
        <tx-button type="icon" :icon="subMenu.icon" :faicon="subMenu.faicon" />
        <p class="ml-1">
          {{ subMenu.label }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isEmpty, isEqual } from 'lodash-es'
import { fabric } from 'fabric'
import { useI18n } from 'vue-i18n'
import { useElementSize, useWindowSize } from '@vueuse/core'
import { computed, onUnmounted, ref, watchEffect } from 'vue'
import type Merch from '../services/merch'
import MbArticleDetails from '../services/articleDetails'
import TxInputColorPicker from '@/shared/components/TxInputColorPicker.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import TxButton from '@/shared/components/TxButton.vue'
import { merchConstants } from '@/models/constants'
import TxSwitch from '@/shared/components/TxSwitch.vue'
import TxCheckbox from '@/shared/components/TxCheckbox.vue'

// import MyArticle from '@/models/myArticle'

//   import Utility from '../services/utility'
import { useUserStore } from '@/store/userData'
import utils from '@/services/utils'

const props = defineProps<{
  merch?: Merch
  objectProps?: Record<string, IMbObjectProp>
}>()

const emit = defineEmits<{
  (e: 'showAlignDialog')
  (e: 'updateArticles', objects: IMbObject[])
  (e: 'updateRetailWindow', objects: IMbObject[])
  (e: 'updateArticleSegmentation', objects: IMbObject[])
  (e: 'editFavorites', objects: IMbObject[])
  (e: 'updatedObjectPropsModel', modelValue: Record<string, any>)
}>()

const { t } = useI18n()
const userStore = useUserStore()
// const notificationsStore = useNotificationStore()

const toolbarVisible = ref(false)
const editableProps = ref<Record<string, IMbObjectProp>>({})
const selectedObjectSubMenu = ref<Record<string, IWObjectActions>>({})
const winSize = useWindowSize()
const toolbarMerchRef = ref<HTMLElement>()
const toolbarSize = useElementSize(toolbarMerchRef)
const selectedObj = ref()
const doesSelectionContainsModelDetails = ref(false) // TODO: this flag is not required if selectedObj hold all the selected objects (probably logic on selectedObj should be revisited)
const doesSelectionContainsArticleDetails = ref(false) // TODO: this flag is not required if selectedObj hold all the selected objects (probably logic on selectedObj should be revisited)
const selObjCoords = ref<[fabric.Point, fabric.Point, fabric.Point, fabric.Point]>()
const availableColors = ['#000000', '#808080', '#ffffff', '#ff0101', '#ff0080', '#800080', '#0080ff', '#ff8000', '#ffff00', '#008000']
const availableFonts = ['Arial', 'Verdana', 'Helvetica', 'Tahoma', 'Trebuchet MS', 'Times New Roman', 'Georgia', 'Garamond', 'Courier New', 'Brush Script MT']
const availableFontSizes = [{ key: '-1', value: 'Auto' }, { key: '4', value: '4' }, { key: '8', value: '8' }, { key: '10', value: '10' }, { key: '12', value: '12' }, { key: '14', value: '14' }, { key: '18', value: '18' }, { key: '20', value: '20' }, { key: '24', value: '24' }, { key: '32', value: '32' }, { key: '48', value: '48' }, { key: '64', value: '64' }]
const availableTextAligns = { left: 'fa-light fa-align-left', center: 'fa-light fa-align-center', right: 'fa-light fa-align-right' }
const availableTextAlignsOptions = ['left', 'center', 'right']
const fontStyleOptions = ['bold', 'italic']
const availableSizes = ['S', 'M', 'L']
const availablePositions = [{ key: 'topLeft', value: 'Top, Left' }, { key: 'topCenter', value: 'Top, Center' }, { key: 'topRight', value: 'Top, Right' }, { key: 'rightTop', value: 'Right, Top' }, { key: 'rightMiddle', value: 'Right, Middle' }, { key: 'rightBottom', value: 'Right, Bottom' }, { key: 'bottomLeft', value: 'Bottom, Left' }, { key: 'bottomCenter', value: 'Bottom, Center' }, { key: 'bottomRight', value: 'Bottom, Right' }, { key: 'leftTop', value: 'Left, Top' }, { key: 'leftMiddle', value: 'Left, Middle' }, { key: 'leftBottom', value: 'Left, Bottom' }]
const availableAssets = ref<Record<string, any>>([])
const availableScales = [100, 50, 25, 10, 5]
const dropdownVisible = ref('')
const propDropdownPos = ref<IPoint>({ x: 0, y: 0 })
const borderPropThickness = ref(1)
const borderPropColor = ref('#000000')
const addArticleDetailsGroupProp = ref(false)
const colorProp = ref('#ffffff')
const fontProp = ref('Helvetica')
const fontSizeProp = ref('-1')
const textColorProp = ref('#000000')
const textAlignProp = ref('center')
const boldProp = ref(false)
const italicProp = ref(false)
const sizeProp = ref('M')
const lockProp = ref(false)
const assetKeyProp = ref('')
const scaleProp = ref(100)
const showLabelsProp = ref(true)
const attributesProp = ref([])
const discussionIdProp = ref<number>()
const objectPropsModel = ref<Record<string, any>>({})
const checkedObjectsProps = ref<Record<string, boolean>>({})

//   let selectedArticleImage: WbArticleImage | undefined = undefined

const availableAttributes = computed(() => {
  const excludeAttributeSystemName = new Set(['_DeliveryDates', '_Segmentations', '_FavoriteTags'])
  const availableAttributes = Object.values(userStore.myAttributes!).filter(attribute => !excludeAttributeSystemName.has(attribute.SystemName) && (!doesSelectionContainsModelDetails.value || attribute.IsModelLevel))
  // selection does not contains article details, add SKU fields
  // if (doesSelectionContainsModelDetails.value && !doesSelectionContainsArticleDetails.value) {
  //   const SKUField = { ...appConstants.staticFieldTemplate }
  //   SKUField.DisplayName = `${userStore.activeCatalog!.Season} SKU`
  //   SKUField.SystemName = userStore.activeCatalog!.CatalogCode.toString()
  //   availableAttributes.push(SKUField)
  //   for (const key in userStore.linkedCatalogDetails) {
  //     const SKUField = { ...appConstants.staticFieldTemplate }
  //     SKUField.DisplayName = `${userStore.linkedCatalogDetails[key].Season} SKU`
  //     SKUField.SystemName = userStore.linkedCatalogDetails[key].CatalogCode.toString()
  //     availableAttributes.push(SKUField)
  //   }
  // }
  return availableAttributes
})

watchEffect(() => {
  if (props.merch) {
    attach(props.merch)
  }
})

onUnmounted(() => {
  if (props.merch && !props.objectProps) {
    detach(props.merch)
  }
})

function attach(obj: Merch) {
  obj.canvas.on('selection:created', onSelection)
  obj.canvas.on('selection:updated', onSelection)
  obj.canvas.on('selection:cleared', onSelection)
  obj.canvas.on('object:moving', (e) => {
    onSelection(e)
  })
  obj.canvas.on('object:scaling', onSelection)
  obj.on('zoom', onSelection)
}

function detach(obj: Merch) {
  obj.canvas.off('selection:created')
  obj.canvas.off('selection:updated')
  obj.canvas.off('selection:cleared')
  obj.canvas.off('object:moving')
  obj.canvas.off('object:scaling')
  obj.canvas.off('mouse:up')
  obj.canvas.off('dragover')
  obj.canvas.off('drop')
}
const toolbarPos = computed(() => {
  if (!selObjCoords.value) { return { x: 0, y: 0 } }
  const margin = 40
  const canvasZoom = props.merch!.canvas.getZoom() || 0
  const vpCoords = props.merch!.canvas.vptCoords
  let x = (selObjCoords.value[0].x - vpCoords!.tl.x)
  let y = (selObjCoords.value[0].y - vpCoords!.tl.y)
  const bottomY = (selObjCoords.value[3].y - vpCoords!.tl.y) * canvasZoom
  const width = selObjCoords.value[1].x - selObjCoords.value[0].x

  x = x * canvasZoom + (width * canvasZoom / 2) - toolbarSize.width.value / 2

  const dynamicAdjustment = 18 * canvasZoom
  y = (y * canvasZoom - toolbarSize.height.value - margin) - dynamicAdjustment

  x = Math.min(Math.max(x, 0), winSize.width.value - toolbarSize.width.value - 100)
  if (y < 50) {
    if (bottomY + margin + toolbarSize.height.value > winSize.height.value) {
      y = 50
    }
    else {
      y = bottomY + margin
    }
  }
  return { x, y }
})
function onSelection(e) {
  selectedObj.value = null
  editableProps.value = {}
  selectedObjectSubMenu.value = {}
  doesSelectionContainsModelDetails.value = false
  doesSelectionContainsArticleDetails.value = false
  const objs = props.merch?.canvas.getActiveObjects() as Array<IMbObject>
  const selection = props.merch?.canvas.getActiveObject()
  if (!objs || !objs.length || !selection) {
    toolbarVisible.value = false
    dropdownVisible.value = ''
    return
  }

  // if (objs.some(itm => !(itm as any).editableProps || itm.type !== objs[0].type)) {
  //   toolbarVisible.value = false
  //   dropdownVisible.value = ''
  //   return
  // }

  // if later decided to show actions that are uncommon between objects then while performing the action, the action should be performed on supported objects (check while performing action)
  const commonSubMenu = Object.fromEntries((Object.entries((objs[0] as any).actions || {})).filter((item: Array<any>) => item[1].showInSubMenu)) as Record<string, IWObjectActions>
  for (let i = 0; i < objs.length; i++) {
    // create common subMenu
    if (!isEmpty(commonSubMenu) && i !== 0) { // if commonSubMenu is already empty then don't repeat the loop on actions(not required for index 0 as well)
      for (const actionName in commonSubMenu) {
        if (!objs[i].actions?.hasOwnProperty(actionName)) {
          delete commonSubMenu[actionName]
        }
      }
    }

    if (objs[i].type === merchConstants.objectTypes.articleDetails) {
      doesSelectionContainsArticleDetails.value = true
    }
  }
  if (objs.length === 1) {
    for (const actionName in commonSubMenu) {
      if (commonSubMenu[actionName].multiple && commonSubMenu[actionName].multiple === true) {
        delete commonSubMenu[actionName]
      }
    }
  }

  selectedObjectSubMenu.value = commonSubMenu // later on can implement actions that should appear on main men
  selObjCoords.value = selection.getCoords(true)
  const commonEditablePropsKeysWithValue: Record<string, any> = {}
  Object.keys(objs[0].editableProps).forEach((key) => {
    commonEditablePropsKeysWithValue[key] = objs[0].getProp(key)
  })
  for (let i = 1; i < objs.length; i++) {
    Object.keys(commonEditablePropsKeysWithValue).forEach((key) => {
      if (objs[i].editableProps.hasOwnProperty(key)) {
        if (commonEditablePropsKeysWithValue[key] !== null && !isEqual(commonEditablePropsKeysWithValue[key], objs[i].getProp(key))) {
          commonEditablePropsKeysWithValue[key] = {}
        }
      }
      else {
        delete commonEditablePropsKeysWithValue[key]
      }
    })
  }
  selectedObj.value = (objs[0] as any)
  lockProp.value = false
  discussionIdProp.value = undefined
  if (selectedObj.value.editableProps && Object.keys(commonEditablePropsKeysWithValue).length !== 0) {
    Object.keys(commonEditablePropsKeysWithValue).forEach((key) => {
      editableProps.value[key] = selectedObj.value.editableProps[key]
    })
    if (editableProps.value.hasOwnProperty('border')) {
      const prop = commonEditablePropsKeysWithValue.border // selectedObj.value.getProp('border')
      borderPropThickness.value = prop.strokeWidth || 1
      borderPropColor.value = prop.borderColor || '#000000'
    }
    if (editableProps.value.hasOwnProperty('backgroundColor')) {
      const prop = commonEditablePropsKeysWithValue.backgroundColor
      colorProp.value = prop.backgroundColor || '#ffffff'
    }
    if (editableProps.value.hasOwnProperty('font')) {
      const prop = commonEditablePropsKeysWithValue.font
      fontProp.value = prop.font || 'Helvetica'
    }
    if (editableProps.value.hasOwnProperty('fontSize')) {
      const prop = commonEditablePropsKeysWithValue.fontSize
      fontSizeProp.value = prop.fontSize ? Math.round((prop.fontSize * 72) / 96).toString() || '-1' : '-1'
    }
    if (editableProps.value.hasOwnProperty('fill')) {
      const prop = commonEditablePropsKeysWithValue.fill
      textColorProp.value = prop.fill || '#000000'
    }
    if (editableProps.value.hasOwnProperty('textAlign')) {
      const prop = commonEditablePropsKeysWithValue.textAlign
      textAlignProp.value = prop.textAlign || 'center'
    }
    if (editableProps.value.hasOwnProperty('bold')) {
      const prop = commonEditablePropsKeysWithValue.bold
      boldProp.value = prop.bold || false
    }
    if (editableProps.value.hasOwnProperty('italic')) {
      const prop = commonEditablePropsKeysWithValue.italic
      italicProp.value = prop.italic || false
    }
    if (editableProps.value.hasOwnProperty('size')) {
      const prop = commonEditablePropsKeysWithValue.size
      sizeProp.value = prop.size || 'M'
    }
    if (editableProps.value.hasOwnProperty('locked')) {
      const prop = commonEditablePropsKeysWithValue.locked
      lockProp.value = prop.lock || false
    }
    if (editableProps.value.hasOwnProperty('imageType')) {
      const prop = commonEditablePropsKeysWithValue.imageType
      availableAssets.value = []
      const assets: Record<string, any> = getAvailableAssets(objs as any)
      availableAssets.value = assets && assets.length !== 0 ? assets : []
      assetKeyProp.value = availableAssets.value.find(type => type.key === prop.assetKey)?.value || ''
    }
    if (editableProps.value.hasOwnProperty('scale')) {
      const prop = commonEditablePropsKeysWithValue.scale
      scaleProp.value = prop.scale || 100
    }
    if (editableProps.value.hasOwnProperty('showLabels')) {
      const prop = commonEditablePropsKeysWithValue.showLabels
      showLabelsProp.value = !!prop.showLabels
    }
    if (editableProps.value.hasOwnProperty('attributes')) {
      const prop = commonEditablePropsKeysWithValue.attributes
      attributesProp.value = prop.attributes || []
    }
    if (editableProps.value.hasOwnProperty('addArticleDetails')) {
      addArticleDetailsGroupProp.value = false
    }
    toolbarVisible.value = true
  }
  // if (selectedObj.value?.type === whiteboardConstants.objectTypes.discussion) {
  //   discussionIdProp.value = selectedObj.value.discussionId
  // }
  if (e.e) {
    propDropdownPos.value = { x: e.e.x - 60, y: e.e.y - 60 }
  }
}
function onChangeObjectProp(key: string) {
  checkedObjectsProps.value[key] = true
  updateModelValue()
}
function onChangeObjectPropCheckbox(key: string) {
  if (!objectPropsModel.value[key] && (props.objectProps![key].type === 'lock' || props.objectProps![key].type === 'showLabels' || props.objectProps![key].type === 'addArticleDetails')) {
    objectPropsModel.value[key] = 0
  }
  updateModelValue()
}
function updateModelValue() {
  const modelValue = {}
  Object.keys(checkedObjectsProps.value).forEach((key) => {
    if (checkedObjectsProps.value[key] && utils.isDefined(objectPropsModel.value[key])) {
      modelValue[key] = objectPropsModel.value[key]
    }
  })
  emit('updatedObjectPropsModel', modelValue)
}
function getAvailableAssets(objs: any) {
  const availableImageTypes: Record<string, any > = []
  let commonKeys: string[] = []
  let allObjsHaveImages = true
  for (let i = 0; i < objs.length; i++) {
    if (objs[i].assets!.length !== 0) {
      if (i === 0) {
        commonKeys = objs[i].assets.map(asset => asset.Key)
      }
      else {
        const currentObjKeys = objs[i].assets.map(asset => asset.Key)
        commonKeys = commonKeys.filter(key => currentObjKeys.includes(key))
      }
    }
    else {
      allObjsHaveImages = false
      commonKeys = []
      break
    }
  }
  if (allObjsHaveImages) {
    availableImageTypes.push(...merchConstants.defaultImageType)
  }
  if (commonKeys.length) {
    objs[0].assets.forEach((asset) => {
      if (commonKeys.includes(asset.Key)) {
        availableImageTypes.push({ key: asset.Key, value: asset.Key })
      }
    })
  }
  return availableImageTypes
}
function onOpenSelection(e: PointerEvent | MouseEvent, type: MbObjectPropType) {
  // const rect = props.merch!.canva
  // const x = e.clientX - rect.left
  // const y = e.clientY - rect.top
  dropdownVisible.value = dropdownVisible.value === type ? '' : type
  // todo need to fix the position
  propDropdownPos.value = { x: toolbarPos.value.x + 74, y: toolbarPos.value.y + toolbarSize.height.value + 10 }
}

function onSelectedObjectSubMenuClick(action: string) {
  const activeObject = props.merch!.canvas.getActiveObject()
  if (activeObject === null) { return }

  let objects
  const objectsKey = '_objects'
  if (activeObject.type === 'activeSelection') {
    objects = activeObject[objectsKey]
  }
  else {
    objects = [activeObject]
  }
  switch (action) {
    case 'delete':
      props.merch!.removeObjects(objects, true)
      break
    case 'selectSimilar': {
      props.merch!.getSimilarObjects(objects)
      break
    }
    case 'bringFront':
      activeObject.bringToFront()
      dropdownVisible.value = ''
      break
    case 'sendBack':
      activeObject.sendToBack()
      dropdownVisible.value = ''
      break
    case 'unGroup':
      props.merch!.ungroupObject(activeObject, true)
      break
    case 'group':
      props.merch!.groupObjects(objects, {}, true)
      break
    case 'copy':
      props.merch!.copySelectedObjects()
      dropdownVisible.value = ''
      break
    case 'align':
      dropdownVisible.value = ''
      emit('showAlignDialog')
      break
    case 'updateArticle':
      dropdownVisible.value = ''
      emit('updateArticles', objects)
      break
    case 'updateArticleSegmentation':
      dropdownVisible.value = ''
      emit('updateArticleSegmentation', objects)
      break
    case 'updateRetailWindow':
      dropdownVisible.value = ''
      emit('updateRetailWindow', objects)
      break
    case 'editFavorites':
      dropdownVisible.value = ''
      emit('editFavorites', objects)
      break
  }
}

function onAddArticleDetails(position) {
  dropdownVisible.value = ''
  const objs = props.merch?.canvas.getActiveObjects()
  props.merch!.canvas.discardActiveObject()
  const addedObjects: IMbObject[] = []
  let customProps = { articleProps: ['ArticleNumber', 'ModelName'] }
  if (userStore.activeCatalog && userStore.activeCatalog.Config.MerchDefaultArticleDetailsAttributes.length) {
    customProps = { articleProps: userStore.activeCatalog.Config.MerchDefaultArticleDetailsAttributes }
  }
  objs?.forEach(async (o: any) => {
    let articleDetails: MbArticleDetails | null = null
    if (o.type === merchConstants.objectTypes.articleImage) {
      const opt = { left: o.aCoords.bl.x, top: o.aCoords.bl.y, showLabels: true, customOptions: customProps }
      if (o.articleId) {
        articleDetails = await MbArticleDetails.loadArticleDetailsById(o.articleId, o.objectId, o.isRequest, opt)
        if (position !== '') {
          articleDetails = MbArticleDetails.updateTextPosition(position, articleDetails, o)
        }
      }
    }
    if (articleDetails) {
      // set article details object height otherwise it will take random height and while selection unneccesary white space is selected
      // articleDetails.setProp('height', 52)
      props.merch?.addObjects([articleDetails], true)
      addedObjects.push(articleDetails as IMbObject)
    }
    if (addArticleDetailsGroupProp.value) {
      props.merch!.groupObjects([o, articleDetails])
    }
  })
  if (objs?.length) {
    const selection = new fabric.ActiveSelection(addedObjects, { canvas: props.merch!.canvas })
    props.merch!.canvas.setActiveObject(selection)
    props.merch?.canvas.requestRenderAll()
  }
}

// function onAddComment(e: PointerEvent | MouseEvent) {
//   dropdownVisible.value = ''
//   const opt = { left: 0, top: 0 }
//   const objs = props.whiteboard?.canvas.getActiveObjects()
//   props.whiteboard!.canvas.discardActiveObject()
//   objs?.forEach(async (o: any) => {
//     if (o.type !== whiteboardConstants.objectTypes.discussion) {
//       opt.left = (o.aCoords.tl.x + ((o.width * o.scaleX) / 2))
//       opt.top = (o.aCoords.tl.y + ((o.height * o.scaleY) / 2))
//       const commentIcon = new WbDiscussionIcon(opt)
//       commentIcon.bringToFront()
//       if (commentIcon) {
//         props.whiteboard?.addObjects([commentIcon], true)
//         // NOTE: As discussed with saad currently not going to select the comment after adding the comment, whenuser select then comment then its dropdown will be displayed
//         // props.whiteboard?.canvas.setActiveObject(commentIcon)
//       }
//     }
//   })
//   if (objs?.length) {
//     props.whiteboard?.canvas.requestRenderAll()
//     // propDropdownPos.value = { x: opt.left + 30, y: opt.top - 30 }
//   }
// }

function onBorderPropChange(color: string, thickness: number) {
  const objs = props.merch?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('border', { borderColor: color, strokeWidth: thickness })
  })
  borderPropColor.value = color
}

function onColorPropChange(color: string) {
  const objs = props.merch?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('backgroundColor', { backgroundColor: color })
  })
  colorProp.value = color
}

function onFontPropChange(font: string) {
  const objs = props.merch?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('font', { font })
  })
  fontProp.value = font
}

function onFontSizePropChange(fontSize: string) {
  const objs = props.merch?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('fontSize', { fontSize })
  })
  fontSizeProp.value = fontSize
}

function onTextColorPropChange(textColor: string) {
  const objs = props.merch?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('fill', { fill: textColor })
  })
  textColorProp.value = textColor
}

function onTextAlignPropChange(textAlign: string) {
  const objs = props.merch?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('textAlign', { textAlign })
  })
  textAlignProp.value = textAlign
}

function onBoldPropChange(bold: boolean) {
  const objs = props.merch?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('bold', { bold })
  })
  boldProp.value = bold
}

function onItalicPropChange(italic: boolean) {
  const objs = props.merch?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('italic', { italic })
  })
  italicProp.value = italic
}

function onSizePropChange(size: string) {
  const objs = props.merch?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('size', { size })
  })
  sizeProp.value = size
}

function onLockPropChange(lock: boolean) {
  const objs = props.merch?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('locked', { locked: lock })
  })
  lockProp.value = lock
}

function onAssetPropChange(asset: Record<string, any>) {
  const objs = props.merch?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('imageType', { assetKey: asset.key })
  })
  assetKeyProp.value = asset.value
}

function onScalePropChange(scale: number) {
  const objs = props.merch?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('scale', { scale })
  })
  scaleProp.value = scale
}

function onShowLabelsPropChange(showLabels: boolean) {
  const objs = props.merch?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('showLabels', { showLabels })
  })
  showLabelsProp.value = showLabels
}

function onAttributePropChange(attributes: string[]) {
  const objs = props.merch?.canvas.getActiveObjects()
  objs?.forEach((o) => {
    (o as any).setProp('attributes', { attributes })
  })
}
</script>
