<template>
  <div
    v-if="visible" class="h-[85px] m-h-[85px] bg-gray-100 border-b border-gray-300 flex flex-row items-center"
    :style="{ minWidth: `${fullWidth}px` }"
  >
    <!-- Header Checkbox -->
    <div class="sticky left-0 z-10 p-3 bg-gray-100 w-9">
      &nbsp;
    </div>
    <!-- Header Cell -->
    <div
      v-for="col in visibleColumns" :key="col.property"
      class="flex flex-row items-center px-1 py-2 overflow-hidden text-sm text-left align-middle bg-gray-100"
      :style="{ width: `${col.width}px` }"
    >
      <div class="w-full overflow-hidden">
        <div v-if="indexedColumns.get(col.property) && [AttributeType.Date, AttributeType.DateTime, AttributeType.TimeAgo].indexOf(indexedColumns.get(col.property)!.type) >= 0" class="flex items-center">
          <div height="52px" @click="openDateRangeFilter(col)">
            <tx-input v-model="dateRangeFilterValue" type="text" :value="getDateRangeValue(col)" faicon="fa-light fa-calendar" />
          </div>
          <tx-button
            v-if="dateRangeFilterValue !== ''" type="icon" faicon="fa-light fa-xmark" class="shrink-0" :title="t('dataTable.advanceFilter.clearFilter')" width="24px"
            height="24px" icon-size="15px" @click="clearFilterCriteria(col)"
          />
        </div>
        <tx-select
          v-else-if="indexedColumns.get(col.property)?.filterLookup && indexedColumns.get(col.property)!.filterLookup!.size > 0 && indexedColumns.get(col.property)?.type === AttributeType.Status"
          v-model="localFilterCriteria[col.property].statusVal" :sort-list="false"
          :data="Array.from(indexedColumns.get(col.property)?.filterLookup!, ([key, label]) => ({ key, label }))" value-prop="key" display-prop="label" filterable
          clearable @change="doChange"
        />
        <tx-select
          v-else-if="indexedColumns.get(col.property)?.filterLookup && indexedColumns.get(col.property)!.filterLookup!.size > 0"
          v-model="localFilterCriteria[col.property].multipleVals"
          :data="Array.from(indexedColumns.get(col.property)?.filterLookup!, ([key, label]) => ({ key, label }))" value-prop="key" display-prop="label" filterable multiple-values
          clearable @change="doChange"
        />
        <div v-else-if="indexedColumns.get(col.property)?.type === AttributeType.Int || indexedColumns.get(col.property)?.type === AttributeType.Decimal" class="flex items-center">
          <div v-if="showAdvancedFilterButton(col)" class="flex items-center">
            <tx-button
              type="confirm" :text="t('dataTable.advanceFilter.advancedFilter')" width="115px" height="30px"
              @click="openAdvanceFilter(col)"
            />
            <tx-button
              type="icon" faicon="fa-light fa-xmark" class="shrink-0" :title="t('dataTable.advanceFilter.clearFilter')" width="24px"
              height="24px" icon-size="15px" @click="clearFilterCriteria(col)"
            />
          </div>
          <div v-else class="flex items-center">
            <tx-input v-model="localFilterCriteria[col.property].numberVal" type="number" clearable @change="doChange" />
            <tx-button
              type="icon" faicon="fa-light fa-filter-list" class="shrink-0" :title="t('dataTable.advanceFilter.advancedFilter')" width="24px"
              height="24px" icon-size="15px" @click="openAdvanceFilter(col)"
            />
          </div>
        </div>
        <div v-else class="flex items-center">
          <div v-if="showAdvancedFilterButton(col)" class="flex items-center">
            <tx-button
              type="confirm" :text="t('dataTable.advanceFilter.advancedFilter')" width="115px" height="30px"
              @click="openAdvanceFilter(col)"
            />
            <tx-button
              type="icon" faicon="fa-light fa-xmark" class="shrink-0" :title="t('dataTable.advanceFilter.clearFilter')" width="24px"
              height="24px" icon-size="15px" @click="clearFilterCriteria(col)"
            />
          </div>
          <div v-else class="flex items-center">
            <tx-input v-model="localFilterCriteria[col.property].stringVal" type="text" clearable @change="doChange" />
            <tx-button
              type="icon" faicon="fa-light fa-filter-list" class="shrink-0" :title="t('dataTable.advanceFilter.advancedFilter')" width="24px"
              height="24px" icon-size="15px" @click="openAdvanceFilter(col)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Header Actions -->
    <div
      class="w-14 h-[44px] sticky right-0 bg-gray-100 text-sm font-medium flex flex-row justify-start align-middle items-center p-2 text-center"
    >
      <tx-button type="icon" faicon="fa-light fa-xmark" @click="doCloseFilter()" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import TxSelect from '../TxSelect.vue'
import TxInput from '../TxInput.vue'
import TxButton from '../TxButton.vue'
import type { ITxDataTableColumn, ITxDataTableVisibleColumn } from './TxDataTable.types'
import { AttributeType } from '@/models/catalogAttribute'
import { FilterCriteria, FilterCriteriaMode } from '@/models/filterCriteria'

const props = defineProps<{
  visible: boolean
  fullWidth: number
  visibleColumns: ITxDataTableVisibleColumn[]
  indexedColumns: Map<string, ITxDataTableColumn>
  filterCriteria: Record<string, FilterCriteria>
}>()

const emits = defineEmits<{
  (e: 'update:filterCriteria', filterCriteria: Record<string, FilterCriteria>): void
  (e: 'update:visible', visible: boolean): void
  (e: 'close'): void
  (e: 'openAdvanceFilter', column: ITxDataTableVisibleColumn): void
  (e: 'openDateRangeFilter', column: ITxDataTableVisibleColumn): void
}>()

const { t } = useI18n()
let dateRangeFilterValue = ''

const localFilterCriteria = computed({
  get: () => props.filterCriteria,
  set: modelValue => emits('update:filterCriteria', modelValue),
})

function doChange() {
  emits('update:filterCriteria', localFilterCriteria.value)
}

function openAdvanceFilter(col: ITxDataTableVisibleColumn) {
  emits('openAdvanceFilter', col)
}

function openDateRangeFilter(col: ITxDataTableVisibleColumn) {
  emits('openDateRangeFilter', col)
}

function getDateRangeValue(col: ITxDataTableVisibleColumn) {
  if (props.filterCriteria[col.property] && props.filterCriteria[col.property].multipleVals) {
    const startDate = new Date(props.filterCriteria[col.property].multipleVals![0])
    const endDate = new Date(props.filterCriteria[col.property].multipleVals![1])
    if (startDate.toString() === 'Invalid Date' && endDate.toString() !== 'Invalid Date') {
      dateRangeFilterValue = ` - ${endDate.toLocaleDateString()}`
    }
    else if (endDate.toString() === 'Invalid Date' && startDate.toString() !== 'Invalid Date') {
      dateRangeFilterValue = `${startDate.toLocaleDateString()} - `
    }
    else if (startDate.toString() !== 'Invalid Date' && endDate.toString() !== 'Invalid Date') {
      dateRangeFilterValue = `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
    }
    else {
      dateRangeFilterValue = ''
    }
  }
  else {
    dateRangeFilterValue = ''
  }
}

function showAdvancedFilterButton(col: ITxDataTableVisibleColumn) {
  return props.filterCriteria[col.property].isAdvancedFilter
}

function clearFilterCriteria(col: ITxDataTableVisibleColumn) {
  // clear filter criteria for the column
  localFilterCriteria.value[col.property] = new FilterCriteria({
    attribute: col.property,
    mode: FilterCriteriaMode.string,
    multipleVals: undefined,
    exclude: false,
    filterBlank: false,
    isAdvancedFilter: false,
  })
  emits('update:filterCriteria', localFilterCriteria.value)
}

function doCloseFilter() {
  emits('update:visible', false)
  emits('close')
}
</script>
