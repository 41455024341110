import { AttributeType } from '@/models/catalogAttribute'

function generateComparer(property: string, ascending: boolean, type: AttributeType, getRecordPropertyValue?: (record: any, property: string) => any) {
  if (getRecordPropertyValue == null) {
    getRecordPropertyValue = (record, property) => record[property]
  }
  switch (type) {
    case AttributeType.ArticleNumber:
    case AttributeType.LinkedCatalogArticleNumber:
    case AttributeType.Nvarchar:
    case AttributeType.Calc:
    case AttributeType.SellerModel:
    case AttributeType.SizeScale:
    case AttributeType.MasterSizeScale:
      if (ascending) {
        return function (a, b) { return getRecordPropertyValue!(a, property)?.toString().localeCompare(getRecordPropertyValue!(b, property)?.toString()) }
      }
      return function (a, b) { return getRecordPropertyValue!(b, property)?.toString().localeCompare(getRecordPropertyValue!(a, property)?.toString()) }
    case AttributeType.Bool:
    case AttributeType.Decimal:
    case AttributeType.Int:
    case AttributeType.Status:
      if (ascending) {
        return function (a, b) { return (getRecordPropertyValue!(a, property) ?? 0) - (getRecordPropertyValue!(b, property) ?? 0) }
      }
      return function (a, b) { return (getRecordPropertyValue!(b, property) ?? 0) - (getRecordPropertyValue!(a, property) ?? 0) }

    case AttributeType.Date:
    case AttributeType.DateTime:
    case AttributeType.DateOption:
    case AttributeType.TimeAgo:
      if (ascending) {
        return (a, b) => (new Date(getRecordPropertyValue!(a, property)).getTime() - new Date(getRecordPropertyValue!(b, property)).getTime())
      }
      return (a, b) => (new Date(getRecordPropertyValue!(b, property)).getTime() - new Date(getRecordPropertyValue!(a, property)).getTime())

    case AttributeType.MultiValue:
      if (ascending) {
        return function (a, b) { return (getRecordPropertyValue!(a, property)[0] ?? 0) - (getRecordPropertyValue!(b, property)[0] ?? 0) }
      }
      return function (a, b) { return (getRecordPropertyValue!(b, property)[0] ?? 0) - (getRecordPropertyValue!(a, property)[0] ?? 0) }
    default:
      return function (_a, _b) { return 0 }
  }
}

export {
  generateComparer,
}
