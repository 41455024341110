import type { CreateUpdateSavedViewModel, SavedViewItemModel, UpdateSavedViewsBulkModel, UpdateSavedViewsStatusModel } from './model/savedViewModel'
import Net from '@/services/net'

export function getMySavedViews(catalogCode: number) {
  return Net.t1.get<SavedViewItemModel[]>(`/catalogs/${catalogCode}/mysavedviews`)
}

export function getSavedViewDetails(catalogCode: number, savedViewId: number) {
  return Net.t1.get<SavedViewItemModel>(`/catalogs/${catalogCode}/savedviews/${savedViewId}`)
}

export function createSavedView(catalogCode: number, requestObject: CreateUpdateSavedViewModel) {
  return Net.t1.post<SavedViewItemModel>(`/catalogs/${catalogCode}/savedviews`, requestObject)
}

export function updateSavedView(catalogCode: number, savedViewId: number, requestObject: CreateUpdateSavedViewModel) {
  return Net.t1.post<SavedViewItemModel>(`/catalogs/${catalogCode}/savedviews/${savedViewId}`, requestObject)
}

export function updateSavedViewsBulk(catalogCode: number, requestObject: UpdateSavedViewsBulkModel[]) {
  return Net.t1.put(`/catalogs/${catalogCode}/savedviews/bulkupdate`, requestObject)
}

export function updateSavedViewsStatus(catalogCode: number, requestObject: UpdateSavedViewsStatusModel[]) {
  return Net.t1.put(`/catalogs/${catalogCode}/savedviews/status`, requestObject)
}
