class ApiResponse<T = any> {
  status: boolean
  error: any
  data: T | null

  constructor(status = true) {
    this.status = status
    this.error = null
    this.data = null
  }
}

export { ApiResponse }
