import { useI18n } from 'vue-i18n'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import type CatalogDetails from '@/models/catalogDetails'
import { useUserStore } from '@/store/userData'

export default function usePriceGroupsLabel(catalogDetailsRef: Ref<CatalogDetails | undefined> = ref(undefined)) {
  const { t } = useI18n()
  const userStore = useUserStore()
  const catalogDetails = catalogDetailsRef

  const priceGroupsLabel = computed(() => {
    let result = {
      wholesalePrice: t('priceGroupsLabel.wholesalePrice'),
      retailPrice: t('priceGroupsLabel.retailPrice'),
      outletPrice: t('priceGroupsLabel.outletPrice'),
      orderPrice: t('priceGroupsLabel.orderPrice'),
    }
    if (catalogDetails.value && catalogDetails.value.Config) {
      result = catalogDetails.value.Config.PriceGroupsLabel
    }
    else if (userStore.activeCatalog && userStore.activeCatalog.Config) {
      result = userStore.activeCatalog.Config.PriceGroupsLabel
    }
    return result
  })

  return {
    priceGroupsLabel,
  }
}
