<template>
  <tx-dialog v-model="dialogVisible" :title="t('dataTable.advanceFilter.filterBy', { displayLabel: headerDisplyLabel })" width="800px" height="550px">
    <!-- BODY -->
    <div class="w-full h-full">
      <div class="flex items-center space-x-4">
        <tx-checkbox v-model="dateRangeCheckBoxChecked" class="w-auto h-5" @change="handleChange('dateRange')" />
        <tx-date-picker v-model="formModel.startDate" auto-apply :label="t('dataTable.dateRangeFilter.startDate')" :max-date="formModel.endDate.toString()" @change="handleDateChange" />
        <tx-date-picker v-model="formModel.endDate" auto-apply :label="t('dataTable.dateRangeFilter.endDate')" :min-date="formModel.startDate.toString()" @change="handleDateChange" />
      </div>
      <div class="flex items-center pt-2 space-x-4">
        <tx-checkbox v-model="formModel.filterBlank" class="w-auto h-5" @change="handleChange('filterBlank')" />
        <label class="pr-5 text-xs tracking-wide uppercase label" v-text="t('dataTable.dateRangeFilter.filterBlank')" />
      </div>
    </div>

    <!-- FOOTER -->
    <template #footer>
      <div class="flex items-center justify-center px-6 py-4 space-x-3 sm:justify-end bg-gray-50">
        <tx-button type="confirm" :text="t('general.save')" :disabled="!isOptionSelected" width="100px" @click="doOk()" />
        <tx-button type="cancel" :text="t('general.cancel')" width="100px" @click="doCancel()" />
      </div>
    </template>
  </tx-dialog>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import TxDialog from '../TxDialog.vue'
import TxDatePicker from '../TxDatePicker.vue'
import type { ITxDataTableVisibleColumn } from './TxDataTable.types'
import TxButton from '@/shared/components/TxButton.vue'
import TxCheckbox from '@/shared/components/TxCheckbox.vue'
import { useUserStore } from '@/store/userData'
import type { FilterCriteria } from '@/models/filterCriteria'

const props = defineProps<{
  filterCriteria?: Record<string, FilterCriteria>
}>()

const emits = defineEmits<{
  (e: 'ok', columnFilterObject: Record<string, IFormItem>): void
  (e: 'cancel'): void
}>()

defineExpose({ show })

interface IFormItem {
  startDate: string | ''
  endDate: string | ''
  filterBlank: boolean | false
}

const formModel = reactive<IFormItem>({
  startDate: '',
  endDate: '',
  filterBlank: false,
})

const { t } = useI18n()
const userStore = useUserStore()
const dialogVisible = ref(false)
let attributeSystemName: string = ''
let headerDisplyLabel: string = ''
const column = ref<ITxDataTableVisibleColumn>()
const dateRangeCheckBoxChecked = ref(false)

async function show(col: ITxDataTableVisibleColumn) {
  column.value = col
  attributeSystemName = column.value.property
  if (userStore.myAttributes && userStore.myAttributes.hasOwnProperty(attributeSystemName)) {
    headerDisplyLabel = userStore.myAttributes[attributeSystemName].DisplayName
  }
  // prefill formModel if advanced filter is applied
  const columnFilterValues = props.filterCriteria?.[attributeSystemName]
  if (columnFilterValues) {
    if (columnFilterValues.multipleVals!.length) {
      formModel.startDate = columnFilterValues.multipleVals![0] ? columnFilterValues.multipleVals![0] : ''
      formModel.endDate = columnFilterValues.multipleVals![1] ? columnFilterValues.multipleVals![1] : ''
      dateRangeCheckBoxChecked.value = true
    }
    else {
      formModel.startDate = ''
      formModel.endDate = ''
      dateRangeCheckBoxChecked.value = false
    }
    formModel.filterBlank = columnFilterValues.filterBlank && !dateRangeCheckBoxChecked.value ? columnFilterValues.filterBlank : false
  }
  else {
    // clear formModel
    formModel.startDate = ''
    formModel.endDate = ''
    formModel.filterBlank = false
  }
  dialogVisible.value = true
}

const isOptionSelected = computed(() => {
  const isDateRangeSelected = dateRangeCheckBoxChecked.value
  const isStartDateSelected = formModel.startDate !== '' && formModel.startDate != null
  const isEndDateSelected = formModel.endDate !== '' && formModel.endDate != null
  const isFilterBlankSelected = formModel.filterBlank

  if (isDateRangeSelected) {
    return (isStartDateSelected || isEndDateSelected) || isFilterBlankSelected
  }
  else {
    return isStartDateSelected || isEndDateSelected || isFilterBlankSelected
  }
})

function handleChange(option: string) {
  if (option === 'filterBlank') {
    if (formModel.filterBlank) {
      dateRangeCheckBoxChecked.value = false // uncheck the daterange checkbox
    }
  }
  else if (option === 'dateRange' && dateRangeCheckBoxChecked.value) {
    formModel.filterBlank = false // uncheck the filterBlank checkbox
  }
}

// when user select a date check the daterangefilter check box
function handleDateChange() {
  dateRangeCheckBoxChecked.value = true
  formModel.filterBlank = false // setting form model value
}

function doOk() {
  dialogVisible.value = false
  const filterCriteriaObject = {}
  filterCriteriaObject[attributeSystemName] = formModel
  emits('ok', filterCriteriaObject)
}

function doCancel() {
  dialogVisible.value = false
  emits('cancel')
}
</script>
