<template>
  <button
    :disabled="disabled" class="h-10 text-base leading-4 tracking-wide text-center ripple hover:brightness-95 active:brightness-75 "
    :class="[classes, { 'cursor-wait': loading, 'cursor-not-allowed': disabled }]"
    :style="{ width: width || defaultSize.width, height: height || defaultSize.height }" @click="doClick"
  >
    <span v-if="ping" class="relative flex float-right w-2 h-2" :class="type === 'nav' ? '-mt-2' : '-mr-1'">
      <span class="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-warn" />
      <span class="relative inline-flex w-2 h-2 rounded-full bg-warn" />
    </span>
    <div v-show="!loading && !isRequest" class="flex flex-col items-center flex-1">
      <font-awesome-icon
        v-if="faicon" :icon="faicon" class="grow"
        :style="{ width: iconSize || defaultIconSize, height: iconSize || defaultIconSize }"
      />
      <div v-else-if="icon" class="h-full grow" :style="{ width: iconSize || defaultIconSize }" v-html="icon" />
      <div class="flex-grow leading-5 text-center">
        <slot>{{ text }}</slot>
      </div>
    </div>
    <div v-show="!loading && isRequest" class="flex items-center justify-center flex-1 space-x-2 px-3 py-2">
      <font-awesome-icon
        v-if="faicon" :icon="faicon"
        class="h-5 w-5"
      />
      <div class="text-center">
        <slot>{{ text }}</slot>
      </div>
    </div>
    <div v-if="loading" class="flex items-center justify-center text-white">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-5 h-full animate-spin">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
        <path
          class="opacity-75" fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface IProps {
  type?: 'confirm' | 'danger' | 'default' | 'cancel' | 'text' | 'icon' | 'nav' | 'plainIcon' | 'staticIcon'
  disabled?: boolean
  text?: string
  width?: string
  height?: string
  loading?: boolean
  active?: boolean
  faicon?: string
  icon?: string
  iconSize?: string
  ping?: boolean
  isRequest?: boolean
}
const props = withDefaults(defineProps<IProps>(), { type: 'confirm', disabled: false, text: '', loading: false, isRequest: false })

const emit = defineEmits<{
  (e: 'click', event: Event): void
}>()

const classes = computed(() => {
  switch (props.type) {
    case 'confirm':
      return `rounded-full ${props.disabled ? 'text-gray-400 bg-gray-300' : 'bg-primary text-on-primary '}`
    case 'danger':
      return `rounded-full ${props.disabled ? 'text-gray-400 bg-gray-300' : 'bg-warn text-on-primary '}`
    case 'default':
      return `rounded-full shadow-sm hover:bg-primary hover:text-on-primary ${props.disabled ? 'text-gray-400' : 'bg-gray-200 text-black'}`
    case 'cancel':
      return `rounded-full border border-form ${props.disabled ? 'text-gray-400 bg-gray-300' : 'text-default hover:bg-hover'}`
    case 'text':
      return `bg-transparent ${props.disabled ? 'text-gray-400' : 'text-primary-500'}`
    case 'icon':
      return `rounded-full text-grey-dark hover:text-default hover:bg-hover p-1 ${props.disabled ? 'opacity-40' : 'opacity-100'}`
    case 'plainIcon':
      return 'text-grey-dark hover:text-default p-1 '
    case 'staticIcon':
      return 'text-grey-dark p-1 '
    case 'nav':
      return `rounded text-default p-1 hover:bg-hover hover:opacity-100 ${props.active && !props.disabled ? 'opacity-100 bg-hover' : props.disabled ? 'opacity-40' : 'opacity-80'}`
    default:
      return ''
  }
})
const defaultSize = computed(() => {
  switch (props.type) {
    case 'confirm':
    case 'danger':
    case 'cancel':
      return !props.isRequest ? { width: '170px', height: '40px' } : { width: '100px', height: '35px' }
    case 'icon':
    case 'staticIcon':
    case 'plainIcon':
      return { width: '32px', height: '32px' }
    case 'nav':
      return { width: '48px', height: '48px' }
    case 'text':
    default:
      return { width: 'unset', height: 'unset' }
  }
})

const defaultIconSize = computed(() => {
  switch (props.type) {
    case 'nav':
      return '28px'
    default:
      if (props.faicon) {
        return !props.isRequest ? '21px' : '15px'
      }
      else {
        return '24px'
      }
  }
})
function doClick(e: Event) {
  if (props.disabled || props.loading) { return }
  emit('click', e)
}
</script>
