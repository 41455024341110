export default class LinkedCatalog {
  Id: number
  CatalogId: number
  CatalogCode: number
  CatalogName: string
  Season: string
  Status: number

  constructor(obj: LinkedCatalog) {
    this.Id = obj.Id
    this.CatalogId = obj.CatalogId
    this.CatalogCode = obj.CatalogCode
    this.CatalogName = obj.CatalogName
    this.Season = obj.Season
    this.Status = obj.Status
  }
}
