<template>
  <tx-dialog
    v-model="visible" :title="t('merch.dialog.addSlide.title')" width="450px" show-ok-cancel
    :ok-state="v$.$invalid ? 'disabled' : 'enabled'" :loading="loading" confirm-text="general.save"
    @ok="doCreate()"
  >
    <div class="w-full h-full">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      <div class="w-full h-full">
        <div
          v-for="(attribute, index) in formFields"
          :key="attribute.SystemName"
          :class="{ 'col-span-2': index === 0, 'col-span-1': index > 0 }" class="mb-5 last-of-type:mb-4"
        >
          <form-editor
            v-if="attribute.SystemName !== 'folder'"
            v-model="formModel[attribute.SystemName]"
            :attribute="attribute"
            :form="formModel"
            :required="attribute.IsRequired"
            :errors="v$[attribute.SystemName]?.$errors"
            :show-label="true"
            :clearable="true"
            @blur="v$[attribute.SystemName]?.$touch"
          />
          <div v-else>
            <parent-folder-editor v-model="selectedFolderLabel" :slide-tree="props.slideTree" :merch="props.merch" @update="updateFolderValue" />
          </div>
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, helpers, maxLength, minLength, required } from '@vuelidate/validators'
import { computed, reactive, ref } from 'vue'
import type Merch from '../services/merch'
import ParentFolderEditor from './ParentFolderEditor.vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import { appConstants, merchConstants } from '@/models/constants'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import { AttributeType } from '@/models/catalogAttribute'
import FormEditor from '@/shared/components/FormEditor.vue'

const props = defineProps<{
  slideTree: ITreeNode[]
  merch: Merch | undefined
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'create', formModel: Record<string, any>, resourceUrl: null | string): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()
const withI18nMessage = createI18nMessage({ t })

const visible = ref(false)
const loading = ref(false)
const formModel = reactive({
  template: -1,
  slide: '',
  folder: '',
  slideSize: merchConstants.slideSize.wideScreen,
  imageScaleFactor: merchConstants.slideImageDefaultScaleFactors.large,
})
const allowedSlideTemplates = ref<any[]>([])
const slideImageSizes = [
  { label: t('general.large'), value: merchConstants.slideImageDefaultScaleFactors.large },
  { label: t('general.medium'), value: merchConstants.slideImageDefaultScaleFactors.medium },
  { label: t('general.small'), value: merchConstants.slideImageDefaultScaleFactors.small },
]
const slideSizes = [
  { label: t('merch.slideSizes.A4'), value: merchConstants.slideSize.A4 },
  { label: t('merch.slideSizes.A3'), value: merchConstants.slideSize.A3 },
  { label: t('merch.slideSizes.widescreen'), value: merchConstants.slideSize.wideScreen },
]
const selectedFolderLabel = computed(() => {
  if (props.slideTree && props.slideTree.length && props.slideTree[0]?.key) {
    return props.slideTree[0].key
  }
  else {
    return ''
  }
})

const formFields = computed(() => {
  const fields: Array<IMyAttribute> = [

    Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'template',
      DisplayName: t('merch.dialog.addSlide.fields.template'),
      IsRequired: true,
      AttributeType: AttributeType.Nvarchar,
      DropDownData: allowedSlideTemplates.value,
      DropDownValueProp: 'value',
      DropDownValueDisplayProp: 'label',
    }),
    Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'folder',
      DisplayName: t('merch.dialog.addSlide.fields.folder'),
      Creatable: true,
      AttributeType: AttributeType.Nvarchar,
      IsRequired: true,
      VettingList: [],
      showNone: false,
    }),
    Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'slide',
      DisplayName: t('merch.dialog.addSlide.fields.slideName'),
      Creatable: true,
      AttributeType: AttributeType.Nvarchar,
      IsRequired: true,
    }),
    Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'slideSize',
      DisplayName: t('merch.dialog.addSlide.fields.slideSize'),
      Creatable: true,
      AttributeType: AttributeType.Nvarchar,
      IsRequired: true,
      DropDownData: slideSizes,
      DropDownValueProp: 'value',
      DropDownValueDisplayProp: 'label',
    }),
    Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'imageScaleFactor',
      DisplayName: t('merch.dialog.addSlide.fields.imageSize'),
      AttributeType: AttributeType.Nvarchar,
      IsRequired: true,
      DropDownData: slideImageSizes,
      DropDownValueProp: 'value',
      DropDownValueDisplayProp: 'label',
    }),
  ]
  return fields
})
const rules = computed(() => {
  const result: Record<string, any> = {}
  if (visible.value) {
    result.template = { required: withI18nMessage(required) }
    result.slide = { required: helpers.withMessage(t('validations.required', { property: t('merch.dialog.addSlide.fields.slideName') }), required), minLength: withI18nMessage(minLength(1)), maxLength: withI18nMessage(maxLength(250)) }
    result.imageScaleFactor = { required: withI18nMessage(required) }
    result.slideSize = { required: withI18nMessage(required) }
    result.folder = { required: helpers.withMessage(t('validations.required', { property: t('merch.dialog.addSlide.fields.folder') }), required) }
  }
  return result
})
const v$ = useVuelidate(rules, formModel)

async function init() {
  if (userStore.activeCatalog) {
    const resources = await appConfig.DB!.resources.where({ CatalogCode: userStore.activeCatalog.CatalogCode }).filter(resource => resource.ResourceType === 'T1S-SlideTemplate' && resource.Status === 1).toArray()
    allowedSlideTemplates.value.push({ label: 'Blank', value: -1, resourceUrl: null })

    resources.forEach((templateResource) => {
      allowedSlideTemplates.value.push({ label: templateResource.ResourceName, value: templateResource.ResourceId, resourceUrl: templateResource.ResourceUrl })
    })
  }
}
async function showDialog() {
  reset()
}
function updateFolderValue(modelValue: string) {
  formModel.folder = modelValue
}
async function doCreate() {
  errorMessage.value = ''
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
  }

  loading.value = true
  let resourceUrl = null
  if (formModel.template && formModel.template !== -1) {
    const selectedTemplate = allowedSlideTemplates.value.find(template => template.value === formModel.template)
    if (selectedTemplate) {
      resourceUrl = selectedTemplate.resourceUrl
    }
  }

  emit('create', formModel, resourceUrl)
}

function reset() {
  loading.value = false
  visible.value = true
  formModel.template = -1
  formModel.slide = ''
  formModel.slideSize = merchConstants.slideSize.wideScreen
  formModel.imageScaleFactor = merchConstants.slideImageDefaultScaleFactors.large
}
init()
defineExpose({
  showDialog,
  loading,
  visible,
  errorMessage,
})
</script>
