<template>
  <div
    class="flex flex-row flex-shrink h-4 pl-2 pr-1 my-1 mr-1 rounded-lg text-sm whitespace-nowrap"
    :class="{ 'cursor-pointer': closable || clickable }" :style="{ 'background-color': color }"
  >
    <div @click="doClick">
      <slot />
    </div>
    <font-awesome-icon v-if="closable" icon="fa-light fa-xmark" class="w-4 h-4 ml-0.5" @click="doClose" />
  </div>
</template>

<script setup lang="ts">
interface IProps {
  closable?: boolean
  clickable?: boolean
  color?: string
}
const props = withDefaults(defineProps<IProps>(), { closable: false, clickable: false, color: '#dbdcdc' })

const emit = defineEmits<{
  (e: 'click'): void
  (e: 'close'): void
}>()

function doClose() {
  emit('close')
}

function doClick() {
  if (props.clickable) {
    emit('click')
  }
  else if (props.closable) {
    emit('close')
  }
}
</script>
