<template>
  <div
    class="h-full w-full flex flex-row relative" :class="{ 'cursor-move': leftResizing }" @mouseup="doStopResizing"
    @mousemove="doResize" @touchend="doStopResizing"
  >
    <div
      id="leftAside" class="overflow-hidden transition-all ease-in delay-300 border-r border-form flex bg-card"
      :style="getLeftAsideStyle"
    >
      <slot name="leftAside" />
    </div>
    <div
      v-show="leftOpened" class="h-full w-1 cursor-move hover:bg-grey absolute z-splitter"
      :style="{ left: `${splitterLeft}px` }" :class="{ 'bg-grey': leftResizing }" @mousedown="doStartLeftResizing"
    />
    <div class="transition-all ease-in delay-300 grow flex flex-col w-px">
      <slot />
    </div>
    <div
      v-show="rightOpened" class="h-full w-1 cursor-move hover:bg-grey absolute z-splitter"
      :style="{ right: `${splitterRight}px` }" :class="{ 'bg-grey': rightResizing }" @mousedown="doStartRightResizing"
    />
    <div
      id="rightAside" class="overflow-hidden transition-all ease-in delay-300 border-l border-form flex bg-card"
      :style="getRightAsideStyle"
    >
      <slot name="rightAside" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useThrottleFn } from '@vueuse/core'
import { computed, ref } from 'vue'

interface IProps {
  leftWidth?: number
  rightWidth?: number
  leftOpened?: boolean
  rightOpened?: boolean
}
const props = withDefaults(defineProps<IProps>(), { leftWidth: 260, rightWidth: 220, leftOpened: true, rightOpened: true })

const leftResizing = ref(false)
const splitterLeft = ref(props.leftWidth)
const internalLeftWidth = ref(props.leftWidth)
const rightResizing = ref(false)
const splitterRight = ref(props.rightWidth)
const internalRightWidth = ref(props.rightWidth)

let startX = 0
let leftMoved = false
let startY = 0
let rightMoved = false

const getLeftAsideStyle = computed(() => {
  return {
    width: props.leftOpened ? `${internalLeftWidth.value}px` : '0',
    left: '0',
    right: 'unset',
  }
})
const getRightAsideStyle = computed(() => {
  return {
    width: props.rightOpened ? `${internalRightWidth.value}px` : '0',
    left: 'unset',
    right: '0',
  }
})
function doStartLeftResizing(e: MouseEvent) {
  startX = e.clientX
  leftResizing.value = true
}
function doStartRightResizing(e: MouseEvent) {
  startY = e.clientY
  rightResizing.value = true
}
function doStopResizing() {
  leftResizing.value = false
  rightResizing.value = false
  if (leftMoved) {
    internalLeftWidth.value = splitterLeft.value
  }
  leftMoved = false
  if (rightMoved) {
    internalRightWidth.value = splitterRight.value
  }
  rightMoved = false
}

const doResize = useThrottleFn((e: MouseEvent) => {
  if (leftResizing.value && e.currentTarget) {
    splitterLeft.value += (e.clientX - startX)
    startX = e.clientX
    leftMoved = true
  }
  else if (rightResizing.value && e.currentTarget) {
    splitterRight.value += (e.clientY - startY)
    startY = e.clientY
    rightMoved = true
  }
}, 100)
</script>
