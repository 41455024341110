<template>
  <div v-if="needRefresh" class="pwa-toast" role="alert">
    <div class="message">
      <span>New content available, click on reload button to update.</span>
    </div>
    <button @click="doRefresh">
      Reload
    </button>
    <button @click="close">
      Close
    </button>
  </div>
</template>

<script lang="ts" setup>
import { useRegisterSW } from 'virtual:pwa-register/vue'

const {
  needRefresh,
  updateServiceWorker,
} = useRegisterSW()

function doRefresh() {
  console.log('refreshing')
  updateServiceWorker(true)
    .then(() => console.log('refreshed'))
    .catch(e => console.error(e))
    .finally(() => window.location.reload())
}

async function close() {
  needRefresh.value = false
}
</script>

<style>
.pwa-toast {
  background-color: #ffffff;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px;
  border: 1px solid #8885;
  border-radius: 4px;
  z-index: 1;
  text-align: left;
  box-shadow: 3px 4px 5px 0px #8885;
}

.pwa-toast .message {
  margin-bottom: 8px;
}

.pwa-toast button {
  border: 1px solid #8885;
  outline: none;
  margin-right: 5px;
  border-radius: 2px;
  padding: 3px 10px;
}
</style>
