import { useI18n } from 'vue-i18n'
import type { ComputedRef } from 'vue'
import { computed, ref, watch } from 'vue'
import type MyArticle from '@/models/myArticle'
import { appConstants, privileges } from '@/models/constants'
import useDisableModule from '@/shared/composables/disableModule'
import { useUserStore } from '@/store/userData'
import utils from '@/services/utils'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import type Article from '@/models/article'

export default function useArticleActions(article: ComputedRef<MyArticle>) {
  const { t } = useI18n()
  const userStore = useUserStore()
  const { isResourcesModuleEnabled, isForecastModuleEnabled } = useDisableModule()
  const { getArticlesMaxStateDetails, getArticlesLocked } = useArticleFormHelper()
  const doesArticleLockedDueToAnyModelArticle = ref(false)
  getArticlesLocked([article.value], !!userStore.activeCatalog!.IsPriceByStyleApply).then((value) => {
    doesArticleLockedDueToAnyModelArticle.value = value
  })
  watch(article, (articleData) => {
    getArticlesLocked([articleData], !!userStore.activeCatalog!.IsPriceByStyleApply).then((value) => {
      doesArticleLockedDueToAnyModelArticle.value = value
    })
  })

  const selectedArticleAction = ref<string | null>(null)
  const enableNewStyleDetails = computed(() => userStore.activeCatalog?.Config.EnableNewStyleDetails || false)
  const currentArticleStateDetails = computed(() => getArticlesMaxStateDetails([article.value], false))
  const allArticleActions: Record<string, IKeyLabel> = {
    edit: { key: 'edit', label: t('articleDetails.actions.edit') },
    manageArticleState: { key: 'manageArticleState', label: t('articleDetails.actions.manageArticleState') },
    activate: { key: 'activate', label: t('activeOrDeactiveArticle.activate') },
    deactivate: { key: 'deactivate', label: t('activeOrDeactiveArticle.deactivate') },
    editSegmentations: { key: 'editSegmentations', label: t('articleDetails.actions.editSegmentations') },
    editRetailWindow: { key: 'editRetailWindow', label: t('articleDetails.actions.editRetailWindow') },
    editPeriod: { key: 'editPeriod', label: t('articleDetails.actions.editPeriod') },
    editSizes: { key: 'editSizes', label: t('articleDetails.actions.editSizes') },
    editPrices: { key: 'editPrices', label: t('articleDetails.actions.editPrices') },
    editArticleColor: { key: 'editArticleColor', label: t('articleDetails.actions.editArticleColor') },
    editForecasts: { key: 'editForecasts', label: t('articleDetails.actions.editForecasts') },
    addAttachments: { key: 'addAttachments', label: t('articleDetails.actions.addAttachments') },
    assignResources: { key: 'assignResources', label: t('articleDetails.actions.assignResources') },
    editRelations: { key: 'editRelations', label: t('articleDetails.actions.editRelations') },
    assignColor: { key: 'assignColor', label: t('articleDetails.actions.assignColor') },
    editParentModel: { key: 'editParentModel', label: t('articleDetails.actions.editParentModel') },
    overwriteColorCode: { key: 'overwriteColorCode', label: t('articleDetails.actions.overwriteColorCode') },
    assortArticles: { key: 'assortArticles', label: t('articleDetails.actions.assortArticles') },
    approve: { key: 'approve', label: t('articleDetails.actions.approve') },
    reject: { key: 'reject', label: t('articleDetails.actions.reject') },
    confirm: { key: 'confirm', label: t('articleDetails.actions.confirm') },
    lock: { key: 'lock', label: t('articleDetails.actions.lock') },
    unlock: { key: 'unlock', label: t('articleDetails.actions.unlock') },
  }
  const getArticleActions = computed(() => {
    const res = [] as Array<IKeyLabel>
    // hide edit action for new model screen
    if (article.value._IsRequestArticle) {
      // if (userStore.userProfile.isValidPrivilege(privileges.request.approveRequest) && (article.value._RequestState === requestConstants.requestStates.new || article.value._RequestState === requestConstants.requestStates.reject)) {
      //   res.push(allArticleActions.approve)
      // }
      // if (userStore.userProfile.isValidPrivilege(privileges.request.confirmRequest) && article.value._RequestState === requestConstants.requestStates.approve) {
      //   res.push(allArticleActions.confirm)
      // }
      // if (userStore.userProfile.isValidPrivilege(privileges.request.rejectRequest) && (article.value._RequestState === requestConstants.requestStates.new || article.value._RequestState === requestConstants.requestStates.approve)) {
      //   res.push(allArticleActions.reject)
      // }
    }
    else {
      if (userStore.userProfile.isValidPrivilege(privileges.article.update) && !enableNewStyleDetails.value && article.value.Status !== 2 && article.value.Status !== 3
        && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)) { // allow updating inactive articles
        res.push(allArticleActions.edit)
      }
      if (userStore.userProfile.isValidPrivilege(privileges.article.AssignArticleStateToArticles) && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        res.push(allArticleActions.manageArticleState)
      }
      if (userStore.userProfile.isValidPrivilege(privileges.article.updateStatus) && article.value.Status !== 2 && article.value.Status !== 3 && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        res.push((article.value.Status === 0) ? allArticleActions.activate : allArticleActions.deactivate)
      }
      if (userStore.userProfile.isValidPrivilege(privileges.article.updateSegmentation) && article.value.Status === 1 && !utils.isDefined(userStore.currentCustomer) && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        res.push(allArticleActions.editSegmentations)
      }
      // if (userStore.userProfile.isValidPrivilege(privileges.article.updateRetailWindow) && userStore.activeCatalog?.IsShippingWindowApply &&
      //   (userStore.activeCatalog.DataSourceTypeId === appConstants.catalogTypes.master || article.AllowRetailWindowUpdate)) {
      //   res.push(allArticleActions.editRetailWindow)
      // }
      if (userStore.userProfile.isValidPrivilege(privileges.article.updateRetailWindow) && userStore.activeCatalog?.IsShippingWindowApply
        && (userStore.activeCatalog.DataSourceTypeId === appConstants.catalogTypes.master || article.value.AllowRetailWindowUpdate) && article.value.Status === 1
        && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        res.push(allArticleActions.editPeriod)
      }
      if (userStore.activeCatalog!.DataSourceTypeId !== appConstants.catalogTypes.inherited && userStore.userProfile.isValidPrivilege(privileges.article.updateSizeScale) && userStore.sellerDetails?.IsSizeScaleByStyle && article.value.Status !== 2 && article.value.Status !== 3
        && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        res.push(allArticleActions.editSizes)
      }
      if (userStore.userProfile.isValidPrivilege(privileges.article.update) && userStore.activeCatalog!.DataSourceTypeId !== appConstants.catalogTypes.inherited
        && (article.value.ColorId === null || article.value.ColorId === 0) && userStore.sellerDetails?.AutomaticNumbering === 1 && article.value.Status === 1
        && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        res.push(allArticleActions.assignColor)
      }
      if (((userStore.userProfile.isValidPrivilege(privileges.article.UpdateCatalogModelPrices)) || (userStore.userProfile.isValidPrivilege(privileges.article.UpdateCatalogArticleBulkPrices))) && article.value.Status === 1 && !doesArticleLockedDueToAnyModelArticle.value
        && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        res.push(allArticleActions.editPrices)
      }
      // res.push(allArticleActions.editArticleColor)
      if (isForecastModuleEnabled.value && userStore.userProfile.isValidPrivilege(privileges.article.updateForecast) && article.value.Status === 1
        && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        res.push(allArticleActions.editForecasts)
      }
      // TODO: Need to check by using customer segmentation
      // if(props.article.value.Status === 1) {
      //   res.push(allArticleActions.addAttachments)
      // }
      if (isResourcesModuleEnabled.value && article.value.Status === 1 && (userStore.userProfile.isValidPrivilege(privileges.resource.create) || userStore.userProfile.isValidPrivilege(privileges.resource.assign))
        && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        res.push(allArticleActions.assignResources)
      }
      if ((userStore.userProfile.isValidPrivilege(privileges.objectRelation.create) || userStore.userProfile.isValidPrivilege(privileges.objectRelation.delete)) && article.value.Status === 1) {
        res.push(allArticleActions.editRelations)
      }
      if (userStore.userProfile.isValidPrivilege(privileges.article.updateParentModel) && userStore.activeCatalog?.DataSourceTypeId === appConstants.catalogTypes.master && (userStore.myAttributes && utils.isDefined(userStore.myAttributes.parent_model_type)) && article.value.Status === 1
        && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        res.push(allArticleActions.editParentModel)
      }
      const articleCurrentArticleState = currentArticleStateDetails.value
      const skipRestrictingPropertyUpdateBasedOnArticleState: boolean = userStore.userProfile.Permissions.has('SpecialPermissionToIgnoreArticleState')
      const isArticleNumberLockedBasedOnArticleState = !skipRestrictingPropertyUpdateBasedOnArticleState
        && articleCurrentArticleState != null && !articleCurrentArticleState.IsArticleNumberEditable
      if (userStore.userProfile.isValidPrivilege(privileges.article.overwriteColorCode) && userStore.activeCatalog!.DataSourceTypeId !== appConstants.catalogTypes.inherited && !isArticleNumberLockedBasedOnArticleState
        && (article.value.ColorId != null && article.value.ColorId !== 0) && userStore.sellerDetails?.AutomaticNumbering === 1 && article.value.Status === 1
        && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        res.push(allArticleActions.overwriteColorCode)
      }
      if (userStore.userProfile.isValidPrivilege(privileges.article.updateStatus) && userStore.userProfile.isValidPrivilege(privileges.article.createModelWithArticles) && userStore.activeCatalog?.DataSourceTypeId === appConstants.catalogTypes.inherited && article.value.Status === 2
        && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        res.push(allArticleActions.assortArticles)
      }
      if (userStore.activeCatalog?.ArticleLocking === 1 && article.value.Status === 1 && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        if (article.value.Locked === 0 && userStore.userProfile.isValidPrivilege(privileges.article.lockArticles)) {
          res.push(allArticleActions.lock)
        }
        else if (article.value.Locked === 1 && userStore.userProfile.isValidPrivilege(privileges.article.unlockArticles)) {
          res.push(allArticleActions.unlock)
        }
      }
    }
    return res
  })

  function getUpdatedMessage(articles: MyArticle[] | Article[], type: string = '') {
    let message = t('general.updatedSuccessfully')

    if (selectedArticleAction.value === allArticleActions.edit.key) {
      message = t('updateArticle.updateSuccessfully', articles.length)
    }
    else if (selectedArticleAction.value === allArticleActions.activate.key) {
      message = t('activeOrDeactiveArticle.activateSuccessfully', articles.length)
    }
    else if (selectedArticleAction.value === allArticleActions.deactivate.key) {
      message = t('activeOrDeactiveArticle.deactivateSuccessfully', articles.length)
    }
    else if (selectedArticleAction.value === allArticleActions.lock.key) {
      message = t('lockOrUnlockArticle.lockSuccessfully', articles.length)
    }
    else if (selectedArticleAction.value === allArticleActions.unlock.key) {
      message = t('lockOrUnlockArticle.unlockSuccessfully', articles.length)
    }
    else if (selectedArticleAction.value === allArticleActions.editSegmentations.key) {
      message = t('editSegmentations.updatedSuccessfully')
    }
    else if (selectedArticleAction.value === allArticleActions.manageArticleState.key) {
      message = t('manageArticleState.updatedSuccessfully')
    }
    else if (selectedArticleAction.value === allArticleActions.editRetailWindow.key) {
      message = t('updateArticleRetailWindow.saveSuccessfully')
    }
    else if (selectedArticleAction.value === allArticleActions.editPeriod.key) {
      message = t('updateArticleRetailWindow.saveSuccessfully')
    }
    else if (selectedArticleAction.value === allArticleActions.editSizes.key) {
      message = t('editSizes.updatedSuccessfully')
    }
    else if (selectedArticleAction.value === allArticleActions.editPrices.key) {
      message = t('editPrices.updatedSuccessfully')
    }
    else if (selectedArticleAction.value === allArticleActions.assignColor.key) {
      message = t('assignColor.colorAssignSuccessfully')
    }
    else if (selectedArticleAction.value === allArticleActions.editForecasts.key) {
      message = t('updateArticleForecast.updatedSuccessfully')
    }
    else if (selectedArticleAction.value === allArticleActions.assignResources.key) {
      message = type === 'create' ? t('createResourceForm.resourceAddedSuccessfully') : t('assignResourceForm.resourceAssignedSuccessfully')
    }
    else if (selectedArticleAction.value === allArticleActions.editParentModel.key) {
      message = t('editParentModel.updatedSuccessfully')
    }
    else if (selectedArticleAction.value === allArticleActions.assortArticles.key) {
      message = t('assortArticles.articlesAssortedSuccessfully')
    }

    return message
  }

  return {
    selectedArticleAction,
    allArticleActions,
    getArticleActions,
    getUpdatedMessage,
  }
}
