import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import { merchConstants } from '@/models/constants'

interface IMbGroupOptions extends fabric.IGroupOptions {
  id?: string
  locked?: boolean
  templateObject?: boolean
}
const editableProps: Record<string, IMbObjectProp> = {
  locked: { name: 'lock', type: 'lock' },
  // addDiscussion: { name: 'add comment', type: 'addDiscussion' },
}
export default class MbGroup extends fabric.Group implements IMbObject {
  public id: string
  public type = merchConstants.objectTypes.group
  public locked: boolean
  public templateObject: boolean
  public editableProps: Record<string, IMbObjectProp> = editableProps
  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    unGroup: { action: 'unGroup', label: 'Ungroup', faicon: 'fa-light fa-object-ungroup', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    copy: { action: 'copy', label: 'Copy Selection', faicon: 'fa-light fa-copy', showInSubMenu: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  constructor(objects, opt?: IMbGroupOptions) {
    super(objects, opt)
    this.id = opt?.id || guid()
    this.locked = opt?.locked || false
    this.originX = 'center'
    this.objectCaching = false
    this.originY = 'center'
    this.templateObject = opt?.templateObject || false
    this.setLock(this.locked)
    this.stateProperties = this.stateProperties?.concat(['locked'])
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'locked':
        this.set('locked', value.locked)
        this.setLock(value.locked)
        break
      default:
        console.warn('Attempting to set unsupported MbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'locked':
        result.lock = this.locked
        break
      default:
        console.warn('Attempting to get unsupported MbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject() {
    return super.toObject(['id'])
  }

  static fromObject(object: fabric.Object, callback?: Function) {
    return fabric.Object._fromObject(merchConstants.objectTypes.group, object, callback) as MbGroup
  }

  static getEditableProps() {
    return editableProps
  }
}

const f: any = fabric
f.MbGroup = MbGroup
