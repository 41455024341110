import type { IProfileSettings } from '@/api/t1/model/userModel'
import { updateUserProfile } from '@/api/t1/user'
import type { ITxDataTableColumn, ITxDataTableVisibleColumn } from '@/shared/components/txDataTable/TxDataTable.types'
import { useUserStore } from '@/store/userData'

export default function useUserProfileSettings() {
  const userStore = useUserStore()
  const settings: IProfileSettings = userStore.userProfile.Settings
  const saveColumnsIntervalDurationMilli = 5000
  let timeoutId: number | null = null
  const catalogsQueueSize = 5

  // being called when user changes table column layout and to persist the columns layout in database
  function persistListViewLayout(isCatalogSpecificModel: boolean, modelPath: string, columnWidthRatio: Record<string, number>, totalTableColumnWidth: number, isResizing: boolean = false) {
    if (isCatalogSpecificModel) {
      const isIdenticalLayout = persistColumnsAndManageQueue(modelPath, columnWidthRatio, totalTableColumnWidth, isResizing)
      if (isIdenticalLayout) {
        return
      }
    }
    else {
      if (!isResizing && settings.persistedColumns!.listView[modelPath]
        && isIdenticalArrays(Object.keys(settings.persistedColumns!.listView[modelPath]), [columnWidthRatio])) {
        return
      }

      settings.persistedColumns!.listView[modelPath] = {
        ...columnWidthRatio,
        totalTableColumnWidth,
      }
    }

    if (timeoutId) { clearTimeout(timeoutId) }
    timeoutId = window.setTimeout(saveSettings, saveColumnsIntervalDurationMilli)
  }

  // this method will retrieve persisted default colums for model if existed and apply that on model
  function applyPersistedListViewLayout(isCatalogSpecificModel: boolean, modelPath: string, columns: ITxDataTableColumn[], defaultVisibleColumns: ITxDataTableVisibleColumn[]) {
    if (!modelPath) {
      console.warn('Unable to retrieve columns, modelPath for this module is undefined')
      return { columns: [], totalTableColumnWidth: null }
    }

    let persistedColumns: ITxDataTableVisibleColumn[] = defaultVisibleColumns
    let totalTableColumnWidth: number | null = null

    if (isCatalogSpecificModel) {
      const activeCatalogCode = userStore.activeCatalog!.CatalogCode
      const persistedCatalogColumns = settings && settings.persistedColumns && settings.persistedColumns!.listView && settings.persistedColumns!.listView[modelPath]?.[activeCatalogCode]

      if (persistedCatalogColumns && !Array.isArray(persistedCatalogColumns)) {
        persistedColumns = filterPersistedColumns(persistedCatalogColumns, columns)
        totalTableColumnWidth = persistedCatalogColumns.totalTableColumnWidth || null
      }
    }
    else {
      const persistedModelColumns = settings && settings.persistedColumns && settings.persistedColumns!.listView && settings.persistedColumns!.listView[modelPath]

      if (persistedModelColumns && !Array.isArray(persistedModelColumns)) {
        persistedColumns = filterPersistedColumns(persistedModelColumns, columns)
        totalTableColumnWidth = persistedModelColumns.totalTableColumnWidth || null
      }
    }

    return { persistedColumns, totalTableColumnWidth }
  }

  // this method will set the persisted columns width and return the columns in the persisted order
  function filterPersistedColumns(persisted: Record<string, number>, columns: ITxDataTableColumn[]) {
    const columnOrderMap = Object.keys(persisted).reduce((map, key, index) => {
      map.set(key, index)
      return map
    }, new Map<string, number>())

    return columns
      .filter(column => column.property in persisted)
      .map(column => ({
        property: column.property,
        order: 1,
        width: persisted[column.property],
      }))
      .sort((a, b) => (columnOrderMap.get(a.property) ?? 0) - (columnOrderMap.get(b.property) ?? 0))
  }

  function getSettings() {
    return settings
  }

  function saveSettings() {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    const userProfile: any = { ...userStore.userProfile }
    // removing below propeties from request
    delete userProfile.Email
    delete userProfile.Permissions

    // send settings as JSON to API
    const userProfileForApi = { ...userProfile, Settings: JSON.stringify(settings) }

    updateUserProfile(userProfileForApi).then(() => {
      userStore.userProfile.Settings = settings
    })
  }

  /**
   * @description
   * this method being called by persistListViewLayout when it needs to persist columns per catalog
   * this method will create object for modelPath which contain per catalog columns list, an array(queue) to track last 'catalogsQueueSize' number
   * of module's columns that modelPath needs to have in settings (simulate circular queue functionality) and queueRearIndex is an integer indicating
   * rear index of queue where data last updated on
   * this method make sure there wont be more than 'catalogsQueueSize' number of column list per catalogSpecific module track by virtual queue
   */
  function persistColumnsAndManageQueue(modelPath: string, columnWidthRatio: Record<string, number>, totalTableColumnWidth: number, isResizing: boolean) {
    let rearIndex = -1

    if (userStore.activeCatalog!.CatalogCode) {
      if (!settings.persistedColumns!.listView[modelPath]) {
        settings.persistedColumns!.listView[modelPath] = { queue: [] }
        rearIndex = 0
      }
      else {
        rearIndex = (settings.persistedColumns!.listView[modelPath].queueRearIndex + 1) % catalogsQueueSize
      }

      if (!isResizing && settings.persistedColumns!.listView[modelPath][userStore.activeCatalog!.CatalogCode]
        && isIdenticalArrays(Object.keys(settings.persistedColumns!.listView[modelPath][userStore.activeCatalog!.CatalogCode]), [columnWidthRatio])) {
        return 1
      }

      const catalogIndex = settings.persistedColumns!.listView[modelPath].queue.indexOf(userStore.activeCatalog!.CatalogCode)

      if (catalogIndex === -1) {
        const catalogCodeAtRearIndex = settings.persistedColumns!.listView[modelPath].queue[rearIndex]
        if (catalogCodeAtRearIndex) {
          delete settings.persistedColumns!.listView[modelPath][catalogCodeAtRearIndex]
        }
        settings.persistedColumns!.listView[modelPath].queue.splice(rearIndex, 1, userStore.activeCatalog!.CatalogCode)
        settings.persistedColumns!.listView[modelPath].queueRearIndex = rearIndex
      }
      else {
        settings.persistedColumns!.listView[modelPath].queue.splice(catalogIndex, 1)
        settings.persistedColumns!.listView[modelPath].queue.push(userStore.activeCatalog!.CatalogCode)
      }

      settings.persistedColumns!.listView[modelPath][userStore.activeCatalog!.CatalogCode] = {
        ...columnWidthRatio,
        totalTableColumnWidth,
      }
    }
    else {
      console.warn('Could not find any active catalog')
    }

    return 0
  }

  function isIdenticalArrays(arr1: any[], arr2: any[]): boolean {
    return JSON.stringify(arr1) === JSON.stringify(arr2)
  }

  return {
    persistListViewLayout,
    applyPersistedListViewLayout,
    getSettings,
  }
}
