<template>
  <div class="relative flex w-full h-full overflow-auto">
    <loader v-if="loading || parentLoading" />
    <div
      v-else class="grid auto-rows-fr w-full h-full gap-2"
      :class="{ 'grid-cols-2': getCharts.length > 1, 'grid-cols-1': getCharts.length === 1 }"
    >
      <div v-if="!getCharts.length" class="col-start-1 col-end-12">
        <p>{{ t('general.emptyList') }}</p>
      </div>
      <div v-for="(chart, chartIndex) in getCharts" v-else :key="chartIndex" class="p-1 m-1 rounded-lg shadow-md">
        <bar :data="chart.data" :options="chart.options" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ChartData, ChartOptions } from 'chart.js'
import { Bar } from 'vue-chartjs'
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import Loader from '@/shared/components/Loader.vue'
import type MyArticle from '@/models/myArticle'
import { getModelStats } from '@/api/t1/article'
import type { ModelStatsModel } from '@/api/t1/model/articleModel'
import { useUserStore } from '@/store/userData'

interface IChart {
  data: ChartData<'bar'>
  options: ChartOptions<'bar'>
}

const props = defineProps<{
  article: MyArticle
  parentLoading?: boolean
}>()

const { t } = useI18n()
const userStore = useUserStore()

const loading = ref(false)
const modelStats = ref<ModelStatsModel[]>([])

function getChartOptions(title: string) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: title,
      },
    },
  } as ChartOptions<'bar'>
}

const getCharts = computed(() => {
  const chartsMap = new Map<string, IChart>()

  modelStats.value.forEach((modelStat) => {
    for (const key in modelStat) {
      if (key !== 'CatalogCode' && key !== 'Season') {
        const chart = chartsMap.get(key) || { data: { labels: [] as string[], datasets: [{ label: key, data: [] as number[] }] }, options: getChartOptions(key) }
        chart.data.labels!.push(modelStat.Season)
        chart.data.datasets[0].data.push(Number(modelStat[key]))
        chartsMap.set(key, chart)
      }
    }
  })
  return Array.from(chartsMap.values())
})

function onLoad() {
  if (userStore.activeCatalog) {
    loading.value = true
    getModelStats(userStore.activeCatalog.CatalogCode, props.article.ModelId)
      .then((res) => {
        modelStats.value = res.data
      })
      .catch(e => console.error(e))
      .finally(() => {
        loading.value = false
      })
  }
}

watch(() => props.parentLoading, (val) => {
  if (val) {
    onLoad()
  }
})
</script>
