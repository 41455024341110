<template>
  <tx-dialog
    v-model="visible" :title="t('whiteboard.dynamic.properties.title')" width="600px"
    show-ok-cancel @click="doCancel()" @ok="doConfirm()"
  >
    <div class="w-full h-full">
      <!-- Group By -->
      <div class="text-md">
        {{ t('whiteboard.dynamic.properties.groupBy') }}
      </div>
      <tx-select
        v-model="groupBy" :data="getGroupByOptions()" value-prop="articleProperty"
        display-prop="displayLabel" filterable clearable :placeholder="t('whiteboard.dynamic.properties.groupBy')"
        multiple-values
      >
        <template #prefix>
          <font-awesome-icon icon="fa-light fa-columns" class="w-4" />
        </template>
      </tx-select>

      <!-- Filter -->
      <div class="text-md pt-4">
        {{ t('whiteboard.dynamic.properties.criteria') }}
      </div>
      <articles-filter ref="articlesFilterRef" :criteria="criteria" simple class="max-h-[70vh]" />
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { nextTick, ref } from 'vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import { useUserStore } from '@/store/userData'
import ArticlesFilter from '@/shared/components/ArticlesFilter.vue'
import type { FilterCriteria } from '@/models/filterCriteria'

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'confirm', groupBy: string[], criteria: FilterCriteria[]): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const visible = ref(false)
const loading = ref(false)
const groupBy = ref<string[]>([])
const criteria = ref<FilterCriteria[]>([])
const articlesFilterRef = ref<InstanceType<typeof ArticlesFilter>>()
const errorMsg = ref('')

function showDialog(group: string[], filters: FilterCriteria[]) {
  loading.value = false
  visible.value = true
  groupBy.value = group
  criteria.value = filters
  nextTick(() => {
    articlesFilterRef.value?.resetCriteria(filters.length === 0)
  })
}

function doCancel() {
  visible.value = false
  emit('cancel')
}

async function doConfirm() {
  const error: string[] = []

  if (!groupBy.value || groupBy.value.length <= 0) {
    error.push(t('whiteboard.dynamic.properties.errors.missingGroupBy'))
  }
  criteria.value = articlesFilterRef.value?.getCriteriaResult() || []
  if (criteria.value.length <= 0) {
    error.push(t('whiteboard.dynamic.properties.errors.missingCriteria'))
  }

  if (error.length > 0) {
    errorMsg.value = error.join('\n')
    return
  }

  visible.value = false
  emit('confirm', groupBy.value, criteria.value)
}

function getGroupByOptions() {
  if (userStore.activeCatalog) {
    const res = [] as Array<{ displayLabel: string, articleProperty: string, direction?: string }>
    Object.keys(userStore.activeCatalog.Config.GroupByAttributes).forEach((itm) => {
      if (itm === '_Segmentation') {
        itm = '_Segmentations'
      }
      if (userStore.myAttributes && userStore.myAttributes.hasOwnProperty(itm)) {
        res.push({ displayLabel: userStore.myAttributes[itm].DisplayName, articleProperty: itm })
      }
      else {
        console.warn(`Misconfigured GroupByAttributes: Property ${itm} not found`)
      }
    })
    return res
  }
  else {
    return []
  }
}

defineExpose({
  showDialog,
})
</script>
