<template>
  <img v-if="utils.isValidStringValue(resource.ResourceIcon)" :src="resource.ResourceIcon">
  <font-awesome-icon v-else :icon="getPlaceholderIcon(resource.ResourceUrl)" class="w-5 h-5" />
</template>

<script setup lang="ts">
import type Resource from '@/models/resource'
import utils from '@/services/utils'

interface IProps {
  type?: 'normal' | 'text' | 'icon'
  resource: Resource
}
withDefaults(defineProps<IProps>(), { type: 'normal' })

function getPlaceholderIcon(url: string) {
  if (url && url !== '') {
    const fileExtension = url.split('.').pop()
    switch (fileExtension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'tif':
      case 'bmp':
        return 'fa-light fa-image'
      case 'mp4':
        return 'fa-light fa-video'
      case 'pdf':
        return 'fa-light fa-file-pdf'
      case 'xls':
      case 'xlsx':
      case 'xlsb':
        return 'fa-light fa-file-excel'
      case 'doc':
      case 'docx':
      case 'rtf':
        return 'fa-light fa-file-word'
      case 'ppt':
      case 'pptx':
        return 'fa-light fa-file-powerpoint'
      case 'txt':
        return 'fa-light fa-file-text'
      case 'zip':
      case 'rar':
      case '7z':
        return 'fa-light fa-file-zipper'
    }
  }
  return 'fa-light fa-file'
}
</script>
