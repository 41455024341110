export default class Resource {
  CatalogCode: number
  Id: number
  ResourceId: number
  ResourceName: string
  ResourceUrl: string
  ResourceDescription: string
  ResourceIcon: string
  ResourceCategory: string
  ResourceTypeId: number
  ResourceType: string
  ResourceTypeIcon: string
  ResourceVisibleInResourceList: number
  Cascading: number
  RefResourceId: number
  SortOrder: number
  Status: number
  Articles: number[]

  constructor(CatalogCode: number, resource: any) {
    this.CatalogCode = CatalogCode
    this.Id = resource.Id
    this.ResourceId = resource.ResourceId || resource.RefResourceId
    this.ResourceName = resource.ResourceName || resource.RefResourceName || ''
    this.ResourceUrl = resource.ResourceFilePath != null
      ? (`${resource.ResourceFilePath}/${resource.ResourceFileName}`)
      : resource.RefResourceFilePath != null ? (`${resource.RefResourceFilePath}/${resource.RefResourceFileName}`) : ''
    this.ResourceDescription = resource.ResourceDescription || resource.RefResourceDescription
    this.ResourceIcon = resource.ResourceIcon || resource.RefResourceIcon
    this.ResourceCategory = resource.ResourceCategory || resource.RefResourceCategory || ''
    this.ResourceTypeId = resource.ResourceTypeId || resource.RefResourceTypeId
    this.ResourceType = resource.ResourceType || resource.RefResourceType
    this.ResourceTypeIcon = resource.ResourceTypeIcon || resource.RefResourceTypeIcon
    this.ResourceVisibleInResourceList = resource.ResourceVisibleInResourceList || resource.RefResourceVisibleInResourceList
    this.Cascading = resource.Cascading
    this.RefResourceId = resource.RefResourceId
    this.SortOrder = resource.SortOrder
    this.Status = resource.Status
    this.Articles = resource.ArticleList.filter(a => a.Status > 0).map((article: any) => article.ArticleId || article.Id)
  }
}
