<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <!-- HEADER -->

    <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="t('orders.createOrder.title')" :show-header="true" />

    <!-- BODY -->
    <div class="relative flex-auto mt-4 overflow-auto body">
      <div class="flex flex-col w-full px-10 overflow-hidden">
        <div class="self-center mb-9">
          <span class="block mb-2 uppercase">{{ t('orders.createOrder.fields.type') }}</span>
          <label class="mr-8">
            <input v-model="formModel.CreateType" type="radio" :value="typeEnum.blank">
            <span class="ml-2">{{ t('orders.createOrder.fields.blank') }}</span>
          </label>
          <label>
            <input v-model="formModel.CreateType" type="radio" :value="typeEnum.fromFavorites">
            <span class="ml-2">{{ t('orders.createOrder.fields.fromFavorites') }}</span>
          </label>
        </div>

        <div
          v-for="(attribute, index) in visibleAttributes" :key="attribute.SystemName"
          :class="{ 'col-span-2': index === 0, 'col-span-1': index > 0 }" class="mb-5 last-of-type:mb-4"
        >
          <form-editor
            v-model="formModel[attribute.SystemName]"
            :attribute="attribute"
            :form="formModel"
            :required="attribute.IsRequired"
            :errors="v$[attribute.SystemName]?.$errors"
            :show-label="true"
            :clearable="true"
            @blur="v$[attribute.SystemName]?.$touch"
            @change="onFormItemChanged(attribute)"
          />
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <tx-form-footer
      class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-text="t('general.save')" :primary-disabled="v$.$invalid" @primary-click="onCreate"
      @secondary-click="onCancel"
    />
  </div>
</template>

<script lang='ts' setup>
import useVuelidate from '@vuelidate/core'
import { computed, reactive, ref } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import appConfig from '@/services/appConfig'
import FormEditor from '@/shared/components/FormEditor.vue'
import type CustomerLocation from '@/models/CustomerLocation'
import type FavoriteTag from '@/models/favoriteTag'
import type LinkedCustomer from '@/models/linkedCustomer'
import type MyArticle from '@/models/myArticle'
import Order from '@/models/order'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import { appConstants } from '@/models/constants'
import { useUserStore } from '@/store/userData'

interface IProps {
  indexedCustomers: Record<number, LinkedCustomer>
  indexedLocations: Record<number, CustomerLocation>
  indexedArticles: Record<number, MyArticle>
}
const props = withDefaults(defineProps<IProps>(), {})

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'create', order: Order): void
}>()

const { t } = useI18n()
const userStore = useUserStore()

const formModel = reactive<Record<string, any>>({})
const availableFavorites = ref<Array<FavoriteTag>>([])
const locations = ref<Array<CustomerLocation>>([])
const typeEnum = {
  blank: 1,
  fromFavorites: 2,
}

const createType = computed(() => formModel.CreateType)

const visibleAttributes = computed(() => {
  const visibleAttributes: Array<IFormEditorAttribute> = []
  if (createType.value === typeEnum.fromFavorites) {
    const customersField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'Favorites',
      AttributeType: AttributeType.MultiValue,
      DisplayName: t('orders.createOrder.fields.filterArticleByFavorites'),
      Creatable: true,
      DropDownData: availableFavorites.value,
      DropDownValueProp: 'Id',
      DropDownValueDisplayProp: 'Tag',
      DropDownValueSecondaryDisplayProp: 'CreatedByUserName',
      IsRequired: true,
    })
    visibleAttributes.push(customersField)
  }
  if (!utils.isDefined(userStore.currentCustomer)) {
    const customersField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'CustomerId',
      AttributeType: AttributeType.Nvarchar,
      DisplayName: t('orders.createOrder.fields.customer'),
      Creatable: true,
      DropDownData: utils.sort(Object.values(props.indexedCustomers), 'Name').map(customer => ({ ...customer, displayValue: `${customer.Name} (${customer.CustomerNumber})` })),
      DropDownValueProp: 'CustomerId',
      DropDownValueDisplayProp: 'displayValue',
      IsRequired: true,
    })
    visibleAttributes.push(customersField)
  }
  const locationField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'LocationId',
    AttributeType: AttributeType.Nvarchar,
    DisplayName: t('orders.createOrder.fields.location'),
    Creatable: true,
    DropDownData: utils.sort(locations.value, 'Name').map(location => ({ ...location, displayValue: `${location.Name} (${location.Code})` })),
    DropDownValueProp: 'Id',
    DropDownValueDisplayProp: 'displayValue',
    IsRequired: true,
  })
  const AliasField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'Alias',
    AttributeType: AttributeType.Nvarchar,
    DisplayName: t('orders.createOrder.fields.alias'),
    Creatable: true,
  })
  const CustomerReferenceField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'CustomerReference',
    AttributeType: AttributeType.Nvarchar,
    DisplayName: t('orders.createOrder.fields.customerReference'),
    Creatable: true,
  })
  visibleAttributes.push(locationField, AliasField, CustomerReferenceField)
  return visibleAttributes
})

const validations = computed(() => {
  const result: Record<string, any> = {}
  visibleAttributes.value.forEach((attribute) => {
    result[attribute.SystemName] = {}
    if (attribute.IsRequired) {
      result[attribute.SystemName].required = helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required)
    }
  })
  return result
})

const v$ = useVuelidate(validations, formModel)

function init() {
  console.log('init')
  loadLocationsForSpecificCustomer()
  resetCreateType()
  loadFavorites()
}

async function loadFavorites() {
  try {
    availableFavorites.value = (await appConfig.DB!.favoriteTags
      .where({ CatalogCode: userStore.activeCatalog!.CatalogCode, Status: 1 })
      .filter((tag) => {
        if (tag.CreatedByUserName === userStore.currentUsername) { return true }
        if (tag.SharedUsers && tag.SharedUsers.length > 0) { return true }
        if (tag.SharedUsersGroups && tag.SharedUsersGroups.length > 0) { return true }
        return false
      })
      .toArray()
    ).sort((a, b) => {
      if (a.CreatedByUserName === userStore.currentUsername) { return -1 }
      return a.Tag.localeCompare(b.Tag)
    })
  }
  catch (error) {
    availableFavorites.value = []
    console.warn(error)
  }
}

function resetCreateType() {
  formModel.CreateType = typeEnum.blank
}

function onCancel() {
  emit('cancel')
}

async function onCreate() {
  if (userStore.currentCustomer) {
    formModel.CustomerId = userStore.currentCustomer.CustomerId
  }
  const customer = props.indexedCustomers[formModel.CustomerId]
  const customerLocation = props.indexedLocations[formModel.LocationId]
  const articles: Array<MyArticle> = []
  const formData = {
    locationId: formModel.LocationId,
    locationCode: customerLocation.Code,
    locationName: customerLocation.Name,
    customerReference: formModel.CustomerReference,
    alias: formModel.Alias,
  }
  const order = new Order(customer, userStore.activeCatalog!, userStore.userProfile, null, formData)
  if (formModel.CreateType === typeEnum.fromFavorites) {
    try {
      const selectedFavorites = await appConfig.DB!.favoriteTags.where('Id').anyOf(formModel.Favorites).toArray()
      const articleIdTracker: Array<number> = []
      selectedFavorites.forEach((tag) => {
        tag.Articles.forEach((articleId) => {
          if (!articleIdTracker.includes(articleId)) {
            articleIdTracker.push(articleId)
            const article = props.indexedArticles[articleId]
            if (article) {
              articles.push(article)
            }
          }
        })
      })
    }
    catch (error) {
      console.error(error)
    }
  }
  if (articles.length) {
    order.fillOrder(articles)
  }
  emit('create', order)
}

function onFormItemChanged(attribute) {
  switch (attribute.SystemName) {
    case 'CustomerId':
      onCustomerChanged()
      break
    case 'CreateType': {
      if (!formModel.CreateType) {
        resetCreateType()
      }
      break
    }
    default:
      break
  }
}

async function onCustomerChanged() {
  formModel.LocationId = null
  if (formModel.CustomerId != null) {
    try {
      const customerLocations = await appConfig.DB?.locations.where({ CustomerId: formModel.CustomerId }).toArray() ?? []
      locations.value = utils.sort(customerLocations, 'Name').map(location => ({ ...location, displayValue: `${location.Name} (${location.Code})` }))
    }
    catch (error) {
      console.warn(`unable to load locations for customer: ${formModel.CustomerId}\n error`)
    }
  }
  else {
    locations.value = []
  }
}

async function loadLocationsForSpecificCustomer() {
  if (userStore.currentCustomer && userStore.currentCustomer !== null) {
    const customerLocations = await appConfig.DB?.locations.where({ CustomerId: userStore.currentCustomer.CustomerId }).toArray() ?? []
    locations.value = utils.sort(customerLocations, 'Name').map(location => ({ ...location, displayValue: `${location.Name} (${location.Code})` }))
  }
}
init()
</script>
