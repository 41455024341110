import { jwtDecode } from 'jwt-decode'
import type { AppConfig } from './appConfig'
import Net from './net'
import { ApiResponse } from '@/models/ApiResponse'
import { AuthLoginType, AuthUser } from '@/models/auth'

class Auth {
  baseUrl: string
  envCode: string
  clientCode: string
  processingExternalLogin: boolean
  externalLoginWindow?: Window | null
  externalLoginPromise?: { resolve: Function, reject: Function }
  loginType?: number
  externalLoginInterval: any

  constructor() {
    this.baseUrl = ''
    this.envCode = ''
    this.clientCode = ''
    this.processingExternalLogin = false
    this.externalLoginWindow = undefined
  }

  init(appConfig: AppConfig) {
    this.baseUrl = appConfig.AuthUrl
    this.envCode = appConfig.T1Env
    this.clientCode = appConfig.ClientCode
  }

  /**
   * Returns the logintype of this username
   *
   * @param {string} username Username
   *
   * @returns {Promise<ApiResponse<AuthLoginType>>} ApiResponse<AuthLoginType
   */
  async getLoginType(username: string): Promise<ApiResponse<AuthLoginType>> {
    const result = new ApiResponse<AuthLoginType>()
    await Net.auth.get(`/logintype?Username=${username}&EnvCode=${this.envCode}`)
      .then(res => result.data = new AuthLoginType(res.data))
      .catch((e) => {
        result.status = false
        if (e.response && e.response.data) {
          result.error = e.response.data
        }
      })
    return result
  }

  /**
   * Call the login API to get token
   * @param username Username
   * @param password Password
   * @returns {Promise<ApiResponse<string>>} ApiResponse<string>
   */
  async login(username: string, password: string): Promise<ApiResponse<string>> {
    const result = new ApiResponse<string>()
    await Net.auth.post('/token', {
      username,
      password,
      grant_type: 'password',
      env: this.envCode,
      client_code: this.clientCode,
    })
      .then((res) => {
        result.status = false
        if (res.data && res.data.access_token) {
          result.status = true
          result.data = res.data.access_token
        }
      })
      .catch((e) => {
        result.status = false
        if (e.response && e.response.data) {
          result.error = e.response.data
        }
      })
    return result
  }

  /**
   * Opens a window to complete the external login flow
   *
   * @param {string} username Username
   * @param {number} loginType The external login type
   *
   * @returns {Promise} Resolves when the login flow has completed
   */
  doExternalLogin(username: string, loginType: number): Promise<string> {
    const url = `${this.baseUrl}/gotologin?Username=${username}&EnvCode=${this.envCode}&ClientCode=${this.clientCode}`
    const p = new Promise<string>((resolve, reject) => {
      this.externalLoginPromise = { resolve, reject }
    })
    const left = (screen.width - 500) / 2
    const top = (screen.height - 500) / 2
    window.addEventListener('message', receiveMessage.bind(this), false)
    this.loginType = loginType
    this.processingExternalLogin = true
    this.externalLoginWindow = window.open(url, '_blank', `width=500,height=500,left=${left},top=${top}`)
    this.externalLoginInterval = window.setInterval(() => {
      if (!this.externalLoginWindow || this.externalLoginWindow.closed) {
        this.processingExternalLogin = false
        this.cancelExternalLogin()
      }
    }, 1000)

    return p
  }

  cancelExternalLogin() {
    if (this.externalLoginWindow) {
      this.externalLoginWindow.close()
      this.externalLoginWindow = null
      this.processingExternalLogin = false
    }
    if (this.externalLoginInterval) {
      window.clearInterval(this.externalLoginInterval)
      this.externalLoginInterval = null
    }
    if (this.externalLoginPromise) {
      this.externalLoginPromise.reject('canceled')
    }
    window.removeEventListener('message', receiveMessage.bind(this), false)
  }

  hasValidToken(token?: string) {
    const tokenString = token || this.getLocalToken()
    if (tokenString) {
      // Validate token
      const token = jwtDecode<IT1AuthToken>(tokenString)
      if (token) {
        const envCodes = token.envs.split(',')
        const clientCodes = token.clients.split(',')
        if (envCodes.includes(this.envCode) && clientCodes.includes(this.clientCode)) {
          return token
        }
      }
    }
    return false
  }

  getLocalToken() {
    return localStorage.getItem('tk')
  }

  setLocalToken(token: string) {
    localStorage.setItem('tk', token)
  }

  clearLocalToken() {
    localStorage.setItem('tk', '')
  }

  /**
   * Get my users based on current logged in user
   * @returns {Promise<AuthUser[]>} AuthUser[]
   */
  async getMyUsers(): Promise<AuthUser[]> {
    const res = await Net.auth.get('/myusers')
    const users: Array<AuthUser> = []
    if (res.data.length) {
      res.data.forEach((user) => {
        if (user.status && user.allowedClients.includes(this.clientCode)
          && user.allowedEnvironments.includes(this.envCode)) { users.push(new AuthUser(user)) }
      })
    }
    return users
  }

  /**
   * Call the child token API to get token
   * @param username Username
   * @returns {Promise<ApiResponse<string>>} ApiResponse<string>
   */
  async getChildToken(username: string): Promise<ApiResponse<string>> {
    const result = new ApiResponse<string>()
    await Net.auth.post('/childtoken', {
      username,
      env: this.envCode,
    })
      .then((res) => {
        result.status = false
        if (res.data && res.data.access_token) {
          result.status = true
          result.data = res.data.access_token
        }
      })
      .catch((e) => {
        result.status = false
        if (e.response && e.response.data) {
          result.error = e.response.data
        }
      })
    return result
  }

  /**
   * @param username Username
   * @returns {Promise<ApiResponse<boolean>>} ApiResponse<boolean>
   */
  async forgot(username: string): Promise<ApiResponse<boolean>> {
    const result = new ApiResponse<boolean>()
    await Net.auth.post('/forgot', {
      username,
      env: this.envCode,
      client_code: this.clientCode,
    })
      .then((res) => {
        result.status = !!res.data
        result.data = !!res.data
      })
      .catch((e) => {
        result.status = false
        if (e.response && e.response.data) {
          result.error = e.response.data
        }
      })
    return result
  }

  /**
   * Call the forgot complete API to reset the user password
   * @param username Username
   * @param secretCode SecretCode
   * @param newPassword NewPassword
   * @returns {Promise<ApiResponse<boolean>>} ApiResponse<boolean>
   */
  async forgotcomplete(username: string, secretCode: string, newPassword: string): Promise<ApiResponse<boolean>> {
    const result = new ApiResponse<boolean>()
    await Net.auth.post('/forgotcomplete', {
      username,
      otp: secretCode,
      newPassword,
      validateUser: true,
      env: this.envCode,
      client_code: this.clientCode,
    })
      .then((res) => {
        result.status = !!res.data
        result.data = !!res.data
      })
      .catch((e) => {
        result.status = false
        if (e.response && e.response.data) {
          result.error = e.response.data
        }
      })
    return result
  }
}

function receiveMessage(this: any, event: MessageEvent) {
  if (!(event.origin) || !(event.data) || typeof event.data !== 'string') {
    return
  }
  if (this.externalLoginWindow) {
    this.externalLoginWindow.close()
  }
  if (this.externalLoginInterval) {
    window.clearInterval(this.externalLoginInterval)
    this.externalLoginInterval = null
  }

  this.processingExternalLogin = false

  const data = JSON.parse(event.data)
  if (this.loginType === 2 && data.id_token) {
    window.removeEventListener('message', receiveMessage.bind(this), false)
    this.decodedToken = jwtDecode(data.id_token)
    if (!this.decodedToken.envs || !this.decodedToken.envs.includes(this.envCode)) {
      this.externalLoginPromise.reject('noenv')
    }
    else {
      this.externalLoginPromise.resolve(data.id_token)
    }
  }
  else if (this.loginType === 2 && data.error) {
    window.removeEventListener('message', receiveMessage.bind(this), false)
    const errorDescription = data.error_description
    if (this.externalLoginWindow) {
      this.externalLoginWindow.close()
    }
    this.externalLoginWindow = null
    if (errorDescription.includes('ClientNotAllowed')) {
      this.externalLoginPromise.reject('clientnotallowed')
    }
    else {
      this.externalLoginPromise.reject('undexpected')
    }
  }
}

export default new Auth()
