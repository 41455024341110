<template>
  <div class="">
    <div
      v-if="modelValue" class="absolute flex flex-col w-64 h-full px-1 py-2 overflow-hidden rounded-lg left-1 bg-grey-light shadow-toolbar top-20"
      style="height: calc(100% - 100px);"
    >
      <div class="flex w-full p-4">
        <div
          class="pt-1 text-xl font-medium leading-6 text-center grow text-dark-75"
          v-text="t('whiteboard.sidebar.frames', list.length)"
        />
        <tx-button type="icon" faicon="fa-light fa-xmark" @click="doClose" />
      </div>
      <tx-button class="m-auto" :text="t('general.present')" @click="onPresent" />
      <div class="flex-1 w-full h-full mt-4 overflow-auto">
        <draggable v-model="list" class="w-full leading-10" item-key="id" @change="listChanged">
          <template #item="{ element, index }">
            <div
              class="relative flex items-center h-10 pl-2 cursor-pointer"
              :class="{ 'bg-primary text-white': element.selected }" @click="onFrameSelected(element)"
            >
              <div class="pl-2 text-base truncate border-b grow border-grey">
                <span class="mr-2 font-bold">{{ index + 1 }}</span>
                <span>{{ element.name }}</span>
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>
    <!-- Sidebar toggle button -->
    <div class="absolute z-[1000] w-14 bg-grey-light rounded-lg shadow-toolbar" :style="toggleButtonStyles">
      <div class="p-1">
        <div class="flex flex-col items-center justify-center gap-1 transition-all">
          <tx-button
            v-tooltip="{ text: modelValue ? t('whiteboard.sidebar.closeSidebar') : t('whiteboard.sidebar.openSidebar'), theme: { placement: 'right' } }"
            type="icon" faicon="fa-light fa-sidebar" @click="onSidebarBtnClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import draggable from 'vuedraggable'
import { useI18n } from 'vue-i18n'
import type { StyleValue } from 'vue'
import { computed } from 'vue'
import type Whiteboard from '../services/whiteboard'
import type WbFrame from '../services/frame'
import TxButton from '@/shared/components/TxButton.vue'

interface ListItem {
  id: string
  name: string
  selected: boolean
  frame: WbFrame
}

interface IProps {
  modelValue?: boolean
  whiteboard?: Whiteboard
}
const props = withDefaults(defineProps<IProps>(), { modelValue: false })

const emit = defineEmits<{
  (e: 'update:modelValue', val: boolean): void
  (e: 'toggleFullscreen')
}>()

const { t } = useI18n()

const list = computed<ListItem[]>(() => {
  let result: ListItem[] = []
  if (props.whiteboard) {
    result = props.whiteboard?.frames.value
      .map(frame => ({
        id: frame.id,
        name: frame.name || '',
        selected: false,
        frame,
      } as ListItem))
  }
  return result
})

const toggleButtonStyles = computed(() => {
  return {
    left: props.modelValue ? '280px' : '20px',
    bottom: '22px',
  } as StyleValue
})

function doClose() {
  emit('update:modelValue', false)
}

function onPresent() {
  emit('toggleFullscreen')
}

function onSidebarBtnClick() {
  emit('update:modelValue', !props.modelValue)
}

function listChanged() {
  if (list.value.length > 0) {
    for (let index = 0; index < list.value.length; index++) {
      const frame = list.value[index].frame
      frame.setProp('sortOrder', { sortOrder: (index + 1) })
    }
  }
}

function onFrameSelected(item: ListItem) {
  list.value.forEach(item => item.selected = false)
  item.selected = true
  props.whiteboard?.centerObject(item.frame)
}
</script>
