<template>
  <div
    class="h-full w-full flex flex-row relative" :class="{ 'cursor-move': resizing }" @mouseup="doStopResizing"
    @mousemove="doResize" @touchend="doStopResizing"
  >
    <div
      id="aside" class="overflow-hidden transition-all ease-in delay-300 border-r border-form flex bg-card"
      :style="getAsideStyle"
    >
      <slot name="aside" />
    </div>
    <div
      v-show="opened" class="h-full w-1 cursor-move hover:bg-grey absolute z-splitter"
      :style="{ left: `${splitterLeft}px` }" :class="{ 'bg-grey': resizing }" @mousedown="doStartResizing"
    />
    <div class="transition-all ease-in delay-300 grow flex flex-col w-px">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useThrottleFn } from '@vueuse/core'
import { computed, ref } from 'vue'

type AsidePosition = 'left' | 'right'
interface IProps {
  width?: number
  position?: AsidePosition
  pushContent?: boolean
  opened?: boolean
}
const props = withDefaults(defineProps<IProps>(), { width: 250, position: 'left', pushContent: true, opened: true })

defineEmits<{
  (e: 'update:opened', value: boolean): void
}>()

const resizing = ref(false)
const splitterLeft = ref(props.width)
const internalWidth = ref(props.width)
let startX = 0
let moved = false

// const getSetOpened = computed({
//   get: () => props.opened,
//   set: val => emit('update:opened', val),
// })

const getAsideStyle = computed(() => {
  return {
    width: props.opened ? `${internalWidth.value}px` : '0',
    left: props.position === 'left' ? '0' : 'unset',
    right: props.position === 'right' ? '0' : 'unset',
  }
})

// function doCloseAside() {
//   getSetOpened.value = false
// }

// function doOpenAside() {
//   getSetOpened.value = true
// }

// function doToggle() {
//   getSetOpened.value = !getSetOpened.value
// }

function doStartResizing(e: MouseEvent) {
  startX = e.clientX
  resizing.value = true
}

function doStopResizing() {
  resizing.value = false
  if (moved) {
    internalWidth.value = splitterLeft.value
  }
  moved = false
}

const doResize = useThrottleFn((e: MouseEvent) => {
  if (resizing.value && e.currentTarget) {
    splitterLeft.value += (e.clientX - startX)
    startX = e.clientX
    moved = true
  }
}, 100)
</script>
