import type { IOrderDetailsModel, IOrderModel, IProcessStatusResponse, ISaveDraftPayload, ISellerVASModel, ISendOrderPayload, ISendOrderResponse, IUpdateOrderPayload } from './model/orderModel'
import Net from '@/services/net'

export function fetchOrders(catalogCode: number, customerId: number | null | undefined) {
  let URL = `/catalogs/${catalogCode}/orders`
  if (customerId != null) {
    URL = `/catalogs/${catalogCode}/customers/${customerId}/orders`
  }
  return Net.t1.get<Array<IOrderModel>>(URL)
}

export function fetchOrderlines(catalogCode: number, customerId: number | null | undefined, orderId: number) {
  let URL = `/catalogs/${catalogCode}/orders/${orderId}?status=0`
  if (customerId != null) {
    URL = `/catalogs/${catalogCode}/customers/${customerId}/orders/${orderId}?status=0`
  }
  return Net.t1.get<IOrderDetailsModel>(URL)
}

export function fetchSellerVAS(accountId: number) {
  return Net.t1.get<Array<ISellerVASModel>>(`/sellers/${accountId}/vas`)
}

export function updateOrderDetails(catalogCode: number, orderId: number, requestObj: IUpdateOrderPayload): (Promise<{ data: IOrderModel }>) {
  return Net.t1.put(`/catalogs/${catalogCode}/orders/${orderId}`, requestObj)
}

export function saveDraft(catalogCode: number, payLoad: ISaveDraftPayload): (Promise<{ data: IOrderModel }>) {
  return Net.t1.put(`/catalogs/${catalogCode}/orders/draft`, payLoad)
}

export function reopenOrders(catalogCode: number, orderIds: Array<number>) {
  return Net.t1.put<string>(`catalogs/${catalogCode}/orders/reopen`, { Orders: orderIds })
}

export function deleteOrders(catalogCode: number, orderIds: Array<number>) {
  return Net.t1.put<string>(`catalogs/${catalogCode}/orders/status`, orderIds.map(orderId => ({ Id: orderId, status: 0 })))
}

export function sendOrderAPI(catalogCode: number, requestObject: ISendOrderPayload) {
  return Net.t1.put<ISendOrderResponse>(`/catalogs/${catalogCode}/orderprocess`, [requestObject])
}

export function getProcessStatus(catalogCode: number, orderReferences: Array<string>) {
  return Net.t1.put<Array<IProcessStatusResponse>>(`/catalogs/${catalogCode}/orders/getprocessstatus`, { OrderReferences: orderReferences })
}
