<template>
  <div class="mx-6 mt-6 mb-8 footer">
    <tx-button
      :type="secondaryType" :text="secondaryText" :disabled="secondaryDisabled"
      :loading="secondaryLoading" @click="onSecondaryClick"
    />
    <tx-button
      :type="primaryType" :text="primaryText" :disabled="primaryDisabled" class="ml-5"
      :loading="primaryLoading" @click="onPrimaryClick"
    />
  </div>
</template>

<script setup lang='ts'>
import TxButton from '@/shared/components/TxButton.vue'

interface IProps {
  primaryType?: 'confirm' | 'danger' | 'default' | 'cancel' | 'text' | 'icon' | 'nav' | 'plainIcon' | 'staticIcon'
  primaryText?: string
  primaryLoading?: boolean
  primaryDisabled?: boolean
  secondaryType?: 'confirm' | 'danger' | 'default' | 'cancel' | 'text' | 'icon' | 'nav' | 'plainIcon' | 'staticIcon'
  secondaryText?: string
  secondaryLoading?: boolean
  secondaryDisabled?: boolean
}

withDefaults(defineProps<IProps>(), {
  primaryType: 'confirm',
  primaryText: 'Ok',
  primaryLoading: false,
  primaryDisabled: false,
  secondaryType: 'cancel',
  secondaryText: 'Cancel',
  secondaryLoading: false,
  secondaryDisabled: false,
})

const emit = defineEmits(['primaryClick', 'secondaryClick'])

function onPrimaryClick() {
  emit('primaryClick')
}

function onSecondaryClick() {
  emit('secondaryClick')
}
</script>
