<template>
  <div
    v-if="visible"
    class="h-[85px] m-h-[85px] bg-gray-100 border-b border-gray-300 flex flex-row items-center" :style="{ minWidth: `${fullWidth}px` }"
  >
    <!-- Header Checkbox -->
    <div class="sticky left-0 z-10 p-3 bg-gray-100 w-9">
      &nbsp;
    </div>
    <!-- Header empty space to compensate for icons, VAS and article image -->
    <div :style="{ width: `${staticColumnsWidth.infoIconColumnWidth}px` }">
      &nbsp;
    </div>
    <div :style="{ width: `${staticColumnsWidth.warnIconColumnWidth}px` }">
      &nbsp;
    </div>
    <div v-if="containsValidVas" :style="{ width: `${staticColumnsWidth.vasColumnWidth}px` }">
      &nbsp;
    </div>
    <div :style="{ width: `${staticColumnsWidth.imageColumnWidth}px` }">
      &nbsp;
    </div>
    <!-- Header Cell -->
    <div
      v-for="visibleColumn in visibleColumns" v-show="visibleColumn.property !== 'Quantities'" :key="visibleColumn.property"
      class="flex flex-row items-center justify-start px-1 py-2 overflow-hidden text-sm text-left align-middle bg-gray-100"
      :style="{ width: `${visibleColumn.width}px` }"
    >
      <div class="w-full overflow-hidden">
        <tx-date-picker
          v-if="[AttributeType.Date, AttributeType.DateOption, AttributeType.DateTime].includes(visibleColumn.type)"
          v-model="localFilterCriteria[visibleColumn.property].multipleVals" :enable-time-picker="visibleColumn.type === AttributeType.DateTime" range
          multi-calendars auto-apply @change="doChange"
        />
        <tx-select
          v-else-if="visibleColumn.filterLookup && visibleColumn.filterLookup.size > 0"
          v-model="localFilterCriteria[visibleColumn.property].multipleVals"
          :data="Array.from(visibleColumn.filterLookup!, ([key, label]) => ({ key, label }))" value-prop="key" display-prop="label" filterable multiple-values
          clearable @change="doChange"
        />
        <div v-else-if="visibleColumn.type === AttributeType.Int || visibleColumn.type === AttributeType.Decimal" class="flex items-center">
          <div v-if="showAdvancedFilterButton(visibleColumn)" class="flex items-center">
            <tx-button
              type="confirm" :text="t('dataTable.advanceFilter.advancedFilter')" width="115px" height="30px"
              @click="openAdvanceFilter(visibleColumn)"
            />
            <tx-button
              type="icon" faicon="fa-light fa-xmark" class="shrink-0" title="Clear Advanced Filter" width="24px"
              height="24px" icon-size="15px" @click="clearAdvancedFilter(visibleColumn)"
            />
          </div>
          <div v-else class="flex items-center">
            <tx-input v-model="localFilterCriteria[visibleColumn.property].numberVal" type="number" clearable @change="doChange" />
            <tx-button
              type="icon" faicon="fa-light fa-filter-list" class="shrink-0" title="Advanced Filter" width="24px"
              height="24px" icon-size="15px" @click="openAdvanceFilter(visibleColumn)"
            />
          </div>
        </div>
        <div v-else class="flex items-center">
          <div v-if="showAdvancedFilterButton(visibleColumn)" class="flex items-center">
            <tx-button
              type="confirm" :text="t('dataTable.advanceFilter.advancedFilter')" width="115px" height="30px"
              @click="openAdvanceFilter(visibleColumn)"
            />
            <tx-button
              type="icon" faicon="fa-light fa-xmark" class="shrink-0" title="Clear Advanced Filter" width="24px"
              height="24px" icon-size="15px" @click="clearAdvancedFilter(visibleColumn)"
            />
          </div>
          <div v-else class="flex items-center">
            <tx-input v-model="localFilterCriteria[visibleColumn.property].stringVal" type="text" clearable @change="doChange" />
            <tx-button
              type="icon" faicon="fa-light fa-filter-list" class="shrink-0" title="Advanced" width="24px"
              height="24px" icon-size="15px" @click="openAdvanceFilter(visibleColumn)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Header Actions -->
    <div
      class="w-14 h-[44px] sticky right-0 bg-gray-100 text-sm font-medium flex flex-row justify-start align-middle items-center p-2 text-center"
    >
      <tx-button type="icon" faicon="fa-light fa-xmark" @click="doCloseFilter()" />
    </div>
  </div>
</template>

<script setup lang='ts'>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import type { IOrderlineVisibleColumn } from '../../Orders.types'
import TxButton from '@/shared/components/TxButton.vue'
import TxDatePicker from '@/shared/components/TxDatePicker.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import { AttributeType } from '@/models/catalogAttribute'
import { FilterCriteria, FilterCriteriaMode } from '@/models/filterCriteria'

const props = defineProps<{
  containsValidVas: boolean
  filterCriteria: Record<string, FilterCriteria>
  fullWidth: number
  staticColumnsWidth: { infoIconColumnWidth: number, warnIconColumnWidth: number, vasColumnWidth: number, imageColumnWidth: number }
  visible: boolean
  visibleColumns: Array<IOrderlineVisibleColumn>
}>()

const emits = defineEmits<{
  (e: 'update:filterCriteria', filterCriteria: Record<string, FilterCriteria>): void
  (e: 'update:visible', visible: boolean): void
  (e: 'close'): void
  (e: 'openAdvanceFilter', column: IOrderlineVisibleColumn): void
}>()

const { t } = useI18n()

const localFilterCriteria = computed({
  get: () => props.filterCriteria,
  set: modelValue => emits('update:filterCriteria', modelValue),
})

function doChange() {
  emits('update:filterCriteria', localFilterCriteria.value)
}

function openAdvanceFilter(col: IOrderlineVisibleColumn) {
  emits('openAdvanceFilter', col)
}

function showAdvancedFilterButton(col: IOrderlineVisibleColumn) {
  return props.filterCriteria[col.property].isAdvancedFilter
}

function clearAdvancedFilter(col: IOrderlineVisibleColumn) {
  // clear advanced filter criteria for the column
  localFilterCriteria.value[col.property] = new FilterCriteria({
    attribute: col.property,
    mode: FilterCriteriaMode.string,
    multipleVals: undefined,
    exclude: false,
    filterBlank: false,
    isAdvancedFilter: false,
  })
  emits('update:filterCriteria', localFilterCriteria.value)
}

function doCloseFilter() {
  emits('update:visible', false)
  emits('close')
}
</script>
