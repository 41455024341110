<template>
  <div class="w-full">
    <div v-if="label.length > 0" class="flex">
      <label v-if="label.length > 0" class="text-xs tracking-wide uppercase" v-text="label" />
      <p v-if="restrictedFormats.length > 0" class="ml-2 text-xs italic text-gray-600">
        {{ allowedFileFormats }}
      </p>
    </div>
    <div ref="refUploadFile" class="mt-1 overflow-y-auto border rounded cursor-pointer h-28" :class="{ 'flex items-center justify-center': validFiles.length <= 0 }">
      <ul v-if="validFiles.length > 0">
        <li v-for="(file, index) in validFiles" :key="index" class="flex">
          <div class="p-1 border">
            {{ index + 1 }}
          </div>
          <div class="flex-1 p-1 border">
            {{ file.name }}
          </div>
          <div v-if="file.error" v-t="'general.fileUploadError'" class="p-1 text-red-400 border" />
          <tx-button class="p-1 border" type="icon" faicon="fa-light fa-check" @click.stop="onRemoveFile(file.uniqueIdentifier)" />
        </li>
      </ul>
      <p v-else v-t="'general.browseOrDropFileHere'" class="text-base" />
    </div>
    <div v-if="hasError">
      <p v-if="localError.length > 0" class="text-xs italic text-red-500">
        {{ localError }}
      </p>
      <p v-for="error in errors" :key="error.$uid" class="text-xs italic text-red-500">
        {{ error.$message }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FlowFile } from '@flowjs/flow.js'
import Flow from '@flowjs/flow.js'
import { useI18n } from 'vue-i18n'
import type { ErrorObject } from '@vuelidate/core'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import TxButton from '@/shared/components/TxButton.vue'
import auth from '@/services/auth'
import Net from '@/services/net'
import utils from '@/services/utils'

interface IProps {
  label?: string
  errors?: ErrorObject[]
  restrictedFormats?: string[]
  multiple?: boolean
}
const props = withDefaults(defineProps<IProps>(), { label: '', errors: () => [] as ErrorObject[], restrictedFormats: () => [] as string[], multiple: false })

const emit = defineEmits<{
  (e: 'update:modelValue', fileId: string | string[]): void
  (e: 'onUpload', file: FlowFile, fileId: string): void
  (e: 'onRemove', file: FlowFile): void
}>()

const { t } = useI18n()

const refUploadFile = ref<HTMLDivElement>()
const flow = new Flow({
  target: `${Net.t1.defaults.baseURL}/UploadFileChunk`,
  headers: { Authorization: `Bearer ${auth.getLocalToken()}` },
  speedSmoothingFactor: 0.02,
  chunkSize: 5 * 1024 * 1024,
  singleFile: !props.multiple,
})
const localError = ref('')
const validFiles = ref<FlowFile[]>([])
let selectedFileMap: Record<string, string> = {}

const allowedFileFormats = computed(() => t('general.allowedFileFormats', { fileFormats: props.restrictedFormats.join(', ') }))

const hasError = computed(() => utils.isValidStringValue(localError.value) || props.errors.length)

function validateFile(file: FlowFile) {
  if (utils.isDefined(file) && !file.name.match(new RegExp(`.(${props.restrictedFormats.join('|')})$`))) {
    return false
  }
  else {
    return true
  }
}

function onFilesAdded(files: FlowFile[]) {
  localError.value = ''
  files.forEach((file) => {
    if (validateFile(file)) {
      if (props.multiple) {
        validFiles.value.push(file)
      }
      else {
        validFiles.value[0] = file
      }
    }
    else {
      localError.value = t('general.unsupportedFile', { fileName: file.name })
      return false
    }
  })
}

function onFileSubmitted(files: FlowFile[]) {
  if (files.length) {
    files.forEach((file) => {
      if (utils.isDefined(validFiles.value.find(f => f.uniqueIdentifier === file.uniqueIdentifier))) {
        flow.upload()
      }
      else {
        flow.removeFile(file)
      }
    })
  }
}

function onFileSuccess(file: FlowFile, message: string) {
  const fileId: string = message.replace(/['"]+/g, '')
  const uniqueIdentifier: string = file.uniqueIdentifier
  if (props.multiple) {
    selectedFileMap[uniqueIdentifier] = fileId
    emit('update:modelValue', Object.values(selectedFileMap))
  }
  else {
    selectedFileMap = { uniqueIdentifier: fileId }
    emit('update:modelValue', fileId)
  }
  emit('onUpload', file, fileId)
}

function onFileError(file: FlowFile, message: string) {
  console.error(file, message)
  localError.value = message
}

function onRemoveFile(fileKey: string) {
  const flowFile = flow.files.find(file => file.uniqueIdentifier === fileKey)
  const fileIndex = validFiles.value.findIndex(file => file.uniqueIdentifier === fileKey)
  if (utils.isDefined(flowFile) && fileIndex !== -1) {
    delete selectedFileMap[flowFile.uniqueIdentifier]
    validFiles.value.splice(fileIndex, 1)
    flow.removeFile(flowFile)
    emit('update:modelValue', props.multiple ? Object.values(selectedFileMap) : '')
    emit('onRemove', flowFile)
  }
}

onMounted(() => {
  if (utils.isDefined(refUploadFile.value)) {
    flow.assignBrowse([refUploadFile.value])
    flow.assignDrop([refUploadFile.value])
  }
  flow.on('filesAdded', onFilesAdded)
  flow.on('filesSubmitted', onFileSubmitted)
  flow.on('fileSuccess', onFileSuccess)
  flow.on('fileError', onFileError)
})

onUnmounted(() => {
  flow.off('filesAdded')
  flow.off('filesSubmitted')
  flow.off('fileSuccess')
  flow.off('fileError')
})
</script>
