import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import type { Subscription } from 'dexie'
import { liveQuery } from 'dexie'
import type MyArticle from '@/models/myArticle'
import type Article from '@/models/article'
import type DuneAsset from '@/models/duneAsset'
import pngLoading from '@/assets/loading.png'
import pngNoImage from '@/assets/noimg.png'
import appConfig from '@/services/appConfig'
import { useUserStore } from '@/store/userData'
import utils from '@/services/utils'
import { appConstants, privileges, whiteboardConstants } from '@/models/constants'
import { getArticleAssets } from '@/api/t1/article'

interface IWbArticleImageOptions extends fabric.IImageOptions {
  id?: string
  catalogCode: number
  articleId: number
  objectId: string
  isRequest?: boolean
  assetKey?: string
  lock?: boolean
  preventUnlock?: boolean
  requestWaterMarkDetails?: Record<string, any>
  colorCode?: string
  status?: boolean
  localOnly?: boolean
}

export default class WbArticleImage extends fabric.Image implements IWbObject {
  public id: string
  public catalogCode: number
  public articleId: number
  public objectId: string
  public isRequest: boolean
  public requestWaterMarkDetails: Record<string, any> = {}
  public colorCode: string = ''
  public status?: boolean
  public articleNumber?: string
  public assetKey?: string
  public assets: DuneAsset[]
  public type = whiteboardConstants.objectTypes.articleImage
  public selected = false
  public highlighted = false
  public lock: boolean
  public connectable: boolean
  public imgLoaded: boolean
  public imgLoading: boolean
  public localOnly: boolean
  public preventUnlock: boolean
  private isLoadingFirstTime: boolean = true
  public editableProps: Record<string, IWbObjectProp> = {
    imageType: { name: 'image type', type: 'imageType' },
    scale: { name: 'size', type: 'scale' },
    lock: { name: 'Lock', type: 'lock' },
    addArticleDetails: { name: 'add article details', type: 'addArticleDetails' },
    addDiscussion: { name: 'add comment', type: 'addDiscussion' },
    assignImage: { name: 'assign image', type: 'assignImage' },
  }

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    copyModel: {
      action: 'copyModel',
      label: 'Copy Model',
      faicon: 'fa-light fa-copy',
      showInSubMenu: (() => {
        const userStore = useUserStore()
        return userStore.activeCatalog?.DataSourceTypeId !== appConstants.catalogTypes.inherited
          && !userStore.currentCustomer
          && userStore.userProfile.isValidPrivilege(privileges.article.carryOverArticles)
          && userStore.userProfile.isValidPrivilege(privileges.article.createModelWithArticles)
          && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)
      })(),
    },
    copyArticles: {
      action: 'copyArticles',
      label: 'Copy Articles',
      faicon: 'fa-light fa-copy',
      showInSubMenu: (() => {
        const userStore = useUserStore()
        return this.catalogCode === userStore.activeCatalog?.CatalogCode
          && userStore.activeCatalog?.DataSourceTypeId !== appConstants.catalogTypes.inherited
          && !userStore.currentCustomer
          && userStore.userProfile.isValidPrivilege(privileges.article.addOrCarryoverArticles)
          && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)
      })(),
    },
    editArticles: {
      action: 'editArticles',
      label: 'Edit Articles',
      faicon: 'fa-light fa-edit',
      showInSubMenu: (() => {
        const userStore = useUserStore()
        return this.catalogCode === userStore.activeCatalog?.CatalogCode && userStore.userProfile.isValidPrivilege(privileges.article.update)
          && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)
      })(),
    },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  public subscriptions: Array<Subscription>

  constructor(element: string | HTMLImageElement | HTMLCanvasElement | HTMLVideoElement, options: IWbArticleImageOptions, isLiveObject: boolean = true) {
    super(element, options)
    this.id = options.id || guid()
    this.catalogCode = options.catalogCode
    this.articleId = options.articleId
    this.objectId = options.objectId
    this.isRequest = options.isRequest || false
    this.requestWaterMarkDetails = options.requestWaterMarkDetails || { fontColor: '#5093e16e', waterMarkText: 'Request' }
    this.colorCode = options.colorCode || ''
    this.status = options.status
    this.assetKey = options.assetKey
    this.lock = options.lock || false
    this.localOnly = options.localOnly || false
    this.connectable = true
    this.assets = []
    this.imgLoaded = false
    this.imgLoading = false
    this.subscriptions = []
    this.preventUnlock = options.preventUnlock || false
    if (isLiveObject) {
      const userStore = useUserStore()
      const catalogDetails = userStore.linkedCatalogDetails[this.catalogCode] || userStore.activeCatalog
      if (this.isRequest) {
        const observable = liveQuery(async () => await appConfig.DB!.requests.where({ CatalogCode: catalogDetails.CatalogCode, Id: this.articleId, IsCreateArticleRequest: 1 }).toArray())
        const subscription = observable.subscribe(async ([request]) => {
          const article = await utils.getRequestArticle(catalogDetails, request, appConfig.DB)
          // As discussed with Andre we dont need to set article again when we first time create the object as already we are loading the article
          if (!this.isLoadingFirstTime) {
            this.articleNumber = article.ArticleNumber
            this.setProp('status', article.Status === 1)
            this.canvas?.requestRenderAll()
          }
          else {
            this.isLoadingFirstTime = false
          }
        })
        this.subscriptions.push(subscription)
      }
      else {
        // non segmented article will have _IsNonSegmented as true
        const articleIds = new Set([this.articleId])
        const observable = liveQuery(async () => appConfig.DB!.getArticleCollectionByCatalogCodeArticleIds(userStore.activeCatalog!, articleIds, true, userStore.currentCustomer, userStore.currentCustomerSegmentations, true).then(collection => collection.toArray()))
        const subscription = observable.subscribe(async ([article]) => {
          // As discussed with Andre we dont need to set article again when we first time create the object as already we are loading the article
          if (!this.isLoadingFirstTime) {
            this.articleNumber = article.ArticleNumber
            this.setProp('status', article.Status === 1)
            this.setProp('colorCode', '')
            if (userStore.activeCatalog && userStore.activeCatalog.Config.WhiteboardColorCodingAttribute && userStore.activeCatalog.Config.WhiteboardColorCodingAttribute.length !== 0 && userStore.activeCatalog.Config.WhiteboardColorCodingAttribute[0].attribute) {
              for (let i = 0; i < userStore.activeCatalog.Config.WhiteboardColorCodingAttribute.length; i++) {
                const configValue = userStore.activeCatalog.Config.WhiteboardColorCodingAttribute[i]
                if (utils.isDefined(article[configValue.attribute]) && article[configValue.attribute]!.toString().trim() !== '' && article[configValue.attribute]!.toString().trim().toLowerCase() === configValue.value.toString().toLowerCase()) {
                  this.setProp('colorCode', configValue.color)
                  break
                }
              }
            }
            this.canvas?.requestRenderAll()
          }
          else {
            this.isLoadingFirstTime = false
          }
        })
        this.subscriptions.push(subscription)
      }
    }

    this.setLock(this.lock)

    this.stateProperties = this.stateProperties?.concat(['lock'])

    this.on('selected', () => {
      console.log('selected')
      this.selected = true
    })

    this.on('deselected', () => {
      console.log('deselected')
      this.selected = false
    })

    this.on('removed', () => {
      // console.log('unsubscribing from article image observables')
      this.subscriptions.forEach((subscription) => {
        if (subscription && !subscription.closed) {
          subscription.unsubscribe()
        }
      })
    })
  }

  setProp(prop: string, value: any, ignoreHistory: boolean = false) {
    switch (prop) {
      case 'imageType':
        this.set('assetKey', value.assetKey)
        this.imgLoaded = false
        this.imgLoading = false
        break
      case 'scale':
        if (value.scale <= 100 && this.width && this.height) {
          const scale = value.scale / 100
          this.set('scaleX', scale)
          this.set('scaleY', scale)
        }
        break
      case 'lock':
        this.set('lock', value.lock)
        this.setLock(value.lock)
        break
      case 'status':
        this.set('status', value)
        break
      case 'colorCode':
        this.set('colorCode', value)
        break
      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this, ignoreHistory })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'imageType':
        result.assetKey = this.assetKey
        break
      case 'scale':
        result.scale = Math.round((this.scaleX || 1) * 100)
        break
      case 'lock':
        result.lock = this.lock
        break
      case 'status':
        result.status = this.status
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  highlight() {
    this.highlighted = true
    this.set('stroke', '#2196f3')
    this.set('strokeWidth', 4)
    this.set('strokeDashArray', [20])
    this.canvas?.requestRenderAll()
  }

  dim() {
    this.highlighted = false
    this.set('stroke', undefined)
    this.set('strokeWidth', 0)
    this.set('strokeDashArray', undefined)
    this.canvas?.requestRenderAll()
  }

  midIntersectsWithObject(other: fabric.Object, absolute?: boolean, calculate?: boolean) {
    const points1 = this.getCoords(absolute, calculate)
    const points2 = other.getCoords(absolute, calculate)

    const xDelta = (points1[1].x - points1[0].x) / 4
    const yDelta = (points1[2].y - points1[0].y) / 4
    const increaseInX = points1[0].x + xDelta
    const increaseInY = points1[0].y + yDelta

    for (let i = 0; i < points1.length; i++) {
      const point = points1[i]
      point.x = (point.x >= increaseInX) ? (point.x - xDelta) : (point.x + xDelta)
      point.y = (point.y >= increaseInY) ? (point.y - yDelta) : (point.y + yDelta)
    }

    const intersection = fabric.Intersection.intersectPolygonPolygon(points1, points2)

    return intersection.status === 'Intersection'
      || other.isContainedWithinObject(this, absolute, calculate)
      || this.isContainedWithinObject(other, absolute, calculate)
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject(propertiesToInclude?: string[]) {
    const props = propertiesToInclude || []
    props.push('id', 'catalogCode', 'articleId', 'objectId', 'isRequest', 'assetKey', 'lock', 'status', 'localOnly', 'preventUnlock')
    return super.toObject(props)
  }

  static fromObject(object: WbArticleImage, callback?: Function) {
    // handle existing objects
    if (!object.catalogCode) { object.catalogCode = -1 }
    if (object.catalogCode && object.articleId) {
      this.loadArticleImageById(object.catalogCode, object.articleId, object.objectId, object.width || 500, object.height || 500, object).then((v) => { callback && callback(v) })
    }
  }

  static async getImageSrc(catalogCode: number, articleId: number, objectId: string, isRequest: boolean, width: number, height: number, assets?: DuneAsset[], assetKey?: string) {
    let src = pngNoImage
    const userStore = useUserStore()
    const catalogDetails = userStore.linkedCatalogDetails[catalogCode] || userStore.activeCatalog
    if (userStore && catalogDetails && assets && assets.length > 0) {
      let asset = assets[0]
      if (utils.isValidStringValue(assetKey)) {
        const selectedAsset = assets.find(asset => asset.Key === assetKey)
        if (selectedAsset) {
          asset = selectedAsset
        }
      }
      src = `${appConfig.AssetsUrl}/assets/content/${asset.StorageFile}?ContextKey=${encodeURIComponent(catalogDetails.ContextKey)}&f=webp&w=${width}&h=${height}&trim=true`
    }
    else if (userStore && userStore.indexedSilhouetteImages && userStore.activeCatalog?.Config.SilhouetteImagesCriteria) {
      let article: Article | undefined
      if (isRequest) {
        const request = await appConfig.DB!.requests.get({ CatalogCode: catalogDetails.CatalogCode, Id: articleId, IsCreateArticleRequest: 1 })
        if (request && request.Content) {
          article = await utils.getRequestArticle(catalogDetails, request, appConfig.DB)
        }
      }
      else {
        article = await appConfig.DB!.articles.get({ CatalogCode: catalogDetails.CatalogCode, Id: articleId })
      }
      if (article) {
        const imageKey = utils.getPlaceHolderImageKey(userStore.activeCatalog.Config.SilhouetteImagesCriteria, article, userStore.indexedSilhouetteImages)
        if (utils.isValidStringValue(imageKey) && userStore.indexedSilhouetteImages[imageKey] && userStore.activeCatalog.ContextKey) {
          src = `${appConfig.AssetsUrl}/assets/content/${userStore.indexedSilhouetteImages[imageKey].StorageFile}?ContextKey=${encodeURIComponent(userStore.activeCatalog.ContextKey)}&f=webp&w=${width}&h=${height}&trim=true`
        }
      }
    }
    return src
  }

  static loadArticleImage(article: Article, width: number, height: number, opt?: IWbArticleImageOptions) {
    return new Promise<WbArticleImage>((resolve) => {
      fabric.util.loadImage(pngLoading, (img) => {
        const userStore = useUserStore()
        let colorCode = ''
        if (userStore.activeCatalog && userStore.activeCatalog.Config.WhiteboardColorCodingAttribute && userStore.activeCatalog.Config.WhiteboardColorCodingAttribute.length !== 0 && userStore.activeCatalog.Config.WhiteboardColorCodingAttribute[0].attribute) {
          for (let i = 0; i < userStore.activeCatalog.Config.WhiteboardColorCodingAttribute.length; i++) {
            const configValue = userStore.activeCatalog.Config.WhiteboardColorCodingAttribute[i]
            if (utils.isDefined(article[configValue.attribute]) && article[configValue.attribute]!.toString().trim() !== '' && article[configValue.attribute]!.toString().trim().toLowerCase() === configValue.value.toString().toLowerCase()) {
              colorCode = configValue.color
              break
            }
          }
        }

        const o = Object.assign((opt || { width, height }), { catalogCode: article.CatalogCode, articleId: article.Id, objectId: article.CatalogArticleId, isRequest: Boolean(article._IsRequestArticle), requestWaterMarkDetails: utils.getRequestWaterTextAndFontColor(article), colorCode, status: article.Status === 1 })
        const obj = new WbArticleImage(img, o)
        obj.articleNumber = article.ArticleNumber
        const catalogDetails = userStore.linkedCatalogDetails[article.CatalogCode] || userStore.activeCatalog
        obj.imgLoading = true
        getArticleAssets(catalogDetails.DuneContext, catalogDetails.ContextKey, article.ArticleNumber, userStore.activeCatalog?.Config.NewestImageAssetKeyList)
          .then((assets) => {
            obj.assets = assets
            obj.canvas?.requestRenderAll()
          })
          .finally(() => {
            obj.imgLoaded = false
            obj.imgLoading = false
            obj.opacity = article.Status === 1 && !article._IsNonSegmented ? 1 : 0.5
          })
        resolve(obj)
      })
    })
  }

  static async getJsonObject(article: MyArticle, width: number, height: number, scaleValue, opt?: IWbArticleImageOptions) {
    const userStore = useUserStore()
    const o = Object.assign((opt || { width, height }), { catalogCode: article.CatalogCode, articleId: article.Id, objectId: article.CatalogArticleId, isRequest: Boolean(article._IsRequestArticle), status: article.Status === 1 })
    const catalogDetails = userStore.linkedCatalogDetails[article.CatalogCode] || userStore.activeCatalog

    const obj = new WbArticleImage('', o, false).toObject()
    if (scaleValue.scale <= 100 && width && height) {
      const scale = scaleValue.scale / 100
      obj.scaleX = scale
      obj.scaleY = scale
    }
    if (article._Assets && article._Assets.length > 0 && catalogDetails) {
      obj.src = `${appConfig.AssetsUrl}/assets/content/${article._Assets[0].StorageFile}?ContextKey=${encodeURIComponent(catalogDetails.ContextKey)}&f=webp&w=${width}&h=${height}&trim=true`
    }
    else if (userStore && userStore.indexedSilhouetteImages && userStore.activeCatalog?.Config.SilhouetteImagesCriteria) {
      const imageKey = utils.getPlaceHolderImageKey(userStore.activeCatalog.Config.SilhouetteImagesCriteria, article, userStore.indexedSilhouetteImages)
      if (utils.isValidStringValue(imageKey) && userStore.indexedSilhouetteImages[imageKey] && userStore.activeCatalog.ContextKey) {
        obj.src = `${appConfig.AssetsUrl}/assets/content/${userStore.indexedSilhouetteImages[imageKey].StorageFile}?ContextKey=${encodeURIComponent(userStore.activeCatalog.ContextKey)}&f=webp&w=${width}&h=${height}&trim=true`
      }
    }

    obj.height = height
    obj.width = width
    return obj
  }

  static async loadArticleImageById(catalogCode: number, articleId: number, objectId: string, width: number, height: number, opt: IWbArticleImageOptions) {
    const userStore = useUserStore()
    if (userStore && userStore.activeCatalog) {
      const catalogDetails = userStore.linkedCatalogDetails[catalogCode] || userStore.activeCatalog
      if (opt.isRequest) {
        const request = await appConfig.DB!.requests.get({ CatalogCode: catalogDetails.CatalogCode, Id: articleId, IsCreateArticleRequest: 1 })
        if (request && request.Content) {
          const article = await utils.getRequestArticle(catalogDetails, request, appConfig.DB)
          return await this.loadArticleImage(article, width, height, opt)
        }
      }
      else {
        const article = await appConfig.DB!.articles.get({ CatalogCode: catalogDetails.CatalogCode, Id: articleId })
        if (article) {
          return await this.loadArticleImage(article, width, height, opt)
        }
        else if (Object.keys(userStore.linkedCatalogDetails).length > 0) {
          for (const linkedCatalogCode in userStore.linkedCatalogDetails) {
            const article = await appConfig.DB!.articles.get({ CatalogCode: linkedCatalogCode, Id: articleId })
            if (article) {
              return await this.loadArticleImage(article, width, height, opt)
            }
          }
        }
      }
      console.warn('Failed to load article image by Id')
    }
    console.warn('Unable to load Article Image by Id')
    return null
  }

  override _render(ctx: CanvasRenderingContext2D): void {
    super._render(ctx)
    if (!this.imgLoaded && !this.imgLoading) {
      this.imgLoading = true
      WbArticleImage.getImageSrc(this.catalogCode, this.articleId, this.objectId, this.isRequest, 500, 500, this.assets, this.assetKey)
        .then((src) => {
          this.setSrc(src, (img, error) => {
            if (error) {
              this.setSrc(pngNoImage, () => this.canvas?.requestRenderAll())
              console.warn('unable to load WbArticleImage', this, error)
            }
            else {
              this.canvas?.requestRenderAll()
            }
            this.imgLoaded = true
            this.imgLoading = false
            console.log('image loaded')
          })
        })
    }
    if (this.selected && this.articleNumber) {
      // const userStore = useUserStore()
      ctx.font = '50px Helvetica'
      const measure = ctx.measureText(this.articleNumber)
      // let seasonData = userStore.activeCatalog?.CatalogCode === this.article.CatalogCode
      //   ? userStore.activeCatalog.Season
      //   : userStore.linkedCatalogDetails[this.article.CatalogCode].Season
      // const seasonDataMeasure = ctx.measureText(seasonData)

      ctx.fillStyle = '#1484f8'
      ctx.fillRect(-this.width! / 2, -this.height! / 2, measure.width, 60)
      // ctx.fillRect(this.width! / 2 - seasonDataMeasure.width, -this.height! / 2, seasonDataMeasure.width, 60)
      ctx.fillStyle = '#FFFFFF'
      ctx.fillText(this.articleNumber, -this.width! / 2, -this.height! / 2 + 50)
      // ctx.fillText(seasonData, this.width! / 2 - seasonDataMeasure.width, -this.height! / 2 + 50)
    }
    if (this.isRequest) {
      const fontColor = this.requestWaterMarkDetails.fontColor || '#5093e16e'
      const waterMarkText = this.requestWaterMarkDetails.waterMarkText || '#5093e16e'
      const text = waterMarkText.split(' ')
      ctx.lineWidth = 1
      ctx.fillStyle = fontColor
      ctx.font = '50px arial'
      ctx.rotate(-Math.PI / 4)
      ctx.textAlign = 'center'
      if (text.length === 1) {
        ctx.fillText(text, 0, 0)
      }
      else if (text.length === 2) {
        let y = 0
        text.forEach((value, index) => {
          if (index === 0) {
            y = -50
          }
          else if (index === 1) {
            y = 50
          }
          ctx.fillText(value, 0, y)
        })
      }
      else if (text.length === 3) {
        text.forEach((value, index) => {
          let y = 0
          if (index === 0) {
            y = -100
          }
          else if (index === 2) {
            y = 100
          }
          ctx.fillText(value, 0, y)
        })
      }
      else if (text.length === 4) {
        text.forEach((value, index) => {
          let y = 0
          if (index === 0) {
            y = -100
          }
          else if (index === 1) {
            y = -35
          }
          else if (index === 2) {
            y = 35
          }
          else if (index === 3) {
            y = 100
          }
          ctx.fillText(value, 0, y)
        })
      }
      else {
        ctx.fillText(text, 0, 0)
      }
    }
    else {
      if (this.colorCode !== '') {
        const radius = 18
        const centerX = 0
        const centerY = -(this.height || 500) / 2 + 14
        ctx.beginPath()
        ctx.arc(centerX, centerY, radius, 0, (2 * Math.PI), false)
        ctx.fillStyle = this.colorCode
        ctx.fill()
      }
      if (!this.status) {
        ctx.lineWidth = 8
        ctx.beginPath()
        ctx.moveTo(-((this.width || 500) / 2), -((this.height || 500) / 2))
        ctx.lineTo((this.width || 500) / 2, (this.height || 500) / 2)
        ctx.strokeStyle = 'black'
        ctx.stroke()
        ctx.lineWidth = 5
        ctx.strokeStyle = 'red'
        ctx.stroke()
        ctx.lineWidth = 8
        ctx.beginPath()
        ctx.moveTo(((this.width || 500) / 2), -((this.height || 500) / 2))
        ctx.lineTo(-((this.width || 500) / 2), (this.height || 500) / 2)
        ctx.strokeStyle = 'black'
        ctx.stroke()
        ctx.lineWidth = 5
        ctx.strokeStyle = 'red'
        ctx.stroke()
      }
    }
  }
}

const f: any = fabric
f.WbArticleImage = WbArticleImage
