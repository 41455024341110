<template>
  <div class="flex justify-between p-1.5">
    <div class="flex items-center">
      <tx-button
        v-tooltip="t('general.export')" type="icon" class="p-2 mx-1" width="40px" height="40px"
        faicon="fa-light fa-file-export" @click="doExport()"
      />
      <tx-button
        v-for="(action, index) in actions" v-show="action.visible" :key="index" v-tooltip="action.label" type="icon" class="p-2 mx-1" width="40px" height="40px"
        :faicon="action.icon" :disabled="!action.enabled" @click="action.onClick"
      />
      <div class="px-2 py-1 ml-2 text-xs font-medium rounded-sm bg-primary text-on-primary">
        {{ t('dataTable.xOfYSelected', { x: n(selectedCount), y: n(totalCount) }) }}
      </div>
      <div
        v-show="filteredCount < totalCount"
        class="px-2 py-1 ml-2 text-xs font-medium rounded-sm bg-primary text-on-primary"
      >
        {{ t('dataTable.xFiltered', { x: n(filteredCount) }) }}
      </div>
    </div>
    <div class="flex">
      <tx-button
        v-if="showStaticActions" type="icon" class="p-2 mx-1" width="40px" height="40px" faicon="fa-light fa-filter"
        :disabled="loading" @click="doFilter()"
      />
      <tx-button
        v-if="showStaticActions" type="icon" class="p-2 mx-1" width="40px" height="40px" faicon="fa-light fa-table"
        :disabled="loading" @click="doColumnChooser()"
      />
      <tx-switch
        v-if="showStatusFilter" v-model="hideInactive"
        v-tooltip="hideInactive ? t('general.showAll') : t('general.hideInactive')"
        class="pt-2"
        @change="onChangeShowAll"
      />
      <tx-button
        v-if="showStaticActions" type="icon" class="p-2 mx-1" width="40px" height="40px" faicon="fa-light fa-arrows-rotate"
        :disabled="loading" @click="doRefresh()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref, watch } from 'vue'
import TxButton from '../TxButton.vue'
import type { ITxDataTableListAction } from './TxDataTable.types'
import TxSwitch from '@/shared/components/TxSwitch.vue'

const props = defineProps<{
  loading: boolean
  actions: ITxDataTableListAction[]
  totalCount: number
  selectedCount: number
  filteredCount: number
  showStaticActions?: boolean
  showStatusFilter?: boolean
  statusValue?: TxBooleanish
}>()

const emit = defineEmits<{
  (e: 'refresh'): void
  (e: 'columnChooser'): void
  (e: 'export'): void
  (e: 'filter'): void
  (e: 'hideInactive', hideInactive: boolean): void
}>()

const { t, n } = useI18n()
const hideInactive = ref(false)

watch(() => props.statusValue, (value) => {
  hideInactive.value = value === 1
})
function doRefresh() {
  emit('refresh')
}

function doColumnChooser() {
  emit('columnChooser')
}

function doExport() {
  emit('export')
}

function doFilter() {
  emit('filter')
}

function onChangeShowAll() {
  emit('hideInactive', hideInactive.value)
}
</script>
