<template>
  <div class="relative w-full h-full">
    <div class="container relative flex flex-col w-full h-full overflow-hidden">
      <!-- HEADER -->
      <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="t('articleDetails.actions.editParentModel')" :show-header="showHeader" />

      <!-- BODY -->
      <div class="px-10 mt-4 alerts">
        <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      </div>
      <!-- FORM -->
      <div class="relative flex-auto body">
        <div class="grid grid-cols-2 px-10 gap-x-6 gap-y-6">
          <div v-for="(attribute) in visibleAttributes" :key="attribute.SystemName">
            <form-editor
              v-model="formModel[attribute.SystemName]"
              :attribute="attribute"
              :form="formModel"
              :required="attribute.IsRequired"
              :errors="v$[attribute.SystemName]?.$errors"
              :show-label="true"
              :clearable="true"
              :disabled="loading || attribute.ReadOnly"
              :articles="articles"
              @blur="v$[attribute.SystemName]?.$touch"
              @change="onChange(attribute.SystemName)"
            />
          </div>
          <tx-input
            v-model="formModel.parentModelName" class="mb-4"
            :label="t('fields.parentModelName')"
            :disabled="true"
          />
        </div>
      </div>
      <!-- FOOTER -->
      <tx-form-footer
        class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
        :primary-text="t('general.update')" :primary-disabled="loading || v$.$invalid" :secondary-disabled="loading"
        @primary-click="onUpdate" @secondary-click="onCancel"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { clone, pick } from 'lodash-es'
import { createI18nMessage, helpers, required, requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, reactive, ref } from 'vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import type MyArticle from '@/models/myArticle'
import type { UpdateParentModelInfoModel } from '@/api/t1/model/articleModel'
import { updateParentModelInfo } from '@/api/t1/article'
import type { SellerModelsBase } from '@/api/t1/model/sellerModel'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import { appConstants } from '@/models/constants'
import { AttributeType } from '@/models/catalogAttribute'
import FormEditor from '@/shared/components/FormEditor.vue'

interface ISellerModel {
  parent_model_type: string
  parentModelNumber: SellerModelsBase | null
  parentModelName: string | null
}

interface IProps {
  showHeader?: boolean
  articles: MyArticle[]
}
const props = withDefaults(defineProps<IProps>(), { showHeader: true })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[]): void
}>()

const { t } = useI18n()
const { errorMessage, hasError } = useErrorMessage()
const userStore = useUserStore()
const withI18nMessage = createI18nMessage({ t })

const loading = ref<boolean>(false)
const formModel = reactive<ISellerModel>({
  parent_model_type: '',
  parentModelNumber: null,
  parentModelName: null,
})
const disabledParentModelNumber = ref(false)
const requiredParentModelNumber = ref(true)
const parentModelTypeAttribute = computed(() => {
  let res: IMyAttribute | undefined
  if (userStore.myAttributes && userStore.myAttributes.parent_model_type) {
    res = clone(userStore.myAttributes.parent_model_type)
    res.IsRequired = true
  }
  return res
})

const visibleAttributes = computed(() => {
  const visibleAttributes: IFormEditorAttribute[] = []
  if (utils.isDefined(parentModelTypeAttribute.value)) {
    visibleAttributes.push(parentModelTypeAttribute.value)
  }
  const parentModelNumberField: IFormEditorAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'parentModelNumber',
    DisplayName: t('editParentModel.parentModelNumberAutocomplete'),
    Creatable: true,
    AttributeType: AttributeType.SellerModel,
    ReadOnly: formModel.parent_model_type?.toString().toLowerCase() === 'parent',
    IsRequired: requiredParentModelNumber.value,
  })
  visibleAttributes.push(parentModelNumberField)
  return visibleAttributes
})

const rules = computed(() => {
  const result = {
    parent_model_type: { required: withI18nMessage(required) },
    parentModelName: { requiredIf: requiredIf(() => formModel.parent_model_type !== 'Parent') },
    parentModelNumber: { requiredIf: requiredIf(() => formModel.parent_model_type !== 'Parent') },
  }

  visibleAttributes.value.forEach((attribute) => {
    if (attribute.IsRequired) {
      result[attribute.SystemName] = {
        required: helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required),
      }
    }
  })

  return result
})
const v$ = useVuelidate(rules, formModel)

async function initForm() {
  let commonParentModelType = props.articles ? props.articles[0].parent_model_type : null
  let commonParentModelNumber = props.articles ? props.articles[0].ParentModelNumber : null
  utils.resetReactiveObject(formModel)

  for (let i = 1; i < props.articles.length; i++) {
    if (commonParentModelType?.toString() !== props.articles[i].parent_model_type?.toString()) {
      commonParentModelType = null
      break
    }
  }
  if (!utils.isDefined(commonParentModelType)) { // check if the assigned parent model number is common
    for (let i = 1; i < props.articles.length; i++) {
      if (commonParentModelNumber?.toString() !== props.articles[i].ParentModelNumber?.toString()) {
        commonParentModelNumber = null
        break
      }
    }
  }
  // fetch seller models
  loading.value = true
  if (userStore.activeCatalog && userStore.linkedCatalogDetails) {
    // we need to prepopulate the parent model type and parent model number and parent model name when common value
    if (utils.isDefined(commonParentModelType) || utils.isDefined(commonParentModelNumber)) {
      if (utils.isDefined(commonParentModelType)) {
        formModel.parent_model_type = commonParentModelType.toString()
      }
      const queryCriterion: Array<[number, string]> = [[userStore.activeCatalog!.CatalogCode, props.articles[0]!.ParentModelNumber]]
      Object.keys(userStore.linkedCatalogDetails).forEach((catalogCode) => {
        queryCriterion.push([+catalogCode, props.articles[0]!.ParentModelNumber])
      })
      const modelArticles = await appConfig.DB!.articles.where('[CatalogCode+ModelNumber]')
        .anyOf(queryCriterion)
        .toArray()
      if (modelArticles.length) {
        const sellerModel: SellerModelsBase = pick(modelArticles[0], 'ModelId', 'ModelName', 'ModelNumber')
        updateParentModelNumber(sellerModel)
        formModel.parentModelName = sellerModel.ModelName
      }
    }
  }
  loading.value = false
}

function onChange(context: string) {
  v$.value[context].$touch()
  if (context === 'parent_model_type') {
    disabledParentModelNumber.value = false
    requiredParentModelNumber.value = true

    if (utils.isValidStringValue(formModel.parent_model_type) && formModel.parent_model_type.toString().toLowerCase() === 'parent') {
      disabledParentModelNumber.value = true
      requiredParentModelNumber.value = false
      updateParentModelNumber(null)
      formModel.parentModelName = ''
    }
  }
  else if (context === 'parentModelNumber') {
    formModel.parentModelName = formModel.parentModelNumber ? formModel.parentModelNumber.ModelName : ''
  }
}

function updateParentModelNumber(sellerModel?: SellerModelsBase | null) {
  formModel.parentModelNumber = sellerModel || null
}

function onCancel() {
  emit('cancel')
}

async function onUpdate() {
  loading.value = true
  try {
    const payload: UpdateParentModelInfoModel[] = []
    for (let i = 0; i < props.articles.length; i++) {
      const article = props.articles[i]
      let masterModelId = article.ModelId
      if (formModel.parent_model_type?.toLowerCase() !== 'parent' && utils.isDefined(formModel.parentModelNumber)) {
        masterModelId = formModel.parentModelNumber.ModelId
      }
      payload.push({
        ModelId: article.ModelId,
        parent_model_type: formModel.parent_model_type,
        MasterModelId: masterModelId,
      })
    }
    await updateParentModelInfo(userStore.activeCatalog!.AccountId, userStore.activeCatalog!.AttributeGroupId, payload)
    await userStore.doLoadData(['Articles'])
    emit('updated', props.articles)
  }
  catch (error) {
    errorMessage.value = t('general.unexpectedError')
  }
  finally {
    loading.value = false
  }
}

onMounted(() => {
  initForm()
})
</script>
