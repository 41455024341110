<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden select-text">
    <!-- Header -->
    <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="titleText" :show-header="true" />
    <!-- Stepper -->
    <div class="px-6 py-4 bg-gray-100 border-t border-gray-200">
      <div class="flex justify-between">
        <template v-for="(step, index) in steps" :key="step">
          <div class="flex flex-col items-center">
            <div
              class="flex items-center justify-center w-6 h-6 border-2 rounded-full"
              :class="[index <= currentStepIndex ? 'bg-blue-500 text-white border-blue-500' : 'border-gray-400']"
              v-text="index + 1"
            />
            <div class="text-sm" v-text="step" />
          </div>
          <div
            v-if="index < steps.length - 1" class="flex-1 h-2 mx-1 mt-3"
            :class="[index < currentStepIndex ? 'bg-blue-500' : 'bg-gray-400']"
          />
        </template>
      </div>
    </div>

    <!-- Body -->
    <div class="relative flex-auto mt-4 overflow-auto body">
      <div class="flex flex-col w-full px-10 overflow-hidden">
        <!-- FILE UPLOAD STEP 1 -->
        <div v-if="currentStepIndex === stepsEnum.uploadFile" class="flex flex-col space-y-2 h-full w-full">
          <!-- Radio buttons for file selection options -->
          <label class="mt-1">
            <input
              v-model="selectedOption" name="fileOption" type="radio" value="lastUsedFile" class="h-5 w-5"
              :class="{ 'opacity-60': !isLastUsedFileAvailable }" :disabled="!isLastUsedFileAvailable"
            >
            <span class="ml-2" :class="{ 'opacity-60': !isLastUsedFileAvailable }">{{ t('dataImporter.file.lastUsedFile') }}</span>
          </label>
          <label>
            <input v-model="selectedOption" name="fileOption" type="radio" value="newFile" class="h-5 w-5">
            <span class="ml-2">{{ t('dataImporter.file.newFile') }}</span>
          </label>
          <div v-if="selectedOption === 'newFile'">
            <file-upload v-model="formModel.file" file-format-hint="Excel (xlsx)" accept-format=".xlsx" @change="handleFileUpload" />
          </div>
        </div>
        <!-- SELECT SHEET STEP 2 -->
        <div v-if="currentStepIndex === stepsEnum.selectSheet" class="flex flex-col h-full w-full">
          <div class="mt-4 flex flex-wrap w-auto">
            <label class="text-xs tracking-wide uppercase label required" v-text="t('orders.importOrder.mapping.sheet')" />
            <tx-select
              v-if="excelWorkbook" v-model="formModel.sheetId" class="col-span-3" :data="excelWorkbook.worksheets"
              value-prop="id" display-prop="name" :sort-list="false" @change="onSheetChange"
            />
          </div>
        </div>
        <!-- COLUMN MAPPING STEP 3 -->
        <div v-if="currentStepIndex === stepsEnum.columnsMapping" class="w-full p-5">
          <div class="mb-4">
            <span class="text-base">{{ t('dataImporter.columnMapping.heading') }}</span>
            <span class="text-sm">{{ t('dataImporter.columnMapping.subHeading') }}</span>
          </div>
          <!-- Filter Columns -->
          <div class="mb-4 flex items-center">
            <font-awesome-icon class="w-4 h-4 transform rotate-90" icon="fa-light fa-search" />
            <input
              v-model="filterMapping"
              type="text"
              class="w-full p-2 border-b text-sm"
              placeholder="Type to search..."
            >
          </div>
          <template v-for="column in filteredColumns" :key="column.SystemName">
            <div class="mt-4 flex items-center w-full">
              <label :class="{ required: column.IsRequired }" class="text-sm tracking-wide label mt-4 w-1/2" v-text="column.DisplayName" />
              <div class="flex items-center w-1/2 relative">
                <!-- Wrapper to ensure select and warning icon are correctly positioned -->
                <div class="flex items-center w-full">
                  <tx-select
                    v-model="formModel.mapping[column.SystemName]"
                    :data="excelColumns"
                    :sort-list="false"
                    clearable
                    filterable
                    class="w-full pr-8"
                    @change="onMappingChange(column, formModel.mapping[column.SystemName])"
                  />
                </div>
                <!-- Display warning icon if this column has duplicate mappings -->
                <font-awesome-icon
                  v-if="column.mappingWarn"
                  class="absolute right-2"
                  icon="fa-light fa-warning"
                  title="Warning: This column is already mapped to another field"
                />
              </div>
            </div>
          </template>
        </div>
        <!-- FILTER STEP 4 -->
        <div v-if="currentStepIndex === stepsEnum.filter" class="flex flex-col space-y-2 h-full w-full overflow-auto p-4">
          <div class="mb-4">
            {{ t('dataImporter.filter.desc') }}
          </div>
          <label>
            <input v-model="formModel.filter.state" name="importOption" type="radio" :value="0" class="h-5 w-5">
            <span class="ml-2">{{ t('dataImporter.filter.importAllRows') }}</span>
          </label>
          <label>
            <input v-model="formModel.filter.state" name="importOption" type="radio" :value="1" class="h-5 w-5">
            <span class="ml-2">{{ t('dataImporter.filter.importSpecificRows') }}</span>
          </label>
          <div v-if="formModel.filter.state === 1" class="mt-4">
            <div class="flex items-center space-x-2 my-1">
              <tx-select
                v-model="formModel.filter.include" :data="[{ value: 0, label: t('dataImporter.filter.include') }, { value: 1, label: t('dataImporter.filter.exclude') }]"
                value-prop="value" display-prop="label" :sort-list="false"
              />
              <span class="w-64">{{ t('dataImporter.filter.theRowsWhereColumn') }}:</span>
            </div>
            <div class="flex items-center space-x-2 my-1">
              <tx-select v-model="formModel.filter.columnName" :data="excelColumns" :placeholder="t('dataImporter.filter.selectColumn')" required filterable />
              <span>{{ t('dataImporter.filter.contains') }}</span>
              <tx-select
                v-model="formModel.filter.contains" :data="[{ value: 0, label: t('dataImporter.filter.anyValue') }, { value: 1, label: t('dataImporter.filter.noValue') }, { value: 2, label: t('dataImporter.filter.aSpecificValue') }]"
                value-prop="value" display-prop="label" :sort-list="false"
              />
              <tx-input v-if="formModel.filter.contains === 2" v-model="formModel.filter.specificValue" type="text" class="w-32" :placeholder="t('general.enterValue')" required />
            </div>
          </div>
        </div>
        <!-- VALIDATION STEP 5 -->
        <div v-if="currentStepIndex === stepsEnum.validations" class="flex flex-col items-center justify-center h-full w-full p-5">
          <!-- show a loader when isValidating -->
          <div v-if="isValidating" class="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50">
            <div class="flex flex-col items-center">
              <div class="text-blue-400 text-lg font-semibold">
                {{ t('dataImporter.validations.validatingData') }}
              </div>
            </div>
          </div>
          <div v-else class="w-full max-w-xl">
            <table class="w-full table-auto border-collapse">
              <tbody>
                <tr>
                  <td class="p-2 border-b">
                    {{ t('dataImporter.validations.status') }}:
                  </td>
                  <td class="p-2 border-b">
                    <span>{{ t('dataImporter.validations.finished') }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="p-2 border-b">
                    {{ t('dataImporter.validations.totalValidRows') }}:
                  </td>
                  <td class="p-2 border-b">
                    <span>{{ totalValid }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="p-2 border-b">
                    {{ t('dataImporter.validations.totalInvalidRows') }}:
                  </td>
                  <td class="p-2 border-b">
                    <span>{{ totalInvalid }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="p-2 border-b">
                    {{ t('dataImporter.validations.totalSkippedRows') }}:
                  </td>
                  <td class="p-2 border-b">
                    <span>{{ totalSkipped }}</span>
                  </td>
                </tr>
                <tr v-if="totalInvalid > 0 || totalValid === 0">
                  <td class="p-2 flex items-center">
                    <span class="flex items-center">
                      <font-awesome-icon class="w-6 h-6 mr-2 text-red-500" icon="fa-light fa-warning" />
                      {{ t('dataImporter.validations.importValidationFailedMessage') }}
                    </span>
                  </td>
                  <td class="p-2">
                    <tx-button v-if="!resultsLoading && totalInvalid !== 0" type="cancel" :text="t('dataImporter.labels.getResult')" @click="saveResult" />
                  </td>
                </tr>
                <tr v-if="totalInvalid === 0 && totalValid === 0">
                  <td colspan="2" class="p-2 w-full flex items-center">
                    {{ t('dataImporter.validations.noRowsToSubmit') }}
                  </td>
                </tr>
                <tr v-if="totalInvalid === 0 && totalValid > 0">
                  <td colspan="2" class="p-2 w-full flex items-center">
                    {{ t('dataImporter.validations.readyToBeSubmitted') }}
                  </td>
                </tr>
                <tr v-if="totalInvalid !== 0 && totalValid > 0">
                  <td colspan="2" class="p-2 border-t">
                    <tx-switch v-model="skipInvalidRecords" :label="t('dataImporter.skipInvalidRows')" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- INFORMATION STEP 6 -->
        <div v-if="currentStepIndex === stepsEnum.information" class="flex flex-col items-center justify-center h-full w-full overflow-auto p-5">
          <div v-if="!isUpdateByModel">
            <span> {{ t('dataImporter.validations.dataInfoMessage') }} </span>
          </div>
          <div v-else>
            <span> {{ t('dataImporter.validations.modelLevelDataMessage') }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <tx-form-footer
      class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-text="currentStepIndex === stepsEnum.information ? t('general.import') : t('general.next')" :secondary-text="t('general.back')" :secondary-disabled="currentStepIndex === stepsEnum.uploadFile ? true : false" :primary-loading="isValidating"
      :primary-disabled="v$.$invalid || ((skipInvalidRecords || totalInvalid > 0) && skipInvalidRecords !== true)" @primary-click="currentStepIndex === stepsEnum.information ? importData() : nextStep()" @secondary-click="prevStep()"
    />
  </div>
</template>

<script lang='ts' setup>
import { computed, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, required } from '@vuelidate/validators'
import { clone, cloneDeep, isEmpty, isFunction, isNumber } from 'lodash-es'
import ExcelJS from 'exceljs'
import moment from 'moment'
import type { ITxDataTableListAction } from '../txDataTable/TxDataTable.types'
import { useAdminStore } from '@/store/adminData'
import { useUserStore } from '@/store/userData'
import FileUpload from '@/shared/components/FileUpload.vue'
import utils from '@/services/utils'
import TxInput from '@/shared/components/TxInput.vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import TxSwitch from '@/shared/components/TxSwitch.vue'
import { AttributeType } from '@/models/catalogAttribute'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import type Article from '@/models/article'
import type { SellerColorModel } from '@/api/t1/model/catalog'
import useMasterDataLookup from '@/modules/admin/composables/masterDataLookup'
import type { IField, IImportMapping, IImportModel } from '@/modules/admin/type'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'
import { setCatalogImportMapping } from '@/api/t1/catalog'
import type CatalogShipmentWindowRange from '@/models/catalogShipmentWindowRange'

interface IForm {
  file: File | null
  sheetId: number
  mapping: Record<string, any>
  filter: {
    state: number
    include: number
    columnName: string | undefined
    column: number
    contains: number
    specificValue: string
  }
}

interface IProps {
  model: IImportModel
  action: ITxDataTableListAction
  data?: Article[]
}

const props = defineProps<IProps>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'import', data: any[]): void
}>()

const { t } = useI18n()
const titleText = computed(() => props.action?.label ?? t('dataImporter.title'))
const { getArticlesMaxStateDetails, skipRestrictingPropertyUpdateBasedOnArticleState } = useArticleFormHelper()
const userStore = useUserStore()
const adminStore = useAdminStore()
const { loadLookup } = useMasterDataLookup()
const withI18nMessage = createI18nMessage({ t })

const initialFormState: IForm = {
  file: null,
  sheetId: -1,
  mapping: {},
  filter: {
    state: 0,
    include: 0,
    columnName: undefined,
    column: 0,
    contains: 0,
    specificValue: '',
  },
}

const excelWorkbook = ref<ExcelJS.Workbook>()
const formModel = reactive<IForm>(cloneDeep(initialFormState))
const excelColumns = ref([] as string[])
const steps = ['File', 'Sheet', 'Mapping', 'Filter', 'Validation', 'Information']
const stepsEnum = {
  uploadFile: 0,
  selectSheet: 1,
  columnsMapping: 2,
  filter: 3,
  validations: 4,
  information: 5,
}

const currentStepIndex = ref(0)
const selectedOption = ref<'newFile' | 'lastUsedFile'>('newFile')
const isValidating = ref(false)
const totalValid = ref(0)
const totalInvalid = ref(0)
const totalSkipped = ref(0)
const skipInvalidRecords = ref(false)
const resultsLoading = ref(false)
const filterMapping = ref('')
let validData: any[] = []
let lookupData: Record<string, any> = {}
const dataExistConstraintsData: Record<string, any> = {}
const activeCatalogShipmentWindowRangeList = userStore.activeCatalog!.ShipmentWindowRangeList
let catalogImportMappings: Record<string, IImportMapping> = {}
let importMappings: IImportMapping = {}
const dependingAttributesMap: any[] = []

if (utils.isDefined(adminStore.lastUsedFile)) {
  selectedOption.value = 'lastUsedFile'
  handleFileUpload(adminStore.lastUsedFile)
}

const isLastUsedFileAvailable = computed(() => utils.isDefined(adminStore.lastUsedFile))

const filteredColumns = computed(() => {
  if (filterMapping.value === '') {
    return props.model.fields
  }
  return props.model.fields.filter(column =>
    column.DisplayName.toLowerCase().includes(filterMapping.value.toLowerCase()),
  )
})

const isUpdateByModel = computed(() => props.action.id === 'updateViaImportModel')

const rules = computed(() => {
  const result: Record<string, any> = {
    file: {},
    sheetId: -1,
    mapping: {},
    filter: {},
  }
  if (currentStepIndex.value === stepsEnum.uploadFile) {
    if (selectedOption.value === 'newFile') {
      result.file = { required: withI18nMessage(required) }
    }
  }
  if (currentStepIndex.value === stepsEnum.selectSheet) {
    result.sheetId = { required: withI18nMessage(required) }
  }
  if (currentStepIndex.value === stepsEnum.columnsMapping) {
    for (const map of filteredColumns.value) {
      if (map.IsRequired) {
        result.mapping[map.SystemName] = { required: withI18nMessage(required) }
      }
    }
  }
  if (currentStepIndex.value === stepsEnum.filter) {
    result.filter.state = { required: withI18nMessage(required) }
    if (formModel.filter.state === 1) {
      result.filter.include = { required: withI18nMessage(required) }
      result.filter.columnName = { required: withI18nMessage(required) }
      result.filter.contains = { required: withI18nMessage(required) }
      if (formModel.filter.contains === 2) {
        result.filter.specificValue = { required: withI18nMessage(required) }
      }
    }
  }
  return result
})
const v$ = useVuelidate(rules, formModel)

function prevStep() {
  if (currentStepIndex.value <= stepsEnum.validations) {
    totalValid.value = totalInvalid.value = totalSkipped.value = 0
  }
  if (currentStepIndex.value > 0) {
    currentStepIndex.value--
  }
}

function nextStep() {
  if (currentStepIndex.value < steps.length - 1) {
    currentStepIndex.value++
  }
  if (currentStepIndex.value === stepsEnum.selectSheet) {
    if (excelWorkbook.value) {
      const selectedSt = excelWorkbook.value.worksheets.filter(sheet => sheet.id === formModel.sheetId)[0]
      if (selectedSt) {
        importMappings.selectedSheet = selectedSt.name
      }
      else {
        console.error('Sheet with the specified ID not found')
      }
      excelColumns.value = getExcelColumns()
      setMappingBasedOnTitles(excelColumns.value)
    }
  }
  if (currentStepIndex.value === stepsEnum.filter) {
    if (excelWorkbook.value) {
      if (importMappings && importMappings.FilterInfo && !isEmpty(importMappings.FilterInfo)) {
        formModel.filter = importMappings.FilterInfo // load filter criteria from cached value
      }
    }
  }
  else if (currentStepIndex.value === stepsEnum.validations) { // validation
    if (excelWorkbook.value) {
      // the column index from the columnName
      const worksheet = excelWorkbook.value?.getWorksheet(formModel.sheetId)
      if (worksheet) {
        const firstRow: ExcelJS.Row = worksheet.getRow(1)
        if (firstRow.hasValues && firstRow.values) {
          const values = firstRow.values as ExcelJS.CellValue[]

          const columnIndex = values.findIndex(value => typeof value === 'string' && value === formModel.filter.columnName)

          if (columnIndex > 0) {
            formModel.filter.column = columnIndex
            importMappings.FilterInfo = formModel.filter
          }
          else {
            console.warn(`Column name '${formModel.filter.columnName}' in Importmapping filter not found.`)
          }
        }
      }
      validateData()
    }
  }
}

function importData() {
  persistImportMapping()
  emit('cancel')
  emit('import', validData)
}

function handleFileUpload(file: File | null) {
  formModel.sheetId = -1
  formModel.mapping = {}
  // if in validation state move it 1 step back and reset validation
  if (currentStepIndex.value === steps.length - 1) {
    currentStepIndex.value--
  }
  if (file) {
    const reader = new FileReader()
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target && event.target.result) {
        excelWorkbook.value = new ExcelJS.Workbook()
        excelWorkbook.value.xlsx.load(event.target.result as ArrayBuffer)
          .then((workbook) => {
            formModel.sheetId = workbook.worksheets.length > 0 ? workbook.worksheets[0].id : -1
            // get from cache if any
            if (workbook.worksheets.length > 0) {
              if (importMappings && importMappings.selectedSheet && doesCachedSheetExist(workbook.worksheets, importMappings.selectedSheet)) {
                const cachedSheet = workbook.worksheets.filter(sheet => sheet.name === importMappings.selectedSheet)[0] // try to get form cached object in db
                if (utils.isDefined(cachedSheet)) {
                  formModel.sheetId = cachedSheet.id
                }
              }
              else {
                formModel.sheetId = workbook.worksheets[0].id
              }
            }
            else {
              formModel.sheetId = -1
            }

            excelWorkbook.value = workbook
          })
      }
    }
    reader.readAsArrayBuffer(file)
    adminStore.setLastUsedFile(file)
  }
}

function onSheetChange() {
  formModel.mapping = {}
  if (excelWorkbook.value) {
    const selectedSt = excelWorkbook.value.worksheets.filter(sheet => sheet.id === formModel.sheetId)[0]
    if (selectedSt) {
      importMappings.selectedSheet = selectedSt.name
    }
  }
  excelColumns.value = getExcelColumns()
  setMappingBasedOnTitles(excelColumns.value)
}

function getExcelColumns() {
  const cols: string[] = []
  if (excelWorkbook.value && formModel.sheetId && formModel.sheetId !== -1) {
    const worksheet = excelWorkbook.value.getWorksheet(formModel.sheetId)
    if (worksheet) {
      for (let i = 1; i <= worksheet.columnCount; i++) {
        const cell = worksheet.getCell(1, i)
        if (cell && cell.value) {
          cols.push(cell.value.toString())
        }
      }
    }
  }
  return cols
}

function setMappingBasedOnTitles(cols: string[]) {
  for (const column of props.model.fields) {
    // try to map from cached values in db
    if (mapColumnsFromCacheData(cols, column.SystemName)) {
      continue
    }
    const mappedColumn = cols.find(c => c.toLowerCase().replace(/\s/g, '') === column.DisplayName.toLowerCase().replace(/\s/g, ''))
    if (utils.isDefined(mappedColumn)) {
      formModel.mapping[column.SystemName] = mappedColumn
    }
    else if (column.autoMap && column.autoMap.length) {
      const mapped = cols.find(c => column.autoMap && column.autoMap.includes(c.toLowerCase()))
      formModel.mapping[column.SystemName] = mapped || null
    }
  }
  updateMappingValidation()
}

// this method will map autocomplete map to the one selected by user for the module on previous import action
function mapColumnsFromCacheData(availableSheetColumns: string[], systemName: string): boolean {
  const cachedColumnsMapping = importMappings.columnsMapping

  if (cachedColumnsMapping && cachedColumnsMapping[systemName]) {
    for (let i = 0; i < availableSheetColumns.length; i++) {
      if (typeof cachedColumnsMapping[systemName] === 'string'
        && utils.isDefined(availableSheetColumns[i])
        && cachedColumnsMapping[systemName].replace(/\s/g, '').toLowerCase() === availableSheetColumns[i].replace(/\s/g, '').toLowerCase()) {
        formModel.mapping[systemName] = availableSheetColumns[i]
        return true
      }
    }
  }

  return false
}

function onMappingChange(mappingItem, selectedItem) {
  if (utils.isDefined(selectedItem)) {
    importMappings.columnsMapping![mappingItem.SystemName] = selectedItem // cache seleced column
  }
  else {
    selectedItem = undefined
    mappingItem.column = -1
    // reset the import mapping
    importMappings.columnsMapping![mappingItem.SystemName] = mappingItem.SystemName
  }
  updateMappingValidation(mappingItem.SystemName)
}

// to notify or warn user if same column from sheet is selected for two different columns
function updateMappingValidation(columnNameToCheck = null) {
  const mappingCounts = {}
  const columnMapping = {}

  for (const columnName in formModel.mapping) {
    const mappedColumn = formModel.mapping[columnName]
    if (mappedColumn) {
      mappingCounts[mappedColumn] = (mappingCounts[mappedColumn] || 0) + 1
      columnMapping[mappedColumn] = columnName
    }
  }

  for (const mappedColumn in mappingCounts) {
    if (mappingCounts[mappedColumn] > 1) {
      const column = props.model.fields.find(f => f.SystemName === columnMapping[mappedColumn])
      if (column) {
        column.mappingWarn = true
      }
    }
  }

  if (columnNameToCheck) {
    const columnToCheck = props.model.fields.find(f => f.SystemName === columnNameToCheck)
    if (columnToCheck) {
      const mappedColumn = formModel.mapping[columnToCheck.SystemName]
      columnToCheck.mappingWarn = mappingCounts[mappedColumn] > 1
    }
  }
}

function validateData() {
  if (excelWorkbook.value) {
    validData = []
    isValidating.value = true
    const fieldToColumnLabelMap = {}
    let haveUniqueConstraints: boolean = false
    const constraints = {}
    let nonEditableAttributesArticleStateLevel: string[] = []
    let nonEditableAttributesModelStateLevel: string[] = []
    let isSizeScaleEditableAtModelStateLevel: number = 1
    let isArticleNumberEditableAtArticleStateLevel: number = 1
    totalValid.value = totalInvalid.value = totalSkipped.value = 0

    const worksheet = excelWorkbook.value.getWorksheet(formModel.sheetId)
    if (worksheet) {
      const lastColumnIndex = worksheet.columnCount
      const statusCol = lastColumnIndex + 2
      // set Status column label
      worksheet.getCell(`${utils.numberToExcelColumnName(statusCol)}1`).value = t('general.status')

      if (utils.isDefined(props.model.importInfo.uniqueConstraints) && Array.isArray(props.model.importInfo.uniqueConstraints)) {
        haveUniqueConstraints = true
        props.model.importInfo.uniqueConstraints.forEach((uniqueConstraint) => {
          constraints[uniqueConstraint.name] = {}
        })
      }

      // Map column fields to their respective indices
      const mappedColumns: IField[] = []
      props.model.fields.forEach((mappedField) => {
        if (utils.isDefined(formModel.mapping[mappedField.SystemName])) {
          mappedColumns.push(Object.assign({}, mappedField, {
            column: excelColumns.value.indexOf(formModel.mapping[mappedField.SystemName]) + 1,
          }))
        }
      })

      // specific to articles module
      // we will find out the states with height article state rank and height model state rank
      // if the attribute is article level then apply the validation from height state ranked article state
      // if the attribute is model level then apply the validation from the height model state ranked article state
      // find the highestRankStateId of all the articles mapped and if the non editable attributes mapped then show the validation message
      // as these attributes are non editable to and make the excel invalid
      // load articles lookup data for validating states, specific to article module
      if (utils.isDefined(props.model.importInfo.validateStateEditableFields) && props.model.importInfo.validateStateEditableFields && !skipRestrictingPropertyUpdateBasedOnArticleState) {
        // const articleStateList = api.articleStatesList
        const highestArticleAndModelStateObj = getHighestStateIdOfMappedArticles(worksheet, mappedColumns)
        if (highestArticleAndModelStateObj.highestArticleStateDetails) {
          isArticleNumberEditableAtArticleStateLevel = highestArticleAndModelStateObj.highestArticleStateDetails.IsArticleNumberEditable
          nonEditableAttributesArticleStateLevel = highestArticleAndModelStateObj.highestArticleStateDetails.NonEditableAttributes.map((attr) => {
            return attr.AttributeSystemName
          })
        }

        if (highestArticleAndModelStateObj.highestModelStateDetails) {
          isSizeScaleEditableAtModelStateLevel = highestArticleAndModelStateObj.highestModelStateDetails.IsSizeScaleEditable
          nonEditableAttributesModelStateLevel = highestArticleAndModelStateObj.highestModelStateDetails.NonEditableAttributes.map((attr) => {
            return attr.AttributeSystemName
          })
        }
      }

      // lookuptable attributes
      const sourceAttributesMap = {}
      if (utils.isDefined(props.model.importInfo.validateRequiredLookupTableAttributes) && props.model.importInfo.validateRequiredLookupTableAttributes && !isEmpty(userStore.indexedLookupAttributeDefinition)) {
        Object.keys(userStore.indexedLookupAttributeDefinition).forEach((lookupName) => {
          const lookupDefinition = userStore.indexedLookupAttributeDefinition[lookupName]

          lookupDefinition.DestinationAttributes.forEach((attributeSystemName) => {
            const attribute = mappedColumns.filter(column => column.SystemName === attributeSystemName)[0]

            if (utils.isDefined(attribute) && attribute.IsRequired) {
              lookupDefinition.SourceAttributes.forEach((sourceAttributeSystemName) => {
                const displayName = mappedColumns.filter(column => column.SystemName === sourceAttributeSystemName)[0].DisplayName || ''
                let isMapped: boolean = false

                for (let s = 0; s < mappedColumns.length; s++) {
                  if (mappedColumns[s].column! > 0 && mappedColumns[s].SystemName === sourceAttributeSystemName) {
                    isMapped = true
                    break
                  }
                }

                if (!sourceAttributesMap[attributeSystemName]) {
                  sourceAttributesMap[attributeSystemName] = {
                    attributeDisplayName: attribute.DisplayName,
                    lookupSourceAttributesDisplayNameMap: {},
                  }
                }

                sourceAttributesMap[attributeSystemName].lookupSourceAttributesDisplayNameMap[displayName] = isMapped
              })
            }
          })
        })
      }

      // attribute vetting list criteria validation
      const vettingListCriteriaAttributes = mappedColumns.filter(column => utils.isDefined(column.Criteria) && Object.keys(column.Criteria!).length)
      vettingListCriteriaAttributes.forEach((attribute) => {
        const dependentAttributes: Record<string, any> = {}
        Object.entries(attribute.Criteria!).forEach(([criteriaKey, criteriaValues]) => {
          Object.entries(criteriaValues).forEach(([dependentAttributeKey, dependentAttributeValues]) => {
            if (!dependentAttributes[dependentAttributeKey]) {
              dependentAttributes[dependentAttributeKey] = {}
            }

            dependentAttributeValues.forEach((value: string) => {
              const lowerCaseValue = value.toString().toLowerCase()
              if (!dependentAttributes[dependentAttributeKey][lowerCaseValue]) {
                dependentAttributes[dependentAttributeKey][lowerCaseValue] = []
              }

              if (!dependentAttributes[dependentAttributeKey][lowerCaseValue].includes(criteriaKey.toLowerCase())) {
                dependentAttributes[dependentAttributeKey][lowerCaseValue].push(criteriaKey.toLowerCase())
              }
            })
          })
        })

        const reactiveDependingAttributes = { [attribute.SystemName]: dependentAttributes }
        dependingAttributesMap.push(reactiveDependingAttributes)
      })

      const columnsIndexMap = new Map<string, number>()
      // Iterate through each row using eachRow
      worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
        if (rowNumber === 1) {
          // Skip the first row which contains column headers
          row.eachCell((cell, colNumber) => {
            if (cell.value) {
              columnsIndexMap.set(cell.value.toString(), colNumber)
            }
          })
          return
        }
        const currentRowStatus: string[] = []
        let currentRowValid = true
        const rowData = {}

        // Skip filters
        if (formModel.filter.state === 1 && utils.isDefined(formModel.filter.columnName)) {
          const filterColIndex = columnsIndexMap.get(formModel.filter.columnName)
          let filterColValue: string | undefined
          if (filterColIndex) {
            filterColValue = row.getCell(filterColIndex).value?.toString()
          }
          const matchFilterCriteria = (formModel.filter.contains === 0 && utils.isValidStringValue(filterColValue)) /* Any Value */
            || (formModel.filter.contains === 1 && (!utils.isDefined(filterColValue) || filterColValue.trim().length === 0)) /* No Value */
            || (formModel.filter.contains === 2 && utils.isDefined(filterColValue) && filterColValue.toString().trim().toLowerCase() === formModel.filter.specificValue?.trim().toLowerCase()) /* A Specific Value */

          if ((formModel.filter.include === 0 /* Include */ && !matchFilterCriteria)
            || (formModel.filter.include === 1 /* Exclude */ && matchFilterCriteria)) {
            // Skip
            totalSkipped.value++
            return
          }
        }
        // Populate fieldToColumnLabelMap and rowData
        for (let i = 0; i < mappedColumns.length; i++) {
          if (mappedColumns[i].column !== null && excelColumns.value[mappedColumns[i].column! - 1]) {
            // Map of field SystemName to an object containing DisplayName in import sheet as well as its index(column number)
            fieldToColumnLabelMap[mappedColumns[i].SystemName] = {
              DisplayName: excelColumns.value[mappedColumns[i].column! - 1],
              column: mappedColumns[i].column,
            }
          }
        }

        for (let i = 0; i < mappedColumns.length; i++) {
          let cellValue = row.values[mappedColumns[i].column!]
          // if (mappedColumns[i].column !== null && excelColumns.value[mappedColumns[i].column! - 1]) {
          //   // map of field SystemName to an object containing DisplayName in import sheet as well as its index(column number)
          //   fieldToColumnLabelMap[mappedColumns[i].SystemName] = { DisplayName: excelColumns.value[mappedColumns[i].column! - 1], column: mappedColumns[i].column }
          // }

          // validate required field
          if (utils.isDefined(mappedColumns[i].column) && Number.isInteger(mappedColumns[i].column)) {
            if ((mappedColumns[i].IsRequired || (utils.isDefined(mappedColumns[i].isRequiredIfMapped) && mappedColumns[i].isRequiredIfMapped))
              && (!utils.isDefined(cellValue) || cellValue.toString().trim() === '')) {
              currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) is required and value cannot be empty`)
              currentRowValid = false
              continue
            }

            // validate state level non editable properties
            if (utils.isDefined(props.model.importInfo.validateStateEditableFields) && props.model.importInfo.validateStateEditableFields) {
              if ((nonEditableAttributesArticleStateLevel.length && nonEditableAttributesArticleStateLevel.includes(mappedColumns[i].SystemName))
                || (nonEditableAttributesModelStateLevel.length && nonEditableAttributesModelStateLevel.includes(mappedColumns[i].SystemName))
                || (mappedColumns[i].SystemName === 'SizeScale' && !isSizeScaleEditableAtModelStateLevel)
                || (mappedColumns[i].SystemName === 'ColorCode' && !isArticleNumberEditableAtArticleStateLevel)) {
                currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) is set as non editable at state level. Please remove the mapping`)
                currentRowValid = false
                continue
              }
            }

            switch (mappedColumns[i].AttributeType) {
              case AttributeType.Int:
              case AttributeType.Decimal:
                if (utils.isDefined(cellValue) && cellValue.toString().trim() !== '') {
                  const numValue = Number.parseFloat(cellValue.toString().trim())
                  if (!Number.isFinite(numValue)) {
                    currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) is not a valid number`)
                    currentRowValid = false
                    continue
                  }
                  else {
                    cellValue = numValue
                  }
                }
                else {
                  cellValue = 0
                }
                break
              case AttributeType.Bool: // dynamic attributes type will be bool but when sending to API it should be as string
                if (!utils.isDefined(cellValue) || cellValue.toString().trim() === '' || cellValue.toString().toUpperCase() === 'NO' || cellValue.toString().toUpperCase() === 'FALSE') {
                  cellValue = 'false'
                }
                else {
                  cellValue = 'true'
                }
                break
              // when working on any module with bool type static field(API expecting true/false without string) use this
              // case AttributeType.BoolStr: // bool string (attributes bool type is expecting 'string' value)
              //   if(!utils.isDefined(cellValue) || cellValue.toString().trim() === '' || cellValue.toString().toUpperCase() === 'NO' || cellValue.toString().toUpperCase() === 'FALSE') {
              //       cellValue = false
              //   }
              //   else {
              //       cellValue = true
              //   }
              //   break
              case AttributeType.BoolInt:
                if (!utils.isDefined(cellValue) || cellValue.toString().trim() === '' || cellValue.toString().toUpperCase() === 'NO' || cellValue.toString().toUpperCase() === 'FALSE') {
                  cellValue = 0
                }
                else {
                  cellValue = 1
                }
                break
              case AttributeType.IntBool: // TODO when working on module with this type check the exported value
                if (!utils.isDefined(cellValue) || cellValue.toString().trim() === '' || cellValue.toString().toUpperCase() === 'NO' || cellValue.toString().toUpperCase() === 'FALSE') {
                  cellValue = '0'
                }
                else {
                  cellValue = '1'
                }
                break
              case AttributeType.Status:
                if (utils.isDefined(cellValue) && cellValue !== 1 && cellValue !== 0 && String(cellValue).trim().toLowerCase() !== 'true' && String(cellValue).trim().toLowerCase() !== 'false' && String(cellValue).trim().toLowerCase() !== 'yes' && String(cellValue).trim().toLowerCase() !== 'no'
                  && String(cellValue).trim().toLowerCase() !== 'active' && String(cellValue).trim().toLowerCase() !== 'inactive' && String(cellValue).trim().toLowerCase() !== 'globally dropped' && String(cellValue).trim().toLowerCase() !== 'not assorted') {
                  currentRowStatus.push(
                    `${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) is not a valid status`,
                  )
                  currentRowValid = false
                  continue
                }
                else if (cellValue == null || cellValue === 1 || cellValue.toString().trim().toLowerCase() === 'true' || cellValue.toString().trim().toLowerCase() === 'yes' || cellValue.toString().trim().toLowerCase() === 'active') {
                  cellValue = 1
                }
                else {
                  cellValue = 0
                }
                break
              case AttributeType.Date: // date and date option
              case AttributeType.DateOption:
                if ((!utils.isDefined(cellValue) || cellValue === null || cellValue.toString().trim() === '') && !mappedColumns[i].IsRequired && !utils.isDefined(mappedColumns[i].isRequiredIfMapped)) {
                  cellValue = ''
                }
                else if (!utils.isDate(cellValue) && !utils.isNumber(cellValue)) {
                  // validate if the date is ISO string date
                  const formats = [
                    moment.ISO_8601,
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                  ]
                  // The true argument is there so the Moment won't try to parse the input if it doesn't exactly conform to one of the formats provided
                  if (!moment(cellValue, formats, true).isValid()) {
                    currentRowStatus.push(
                        `${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) is not a valid date`,
                    )
                    currentRowValid = false
                    continue
                  }
                }
                if (utils.isNumber(cellValue)) {
                  const date = new Date(cellValue)

                  // Set the time to noon to avoid timezone issues
                  date.setHours(12, 0, 0, 0)

                  // Convert to ISO string and extract the date part
                  const dateValueWithTime = date.toISOString()
                  const dateOnly = dateValueWithTime.split('T')[0]

                  cellValue = dateOnly
                }
                break
              case AttributeType.DateTime: // date time
                if ((!utils.isDefined(cellValue) || cellValue === null || cellValue.toString().trim() === '') && !mappedColumns[i].IsRequired && !utils.isDefined(mappedColumns[i].isRequiredIfMapped)) {
                  cellValue = ''
                }
                else if (!utils.isDate(cellValue) && !utils.isNumber(cellValue)) {
                  // validate if the date is ISO  string date
                  const formats = [
                    moment.ISO_8601,
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                  ]
                  // The true argument is there so the Moment won't try to parse the input if it doesn't exactly conform to one of the formats provided
                  if (!moment(cellValue, formats, true).isValid()) {
                    currentRowStatus.push(
                      `${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) is not a valid date`,
                    )
                    currentRowValid = false
                    continue
                  }
                }
                if (utils.isNumber(cellValue)) {
                  const date = new Date(cellValue)

                  // Set the time to noon to avoid timezone issues
                  date.setHours(12, 0, 0, 0)

                  // Convert to ISO string and extract the date part
                  cellValue = date.toISOString()
                }
                break
              case AttributeType.DateUtc:
                if ((!utils.isDefined(cellValue) || cellValue === null || cellValue.toString().trim() === '') && !mappedColumns[i].IsRequired && !utils.isDefined(mappedColumns[i].isRequiredIfMapped)) {
                  cellValue = ''
                }
                else if ((typeof cellValue !== 'string' && !utils.isDate(cellValue) && !utils.isNumber(cellValue))
                  || (typeof cellValue === 'string' && !utils.isValidDateWithFormat(cellValue, ['MM/DD/YYYY', 'MM/DD/YY']))) {
                  if (cellValue !== '' && typeof cellValue === 'string') {
                    const d = new Date(cellValue)
                    cellValue = new Date(d.setHours(12 - (d.getTimezoneOffset() / 60), 0, 0)).toISOString()
                  }
                  else {
                    currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) is not a valid date`)
                    currentRowValid = false
                    continue
                  }
                }
                // if cell formated in date then get date from integer set time to 12 UTC and convert to ISO string
                else if (utils.isNumber(cellValue)) {
                  const date = new Date(cellValue)
                  // Set the time to noon to avoid timezone issues
                  date.setHours(12, 0, 0, 0)
                  // Convert to ISO string and extract the date part
                  cellValue = date.toISOString()
                }
                // if cell contain date in one of the valid format create date using Date(year, month, day, ...) constructor and set time to 12 UTC
                else if (cellValue !== '' && typeof cellValue === 'string') {
                  const parts = cellValue.split('/')
                  const year = parts[2].length > 2 ? Number.parseInt(parts[2]) : Number.parseInt(parts[2]) + 2000
                  cellValue = new Date(year, Number.parseInt(parts[0]) - 1, Number.parseInt(parts[1]), 12 - (new Date().getTimezoneOffset() / 60)).toISOString()
                }
                break
              case AttributeType.Lookup:
              case AttributeType.ColorPalette:
                if (utils.isDefined(cellValue) && cellValue !== '') {
                  let res: any | undefined = findInLookup(mappedColumns[i], cellValue)
                  let invalidMessage = `${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) contains a value that cannot be found in the list`
                  // filter based on the color palette conditions
                  if (utils.isDefined(res) && mappedColumns[i].isfilterCriteria) {
                    res = validateCriteria(mappedColumns[i], cellValue, rowData)
                    if (!utils.isDefined(res)) {
                      invalidMessage = `${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) value does not match the attribute criteria`
                    }
                  }
                  if (!utils.isDefined(res)) {
                    currentRowStatus.push(invalidMessage)
                    currentRowValid = false
                    continue
                  }
                  if (utils.isDefined(mappedColumns[i].useLookupName) && mappedColumns[i].useLookupName === true) {
                    cellValue = String(cellValue)
                    if (cellValue != null && cellValue !== '' && props.model.modelPath === 'catalogs.articles' && (mappedColumns[i].SystemName === 'RetailIntroMonthId' || mappedColumns[i].SystemName === 'RetailExitMonthId')) {
                      const targetColumns = ['ArticleNumber', 'ModelNumber', 'ArticleName']
                      let article: Article | undefined

                      for (const columnName of targetColumns) {
                        if (fieldToColumnLabelMap[columnName] !== -1) {
                          article = props.data!.find(article =>
                            article.ArticleNumber === rowData[columnName]
                            || article.ModelNumber === rowData[columnName]
                            || article.ArticleName === rowData[columnName],
                          )
                          if (utils.isDefined(article)) { break }
                        }
                      }

                      if (utils.isDefined(article) && !article.AllowRetailWindowUpdate) {
                        currentRowStatus.push(`Retail Window Update is restricted on article. ${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) value cannot be updated.`)
                        currentRowValid = false
                      }
                      else if ((utils.isDefined(article) && article.AllowRetailWindowUpdate) || !utils.isDefined(article)) {
                        const retailWindowAttribute = userStore.activeCatalog!.RetailWindowAttribute
                        const retailWindowAttributeField = mappedColumns.filter(column => column.SystemName === retailWindowAttribute)[0]
                        if (utils.isDefined(retailWindowAttributeField) && utils.isDefined(retailWindowAttributeField.column) && retailWindowAttributeField.column !== -1) { // retail window attribute mapped
                          const retailWindowValue = row.values[retailWindowAttributeField.column]
                          if (utils.isDefined(retailWindowValue) && retailWindowValue !== '') {
                            if (mappedColumns[i].SystemName === 'RetailIntroMonthId') {
                              const lookupDataList = lookupData.RetailIntroMonthId
                              const retailWindowArray = Object.values(lookupDataList) as CatalogShipmentWindowRange[]
                              const retailWindowsWithAttributeValue = retailWindowArray.filter(window => window.CriteriaAttributeValue === rowData[retailWindowAttribute])

                              const retailIntroMonths = retailWindowsWithAttributeValue.map((record) => {
                                return record.RetailIntroMonth.toLowerCase()
                              })
                              if (!retailIntroMonths.includes(cellValue.toLowerCase())) {
                                currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) value does not match the configured Criteria Attribute Value`)
                                currentRowValid = false
                              }
                              else {
                                cellValue = retailWindowsWithAttributeValue.filter(window => window.RetailIntroMonth === cellValue)[0].Id
                              }
                            }
                            else if (mappedColumns[i].SystemName === 'RetailExitMonthId') {
                              const lookupDataList = lookupData.RetailExitMonthId
                              const retailIntroMonthField = mappedColumns.filter(column => column.SystemName === 'RetailIntroMonthId')[0]
                              const retailIntroMonthValue = row.values[retailIntroMonthField.column!]
                              const retailWindowArray = Object.values(lookupDataList) as CatalogShipmentWindowRange[]
                              const retailWindowsWithAttributeValue = retailWindowArray.filter(window => window.CriteriaAttributeValue === rowData[retailWindowAttribute] && window.RetailIntroMonth === retailIntroMonthValue)
                              const retailExitMonths = retailWindowsWithAttributeValue.map((record) => {
                                return record.RetailExitMonth.toLowerCase()
                              })
                              if (!retailExitMonths.includes(cellValue.toLowerCase())) {
                                currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) value does not match the configured Criteria Attribute Value`)
                                currentRowValid = false
                              }
                              else {
                                const retailWindowId = retailWindowsWithAttributeValue.filter(window => window.RetailExitMonth === cellValue)[0].Id
                                // validate if the asigned range is with in parent range for child catalog
                                if (userStore.activeCatalog?.DataSourceTypeId === 3 && utils.isDefined(article)) {
                                  const assignedShipmentWindow = activeCatalogShipmentWindowRangeList.filter(window => window.Id === retailWindowId)[0]
                                  if ((utils.isDefined(article.PShipmentStartDate) && article.PShipmentStartDate != null && article.PShipmentStartDate.toString().trim() !== ''
                                    && utils.isDefined(article.PShipmentEndDate) && article.PShipmentEndDate != null && article.PShipmentEndDate.toString().trim() !== '')) {
                                    if (!(moment(assignedShipmentWindow.ShipmentStartDate).isSameOrAfter(article.PShipmentStartDate, 'day')
                                      && moment(assignedShipmentWindow.ShipmentEndDate).isSameOrBefore(article.PShipmentEndDate, 'day'))) {
                                      currentRowStatus.push('Assigned Retail Window is out of Parent Retail Window Range')
                                      currentRowValid = false
                                    }
                                    else {
                                      cellValue = retailWindowId
                                    }
                                  }
                                  else {
                                    currentRowStatus.push('Parent Retail Window is not assigned. Can not assign Retail Window')
                                    currentRowValid = false
                                  }
                                }
                                else {
                                  cellValue = retailWindowId
                                }
                              }
                            }
                          }
                          else {
                            currentRowStatus.push(`'Retail Window Attribute(${retailWindowAttributeField.DisplayName}) does not have value for ${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) validation`)
                            currentRowValid = false
                          }
                        }
                        else {
                          if (utils.isDefined(article)) {
                            if (utils.isDefined(retailWindowAttribute) && article[retailWindowAttribute]?.toString().trim() !== '') { // validate existing articles when attribute is not mapped
                              const retailWindowValue = article[retailWindowAttribute]
                              if (mappedColumns[i].SystemName === 'RetailIntroMonthId') {
                                const lookupDataList = lookupData.RetailIntroMonthId
                                const retailWindowArray = Object.values(lookupDataList) as CatalogShipmentWindowRange[]
                                const retailWindowsWithAttributeValue = retailWindowArray.filter(window => window.CriteriaAttributeValue === retailWindowValue)
                                const retailIntroMonths = retailWindowsWithAttributeValue.map((record) => {
                                  return record.RetailIntroMonth.toLowerCase()
                                })
                                if (!retailIntroMonths.includes(cellValue.toLowerCase())) {
                                  currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) value does not match the configured Criteria Attribute Value`)
                                  currentRowValid = false
                                }
                                else {
                                  cellValue = retailWindowsWithAttributeValue.filter(window => window.RetailIntroMonth === cellValue)[0].Id
                                }
                              }
                              else if (mappedColumns[i].SystemName === 'RetailExitMonthId') {
                                const lookupDataList = lookupData.RetailExitMonthId
                                const retailIntroMonthField = mappedColumns.filter(column => column.SystemName === 'RetailIntroMonthId')[0]
                                const retailIntroMonthValue = row.values[retailIntroMonthField.column!]
                                const retailWindowArray = Object.values(lookupDataList) as CatalogShipmentWindowRange[]
                                const retailWindowsWithAttributeValue = retailWindowArray.filter(window => window.CriteriaAttributeValue === retailWindowValue && window.RetailIntroMonth === retailIntroMonthValue)
                                const retailExitMonths = retailWindowsWithAttributeValue.map((record) => {
                                  return record.RetailExitMonth.toLowerCase()
                                })
                                if (!retailExitMonths.includes(cellValue.toLowerCase())) {
                                  currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) value does not match the configured Criteria Attribute Value`)
                                  currentRowValid = false
                                }
                                else {
                                  const retailWindowId = retailWindowsWithAttributeValue.filter(window => window.RetailExitMonth === cellValue)[0].Id
                                  // validate if the asigned range is with in parent range for child catalog
                                  if (userStore.activeCatalog?.DataSourceTypeId === 3) {
                                    const assignedShipmentWindow = activeCatalogShipmentWindowRangeList.filter(window => window.Id === retailWindowId)[0]
                                    if ((utils.isDefined(article.PShipmentStartDate) && article.PShipmentStartDate != null && article.PShipmentStartDate.toString().trim() !== ''
                                      && utils.isDefined(article.PShipmentEndDate) && article.PShipmentEndDate != null && article.PShipmentEndDate.toString().trim() !== '')) {
                                      if (!(moment(assignedShipmentWindow.ShipmentStartDate).isSameOrAfter(article.PShipmentStartDate, 'day')
                                        && moment(assignedShipmentWindow.ShipmentEndDate).isSameOrBefore(article.PShipmentEndDate, 'day'))) {
                                        currentRowStatus.push('Assigned Retail Window is out of Parent Retail Window Range')
                                        currentRowValid = false
                                      }
                                      else {
                                        cellValue = retailWindowId
                                      }
                                    }
                                    else {
                                      currentRowStatus.push('Parent Retail Window is not assigned. Can not assign Retail Window')
                                      currentRowValid = false
                                    }
                                  }
                                  else {
                                    cellValue = retailWindowId
                                  }
                                }
                              }
                            }
                            else {
                              currentRowStatus.push(`'Retail Window Attribute(${retailWindowAttributeField.DisplayName}) does not have value for ${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) validation`)
                              currentRowValid = false
                            }
                          }
                          else {
                            currentRowStatus.push(`'Retail Window Attribute(${retailWindowAttributeField.DisplayName}) is not mapped for new article ${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) validation`)
                            currentRowValid = false
                          }
                        }
                      }
                    }
                  }
                  else {
                    if (mappedColumns[i].AttributeType !== AttributeType.ColorPalette) {
                      cellValue = utils.isDefined(mappedColumns[i].lookupIdField) ? String(res[mappedColumns[i].lookupIdField!]) : res.Id
                    }
                    else {
                      // color palette values are stores as key value pair
                      const objectVal = {}
                      objectVal[res.ColorId] = res.ColorName
                      cellValue = JSON.stringify(objectVal) // for color palette type attribute save the values as json object {color id: color value}
                    }
                  }
                }
                break
              case AttributeType.LookupMany:
                if (utils.isDefined(cellValue)) {
                  const lookupValues = cellValue.split(',')
                  const cellValueArray: string[] = []
                  for (let index = 0; index < lookupValues.length; index++) {
                    const res = findInLookup(mappedColumns[i], lookupValues[index])
                    if (!utils.isDefined(res)) {
                      currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) contains a value ${lookupValues[index]} that cannot be found in the list`)
                      currentRowValid = false
                      break
                    }
                    if (utils.isDefined(mappedColumns[i].useLookupName) && mappedColumns[i].useLookupName === true) {
                      cellValueArray.push(cellValue)
                    }
                    else {
                      cellValueArray.push(utils.isDefined(mappedColumns[i].lookupIdField) ? String(res[mappedColumns[i].lookupIdField!]) : res.Id)
                    }
                  }
                  cellValue = cellValueArray
                }
                else {
                  cellValue = []
                }
                break
              case AttributeType.ArticleNumber:
                if (utils.isDefined(cellValue)) {
                  const res = findInLookup(mappedColumns[i], cellValue)
                  if (!utils.isDefined(res)) {
                    currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) contains a value ${cellValue} that cannot be found in the list`)
                    currentRowValid = false
                    continue
                  }
                  if (utils.isDefined(mappedColumns[i].useLookupName) && mappedColumns[i].useLookupName === true) {
                    cellValue = String(cellValue)
                  }
                  else {
                    cellValue = utils.isDefined(mappedColumns[i].lookupIdField) ? String(res[mappedColumns[i].lookupIdField!]) : res.Id
                  }
                }
                break
              case AttributeType.MultiValue:
                // multivalues should be imported as pipe seprated
                if (!utils.isDefined(mappedColumns[i].VettingList) || (utils.isDefined(mappedColumns[i].VettingList) && mappedColumns[i].VettingList!.length === 0)) {
                  if (utils.isDefined(cellValue)) {
                    const multiValueArray: string[] = cellValue.split('|').map((tagValue: string) => {
                      return utils.removeInvisibleCharactersFromString(tagValue) // remove invisible characters
                    })
                    const counts: { [key: string]: number } = {}
                    const uniqueValues: string[] = []

                    for (let index = 0; index < multiValueArray.length; index++) {
                      if (counts[multiValueArray[index]] === undefined) {
                        counts[multiValueArray[index]] = 1
                        uniqueValues.push(multiValueArray[index])
                      }
                      else {
                        currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) contains a duplicate value`)
                        currentRowValid = false
                        break
                      }
                    }

                    cellValue = uniqueValues
                  }
                  else { // send empty array
                    cellValue = []
                  }
                }
                break
              default:
                if (utils.isDefined(cellValue)) {
                  cellValue = utils.removeInvisibleCharactersFromString(String(cellValue))
                }
                else {
                  cellValue = ''
                }
                break
            }
          }
          // validate vetting list and criteria attributes
          if (utils.isDefined(mappedColumns[i].VettingList) && mappedColumns[i].VettingList!.length) {
            if (mappedColumns[i].AttributeType === AttributeType.Int) {
              if (!utils.isDefined(cellValue) || cellValue.toString().trim() === '') {
                cellValue = 0
              }
              else if (!utils.isNumber(cellValue)) {
                currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) is not a valid number`)
                currentRowValid = false
                continue
              }
            }
            else if (mappedColumns[i].AttributeType === AttributeType.Nvarchar) {
              if (!utils.isDefined(cellValue)) {
                cellValue = ''
              }
              else {
                cellValue = utils.removeInvisibleCharactersFromString(String(cellValue))
              }
            }
            if (cellValue !== '' && utils.isDefined(cellValue)) {
              let valuesArray: string[] = [cellValue]
              if (mappedColumns[i].AttributeType === AttributeType.MultiValue) {
                valuesArray = cellValue.toString().trim().split('|').map((value) => {
                  return utils.removeInvisibleCharactersFromString(value)
                })
                cellValue = valuesArray
              }
              const replacedValues: string[] = []
              valuesArray.forEach((cValue) => {
                const attributeAllowedValues = mappedColumns[i].VettingList!.map((value) => {
                  return value.toLowerCase()
                })
                if (utils.isDefined(cValue) && cValue !== '' && Number(cValue) !== 0) {
                  const valueIndex = attributeAllowedValues.indexOf(cValue.toString().toLowerCase())
                  if (valueIndex === -1) {
                    currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) value ${cValue} is not part of attribute vetting list`)
                    currentRowValid = false
                  }
                  else if (utils.isDefined(mappedColumns[i].Criteria) && Object.keys(mappedColumns[i].Criteria!).length) {
                    let attributeCriteria = {}
                    dependingAttributesMap.forEach((criteriaAttr) => {
                      for (const criteriaKey of Object.keys(criteriaAttr)) {
                        if (criteriaKey.toLowerCase() === mappedColumns[i].SystemName.toLowerCase()) {
                          attributeCriteria = criteriaAttr[criteriaKey]
                          break
                        }
                      }
                    })

                    let isCriteriaNotSatisfied: boolean = false
                    for (const dependentAttributeField of Object.keys(attributeCriteria)) {
                      if (utils.isDefined(fieldToColumnLabelMap[dependentAttributeField])) {
                        const dependantAttributeValue = row.values[fieldToColumnLabelMap[dependentAttributeField].column]
                        if (utils.isDefined(attributeCriteria[dependentAttributeField][dependantAttributeValue.toString().toLowerCase()])) {
                          const index = attributeCriteria[dependentAttributeField][dependantAttributeValue.toString().toLowerCase()].indexOf(cValue.toString().toLowerCase())
                          if (index === -1) {
                            isCriteriaNotSatisfied = true
                          }
                        }
                        else {
                          isCriteriaNotSatisfied = true
                        }
                      }
                    }
                    if (isCriteriaNotSatisfied) {
                      currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) value does not match the attribute criteria`)
                      currentRowValid = false
                    }
                    else { // set the value from the vetting list
                      replacedValues.push(mappedColumns[i].VettingList![valueIndex])
                    }
                  }
                  else {
                    replacedValues.push(mappedColumns[i].VettingList![valueIndex])
                  }
                }
              })
              if (currentRowValid) {
                cellValue = (mappedColumns[i].AttributeType === AttributeType.MultiValue) ? replacedValues : replacedValues[0]
              }
            }
            else { // send empty array
              cellValue = (mappedColumns[i].AttributeType === AttributeType.MultiValue) ? [] : cellValue
            }
          }

          // Validate max length
          if (utils.isDefined(mappedColumns[i].maxLength) && mappedColumns[i].maxLength! > 0 && cellValue && cellValue.length > mappedColumns[i].maxLength!) {
            currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) exceeds the maximum allowed length of ${mappedColumns[i].maxLength}`)
            currentRowValid = false
            continue
          }

          // Validate min length
          if (utils.isDefined(mappedColumns[i].minLength) && mappedColumns[i].minLength! > 0 && cellValue && cellValue.length < mappedColumns[i].minLength!) {
            currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) must be of minimum  ${mappedColumns[i].minLength} characters`)
            currentRowValid = false
            continue
          }

          // Validate regex pattern
          if (utils.isDefined(mappedColumns[i].pattern) && mappedColumns[i].pattern!.length > 0 && utils.isDefined(cellValue) && cellValue.toString().trim() !== '' && !new RegExp(mappedColumns[i].pattern!).test(cellValue as string)) {
            const pattern = new RegExp(mappedColumns[i].pattern!)
            if (!pattern.test(cellValue)) {
              currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) does not match the required format: ${mappedColumns[i].messages!.pattern}` || '')
              currentRowValid = false
              continue
            }
          }

          // Validate for exist constraints
          if (utils.isDefined(mappedColumns[i].dataExistConstraints) && !isEmpty(mappedColumns[i].dataExistConstraints)) {
            if (utils.isDefined(cellValue)) {
              const rslt = findInExistConstraintsData(mappedColumns[i], cellValue, rowData)
              if (!utils.isDefined(rslt) && !mappedColumns[i].dataExistConstraints!.shouldNotExist) {
                const errorMessage = utils.isDefined(mappedColumns[i].dataExistConstraints!.errorMessage)
                  ? mappedColumns[i].dataExistConstraints!.errorMessage
                  : 'contains a value that cannot be found in database'
                currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) ${errorMessage}`)
                currentRowValid = false
                continue
              }
              else if (utils.isDefined(rslt) && mappedColumns[i].dataExistConstraints!.shouldNotExist) {
                // let validRecord = true
                // // Specific for catalogs delivery dates module.
                // // when description already exist in db and its delivery date is same then we need to allow the record
                // if (utils.isDefined(mappedColumns[i].dataExistConstraints.isCatalogCRD) && mappedColumns[i].validation.dataExistConstraints.isCatalogCRD) {
                //   const deliveryDateEntered = rowData.CustomerRequiredDate
                //   // if the imported delivery date and db record's delivery date is same then its a valid entry for update
                //   if (rslt.CustomerRequiredDate === deliveryDateEntered) {
                //     validRecord = false
                //   }
                // }

                // if (validRecord) {
                //   const errorMessage = 'already exist in database'// Show error message if value already exists in DB
                //   currentRowStatus.push(`${vm.acModel[mappedColumns[i].SystemName].v || mappedColumns[i].displayLabel} ${errorMessage}`)
                //   ws[cellRef].c = 'Invalid value'
                //   currentRowValid = false
                // }
              }
              // validate size scales and check for obsolete size scales
              if (mappedColumns[i].SystemName === 'MasterSizeScale' || mappedColumns[i].SystemName === 'SizeScale') {
                let modelData
                if (utils.isDefined((rowData as any).ModelNumber)) {
                  modelData = props.data?.filter(article => article.ModelNumber === (rowData as any).ModelNumber)[0]
                }
                else if (utils.isDefined((rowData as any).LicenseeModelNumber)) {
                  const catalogModelAttribute = userStore.activeCatalog?.ModelAttribute
                  const licenseeArticles = filterArticleByModelAttribute((rowData as any).LicenseeModelNumber, catalogModelAttribute)
                  if (licenseeArticles.length) {
                    modelData = licenseeArticles[0]
                  }
                }
                // check if the assigned Size Scale is same as the Excel Size Scale, id its same and SizeScale is obsolete we need to keep as it is else show error
                if (modelData) {
                  if (mappedColumns[i].SystemName === 'MasterSizeScale') {
                    if (utils.isDefined(rslt) && utils.isDefined(rslt.Criteria) && rslt.Criteria !== '') {
                      const criteriaJSON = rslt.Criteria
                      let validItemsCount = 0
                      for (const key in criteriaJSON) {
                        if (criteriaJSON.hasOwnProperty(key)) {
                          let crts = criteriaJSON[key]
                          let isValid = false
                          if (!Array.isArray(criteriaJSON[key])) {
                            crts = [criteriaJSON[key]]
                          }
                          crts.forEach((crt) => {
                            if (crt.toString().toLowerCase() === modelData[key].toString().toLowerCase()) {
                              isValid = true
                            }
                          })

                          if (isValid) {
                            validItemsCount++
                          }
                        }
                      }
                      if (validItemsCount !== Object.keys(criteriaJSON).length) {
                        currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber})  value does not match the configured Criteria`)
                        currentRowValid = false
                      }
                    }
                  }

                  if ((mappedColumns[i].SystemName === 'MasterSizeScale' && modelData.MasterSizeScale !== String(cellValue) && rslt.IsObsolete)
                    || (mappedColumns[i].SystemName === 'SizeScale' && modelData.SizeScale !== String(cellValue) && rslt.IsObsolete)) {
                    currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].SystemName].column)}${rowNumber}) value is obsolete.`)
                    currentRowValid = false
                    continue
                  }
                }
              }
              cellValue = String(cellValue)
            }
          }

          rowData[mappedColumns[i].SystemName] = cellValue
        }

        if (haveUniqueConstraints && utils.isDefined(props.model.importInfo.uniqueConstraints)) {
          props.model.importInfo.uniqueConstraints.forEach((constraint) => {
            const constraintValues: string[] = []
            const columnLabels: string[] = []
            for (let i = 0; i < constraint.fields.length; i++) {
              if (fieldToColumnLabelMap[constraint.fields[i]]) {
                constraintValues.push(rowData[constraint.fields[i]])
                columnLabels.push(fieldToColumnLabelMap[constraint.fields[i]].DisplayName)
              }
            }
            if (constraintValues.length) {
              const constraintValueStr = constraintValues.join('//')
              if (!utils.isDefined(constraints[constraint.name][constraintValueStr])) {
                constraints[constraint.name][constraintValueStr] = 1
              }
              else {
                currentRowStatus.push(
                  `This row contains a duplicate record (must be unique on: ${columnLabels.join(', ')})`,
                )
                currentRowValid = false
              }
            }
          })
        }

        // validate txPattern (attribute validation expression)
        const attributesWithTxPattern = mappedColumns.filter(field => utils.isDefined(field.txPattern) && field.txPattern.length)
        if (attributesWithTxPattern && attributesWithTxPattern.length) {
          attributesWithTxPattern.forEach((field) => {
            let isValidCellValue: boolean = true
            if ([AttributeType.DateUtc, AttributeType.DateOption, AttributeType.Date, AttributeType.DateTime].includes(field.AttributeType)) {
              if (!utils.isDate(rowData[field.SystemName]) && !isNumber(rowData[field.SystemName])) {
                // validate if the date is ISO  string date
                const formats = [
                  moment.ISO_8601,
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                ]
                if (!moment(rowData[field.SystemName], formats, true).isValid()) {
                  isValidCellValue = false
                }
              }
            }
            if (isValidCellValue) {
              const isValid = validateTxPattern(field, rowData)
              if (utils.isDefined(isValid) && !isValid) {
                currentRowStatus.push(`${fieldToColumnLabelMap[field.SystemName].DisplayName} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[field.SystemName].column)}${rowNumber}) ${field.messages?.pattern}`)
                currentRowValid = false
              }
            }
          })
        }

        // validate Article Price Locking
        if (utils.isDefined(props.model.importInfo.validateArticleLocking) && props.model.importInfo.validateArticleLocking) {
          if (userStore.activeCatalog!.IsPriceByStyleApply) {
            if (props.action.id === 'setPricesByLicenseeModelNumber') {
              // find the model number of mapped LicenseeModelNumber
              const catalogModelAttribute = userStore.activeCatalog?.ModelAttribute
              const licenseeArticles = filterArticleByModelAttribute((rowData as any).LicenseeModelNumber, catalogModelAttribute)
              if (licenseeArticles.length) {
                // if any of the article is locked then set showReasonPage flag true and break
                const lockedLicenseeArticles = licenseeArticles.filter(article => article.Locked === 1)
                if (lockedLicenseeArticles.length) {
                  currentRowStatus.push(`The article or one of the articles in the model is locked. The price values cannot be updated.`)
                  currentRowValid = false
                }
                else {
                  for (let j = 0; j < licenseeArticles.length; j++) {
                    // check if any of the model articles is locked
                    const licenseeModelNumberArticleList = props.data!.filter(article => article.ModelNumber === licenseeArticles[j].ModelNumber)
                    const lockedLicenseeModelArticles = licenseeModelNumberArticleList.filter(article => article.Locked === 1)
                    if (lockedLicenseeModelArticles.length) {
                      currentRowStatus.push(`The article or one of the articles in the model is locked. The price values cannot be updated.`)
                      currentRowValid = false
                      break
                    }
                  }
                }
              }
            }
            else if (props.action.id === 'import') { // prices module import Not yet implemented t1sweb
              // if any of the article is locked then set showReasonPage flag true and break
              const modelArticles = props.data!.filter((article: Article) => article.ModelNumber === (rowData as any).ModelNumber && article.Locked === 1)
              if (modelArticles.length) {
                currentRowStatus.push(`The article or one of the articles in the model is locked. The price values cannot be updated.`)
                currentRowValid = false
              }
            }
          }
          else {
            if (props.action.id === 'import') { // prices module import Not implemented in t1sweb
              // if any of the mapped article is locked
              const lockedArticle = props.data!.filter((article: Article) => article.ArticleNumber === (rowData as any).ArticleNumber && article.Locked === 1)[0]
              if (utils.isDefined(lockedArticle)) {
                currentRowStatus.push(`The article is locked. The price values cannot be updated.`)
                currentRowValid = false
              }
            }
          }
        }

        // show error message for the required lookuptable attribute if the source attributes are not mapped
        if (!isEmpty(sourceAttributesMap)) {
          for (const requiredLookuptableAttribute in sourceAttributesMap) {
            if (requiredLookuptableAttribute in sourceAttributesMap) {
              const errorMessages: string[] = []
              const attributeInfo = sourceAttributesMap[requiredLookuptableAttribute]
              Object.keys(attributeInfo.lookupSourceAttributesDisplayNameMap).forEach((displayName) => {
                if (!attributeInfo.lookupSourceAttributesDisplayNameMap[displayName]) {
                  const errorMessage = `Unable to lookup value for ${attributeInfo.attributeDisplayName}, as the following Source Attribute(s) ${displayName} not mapped.`
                  errorMessages.push(errorMessage)
                }
              })
              if (errorMessages.length && !currentRowStatus.includes(errorMessages.join('\n'))) {
                currentRowStatus.push(errorMessages.join('\n'))
                currentRowValid = false
              }
            }
          }
          // validate the lookup values and assign valid values and show error message if source attribute values are not valid
          const lookupTableAttributes = props.model.fields.filter(filed => filed.AttributeType === AttributeType.LookupTable)
          if (lookupTableAttributes.length) {
            lookupTableAttributes.forEach((attribute) => {
              const lookupDefinition = userStore.indexedLookupAttributeDefinition[attribute.field]

              if (lookupDefinition) {
                const attributeLookupValues = lookupDefinition.Values
                let matched = false

                for (let j = 0; j < attributeLookupValues.length; j++) {
                  let allAttributesMatch = true
                  const value = attributeLookupValues[j]

                  Object.keys(value.Source).forEach((attributeSystemName) => {
                    if (rowData[attributeSystemName] == null || value.Source[attributeSystemName].toString().toLowerCase() !== rowData[attributeSystemName].toString().toLowerCase()) {
                      allAttributesMatch = false
                    }
                  })

                  if (allAttributesMatch) {
                    rowData[attribute.field] = value.Destination[attribute.field]
                    matched = true
                    break
                  }
                }

                const attributeInfo = sourceAttributesMap[attribute.field]
                if (!matched && attribute.IsRequired) {
                  // Show error message for required attributes
                  const sourceAttributes = Object.keys(attributeInfo.lookupSourceAttributesDisplayNameMap)
                  currentRowStatus.push(`Did not find any valid match for Lookup table attribute ${attribute.AttributeDisplayName}, please provide the correct values for the source attribute(s) ${sourceAttributes.join(', ')}`)
                  currentRowValid = false
                }
              }
            })
          }
        }

        if (utils.isDefined(props.model.importInfo.atLeastOneRequiredConstraints) && Array.isArray(props.model.importInfo.atLeastOneRequiredConstraints)) {
          props.model.importInfo.atLeastOneRequiredConstraints.forEach((atLeastOneRequiredConstraint) => {
            let containsAtLeastOneValue = false
            const displayNames: string[] = []
            for (let ovi = 0; ovi < atLeastOneRequiredConstraint.fields.length; ovi++) {
              const field = props.model.fields.filter(field => field.SystemName === atLeastOneRequiredConstraint.fields[ovi])[0]
              if (field) {
                displayNames.push(field.DisplayName)
              }
              const columnValue = rowData[atLeastOneRequiredConstraint.fields[ovi]]
              if (utils.isDefined(columnValue) && columnValue !== null && columnValue.toString().trim() !== '') {
                containsAtLeastOneValue = true
                break
              }
            }
            if (!containsAtLeastOneValue) {
              currentRowStatus.push(`This row missing a required value (there should be at least one value on either of: ${displayNames.join(', ')}) fields`)
              currentRowValid = false
            }
          })
        }

        if (currentRowValid) {
          validData.push(rowData)
          totalValid.value++
        }
        else {
          totalInvalid.value++
          // worksheet.getCell(rowNumber, statusCol).value = { formula: currentRowStatus.join() }
          worksheet.getCell(`${utils.numberToExcelColumnName(statusCol)}${rowNumber}`).value = `Invalid row: ${currentRowStatus.join(', ')}`
        }
      })
    }

    isValidating.value = false
  }
}

// function to find the Lookup value using case-insensitive strict matching
function findInLookup(field: IField, cellValue: number | string) {
  const lookupMap = lookupData[field.SystemName] as Record<string, any>

  if (utils.isDefined(lookupMap) && Object.keys(lookupMap).length > 0) {
    // value is the key(color palatte attributes)
    if (utils.isDefined(lookupMap[cellValue])) {
      return lookupMap[cellValue]
    }
    return Object.values(lookupMap).find((item) => {
      const lookupField = field.lookupDisplayField
      return item[lookupField!] && String(item[lookupField!]).toLowerCase() === String(cellValue).toLowerCase()
    })
  }
  return undefined
}

// to validate the color criteria
function validateCriteria(field: IField, cellValue, rowData) {
  let record = {}
  let isValidRecordFound: boolean = false
  const lookupDataList = lookupData.ColorId as Record<number, SellerColorModel>
  const sameNameDataList = Object.values(lookupDataList).filter(color => color.ColorName === cellValue)
  for (let i = 0, len = sameNameDataList.length; i < len; i++) {
    const dataCriteriaJSON = sameNameDataList[i].ColorCriteria // primary color and color palette attributes
    if (utils.isDefined(dataCriteriaJSON) && dataCriteriaJSON !== '' && dataCriteriaJSON.length) {
      try {
        const dataCriteria = JSON.parse(dataCriteriaJSON)
        let validItemsCount: number = 0
        for (const key of Object.keys(dataCriteria)) {
          if (dataCriteria[key].toString().toLowerCase() === rowData[key].toString().toLowerCase()) {
            validItemsCount++
          }
        }
        if (validItemsCount === Object.keys(dataCriteria).length) {
          record = sameNameDataList[i]
          isValidRecordFound = true
        }
      }
      catch (error) {
        console.warn('Criteria : Invalid JSON format')
      }
    }
    else {
      record = sameNameDataList[i]
      isValidRecordFound = true
    }
  }

  if (isValidRecordFound) {
    return record
  }
  else {
    return undefined
  }
}

// as the licencee model attribute is dynamic we need to filter it this way
function filterArticleByModelAttribute(mappedLicenseeModelNumber: string, modelAttribute: string | undefined) {
  const articleList: Article[] = []
  if (modelAttribute) {
    props.data?.forEach((article) => {
      if (utils.isDefined(article[modelAttribute]) && article[modelAttribute] === mappedLicenseeModelNumber) {
        articleList.push(article)
      }
    })
  }
  return articleList
}

async function saveResult() {
  resultsLoading.value = true
  try {
    await generateResultsFile()
  }
  finally {
    resultsLoading.value = false
  }
}

async function generateResultsFile() {
  if (excelWorkbook.value) {
    const buffer = await excelWorkbook.value.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', 'result.xlsx')
    link.click()
  }
}

// this method is to get the stateid's of the highest stateranked article and model
// as we have multiple imports and the mapping fields are different here we will filter the articles based on the mapped fields
function getHighestStateIdOfMappedArticles(worksheet, mappedColumns) {
  let mappedArticles: Article[] = []
  for (let i = 0; i < mappedColumns.length; i++) {
    if (mappedColumns[i].SystemName === 'ModelNumber') {
      const primaryColumnIndex: number = mappedColumns[i].column
      if (primaryColumnIndex !== null) {
        const columnData: string[] = []
        worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          // Skip the first row which contains column headers
          if (rowNumber > 1) {
            const cellValue = row.getCell(primaryColumnIndex).value
            if (cellValue !== undefined && cellValue !== null) {
              columnData.push(cellValue.toString())
            }
          }
        })
        if (columnData.length) {
          columnData.forEach((mappedColumnValue) => {
            const articleWithModelNumber = props.data!.filter(article => article.ModelNumber === mappedColumnValue)[0]
            if (utils.isDefined(articleWithModelNumber)) {
              mappedArticles.push(articleWithModelNumber)
            }
          })
        }
      }
      break
    }
    else if (mappedColumns[i].SystemName === 'ArticleNumber') {
      const primaryColumnIndex: number = mappedColumns[i].column
      if (primaryColumnIndex !== null) {
        const columnData: string[] = []
        worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          // Skip the first row which contains column headers
          if (rowNumber > 1) {
            const cellValue = row.getCell(primaryColumnIndex).value
            if (cellValue !== undefined && cellValue !== null) {
              columnData.push(cellValue.toString())
            }
          }
        })
        if (columnData.length) {
          // when the column is 'ArticleNumber', use ArticleNumber to filter articles
          columnData.forEach((mappedColumnValue) => {
            const articles = props.data!.filter(article => article.ArticleNumber === mappedColumnValue)
            if (articles.length) {
              mappedArticles = mappedArticles.concat(articles)
            }
          })
        }
      }
      break
    }
    else if (mappedColumns[i].SystemName === 'LicenseeModelNumber') {
      const primaryColumnIndex: number = mappedColumns[i].column
      const catalogModelAttribute = userStore.activeCatalog?.ModelAttribute
      if (primaryColumnIndex !== null) {
        const columnData: string[] = []
        worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          // Skip the first row which contains column headers
          if (rowNumber > 1) {
            const cellValue = row.getCell(primaryColumnIndex).value
            if (cellValue !== undefined && cellValue !== null) {
              columnData.push(cellValue.toString())
            }
          }
        })
        if (columnData.length) {
          // when the column is 'LicenseeModelNumber', use catalog model attributes to filter articles
          columnData.forEach((mappedColumnValue) => {
            const licenseeArticles = filterArticleByModelAttribute(mappedColumnValue, catalogModelAttribute)
            if (licenseeArticles.length) {
              mappedArticles = mappedArticles.concat(licenseeArticles)
            }
          })
        }
      }
      break
    }
    else if (mappedColumns[i].SystemName === 'ArticleName') {
      const primaryColumnIndex: number = mappedColumns[i].column
      if (primaryColumnIndex !== null) {
        const columnData: string[] = []
        worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          // Skip the first row which contains column headers
          if (rowNumber > 1) {
            const cellValue = row.getCell(primaryColumnIndex).value
            if (cellValue !== undefined && cellValue !== null) {
              columnData.push(cellValue.toString())
            }
          }
        })
        if (columnData.length) {
          // when the column is 'ArticleName', use ArticleName to filter articles
          columnData.forEach((mappedColumnValue) => {
            const articles = props.data!.filter(article => article.ArticleName === mappedColumnValue)
            if (articles.length) {
              mappedArticles = mappedArticles.concat(articles)
            }
          })
        }
      }
      break
    }
  }
  const highestArticleStateDetails = getArticlesMaxStateDetails(mappedArticles)
  const highestModelStateDetails = getArticlesMaxStateDetails(mappedArticles, true)
  return { highestArticleStateDetails, highestModelStateDetails }
}

function findInExistConstraintsData(field: IField, cellValue, rowData) {
  // skip validation for non mandatory fields when cell value is empty
  if ((!utils.isDefined(cellValue) || cellValue === null || cellValue.toString().trim() === '') && field.IsRequired === false && !utils.isDefined(field.isRequiredIfMapped) && !utils.isDefined(field.isRequiredWhileValidating)) {
    return true
  }

  // for article drop reason skip validation for activating articles
  if (utils.isDefined(field.isRequiredWhileValidating)) {
    // check why status coming as 'true'
    if (rowData.Status === 1 || rowData.Status.toString().toUpperCase() === 'ACTIVE' || rowData.Status.toString().toUpperCase() === 'TRUE' || rowData.Status.toString().toUpperCase() === 'YES') {
      return true
    }
  }

  const existConstraintsDataForField = dataExistConstraintsData[field.SystemName]
  let existConstraintsDataForFieldLookupPropertyName
  const cellVal = String(cellValue).toLowerCase()

  // if validating against lookup data
  if (field.AttributeType === AttributeType.Lookup && field.lookupSource) {
    if (field.useLookupName) {
      existConstraintsDataForFieldLookupPropertyName = field.lookupDisplayField
    }
    else {
      existConstraintsDataForFieldLookupPropertyName = field.lookupIdField
    }
  }
  else if (utils.isDefined(field.dataExistConstraints) && utils.isDefined(field.dataExistConstraints.model) && utils.isDefined(field.dataExistConstraints.model.lookupDisplayField)) {
    // when the lookup field name is different in both models
    existConstraintsDataForFieldLookupPropertyName = field.dataExistConstraints.model.lookupDisplayField
  }
  else {
    existConstraintsDataForFieldLookupPropertyName = field.SystemName
  }
  if (utils.isDefined(existConstraintsDataForField) && Object.keys(existConstraintsDataForField).length > 0) {
    // size scale import when filtering the size scales filter with master size scale name also as sizescales can be mmultiple
    let matchingObject: any

    if (field.SystemName !== 'SizeScale') {
      matchingObject = Object.values(existConstraintsDataForField).find((item: any) => {
        return item[existConstraintsDataForFieldLookupPropertyName]
          && String(item[existConstraintsDataForFieldLookupPropertyName]).toLowerCase() === cellVal
      })
    }
    else {
      matchingObject = Object.values(existConstraintsDataForField).find((item: any) => {
        return item[existConstraintsDataForFieldLookupPropertyName]
          && String(item[existConstraintsDataForFieldLookupPropertyName]).toLowerCase() === cellVal
          && utils.isDefined(rowData.MasterSizeScale)
          && item.MasterSizeScale.toString().toLowerCase() === rowData.MasterSizeScale.toString().toLowerCase()
      })
    }
    return matchingObject
  }
  return undefined
}

function validateTxPattern(field: IField, rowData) {
  let isValid: boolean = true
  const validationExpression = utils.tryParse(field.txPattern!)
  if (validationExpression && utils.isDefined(validationExpression.F) && validationExpression.F.length > 0) {
    const parsedExpressionObjects = validationExpression.F
    for (let i = 0; i < parsedExpressionObjects.length; i++) {
      const parsedExpressionObject = parsedExpressionObjects[i]
      if (utils.isDefined(parsedExpressionObject.Message)) {
        field.messages = field.messages || {}
        field.messages.pattern = parsedExpressionObject.Message
      }

      const isCatalogConditionMet = (
        (userStore.activeCatalog?.DataSourceTypeId === 1 && field.Overridable && !parsedExpressionObject.SourceField.startsWith('#'))
        || (userStore.activeCatalog?.DataSourceTypeId === 3 && field.Overridable)
        || (userStore.activeCatalog?.DataSourceTypeId !== 3 && !field.Overridable)
      )

      if (isCatalogConditionMet) {
        if (parsedExpressionObject.SourceValueRequired) {
          if (utils.isDefined(rowData[parsedExpressionObject.SourceField]) && rowData[parsedExpressionObject.SourceField] !== '') {
            isValid = validateExpression(field, parsedExpressionObject, rowData)
          }
          else if (utils.isDefined(rowData[field.SystemName]) && rowData[field.SystemName] !== '') {
            isValid = true
          }
        }
        else {
          const sourceFieldIsShipmentDate = (
            parsedExpressionObject.SourceField === 'ShipmentStartDate'
            || parsedExpressionObject.SourceField === 'ShipmentEndDate'
          )
          const isRetailWindowValid = (
            rowData.RetailIntroMonthId !== null && rowData.RetailIntroMonthId !== ''
            && rowData.RetailExitMonthId !== null && rowData.RetailExitMonthId !== ''
            && rowData[field.SystemName] !== '' && rowData[field.SystemName] !== null
          )

          if (utils.isDefined(rowData[parsedExpressionObject.SourceField]) && rowData[parsedExpressionObject.SourceField] !== ''
            && rowData[field.SystemName] !== '' && rowData[field.SystemName] !== null
            && parsedExpressionObject.SourceField.startsWith('#') && userStore.activeCatalog?.DataSourceTypeId === 3) {
            isValid = validateExpression(field, parsedExpressionObject, rowData)
          }
          else if (sourceFieldIsShipmentDate && isRetailWindowValid) {
            const retailWindowAttribute = userStore.activeCatalog!.RetailWindowAttribute
            const retailWindowDataList = lookupData.RetailExitMonthId
            const retailWindowArray = Object.values(retailWindowDataList) as CatalogShipmentWindowRange[]

            const mappedRetailWindow = retailWindowArray.find(window =>
              window.CriteriaAttributeValue === rowData[retailWindowAttribute]
              && window.RetailIntroMonth === rowData.RetailIntroMonthId
              && window.RetailExitMonth === rowData.RetailExitMonthId,
            )

            if (mappedRetailWindow) {
              const rowDataCopy = { ...rowData, ShipmentStartDate: mappedRetailWindow.ShipmentStartDate, ShipmentEndDate: mappedRetailWindow.ShipmentEndDate }
              isValid = validateExpression(field, parsedExpressionObject, rowDataCopy)
            }
          }
          else if (parsedExpressionObject.SourceField.startsWith('#') && rowData[field.SystemName] !== '' && rowData[field.SystemName] != null) {
            let articleData: Article | undefined
            if (isUpdateByModel.value) {
              articleData = props.data!.find(article => article.ModelNumber === rowData.ModelNumber)
            }
            else {
              articleData = props.data!.find(article => article.ArticleNumber === rowData.ArticleNumber)
            }

            if (utils.isDefined(articleData) && articleData[parsedExpressionObject.SourceField] != null && articleData[parsedExpressionObject.SourceField] !== ''
              && rowData[field.SystemName] !== '' && rowData[field.SystemName] != null) {
              const rowDataCopy = clone(rowData)
              rowDataCopy[parsedExpressionObject.SourceField] = articleData[parsedExpressionObject.SourceField]
              isValid = validateExpression(field, parsedExpressionObject, rowDataCopy)
            }
          }
        }
      }

      if (!isValid) {
        break
      }
    }
  }
  return isValid
}

function validateExpression(field: IField, parsedExpressionObject, rowData) {
  const { Expression, SourceField } = parsedExpressionObject
  let currentAttributeValue = clone(rowData[field.SystemName])
  let sourceAttributeValue = clone(rowData[SourceField])

  props.model.fields.forEach((item) => {
    if (item.field === field.SystemName || item.field === SourceField) {
      // For date fields
      if ([AttributeType.DateUtc, AttributeType.DateOption, AttributeType.Date, AttributeType.DateTime].includes(item.AttributeType)) {
        if (item.field === field.SystemName) {
          const formattedDate = utils.formatDate(clone(rowData[item.field]))
          currentAttributeValue = new Date(formattedDate)
          currentAttributeValue.setHours(0, 0, 0, 0)
        }
        else if (item.field === SourceField) {
          const formattedDate = utils.formatDate(clone(rowData[item.field].split('T', 1)[0]))
          sourceAttributeValue = new Date(formattedDate)
          sourceAttributeValue.setHours(0, 0, 0, 0)
        }
      }
      // For text input types
      if (item.inputType === 'textArea' || item.inputType === 'text' || (utils.isDefined(item.type) && item.type === 'text' && item.inputType === 'list')) {
        if (item.field === field.SystemName) {
          currentAttributeValue = rowData[item.field].toUpperCase()
        }
        else if (item.field === SourceField) {
          sourceAttributeValue = rowData[item.field].toUpperCase()
        }
      }
    }
  })

  if (userStore.activeCatalog?.DataSourceTypeId === 3 && field.Overridable) {
    if (field.AttributeType === AttributeType.Nvarchar) {
      sourceAttributeValue = rowData[SourceField].toUpperCase()
    }

    // For date fields
    if ([AttributeType.DateUtc, AttributeType.DateOption, AttributeType.Date, AttributeType.DateTime].includes(field.AttributeType)) {
      sourceAttributeValue = new Date(clone(rowData[SourceField]))
      sourceAttributeValue.setHours(0, 0, 0, 0)
    }
  }

  const isValid = utils.validateCondition(currentAttributeValue, sourceAttributeValue, Expression)
  return isValid
}

// persistImportMapping
async function persistImportMapping() {
  if (userStore.activeCatalog) {
    if (!catalogImportMappings) {
      catalogImportMappings = {}
    }
    catalogImportMappings.importMappings = catalogImportMappings.importMappings ? catalogImportMappings.importMappings : {}
    catalogImportMappings.importMappings[props.model.modelPath] = catalogImportMappings.importMappings[props.model.modelPath]
      ? catalogImportMappings.importMappings[props.model.modelPath]
      : {}
    catalogImportMappings.importMappings[props.model.modelPath] = importMappings
    if (props.model.importInfo.isCatalogSpecificModel) { // only should work for catalog data modules
      // TODO IF WE CHANGE IMPLEMENTATION OF HOW WE ARE REFRESHING AFTER IMPORT THEN THIS PART NEEDS TO BE CHECKED
      // CURRENTLY AFTER IMPORT WE CALL CATALOG GETDETAILS API SO WHEN WE SET THE IMPORTMAPPING WE WILL GET IT AFTER THE REFRESH.
      await setCatalogImportMapping(userStore.activeCatalog.CatalogCode, catalogImportMappings)
    }
  }
}

function getImportMappings() {
  let importMapping: IImportMapping = {
    selectedSheet: '',
    columnsMapping: {},
    FilterInfo: undefined,
  }
  if (utils.isDefined(userStore.activeCatalog?.ImportMapping) && userStore.activeCatalog?.ImportMapping) {
    catalogImportMappings = userStore.activeCatalog?.ImportMapping
    if (catalogImportMappings && catalogImportMappings.importMappings && catalogImportMappings.importMappings[props.model.modelPath]) {
      importMapping = catalogImportMappings.importMappings[props.model.modelPath]
    }
  }
  return importMapping
}

function doesCachedSheetExist(sheetList, cachedSheetName) {
  for (let i = 0, len = sheetList.length; i < len; i++) {
    if (sheetList[i]._name.replace(' ', '').toLowerCase() === cachedSheetName.replace(' ', '').toLowerCase()) {
      return true
    }
  }
  return false
}

watch(() => props.model, async (newModel) => {
  if (newModel) {
    if (userStore.activeCatalog) {
      lookupData = await loadLookup(newModel.fields, userStore.activeCatalog)
      // load dataexist constraint lookup
      const dataExistConstraintsLookupFields = newModel.fields.filter(field =>
        utils.isDefined(field.dataExistConstraints) && !isEmpty(field.dataExistConstraints)
        && isFunction(field.dataExistConstraints.lookupSource))

      if (dataExistConstraintsLookupFields.length) {
        const dataExistConstraintsPromises = dataExistConstraintsLookupFields.map(datafield =>
          datafield.dataExistConstraints.lookupSource(userStore.activeCatalog).then((data) => {
            dataExistConstraintsData[datafield.SystemName] = data
          }),
        )
        Promise.all(dataExistConstraintsPromises).then(() => {
          console.log('All lookup data loaded:')
        }).catch((error) => {
          console.error('Error loading lookup data:', error)
        })
      }
      importMappings = getImportMappings()
    }
  }
}, { immediate: true })
</script>

<style scoped>
.import-wizard {
  max-width: 800px;
  margin: 0 auto;
}
.stepper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.step {
  text-align: center;
  flex: 1;
  padding: 0.5rem;
}
.step-active {
  font-weight: bold;
  color: #1d4ed8;
}
.step-completed {
  color: #4b5563;
}
.step-number {
  font-size: 1.25rem;
}
.step-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.step-actions button {
  padding: 0.5rem 1rem;
  background-color: #1d4ed8;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
.step-actions button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}
</style>
