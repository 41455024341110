export default class CatalogSegmentation {
  Id: number
  CatalogCode: number
  Name: string
  Description: string
  Status: number

  constructor({ Id, CatalogCode, Name, Description, Status }: CatalogSegmentation) {
    this.Id = Id
    this.CatalogCode = CatalogCode
    this.Name = Name
    this.Description = Description
    this.Status = Status
  }
}
