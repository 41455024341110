<template>
  <div class="w-full">
    <div v-if="label.length > 0" class="flex">
      <label
        v-if="label.length > 0" class="text-xs tracking-wide uppercase label" :class="{ required }"
        v-text="label"
      />
    </div>
    <div class="flex">
      <tx-select
        v-model="season" style="width: 100px" :data="Object.values(indexedSeasons)" value-prop="value"
        display-prop="displayLabel" :disabled="disabled" :clearable="clearable" :errors="errors" @change="onSeasonSelect"
      />
      <tx-autocomplete
        ref="refArticleNumber" v-model="articleNumber" class="ml-1" :errors="errors"
        :fetch-suggestions="searchArticleNumber" value-prop="Id" display-prop="ArticleNumber"
        :disabled="disabled || !utils.isValidStringValue(season)" :clearable="clearable"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onMounted, ref, watch } from 'vue'
import type { ErrorObject } from '@vuelidate/core'
import TxSelect from '@/shared/components/TxSelect.vue'
import TxAutocomplete from '@/shared/components/TxAutocomplete.vue'
import utils from '@/services/utils'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'

interface IProps {
  modelValue?: string | null
  label?: string
  required?: boolean
  disabled?: boolean
  clearable?: boolean
  errors?: ErrorObject[]
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: null,
  label: '',
  required: false,
  clearable: false,
  disabled: false,
  errors: () => [] as ErrorObject[],
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: string | null): void
}>()

const { t } = useI18n()
const userStore = useUserStore()

const refArticleNumber = ref<InstanceType<typeof TxAutocomplete>>()
const season = ref<string | null>(null)
const articleNumber = ref<string | null>(null)

const indexedSeasons = computed<Record<string, { key: string, value: string, catalogCode: number, displayLabel: string }>>(() => {
  if (userStore.activeCatalog && userStore.linkedCatalogDetails && Object.keys(userStore.linkedCatalogDetails).length) {
    const catalogCode = userStore.activeCatalog.CatalogCode
    const indexedSeasonValueCount = Object.values(userStore.linkedCatalogDetails).reduce((acu, linkedCatalog) => {
      if (linkedCatalog.Status && linkedCatalog.CatalogCode !== catalogCode) {
        const seasonValue = utils.isValidStringValue(linkedCatalog.Season) ? linkedCatalog.Season : t('general.blankValue')
        if (!acu[seasonValue]) {
          acu[seasonValue] = 0
        }
        acu[seasonValue]++
      }
      return acu
    }, {})

    return Object.values(userStore.linkedCatalogDetails).reduce((acu, linkedCatalog) => {
      if (linkedCatalog.Status && linkedCatalog.CatalogCode !== catalogCode) {
        const seasonValue = utils.isValidStringValue(linkedCatalog.Season) ? linkedCatalog.Season : t('general.blankValue')
        const seasonKey = `${seasonValue}${linkedCatalog.CatalogCode}`
        if (!acu[seasonKey]) {
          acu[seasonKey] = {
            key: seasonKey,
            value: seasonKey,
            catalogCode: linkedCatalog.CatalogCode,
            displayLabel: indexedSeasonValueCount[seasonValue] <= 1 ? seasonValue : `${seasonValue} (${linkedCatalog.CatalogCode})`,
          }
        }
      }
      return acu
    }, {})
  }
  return {}
})

function onSeasonSelect() {
  setArticleNumberValue('')
}

function searchArticleNumber(query: string, callback) {
  if (utils.isDefined(season.value) && utils.isDefined(indexedSeasons.value[season.value])) {
    const catalogCode = indexedSeasons.value[season.value].catalogCode
    let articleCollection = appConfig.DB!.articles.where('CatalogCode').equals(catalogCode)
    if (utils.isDefined(query)) {
      articleCollection = appConfig.DB!.articles.where('ArticleNumber').startsWithIgnoreCase(query.trim().toLowerCase())
        .and(a => a.CatalogCode === catalogCode)
    }

    articleCollection
      .limit(100)
      .sortBy('ArticleNumber')
      .then((articles) => {
        callback(articles)
      })
  }
  else {
    callback([])
  }
}

function setArticleNumberValue(val: string) {
  articleNumber.value = val
  if (utils.isDefined(refArticleNumber.value)) {
    refArticleNumber.value.setDisplayModelValue(val)
  }
}

watch(() => articleNumber.value, async (val) => {
  if (utils.isValidStringValue(val) && utils.isDefined(season.value) && utils.isDefined(indexedSeasons.value[season.value])) {
    const catalogCode = indexedSeasons.value[season.value].catalogCode
    const article = await appConfig.DB!.articles.get({ CatalogCode: catalogCode, Id: val })
    if (utils.isDefined(article)) {
      emit('update:modelValue', `${season.value}//${article.Id}//${article.ArticleNumber}`)
    }
  }
  else {
    emit('update:modelValue', '')
  }
})

onMounted(() => {
  if (utils.isDefined(props.modelValue) && utils.isValidStringValue(props.modelValue)) {
    const splits = props.modelValue.split('//')
    if (utils.isDefined(indexedSeasons.value[splits[0]])) {
      season.value = indexedSeasons.value[splits[0]].value
      setArticleNumberValue(splits[2])
    }
  }
})
</script>
