<template>
  <div>
    <tx-dialog
      v-model="dialogVisible" :title="t('merch.dialog.modify.title')" height="700px" width="800px" show-ok :ok-state="loading ? 'loading' : ((currentStepIndex === 0 && (checkedNodes.length === 0 || selectedObjectType === '')) || (currentStepIndex === 1 && Object.keys(objectPropsModel).length === 0)) ? 'disabled' : 'enabled'"
      :confirm-text="currentStepIndex < (steps.length - 1) ? 'general.next' : 'general.update'" :show-back="true"
      :back-state="currentStepIndex === 0 ? 'disabled' : 'enabled'" @opened="onOpen" @back="onBack" @ok="onNext"
    >
      <div class="flex flex-col w-full h-full">
        <loader v-if="loading" />
        <div v-if="!loading" class="h-full overflow-hidden">
          <!-- Steps -->
          <div class="px-6 py-4 bg-gray-100 border-t border-gray-200 h-[65px]">
            <div class="flex justify-between">
              <template v-for="(step, index) in steps" :key="step">
                <div class="flex flex-col items-center">
                  <div
                    class="flex items-center justify-center w-6 h-6 border-2 rounded-full"
                    :class="[index <= currentStepIndex ? 'bg-blue-500 text-white border-blue-500' : 'border-gray-400']"
                    v-text="index + 1"
                  />
                  <div class="text-sm" v-text="step" />
                </div>
                <div
                  v-if="index < steps.length - 1" class="flex-1 h-2 mx-1 mt-3"
                  :class="[index < currentStepIndex ? 'bg-blue-500' : 'bg-gray-400']"
                />
              </template>
            </div>
          </div>
          <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
          <div v-if="currentStepIndex === 0" class="mt-5 h-[calc(100%_-_90px)] overflow-hidden">
            <div class="mb-4 text-md">
              {{ t('merch.dialog.modify.objectTypes') }}
              <div>
                <tx-select
                  v-model="selectedObjectType" :data="availableObjectsTypes" value-prop="key"
                  display-prop="label"
                />
              </div>
            </div>

            <div class="mb-2 text-md">
              {{ t('merch.dialog.modify.selectMerchBoardSlides', { checkedNodesLimit }) }}
            </div>
            <div class="h-[calc(100%_-_80px)] overflow-scroll">
              <tx-tree
                v-if="!loading" ref="refSlideTree" class="tree" show-checkbox="onHoverOrOtherChecked" :data="slideTree" :checked-nodes-limit="checkedNodesLimit" @check-change="OnNodeCheckedChange"
              />
            </div>
          </div>
          <div v-if="currentStepIndex === 1" class="max-w-full mt-5 h-[calc(100%_-_90px)] overflow-auto">
            <object-properties
              :merch="merch" :object-props="objectEditableProps" @updated-object-props-model="updatedObjectPropsModel"
            />
          </div>
        </div>
      </div>
    </tx-dialog>
    <!-- warning for sharing slides -->
    <tx-dialog
      v-model="showSaveWarning" :title="t('general.alert')"
      show-ok-cancel @click="showSaveWarning = false" @ok="saveSlides"
    >
      <div class="text-xl" v-text="t('merch.dialog.modify.saveWarning')" />
    </tx-dialog>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import ObjectProperties from './ObjectProperties.vue'
import merchService from '@/modules/merch/services'
import TxDialog from '@/shared/components/TxDialog.vue'
import Loader from '@/shared/components/Loader.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import type Merch from '@/modules/merch/services/merch'
import { merchConstants } from '@/models/constants'
import TxTree from '@/shared/components/TxTree.vue'
import { useNotificationStore } from '@/store/notification'
import TxSelect from '@/shared/components/TxSelect.vue'

export interface IAccessibleItem {
  id: number
  type: AccessibleItemType
  name: string
  subTitle: string
}

const props = defineProps<{
  merch: Merch | undefined
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()
const notificationStore = useNotificationStore()

const dialogVisible = ref(false)
const loading = ref(false)
const selectedObjectType = ref('')
const steps = ['Slides', 'Properties']
const currentStepIndex = ref(0)
const slideTree = ref<ITreeNode[]>([])
const refSlideTree = ref<InstanceType<typeof TxTree>>()
const checkedNodes = ref<ITreeNode[]>([])
const objectEditableProps = ref<Record<string, IMbObjectProp>>({})
const objectPropsModel = ref<Record<string, any>>({})
const showSaveWarning = ref(false)
const isLoading = ref(false)
const checkedNodesLimit = 500

const availableObjectsTypes = computed(() => {
  const objectsTypes: any[] = []
  Object.keys(merchService).forEach((objectKey) => {
    objectsTypes.push({ key: objectKey, label: t(`merch.dialog.modify.objectType.${objectKey}`) })
  })
  return objectsTypes
})

function onOpen() {
  loading.value = true
  buildSlideTree()
  loading.value = false
}
function buildSlideTree() {
  if (props.merch && props.merch.merchSlides && props.merch.merchSlides.value && Object.keys(props.merch.merchSlides.value).length) {
    slideTree.value = []
    Object.values(props.merch.merchSlides.value).forEach((slideData) => {
      const target = createFolderTrailAndGetTargetNode(slideData, slideTree.value)
      if (!Array.isArray(target)) {
        const node: ITreeNode = {
          key: slideData.SlideId,
          label: slideData.SlideName,
          sortOrder: slideData.SortOrder,
          faicon: 'fa-light fa-frame',
          checked: false,
          expanded: false,
          isFolder: false,
          path: [],
          children: [],
          actions: [],
        }
        node.parent = target
        utils.insertSorted(node, target.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
      }
    })
  }
}
function createFolderTrailAndGetTargetNode(slideData, target: ITreeNode[] | ITreeNode) {
  const folderIdPathList = slideData.FolderId.split(merchConstants.folderPathSeparator)
  const folderNamePathList = slideData.FolderName.split(merchConstants.folderPathSeparator)
  for (let i = 0; i < folderIdPathList.length; i++) {
    const currentTarget = !Array.isArray(target) ? target.children : target
    let subFolder = currentTarget.find(folder => folder.key === folderIdPathList[i])
    if (!subFolder) {
      subFolder = {
        key: folderIdPathList[i],
        label: folderNamePathList[i],
        sortOrder: slideData.FolderIdSortOrder ? slideData.FolderIdSortOrder : 0,
        checked: false,
        faicon: 'fa-light fa-folder',
        expanded: false,
        isFolder: true,
        path: [],
        children: [],
        actions: [], // ['Edit', 'Delete'],
      }
      if (!Array.isArray(target)) {
        subFolder.parent = target
        utils.insertSorted(subFolder, target.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'label']))
      }
      else {
        utils.insertSorted(subFolder, target, (a, b) => utils.comparer(a, b, ['sortOrder', 'label']))
      }
    }
    else {
      subFolder.badgeValue += slideData.unavailableArticleCount
    }
    target = subFolder
  }
  return target
}
function OnNodeCheckedChange() {
  const checkedData = refSlideTree.value?.getCheckedNodes(true)
  checkedNodes.value = []
  if (utils.isDefined(checkedData) && checkedData.length > 0) {
    checkedNodes.value = checkedData.filter(node => node.isFolder === false)
  }
}
function updatedObjectPropsModel(modelValue) {
  objectPropsModel.value = modelValue
}
function onBack() {
  if (currentStepIndex.value === 1) {
    currentStepIndex.value--
  }
}
async function onNext() {
  if (currentStepIndex.value === 0) {
    // As per current implementation all the slides selected will be shared or unshared with all the users selected.
    // And inactive users will be passed silently to the users slide while calling the AP to share. And all the selected slide have the access to the
    // inactive user once it is activated.
    if (userStore.activeCatalog && props.merch) {
      loading.value = true
      objectEditableProps.value = merchService[selectedObjectType.value].getEditableProps()
      loading.value = false
    }
    currentStepIndex.value++
  }
  else if (currentStepIndex.value === 1) {
    if (props.merch && userStore.activeCatalog) {
      loading.value = true

      const selectedSlides: (string | number)[] = checkedNodes.value.map(node => node.key)
      let isSelectedSlideDirty = false
      if (props.merch.isDirty) {
        selectedSlides.forEach((slideId) => {
          if (!isSelectedSlideDirty && props.merch?.merchSlides && props.merch.merchSlides.value && props.merch.merchSlides.value[slideId] && props.merch.merchSlides.value[slideId].isDirty) {
            isSelectedSlideDirty = true
          }
        })
      }
      if (isSelectedSlideDirty) {
        showSaveWarning.value = true
      }
      else {
        await modifyObjects()
      }
    }
  }
}
async function saveSlides() {
  showSaveWarning.value = false
  if (props.merch && userStore.activeCatalog) {
    // isLoading.value = true
    await props.merch.save(userStore.activeCatalog.CatalogCode, userStore.currentCustomer ? userStore.currentCustomer.CustomerId : null).then((response) => {
      if (response.failedSlides.length !== 0 || response.deletedSlides.length !== 0) {
        notificationStore.addNotification({ message: t('merch.errors.saveFailed'), type: 'Alert' })
      }
      if (response.deletedSlides.length === 0 && response.failedSlides.length === 0) {
        notificationStore.addNotification({ message: t('merch.messages.slidesSaved'), type: 'Success' })
      }
    }).catch((error) => {
      notificationStore.addNotification({ message: t('merch.messages.saveFailed'), type: 'Alert', actions: ['Support', 'ShowDetails'], details: utils.getErrorMessage(error) })
    }).then(async () => {
      await modifyObjects()
      // isLoading.value = false
    })
  }
}
async function modifyObjects() {
  let anyValidSlide = false
  const failedSlides: string[] = []
  if (props.merch) {
    props.merch.cacheExistingSlideData()
    const slideIds: string [] = checkedNodes.value.map(node => node.key.toString())
    if (slideIds.length) {
      const slidesData = await props.merch?.getSlidesData(slideIds)
      slideIds.forEach((slideId) => {
        if (slidesData && slidesData[slideId]) {
          const merchSlide = slidesData[slideId]
          if (typeof merchSlide === 'object') {
            const { slideObjects, slideUpdated } = doModifyObjects(merchSlide.objects)
            if (slideUpdated) {
              anyValidSlide = true
              props.merch!.updateModifiedSlideObjects(merchSlide.SlideId, slideObjects)
            }
          }
          else {
            failedSlides.push(slideId)
            console.error(slidesData[slideId])
          }
        }
      })
      if (anyValidSlide) {
        if (failedSlides.length) {
          notificationStore.addNotification({ message: t('merch.dialog.modify.partialUpdate'), type: 'Success' })
        }
        else {
          notificationStore.addNotification({ message: t('merch.dialog.modify.success'), type: 'Success' })
        }
      }
      else {
        if (failedSlides.length === slideIds.length) {
          notificationStore.addNotification({ message: t('merch.dialog.modify.failedLoadingSlideObjects'), type: 'Alert' })
        }
        else {
          notificationStore.addNotification({ message: t('merch.dialog.modify.failed'), type: 'Alert' })
        }
      }
      isLoading.value = false
      close()
    }
  }

  function doModifyObjects(slideObjects) {
    let slideUpdated = false
    // NOTE: Whenever add any condition in below code check the same in merch.vue function onObjectPropChange() as same
    // functionality we are trying to achieve it there as well but oly on current slide objects.
    for (let objectIndex = 0; objectIndex < slideObjects.length; objectIndex++) {
      // if template object, then we will skip the modification
      if (utils.isDefined(slideObjects[objectIndex].templateObject) && slideObjects[objectIndex].templateObject === true) {
        continue
      }
      if (slideObjects[objectIndex].type.toLowerCase() === selectedObjectType.value.toLowerCase() || slideObjects[objectIndex].type.toLowerCase() === 'group') {
        const updatedKeys = Object.keys(objectPropsModel.value)
        for (let propertyIndex = 0; propertyIndex < updatedKeys.length; propertyIndex++) {
          const propValue = objectPropsModel.value[updatedKeys[propertyIndex]]
          slideUpdated = true
          if (slideObjects[objectIndex].type.toLowerCase() !== 'group') {
            if (updatedKeys[propertyIndex] === 'attributes') {
              slideObjects[objectIndex].customOptions.articleProps = propValue
            }
            else if (updatedKeys[propertyIndex] === 'showLabels') {
              slideObjects[objectIndex].showLabels = propValue === 1
            }
            else if (updatedKeys[propertyIndex] === 'font') {
              slideObjects[objectIndex].fontFamily = propValue
            }
            else if (updatedKeys[propertyIndex] === 'fontSize') {
            // current value is in point as converted while display before saving convert to pixel back
              slideObjects[objectIndex].fontSize = propValue * 96 / 72
            }
            else if (updatedKeys[propertyIndex] === 'fontStyle') {
              if (propValue === 'italic') {
                slideObjects[objectIndex].fontStyle = propValue
              }
              else if (propValue === 'bold') {
                slideObjects[objectIndex].fontWeight = propValue
              }
            }
            else if (updatedKeys[propertyIndex] === 'border') {
              slideObjects[objectIndex].stroke = propValue
            }
            else if (updatedKeys[propertyIndex] === 'scale') {
              slideObjects[objectIndex].scaleX = propValue / 100
              slideObjects[objectIndex].scaleY = propValue / 100
            }
            else if (updatedKeys[propertyIndex] === 'backgroundColor' && (slideObjects[objectIndex].type === merchConstants.objectTypes.rectangle || slideObjects[objectIndex].type === merchConstants.objectTypes.triangle || slideObjects[objectIndex].type === merchConstants.objectTypes.circle)) {
              slideObjects[objectIndex].fill = propValue
            }
          }
          if (updatedKeys[propertyIndex] === 'locked') {
            slideObjects[objectIndex].locked = propValue === 1
          }
          else if (slideObjects[objectIndex].type.toLowerCase() === 'group' && selectedObjectType.value.toLowerCase() !== 'group') {
            doModifyObjects(slideObjects[objectIndex].objects) // update all the group objects only if selected object type is not group
          }
          else {
            slideObjects[objectIndex][updatedKeys[propertyIndex]] = propValue
          }
        }
      }
    }
    return { slideObjects, slideUpdated }
  }
}
function open() {
  resetData()

  dialogVisible.value = true
}

function close() {
  resetData()
  dialogVisible.value = false
}
function resetData() {
  currentStepIndex.value = 0
  slideTree.value = []
  checkedNodes.value = []
  selectedObjectType.value = ''
}
defineExpose({
  open,
  close,
  loading,
  errorMessage,
})
</script>
