export default class CatalogCrd {
  Id: number
  CatalogCode: number
  CustomerRequiredDate: string
  Description: string
  Availability: boolean
  SortOrder: number
  Status: number

  constructor({ Id, CatalogCode, CustomerRequiredDate, Description, Availability, SortOrder, Status }: CatalogCrd) {
    this.Id = Id
    this.CatalogCode = CatalogCode
    this.CustomerRequiredDate = CustomerRequiredDate
    this.Description = Description
    this.Availability = Availability
    this.SortOrder = SortOrder
    this.Status = Status
  }
}
