<template>
  <tx-panel
    v-model="panelVisible" :title="t('merch.dialog.findAndReplace.title')"
    :width="600" :height="600" @close="doClose"
  >
    <div class="w-full p-4">
      <loader v-if="loading" />
      <div v-if="!loading" class="h-full overflow-hidden">
        <!-- Steps -->
        <div class="px-6 py-4 h-[65px]">
          <div class="flex justify-between">
            <template v-for="(step, index) in steps" :key="step">
              <div class="flex flex-col items-center">
                <div
                  class="flex items-center justify-center w-6 h-6 border-2 rounded-full"
                  :class="[index <= currentStepIndex ? 'bg-blue-500 text-white border-blue-500' : 'border-gray-400']"
                  v-text="index + 1"
                />
                <div class="text-sm" v-text="step" />
              </div>
              <div
                v-if="index < steps.length - 1" class="flex-1 h-2 mx-1 mt-3"
                :class="[index < currentStepIndex ? 'bg-blue-500' : 'bg-gray-400']"
              />
            </template>
          </div>
        </div>
        <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
        <!-- SLIDES -->
        <div v-if="currentStepIndex === 0" class="mt-5 h-[calc(100% - 90px)] overflow-hidden">
          <div class="mb-2 text-md">
            {{ t('merch.dialog.findAndReplace.selectMerchBoardSlides', { checkedNodesLimit }) }}
          </div>
          <div class="h-[360px] overflow-auto">
            <tx-tree
              v-if="!loading" ref="refSlideTree" class="tree" show-checkbox="onHoverOrOtherChecked" :data="slideTree" :checked-nodes-limit="checkedNodesLimit" @check-change="OnNodeCheckedChange"
            />
          </div>
        </div>
        <!-- FIND -->
        <div v-if="currentStepIndex === 1" style="margin-top: 1.25rem; display: flex; flex-direction: column; padding: 1rem;">
          <div style="display: flex; margin-bottom: 1rem;">
            <!-- Inputs -->
            <div style="flex-grow: 1; margin-right: 1rem;">
              <tx-input v-model="form.findWhat" style="margin-bottom: 1rem;" :label="t('findAndReplaceDialog.findWhat')" clearable />
              <tx-input v-model="form.replaceWith" style="margin-bottom: 1rem;" :label="t('findAndReplaceDialog.replaceWith')" clearable />
            </div>
            <!-- Actions -->
            <div style="display: flex; flex-direction: column; width: 120px;">
              <tx-button style="margin-bottom: 0.5rem;" :text="t('findAndReplaceDialog.find')" :disabled="form.findWhat === '' || isSearching" height="30px" width="120px" @click="doFind" />
              <tx-button style="margin-bottom: 0.5rem;" :text="t('findAndReplaceDialog.findNext')" :disabled="filteredSlides.length === 0" height="30px" width="120px" @click="doFindNext" />
              <tx-button style="margin-bottom: 0.5rem;" :text="t('findAndReplaceDialog.replace')" :disabled="filteredSlides.length === 0 || form.replaceWith === '' || disableReplaceButton" height="30px" width="120px" @click="doReplace" />
              <tx-button style="margin-bottom: 0.5rem;" :text="t('findAndReplaceDialog.replaceAll')" :disabled="filteredSlides.length === 0 || form.replaceWith === ''" height="30px" width="120px" @click="doReplaceAll" />
            </div>
          </div>
          <!-- Matched Slides -->
          <div class="relative p-1 overflow-y-auto h-[220px]">
            <div v-if="isSearching" class="absolute inset-0 flex items-center justify-center">
              <loader />
            </div>
            <p v-if="!isSearching && !matchedTree.length" class="text-center">
              {{ t('general.emptyList') }}
            </p>
            <tx-tree v-if="!isSearching" ref="refMatchedSlideTree" class="tree" show-checkbox="never" :data="matchedTree" @node-click="onTreeItemClick" />
          </div>
        </div>
      </div>
    </div>
    <!-- Back and Next Buttons -->
    <div class="absolute bottom-4 right-4">
      <tx-button :text="t('general.back')" :disabled="checkedNodes.length === 1 || currentStepIndex === 0" class="mr-2" @click="onBack" />
      <tx-button :text="t('general.next')" :disabled="checkedNodes.length === 0 || currentStepIndex === 1" @click="onNext" />
    </div>
  </tx-panel>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { onMounted, onUnmounted, reactive, ref, watchEffect } from 'vue'
import MbArticleDetails from '../services/articleDetails'
import TxPanel from '@/shared/components/TxPanel.vue'
import Loader from '@/shared/components/Loader.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxButton from '@/shared/components/TxButton.vue'
import utils, { CancelToken } from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import type Merch from '@/modules/merch/services/merch'
import { merchConstants } from '@/models/constants'
import TxTree from '@/shared/components/TxTree.vue'
import type MerchSlide from '@/modules/merch/services/merchSlide'
import { useNotificationStore } from '@/store/notification'
import appConfig from '@/services/appConfig'
import type MyArticle from '@/models/myArticle'

export interface IAccessibleItem {
  id: number
  type: AccessibleItemType
  name: string
  subTitle: string
}

interface IProps {
  merch: Merch | undefined
  selectedNodeId?: string
}

const props = withDefaults(defineProps<IProps>(), { selectedNodeId: '' })

const emit = defineEmits<{
  (e: 'findNodeClick', selectedNode: ITreeNode, findWhat: string): void
  (e: 'findNextSearchedTextOnSlide', slideId: string, findWhat: string, replaceWith: string | '', isReplace: boolean): void
  (e: 'replaceAll', selectedNodes: MerchSlide[], findWhat: string, replaceWith: string | ''): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()
const notificationStore = useNotificationStore()

const panelVisible = ref(false)
const loading = ref(false)
const steps = ['Slides', 'Find']
const currentStepIndex = ref(0)
const slideTree = ref<ITreeNode[]>([])
const refSlideTree = ref<InstanceType<typeof TxTree>>()
const checkedNodes = ref<ITreeNode[]>([])
const matchedTree = ref<ITreeNode[]>([])
const refMatchedSlideTree = ref<InstanceType<typeof TxTree>>()
const currentActiveSlide = ref(-1)
const disableReplaceButton = ref(false)
const filteredSlides = ref<MerchSlide[]>([])
const checkedNodesLimit = 500
const isSearching = ref(false)

const form = reactive<{ findWhat: string, replaceWith: string }>({
  findWhat: '',
  replaceWith: '',
})

watchEffect(() => {
  if (props.merch) {
    attach(props.merch)
  }
})

function buildSlideTree() {
  if (props.merch && props.merch.merchSlides && props.merch.merchSlides.value && Object.keys(props.merch.merchSlides.value).length) {
    slideTree.value = []
    Object.values(props.merch.merchSlides.value).forEach((slideData) => {
      const target = createFolderTrailAndGetTargetNode(slideData, slideTree.value)
      if (!Array.isArray(target)) {
        const node: ITreeNode = {
          key: slideData.SlideId,
          label: slideData.SlideName,
          sortOrder: slideData.SortOrder,
          faicon: 'fa-light fa-frame',
          checked: false,
          expanded: false,
          isFolder: false,
          path: [],
          children: [],
          actions: [],
        }
        node.parent = target
        utils.insertSorted(node, target.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
      }
    })
  }
}
function attach(obj: Merch) {
  obj.on('find-next-reached-end-of-slide', movePointerToNextSlide)
  obj.on('searched-text-highlighted', searchedTextHighlighted)
}
function detach(obj: Merch) {
  obj.off('find-next-reached-end-of-slide', movePointerToNextSlide)
  obj.off('searched-text-highlighted', searchedTextHighlighted)
}
function buildMatchedSlideTree(matchedSlides) {
  matchedTree.value = []
  const folderSlideMap = new Map()
  matchedSlides.forEach((slideData) => {
    const folderId = slideData.FolderId
    if (!folderSlideMap.has(folderId)) {
      folderSlideMap.set(folderId, [])
    }
    folderSlideMap.get(folderId).push(slideData)
  })

  // Iterate over the map to build the tree structure
  folderSlideMap.forEach((slides, folderId) => {
    // Create a folder node
    const folderNode: ITreeNode = {
      key: folderId,
      label: slides[0].FolderName,
      sortOrder: 0,
      faicon: 'fa-light fa-folder',
      checked: false,
      expanded: false,
      isFolder: true,
      path: [],
      children: [],
      actions: [],
    }

    // Create slide nodes and add them as children of the folder node
    slides.forEach((slideData) => {
      const slideNode: ITreeNode = {
        key: slideData.SlideId,
        label: slideData.SlideName,
        sortOrder: slideData.SortOrder,
        faicon: 'fa-light fa-frame',
        checked: false,
        expanded: false,
        isFolder: false,
        path: [],
        children: [],
        actions: [],
        parent: folderNode,
      }
      folderNode.children.push(slideNode)
    })

    // Add the folder node to the matched tree
    matchedTree.value.push(folderNode)
  })

  // Sort the matched tree by folder sort order
  matchedTree.value.sort((a, b) => a.sortOrder - b.sortOrder)
}
function createFolderTrailAndGetTargetNode(slideData, target: ITreeNode[] | ITreeNode) {
  const folderIdPathList = slideData.FolderId.split(merchConstants.folderPathSeparator)
  const folderNamePathList = slideData.FolderName.split(merchConstants.folderPathSeparator)
  for (let i = 0; i < folderIdPathList.length; i++) {
    const currentTarget = !Array.isArray(target) ? target.children : target
    let subFolder = currentTarget.find(folder => folder.key === folderIdPathList[i])
    if (!subFolder) {
      subFolder = {
        key: folderIdPathList[i],
        label: folderNamePathList[i],
        sortOrder: slideData.FolderIdSortOrder ? slideData.FolderIdSortOrder : 0,
        checked: false,
        faicon: 'fa-light fa-folder',
        expanded: false,
        isFolder: true,
        path: [],
        children: [],
        actions: [], // ['Edit', 'Delete'],
      }
      if (!Array.isArray(target)) {
        subFolder.parent = target
        utils.insertSorted(subFolder, target.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'label']))
      }
      else {
        utils.insertSorted(subFolder, target, (a, b) => utils.comparer(a, b, ['sortOrder', 'label']))
      }
    }
    else {
      subFolder.badgeValue += slideData.unavailableArticleCount
    }
    target = subFolder
  }
  return target
}
function OnNodeCheckedChange() {
  const checkedData = refSlideTree.value?.getCheckedNodes(true)
  checkedNodes.value = []
  if (utils.isDefined(checkedData) && checkedData.length > 0) {
    checkedNodes.value = checkedData.filter(node => node.isFolder === false)
  }
}
function onBack() {
  if (currentStepIndex.value === 1) {
    currentStepIndex.value--
  }
}
function onNext() {
  if (currentStepIndex.value === 0) {
    currentStepIndex.value++
  }
}
async function doFind() {
  isSearching.value = true
  currentActiveSlide.value = -1
  const matchedSlideIdList: string[] = []
  const slideIds: string[] = checkedNodes.value.map(node => node.key) as string[]
  const slidesData = await props.merch?.getSlidesData(slideIds)
  // get all the article
  const articleIdsList: number[] = []
  for (const slideId of slideIds) {
    if (slidesData && slidesData[slideId]) {
      const merchSlide = slidesData[slideId]
      if (typeof merchSlide === 'object') {
        const objects = merchSlide.objects.filter(obj => obj.type === merchConstants.objectTypes.articleDetails)
        const uniqueArticleIds = new Set(articleIdsList)
        const newArticleIds = objects.map(object => object.articleId).filter(id => !uniqueArticleIds.has(id))
        // push unique article ids
        articleIdsList.push(...newArticleIds)
      }
      else {
        const slideName = checkedNodes.value.find(node => node.key === slideId)?.label
        errorMessage.value = t('merch.errors.invalidSlidecontent', { slideName })
      }
    }
  }
  // getMyArticles from DB
  const articleIdArticleMap: Record<number, MyArticle> = {}
  if (userStore.activeCatalog) {
    const res = await appConfig.DB!.getMyArticles(userStore.activeCatalog, {}, userStore.myAttributes!, userStore.currentUsername, articleIdsList, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
    if (res && !(res instanceof CancelToken) && res.length > 0) {
      for (const article of res) {
        articleIdArticleMap[article.Id] = article
      }
    }
  }

  for (const slideId of slideIds) {
    if (slidesData && slidesData[slideId]) {
      const merchSlide = slidesData[slideId]
      if (typeof merchSlide === 'object') {
        const templateObjects = merchSlide.objects
        for (let objectIndex = 0; objectIndex < templateObjects.length; objectIndex++) {
          const object = templateObjects[objectIndex]
          if (object.type === 'textbox' && object.text !== '' && (object.text.toLowerCase()).includes(form.findWhat.toLowerCase())) {
            matchedSlideIdList.push(slideId)
            break
          }
          else if (object.type === 'articleDetails' && object.customOptions && object.customOptions.articleProps && articleIdArticleMap[object.articleId]) {
            // if article id in MAP
            const content = await MbArticleDetails.getText(articleIdArticleMap[object.articleId], object.showLabels, object.customOptions.articleProps, '\n', '', userStore.myAttributes)
            if (content.toLowerCase().includes(form.findWhat.toLowerCase())) {
              matchedSlideIdList.push(slideId)
              break
            }
          }
        }
      }
    }
  }
  // loop through matchedSlideIdList from props.merch.merchSlides.value
  const validSlides: MerchSlide[] = []
  matchedSlideIdList.forEach((slideId) => {
    if (props.merch && props.merch.merchSlides && props.merch.merchSlides.value && Object.keys(props.merch.merchSlides.value).length) {
      validSlides.push(props.merch.merchSlides.value[slideId])
    }
  })

  buildMatchedSlideTree(validSlides)
  filteredSlides.value = validSlides
  isSearching.value = false
  doFindNext()
}
function onTreeItemClick(nodeData: ITreeNode) {
  if (!nodeData.isFolder) {
    emit('findNodeClick', nodeData, form.findWhat)
  }
}

function doFindNext() {
  currentActiveSlide.value = currentActiveSlide.value !== -1 ? currentActiveSlide.value : 0
  emit('findNextSearchedTextOnSlide', filteredSlides.value[currentActiveSlide.value].SlideId, form.findWhat, '', false)
}
function movePointerToNextSlide() {
  currentActiveSlide.value = currentActiveSlide.value === -1 ? 0 : currentActiveSlide.value + 1
  // if not reached end
  if (filteredSlides.value.length > currentActiveSlide.value) {
    emit('findNextSearchedTextOnSlide', filteredSlides.value[currentActiveSlide.value].SlideId, form.findWhat, '', false)
  }
  else {
    props.merch?.reRenderCurrentSlides().then(() => {
      notificationStore.addNotification({ message: t('merch.dialog.findAndReplace.reachedEnd'), type: 'Success' })
    })
  }
}
function doReplace() {
  currentActiveSlide.value = currentActiveSlide.value !== -1 ? currentActiveSlide.value : 0
  emit('findNextSearchedTextOnSlide', filteredSlides.value[currentActiveSlide.value].SlideId, form.findWhat, form.replaceWith, true)
}
function doReplaceAll() {
  emit('replaceAll', filteredSlides.value, form.findWhat, form.replaceWith)
}

function searchedTextHighlighted() {
  const activeObject = props.merch?.canvas.getActiveObject()
  if (!activeObject || activeObject.type !== 'textbox') {
    disableReplaceButton.value = true
  }
  else {
    disableReplaceButton.value = false
  }
}

function open() {
  resetData()
  panelVisible.value = true
  loading.value = true
  buildSlideTree()
  loading.value = false
}

function doClose() {
  resetData()
  panelVisible.value = false
}
function resetData() {
  currentStepIndex.value = 0
  slideTree.value = []
  checkedNodes.value = []
  form.findWhat = ''
  form.replaceWith = ''
  matchedTree.value = []
  currentActiveSlide.value = -1
  disableReplaceButton.value = false
  filteredSlides.value = []
}
onMounted(async () => {
  loading.value = true
  buildSlideTree()
  loading.value = false
})
onUnmounted(() => {
  if (props.merch) {
    detach(props.merch)
  }
})
defineExpose({
  open,
  close,
  loading,
  errorMessage,
})
</script>
