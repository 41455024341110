<template>
  <tx-dropdown>
    <template #button>
      <div
        data-testid="color-picker" class="w-4 h-4 p-2 rounded-full cursor-pointer"
        :style="`background-color: ${modelValue}`"
      />
    </template>
    <div class="w-36">
      <div
        v-for="(color, index) in predefined" :key="index.toString()" class="w-5 h-5 m-1 float-left cursor-pointer"
        :class="{ 'rounded-full': color === modelValue }" :style="`background-color:${color}`"
        @click="doSelectColor(color)"
      />
    </div>
  </tx-dropdown>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import TxDropdown from '@/shared/components/TxDropdown.vue'

defineProps<{
  predefined: string[]
  modelValue: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string)
}>()

const visible = ref(false)

function doSelectColor(color: string) {
  emit('update:modelValue', color)
  visible.value = false
}
</script>
