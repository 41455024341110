<template>
  <div v-show="localShow" class="flex items-center h-10 p-px m-1 border border-gray-200 border-solid rounded-md">
    <input
      ref="tagNameInput" v-model="tagName" type="text" :minlength="1" :maxlength="50" autofocus
      class="h-6 mx-1 mb-1 text-base w-15" @keyup.enter.stop="onOk" @keyup.esc.stop="onCancel"
    >

    <color-picker v-model="tagColor" :predefined="predefinedColors" />

    <tx-button type="icon" class="w-4 h-4" faicon="fa-light fa-check" @click="onOk" />
    <tx-button v-if="showDeleteAction" type="icon" class="w-4 h-4" faicon="fa-light fa-trash-can" @click="onDelete" />
    <tx-button type="icon" class="w-4 h-4" faicon="fa-light fa-xmark" @click="onCancel" />
  </div>
</template>

<script lang="ts" setup>
import { clone } from 'lodash-es'
import { nextTick, ref, watch } from 'vue'
import ColorPicker from '../ColorPicker.vue'
import TxButton from '@/shared/components/TxButton.vue'
import utils from '@/services/utils'

interface IProps {
  show?: boolean
  tag: ITag
  tags?: ITag[]
  showDeleteAction?: boolean
  adding?: boolean
}

const props = withDefaults(defineProps<IProps>(), { show: true, tags: () => [] as ITag[], showDeleteAction: false, adding: false })

const emit = defineEmits<{
  (e: 'update:show', val: boolean): void
  (e: 'ok', tag: ITag): void
  (e: 'delete', tag: ITag): void
  (e: 'cancel'): void
}>()

const localShow = ref(props.show)
const tagNameInput = ref<HTMLInputElement>()
const tagName = ref(props.tag.tag)
const tagColor = ref(props.tag.color)
const manageTagErrorVisible = ref(false)
const manageTagError = ref('')

const predefinedColors = [
  '#FF3800',
  '#61BD4F',
  '#F2D600',
  '#4363d8',
  '#ED9121',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#800000',
  '#aaffc3',
]

if (!utils.isValidStringValue(props.tag.color)) {
  tagColor.value = predefinedColors[props.tags.length % predefinedColors.length]
}

function onOk() {
  // Check if we entered a tag
  if (!tagName.value || tagName.value.length < 3 || tagName.value.length > 50) {
    manageTagError.value = 'You must enter a unique tag name having a minimum length of 3 characters and maximum length of 50'
    manageTagErrorVisible.value = true
    nextTick(() => tagNameInput.value?.focus())
    return
  }
  // Check if we already have the same tag name
  if (props.tags.findIndex(itm => itm.id !== props.tag.id && itm.tag.toLowerCase() === tagName.value.toLowerCase()) >= 0) {
    manageTagError.value = 'The tag name you entered already exists, please enter a unique tag name'
    manageTagErrorVisible.value = true
    nextTick(() => tagNameInput.value?.focus())
    return
  }

  manageTagErrorVisible.value = false

  const tag = clone(props.tag)
  if (props.adding) {
    tag.id = utils.hashCode(tagName.value)
  }
  tag.tag = tagName.value
  tag.color = tagColor.value

  localShow.value = false
  tagName.value = ''
  emit('ok', tag)
}

function onDelete() {
  manageTagErrorVisible.value = false
  localShow.value = false
  emit('delete', props.tag)
}

function onCancel() {
  manageTagErrorVisible.value = false
  localShow.value = false
  emit('cancel')
}

watch(() => props.show, (show: boolean) => {
  localShow.value = show
  if (show) {
    tagNameInput.value?.focus()
  }
})
watch(() => localShow.value, (localShow: boolean) => {
  emit('update:show', localShow)
})
</script>
