<template>
  <div class="title-skeleton">
    <div class="title" :style="{ width: `${titleSize}px` }" />
  </div>
</template>

<script lang="ts" setup>
const titleSize = Math.floor((Math.random() * 151) + 50)
</script>

<style lang="scss" scoped>
@mixin background-gradient {
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  border-radius: 5px;
}

.title-skeleton {
  width: 100%;
  padding: 10px 0;

  .title {
    height: 25px;
    @include background-gradient;
    animation: shine-lines 1.6s infinite linear;
  }
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 240px;
  }
}
</style>
