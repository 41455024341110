<template>
  <tx-dialog
    v-model="visible" :title="t('general.assignImageConfirmation', { articleNumber: localAssetModel?.ImageSet })" width="500px"
    show-ok-cancel :cancel-state="loading ? 'disabled' : 'enabled'" :ok-state="loading ? 'loading' : 'enabled'"
    @click="doCancel()" @ok="doConfirm()"
  >
    <div class="w-full h-full">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      <img :src="imgSrc" class="mx-auto w-60 h-60">
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import type MyArticle from '@/models/myArticle'
import type { CreateUpdateAssetModel } from '@/api/dune/model/assetModel'
import { createAsset } from '@/api/dune/asset'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'confirm', article: MyArticle, asset: string): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()
const visible = ref(false)
const loading = ref(false)
const localArticle = ref<MyArticle>()
const localAssetModel = ref<CreateUpdateAssetModel>()

const imgSrc = computed(() => {
  if (localAssetModel.value) {
    return URL.createObjectURL(localAssetModel.value.file)
  }
  return ''
})

function showDialog(article: MyArticle, assetModel: CreateUpdateAssetModel) {
  loading.value = false
  visible.value = true
  localArticle.value = article
  localAssetModel.value = assetModel
}

function doCancel() {
  visible.value = false
  emit('cancel')
}

async function doConfirm() {
  if (localAssetModel.value && localArticle.value) {
    errorMessage.value = ''
    loading.value = true
    createAsset(localAssetModel.value)
      .then(async (res) => {
        await userStore.doLoadData(['Articles'])
        emit('confirm', localArticle.value!, res.data.Id)
        visible.value = false
      })
      .catch((e) => {
        console.error(e)
        errorMessage.value = t('general.unexpectedError')
      })
      .finally(() => loading.value = false)
  }
}

defineExpose({
  showDialog,
})
</script>
