<template>
  <tx-dropdown
    id="modNoti" ref="refNotificationDropdown" faicon="fa-light fa-bell" button-type="nav" class="mx-auto" :ping="isAnyNewNotification" :max-height="550"
    @close="onClose()"
  >
    <div class="w-80 h-[550px] flex flex-col overflow-hidden rounded">
      <div class="flex p-2 mb-2 bg-primary text-on-primary">
        <div class="flex-1 text-sm uppercase">
          {{ t('general.notifications', notifications.length) }}
        </div>
        <font-awesome-icon class="w-4 h-4 cursor-pointer" icon="fa-light fa-xmark" @click="refNotificationDropdown?.closeDropdown" />
      </div>
      <div class="flex flex-col overflow-y-auto grow">
        <div
          v-for="notification in notifications" :key="notification.id" class="flex py-2"
          :class="{ 'cursor-pointer': notification.status === 'done' }" @click="onNotificationClick(notification)"
        >
          <font-awesome-icon
            class="w-5 h-5 p-2 mx-2 rounded bg-primary text-on-primary"
            :class="{ 'bg-warn': notification.status === 'fail', 'bg-success': notification.status === 'done' || notification.status === 'complete' }"
            :icon="notification.status === 'done' || notification.status === 'complete' ? 'fa-light fa-cloud-arrow-down' : notification.status === 'fail' ? 'fa-light fa-file-xmark' : 'fa-light fa-file-export'"
          />
          <div class="flex flex-col px-2 grow">
            <div v-if="notification.type === 'Articles'">
              {{ t('notificationDropdown.importArticles') }}
            </div>
            <div v-else-if="notification.type === 'editSizes'">
              {{ t('notificationDropdown.updateSizes') }}
            </div>
            <div v-else-if="notification.type === 'exportPPT'">
              {{ t('notificationDropdown.generatePPT') }}
            </div>
            <div v-else-if="notification.type === 'assortArticles'">
              {{ t('notificationDropdown.assortArticles') }}
            </div>
            <div v-else-if="notification.type === 'updateViaImport'">
              {{ t('notificationDropdown.updateViaImport') }}
            </div>
            <div v-else-if="notification.type === 'updateViaImportModel'">
              {{ t('notificationDropdown.updateViaImportModel') }}
            </div>
            <div v-else-if="notification.type === 'importByLicensee'">
              {{ t('notificationDropdown.importByLicensee') }}
            </div>
            <div v-else-if="notification.type === 'setSizesByLicenseeModelNumber'">
              {{ t('notificationDropdown.setSizesByLicenseeModelNumber') }}
            </div>
            <div v-else-if="notification.type === 'setPricesByLicenseeModelNumber'">
              {{ t('notificationDropdown.setPricesByLicenseeModelNumber') }}
            </div>
            <div v-else>
              {{ t('notificationDropdown.generatePdf') }}
            </div>
            <div v-if="notification.status === 'new' || notification.status === 'processing' || notification.status === 'inprocess'" class="text-xs">
              <p class="text-xs">
                {{ t('notificationDropdown.processing') }}
              </p>
              <tx-indeterminate-progress />
            </div>
            <div v-else-if="notification.status === 'done' && (notification.type === 'exportPPT' || notification.type === 'exportPDF')" class="text-xs">
              {{ t('notificationDropdown.download') }}
            </div>
            <div v-else-if="notification.status === 'complete' && (notification.type === 'Articles' || notification.type === 'editSizes' || notification.type === 'assortArticles' || notification.type === 'importArticles' || notification.type === 'updateViaImport' || notification.type === 'updateViaImportModel' || notification.type === 'importByLicensee' || notification.type === 'setSizesByLicenseeModelNumber' || notification.type === 'setPricesByLicenseeModelNumber')" class="flex flex-row flex-wrap h-4 grow">
              <p class="flex-1 text-xs">
                {{ t('notificationDropdown.completed') }}
              </p>
              <div v-tooltip="{ text: t('general.refresh'), theme: { placement: 'left', width: '100' } }">
                <font-awesome-icon class="w-3 h-3 p-1 mx-1 cursor-pointer hover:text-primary-500" icon="fa-light fa-refresh" @click="onDataRefresh(notification)" />
              </div>
            </div>
            <div v-else-if="notification.status === 'fail'" class="flex flex-row flex-wrap h-4 grow">
              <p class="flex-1 text-xs text-red-600">
                {{ t('notificationDropdown.failed') }}
              </p>
              <div v-tooltip="{ text: t('general.details'), theme: { placement: 'left', width: '4000' } }">
                <font-awesome-icon class="w-3 h-3 p-1 mx-1 text-red-600 cursor-pointer hover:text-red-800" icon="fa-light fa-exclamation-triangle" @click="showErrorList(notification.id)" />
              </div>
            </div>
            <div class="text-xs text-disabled">
              <use-time-ago v-slot="{ timeAgo }" :time="notification.date">
                {{ timeAgo }}
              </use-time-ago>
            </div>
          </div>
        </div>
      </div>
    </div>
  </tx-dropdown>
</template>

<script lang="ts" setup>
import { UseTimeAgo } from '@vueuse/components'
import axios from 'axios'
import type { Subscription } from 'dexie'
import { liveQuery } from 'dexie'
import { useI18n } from 'vue-i18n'
import { onMounted, onUnmounted, ref, watch } from 'vue'
import TxDropdown from '@/shared/components/TxDropdown.vue'
import TxIndeterminateProgress from '@/shared/components/TxIndeterminateProgress.vue'
import { getStatus } from '@/api/fileServer/generate'
import utils from '@/services/utils'
import appConfig from '@/services/appConfig'
import { useUserStore } from '@/store/userData'

export interface INotificationItem {
  id: string
  status: string
  type: 'exportPPT' | 'Articles' | 'exportPDF' | 'editSizes' | 'assortArticles' | 'importArticles' | 'updateViaImport' | 'updateViaImportModel' | 'importByLicensee' | 'setSizesByLicenseeModelNumber' | 'setPricesByLicenseeModelNumber'
  downloadUrl: string
  date: Date
}

const emit = defineEmits<{
  (e: 'refresh', notification: INotificationItem): void
  (e: 'showErrorInformation', notificationId: string): void
}>()

const { t } = useI18n()
const userStore = useUserStore()

const refNotificationDropdown = ref<InstanceType<typeof TxDropdown>>()

let intervalId: number | undefined
let jobsSub: Subscription | undefined
const notifications = ref<INotificationItem[]>([])
const isAnyNewNotification = ref(false)

function onNotificationClick(notification: INotificationItem) {
  if (utils.isValidStringValue(notification.downloadUrl)) {
    axios({
      url: notification.downloadUrl,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const fileName = notification.type === 'exportPPT' ? `${notification.id}.pptx` : `${notification.id}.pdf`
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', fileName) // or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }
}

function initLiveQuery() {
  if (jobsSub && !jobsSub.closed) { jobsSub.unsubscribe() }
  if (userStore.activeCatalog && userStore.userProfile) {
    const observable = liveQuery(async () => await appConfig.DB!.jobs.where({ CatalogCode: userStore.activeCatalog?.CatalogCode, UserId: userStore.userProfile.Id }).toArray())
    jobsSub = observable.subscribe((jobs) => {
      notifications.value = []
      isAnyNewNotification.value = false
      if (jobs.length) {
        jobs.sort((a, b) => b.CreatedDate.getTime() - a.CreatedDate.getTime())
          .forEach((job) => {
            if (!isAnyNewNotification.value && job.CreatedDate > userStore.notificationsLastVisited && (job.Status === 'fail' || job.Status === 'done' || job.Status === 'complete')) {
              isAnyNewNotification.value = true
              userStore.setNotificationsLastVisited()
            }
            notifications.value.push({
              id: job.Id,
              status: job.Status,
              type: job.Type,
              downloadUrl: job.DownloadUrl ? job.DownloadUrl : '',
              date: job.CreatedDate,
            })
          })
      }
    })
  }
}

function onClose() {
  isAnyNewNotification.value = false
}

async function onDataRefresh(notification: INotificationItem) {
  emit('refresh', notification)
}

function showErrorList(notificationId: string) {
  emit('showErrorInformation', notificationId)
}

watch(() => [userStore.activeCatalog, userStore.userProfile], initLiveQuery)

onMounted(() => {
  initLiveQuery()
  intervalId = setInterval(() => {
    if (userStore.activeCatalog && notifications.value && notifications.value.length) {
      notifications.value.forEach((notification) => {
        if (notification.status === 'new' || notification.status === 'processing' || notification.status === 'inprocess') {
          getStatus(notification.id, notification.type)
            .then((res) => {
              if (res.data) {
                appConfig.DB!.jobs.update([userStore.activeCatalog!.CatalogCode, notification.id], {
                  Status: (notification.type === 'Articles' || notification.type === 'editSizes' || notification.type === 'assortArticles' || notification.type === 'importArticles' || notification.type === 'updateViaImport' || notification.type === 'updateViaImportModel' || notification.type === 'importByLicensee' || notification.type === 'setSizesByLicenseeModelNumber' || notification.type === 'setPricesByLicenseeModelNumber') ? res.data.toString().toLowerCase() : res.data.status,
                  DownloadUrl: res.data.url ? res.data.url : '',
                })
              }
            })
            .catch((e) => {
              console.error(e)
            })
        }
      })
    }
  }, 10000)
})

onUnmounted(() => {
  if (jobsSub && !jobsSub.closed) { jobsSub.unsubscribe() }
  if (intervalId) { clearInterval(intervalId) }
})
</script>
