<template>
  <div class="h-full">
    <div class="w-full px-10 mt-2 text-base">
      <span class="pr-1 text-red-500">*</span>{{ t('exportDialog.excel.title.selectAttributes') }}
    </div>
    <div class="w-full mt-2">
      <div class="w-full px-10 my-2 mb-5">
        <tx-checkbox v-model="checkAll" :label="t('general.checkAll')" @change="handleCheckAllChange" />
      </div>
      <div class="grid w-full grid-cols-3 px-10 mb-4">
        <div v-for="field in exportableFields" :key="field.SystemName" class="flex items-center w-full">
          <label class="flex items-center">
            <input v-model="form.checkedFields" type="checkbox" :value="field.SystemName" :errors="v$[field.SystemName]?.$errors" @change="handleCheckedFieldChange()">
            <span class="pl-3 text-base" v-text="field.DisplayName" />
          </label>
        </div>
      </div>
      <div v-if="showIncludeImages" class="px-10 w-full">
        <tx-switch
          v-if="userStore.activeCatalog" v-model="form.includeImages" :label="t('exportDialog.excel.includeImages')"
          :sub-label="t('exportDialog.excel.includeImagesLimit', { limit: userStore.activeCatalog.Config.ExportExcelArticlesWithImageLimit })" :disabled="false" @change="onChange"
        />
      </div>
      <div v-if="showIncludeInactiveArticles" class="flex w-full px-10">
        <tx-switch
          v-model="form.includeInactiveArticles" :label="props.source === 'requests' ? t('exportDialog.excel.includeInactiveRequests') : t('exportDialog.excel.includeInactiveArticles')"
          :disabled="false" @change="onChange"
        />
      </div>
      <div v-if="showFavoriteTagsInSeparateRow" class="flex w-full px-10">
        <tx-switch
          v-model="form.favoriteTagsInSeparateRow" :label="t('exportDialog.excel.favoriteTagsInSeparateRow')"
          :disabled="!isCheckedFieldValid" @change="onChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref } from 'vue'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, required } from '@vuelidate/validators'
import { useUserStore } from '@/store/userData'
import TxCheckbox from '@/shared/components/TxCheckbox.vue'
import TxSwitch from '@/shared/components/TxSwitch.vue'
import { AttributeType } from '@/models/catalogAttribute'
import { appConstants } from '@/models/constants'
import utils from '@/services/utils'

interface IProps {
  modelValue: Record<string, any>
  source?: string
  currentStep?: number
  validationStep?: number
  showIncludeImages?: boolean
  showIncludeInactiveArticles?: boolean
  showFavoriteTagsInSeparateRow?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  showIncludeImages: true,
  showIncludeInactiveArticles: true,
  showFavoriteTagsInSeparateRow: true,
})

const emit = defineEmits(['update:modelValue'])
const { t } = useI18n()
const userStore = useUserStore()
const withI18nMessage = createI18nMessage({ t })

const form = reactive({
  checkedFields: [] as string[],
  includeImages: false,
  includeInactiveArticles: false,
  favoriteTagsInSeparateRow: false,
})
const checkAll = ref(false)

const exportableFields = computed(() => {
  let exportableFields: IMyAttribute[] = []
  if (userStore.activeCatalog && userStore.myAttributes) {
    const exportableArticleProperties: IMyAttribute[] = [] // filterout unwanted fields
    const priceGroupsFields: IMyAttribute[] = []
    const favoriteTagsFields: IMyAttribute[] = []
    // Remove fields starting with underscore from exportableArticleProperties
    for (const attributeSystemName in userStore.myAttributes) {
      const attribute = userStore.myAttributes[attributeSystemName]
      if (!attributeSystemName.startsWith('_') && !attribute.IsRequest
        && !(props.source === 'requests' && attributeSystemName === 'ArticleNumber')) {
        exportableArticleProperties.push(attribute)
      }
    }
    if (userStore.activeCatalog?.Config.ExportAllPriceGroups) {
      // all price groups
      userStore.activeCatalog!.CatalogPriceGroupList.forEach((priceGroup) => {
        if (priceGroup.Status) {
          const priceField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
            AttributeType: AttributeType.Decimal,
            DisplayName: priceGroup.Description,
            SystemName: priceGroup.Id,
          })
          priceGroupsFields.push(priceField)
        }
      })
    }
    else {
      // my prices
      if (userStore.priceGroups && userStore.priceGroups.retail) {
        priceGroupsFields.push(userStore.myAttributes._RetailPrice)
      }
      if (userStore.priceGroups && userStore.priceGroups.wholesale) {
        priceGroupsFields.push(userStore.myAttributes._WholesalePrice)
      }
      if (userStore.priceGroups && userStore.priceGroups.outlet) {
        priceGroupsFields.push(userStore.myAttributes._OutletPrice)
      }
    }

    // favorite tags & allow it to use in lineplan catalog
    favoriteTagsFields.push(userStore.myAttributes._FavoriteTags)

    exportableFields = exportableArticleProperties.concat(priceGroupsFields, favoriteTagsFields)
    const dropReasonAttributeField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'DropReason',
      DisplayName: t('activeOrDeactiveArticle.dropReason'),
      Creatable: true,
      AttributeType: AttributeType.Nvarchar,
      ReadOnly: false,
      IsRequired: true,
    })
    exportableFields.push(dropReasonAttributeField)

    exportableFields = utils.sort(exportableFields, ['DisplayName'])
  }
  return exportableFields
})

const isCheckedFieldValid = computed(() => {
  return form.checkedFields.includes('_FavoriteTags')
})

const rules = computed(() => {
  const result: Record<string, any> = {}
  if (props.currentStep === props.validationStep) {
    return {
      checkedFields: {
        required: withI18nMessage(required),
      },
    }
  }
  return result
})

const v$ = useVuelidate(rules, form)

function handleCheckAllChange(val: TxBooleanish) {
  form.checkedFields = val ? exportableFields.value.map(field => field.SystemName) : []
  emit('update:modelValue', form)
}

function handleCheckedFieldChange() {
  checkAll.value = form.checkedFields.length === exportableFields.value.length
  emit('update:modelValue', form)
}

function onChange() {
  emit('update:modelValue', form)
}
</script>
