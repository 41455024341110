import developmentReview from './developmentReview'
import type { IMerchTemplate } from './IMerchTemplate'
import standard from './standard'
import techSheet from './techSheet'
import uaStandard from './uaStandard'
import visualLineBuilder from './visualLineBuilder'

// TODO: Might need to load them dynamically

const templates: Record<number, IMerchTemplate> = {}
// templates[browseByAttribute.id] = browseByAttribute
templates[techSheet.id] = techSheet
templates[uaStandard.id] = uaStandard
templates[developmentReview.id] = developmentReview
templates[standard.id] = standard
templates[visualLineBuilder.id] = visualLineBuilder

export default templates
