<template>
  <div class="whiteboard-item">
    <div class="image" />
    <div class="name" :style="{ width: `${nameSize}px` }" />
    <div class="footer" :style="{ width: `${footerSize}px` }" />
  </div>
</template>

<script lang="ts" setup>
const nameSize = Math.floor((Math.random() * 80) + 60)
const footerSize = Math.floor((Math.random() * 80) + 40)
</script>

<style lang="scss" scoped>
@mixin background-gradient {
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  border-radius: 5px;
}

.whiteboard-item {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
  display: inline-block;
  height: 150px;
  width: 155px;
  padding: 16px;
  margin: 16px;
  align-items: center;
  border-radius: 5px;
  background-color: #ffffff;

  .image {
    margin: 10px;
    height: 50px;
    width: 50px;
    @include background-gradient;
    animation: shine-lines 1.6s infinite linear;
  }

  .name {
    // display: table-cell;
    margin: 15px 0 0;
    height: 25px;
    @include background-gradient;
    animation: shine-lines 1.6s infinite linear;
  }

  .footer {
    margin-top: 10px;
    height: 15px;
    @include background-gradient;
    animation: shine-lines 1.6s infinite linear;
  }

  @keyframes shine-lines {
    0% {
      background-position: -100px;
    }

    40%,
    100% {
      background-position: 140px;
    }
  }
}
</style>
