<template>
  <tx-autocomplete
    ref="refAutocomplete" :model-value="currentComponentModelValue"
    :clearable="clearable"
    :disabled="disabled"
    :errors="errors"
    :fetch-suggestions="searchModelNumber"
    :label="label"
    :placeholder="placeholder"
    :required="required"
    :data-loading="!isSellerDataFetched"
    display-prop="ModelNumber"
    value-prop="ModelNumber"
    @update:model-value="onModelValueUpdated"
  />
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import type { ErrorObject } from '@vuelidate/core'
import { pick } from 'lodash-es'
import TxAutocomplete from './TxAutocomplete.vue'
import utils from '@/services/utils'
import type { SellerModelsBase } from '@/api/t1/model/sellerModel'
import { appConstants } from '@/models/constants'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'

interface IProps {
  clearable?: boolean
  disabled?: boolean
  errors?: ErrorObject[]
  label?: string
  modelValue?: any
  placeholder?: string
  required?: boolean
}
const props = withDefaults(defineProps<IProps>(), {
  clearable: true,
  disabled: false,
  errors: () => [] as ErrorObject[],
  label: '',
  modelValue: null,
  placeholder: '',
  required: false,
})

const emit = defineEmits<{
  (e: 'change', modelValue: any): void
  (e: 'update:modelValue', modelValue: SellerModelsBase | null): void
  (e: 'loading', loadingData: boolean): void
}>()

const userStore = useUserStore()
const refAutocomplete = ref<InstanceType<typeof TxAutocomplete>>()
const currentComponentModelValue: any = ref(props.modelValue)
const fetchedSellerModels = ref<SellerModelsBase[]>([])
const isSellerDataFetched = ref(false)
const sellerModelsIndexes = ref<Record<string, SellerModelsBase>>({})

watchEffect(() => {
  if (props.modelValue != null && typeof props.modelValue === 'object') {
    currentComponentModelValue.value = props.modelValue.ModelNumber
  }
  else {
    currentComponentModelValue.value = props.modelValue
  }
})

const debouncedUpdateModelFunction = useDebounceFn((modelValue) => {
  const val = sellerModelsIndexes.value[modelValue]
  currentComponentModelValue.value = val
  emit('change', val)
  emit('update:modelValue', val)
}, appConstants.debounce.input)

function onModelValueUpdated(value) {
  debouncedUpdateModelFunction(value)
}

async function searchModelNumber(query: string, callback) {
  if (!isSellerDataFetched.value) {
    // data not fetched yet
    callback([])
    return
  }

  let result: SellerModelsBase[] = []
  if (utils.isDefined(query)) {
    result = fetchedSellerModels.value.filter(sellerModel => sellerModel.ModelNumber.includes(query)).splice(0, 100)
  }
  callback(result)
}

defineExpose({
  focus,
})

onMounted(async () => {
  emit('loading', !isSellerDataFetched.value)
  if (!isSellerDataFetched.value) {
    const catalog = userStore.activeCatalog
    const catalogCodes = Array.from(catalog!._AllRelatedCatalogCodes)
    const catalogArticles = await appConfig.DB?.articles
      .where('CatalogCode')
      .anyOf(catalogCodes)
      .toArray()
    const uniqueModels: SellerModelsBase[] = []
    const uniqueModelIndexes: Record<string, SellerModelsBase> = {}
    if (catalogArticles && catalogArticles.length) {
      if (refAutocomplete.value && typeof props.modelValue === 'object') {
        refAutocomplete.value.setDisplayModelValue(props.modelValue?.ModelNumber || '')
      }
      catalogArticles.forEach((article) => {
        if (!uniqueModelIndexes[article.ModelNumber]) {
          const sellerObject = pick(article, 'ModelId', 'ModelName', 'ModelNumber')
          uniqueModels.push(sellerObject)
          uniqueModelIndexes[article.ModelNumber] = sellerObject
        }
      })
    }
    fetchedSellerModels.value = uniqueModels
    sellerModelsIndexes.value = uniqueModelIndexes
    isSellerDataFetched.value = true
  }
  emit('loading', !isSellerDataFetched.value)
})
</script>
