import ArticleDetails from './articleDetails'
import ArticleImage from './articleImage'
import Circle from './circle'
import Line from './line'
import Rectangle from './rectangle'
import Triangle from './triangle'
import Image from './image'
import Textbox from './textBox'
import Group from './group'

export default {
  ArticleDetails,
  ArticleImage,
  Circle,
  Line,
  Rectangle,
  Triangle,
  Image,
  Textbox,
  Group,
}
