<template>
  <div class="indeterminate-progress-bar">
    <div class="indeterminate-progress-bar__progress" />
  </div>
</template>

<style lang="scss" scoped>
.indeterminate-progress-bar {
  /* Color */
  background-color: #d1d5db;

  /* Size */
  height: 0.5rem;

  position: relative;
  overflow: hidden;
}

.indeterminate-progress-bar__progress {
  /* Color */
  background-color: #3b82f6;

  /* Absolute position */
  position: absolute;
  bottom: 0;
  top: 0;
  width: 50%;

  /* Move the bar infinitely */
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: indeterminate-progress-bar;
}

@keyframes indeterminate-progress-bar {
  from {
    left: -50%;
  }

  to {
    left: 100%;
  }
}
</style>
