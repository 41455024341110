import type { MasterSizeScaleModel, SizeScaleModel, UpdateSizeScaleModel } from './model/sizeModel'
import { backgroundJobConstants } from '@/models/constants'
import Net from '@/services/net'
import utils from '@/services/utils'

export function updateModelSizeScale(catalogCode: number, modelId: number, requestBody: UpdateSizeScaleModel, requestId: number | null = null) {
  let url = `catalogs/${catalogCode}/models/${modelId}/assignsizescale`
  if (requestId !== null) {
    url = `${url}?requestid=${requestId}`
  }
  return Net.t1.put(url, requestBody)
}

export async function getMasterSizeScales(sellerId: number) {
  const response = await Net.t1.get<MasterSizeScaleModel[]>(`sellers/${sellerId}/mastersizescales`)
  const result: MasterSizeScaleModel[] = []
  if (response.data) {
    response.data.forEach((sizeScale) => {
      if (sizeScale.Criteria) {
        sizeScale.Criteria = utils.tryParse(sizeScale.Criteria.toString())
      }
      result.push(sizeScale)
    })
  }
  return result
}

export async function getSellerAvailableSizeScales(sellerId: number) {
  const response = await Net.t1.get<SizeScaleModel[]>(`sellers/${sellerId}/sizescales`)
  return response.data
}

export function getCatalogSizeScales(catalogCode: number) {
  return Net.t1.get<SizeScaleModel[]>(`catalogs/${catalogCode}/sizescales`)
}

export function updateModelSizeScaleBgJob(sellerId: number, attributeGroupId: number, requestObject: Record<string, any>) {
  return Net.t1.put(`/sellers/${sellerId}/${attributeGroupId}/models/bulkassignsizescale?sourceapplication=${backgroundJobConstants.sourceApplication}&sourceaction=${backgroundJobConstants.sourceAction.editSizes}`, requestObject)
}
