<template>
  <div>
    <span v-for="(chunk, index) in chunks" :key="index" :class="chunk.highlight ? highlightClasses : ''" v-text="chunk.text" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface IProps {
  text: string
  query: string
  highlightClasses?: string
}

const props = withDefaults(defineProps<IProps>(), { highlightClasses: 'bg-highlight text-on-highlight rounded-[3px]' })

const chunks = computed(() => {
  if (!props.query || props.query.length === 0) {
    return [{ text: props.text, highlight: false }]
  }
  let index = 0
  const t = props.text.toLocaleLowerCase()
  const q = props.query.toLocaleLowerCase()
  const chunks = [] as { text: string, highlight: boolean }[]
  do {
    const lastIndex = index
    index = t.indexOf(q, index)
    if (index >= 0) {
      if (lastIndex !== index) {
        chunks.push({ text: props.text.substring(lastIndex, index), highlight: false })
      }
      chunks.push({ text: props.text.substring(index, index + q.length), highlight: true })
      index += q.length
    }
    else {
      chunks.push({ text: props.text.substring(lastIndex), highlight: false })
    }
  } while (index < t.length && index >= 0)
  return chunks
})
</script>
