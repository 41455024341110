import isEmpty from 'lodash-es/isEmpty'
import type CatalogDetails from './catalogDetails'
import type CatalogPriceGroup from './catalogPriceGroup'
import type LinkedCustomer from './linkedCustomer'
import type MyArticle from './myArticle'
import Orderline from './orderline'
import { ordersConstants } from './constants'
import type UserProfile from './userProfile'
import type { ArticleCrd } from './articleDeliveryDate'
import type { Segmentation } from './customerSegmentation'
import utils from '@/services/utils'
import type {
  IOrderDetailsModel,
  IOrderModel,
  IOrderlineContent,
  IOrderlineResponse,
  ISaveDraftPayload,
  ISellerVASModel,
  ISendOrderOrderlines,
  ISendOrderPayload,
  ISendOrderSize,
  ISendOrderVAS,
  IUpdateOrderPayload,
} from '@/api/t1/model/orderModel'
import { saveDraft, sendOrderAPI, updateOrderDetails } from '@/api/t1/order'
import type { IOrderlinePayload } from '@/modules/orders/Orders.types'
import appConfig from '@/services/appConfig'
import { useUserStore } from '@/store/userData'

export default class Order {
  ActiveCatalog: CatalogDetails
  CatalogCode: number
  // Customer: LinkedCustomer
  OrderPriceGroup: CatalogPriceGroup
  IndexedOrderlines: null | Record<number, Orderline>
  IndexedTotalQuantityPerCRD: Record<number, number>
  IndexedTotalQuantityPerCRDForActiveLines: Record<number, number>
  IsDirty: boolean
  IsOrderlinesDirty: boolean
  MaxActiveCRD: number
  // Editable: boolean may be add a property which indicate if the order is editable or not (based on process status id, ownership etc)

  Alias: string
  Comment: string
  CreatedBy: number
  CreatedByEmail: string
  CreatedDate: string
  CurrencyCode: string
  CustomerId: number
  CustomerName: string
  CustomerNumber: string
  CustomerReference: string
  GFRQuantity: number
  GFRValue: number
  Id: number | null
  InactiveQuantity: number
  LocationCode: string
  LocationId: number
  LocationName: string
  OrderProcessStatus: string
  OrderProcessStatusId: number | null
  OrderReference: string
  OrderType: string
  OrderTypeId: number | null
  PriceGroupName: string
  RejectedQuantity: number
  RevisionNumber: number
  SellerAccountId: number
  ShiftVersion: number | null
  _Source: number
  SourceId: number
  Status: boolean
  StockCode: string
  StockDefinitionId: number | null
  StockName: string
  TotalCurrentValue: number
  TotalInitialValue: number
  TotalQuantity: number
  UpdatedBy: number
  UpdatedByEmail: string
  UpdatedDate: string

  // these calculated values are when we open an order to see the orderlines, they will be calculated from each orderline
  CalculatedTotalQuantity: number
  CalculatedActiveTotalQuantity: number
  CalculatedTotalInitialValue: number
  CalculatedTotalCurrentValue: number

  constructor(customer: LinkedCustomer, activeCatalog: CatalogDetails, userProfile: UserProfile, order: IOrderModel | null, newOrderFormData?: {
    locationId: number
    locationCode: string
    locationName: string
    customerReference: string
    alias: string
  }) {
    this.ActiveCatalog = activeCatalog
    this.CatalogCode = activeCatalog.CatalogCode
    this.OrderPriceGroup = activeCatalog._IndexedCatalogPriceGroup[customer.OrderPriceGroupId]
    this.CustomerId = customer.CustomerId
    this.CustomerName = customer.Name
    this.CustomerNumber = customer.CustomerNumber

    this.IndexedOrderlines = null
    this.IndexedTotalQuantityPerCRD = {}
    this.IndexedTotalQuantityPerCRDForActiveLines = {}
    this.CalculatedTotalQuantity = 0
    this.CalculatedActiveTotalQuantity = 0
    this.CalculatedTotalInitialValue = 0
    this.CalculatedTotalCurrentValue = 0
    this.IsDirty = false
    this.IsOrderlinesDirty = false
    this.MaxActiveCRD = 1

    if (order) { // load from server data
      this.Alias = order.Alias ?? ''
      this.Comment = order.Comment ?? ''
      this.CreatedBy = order.CreatedBy
      this.CreatedByEmail = order.CreatedByEmail
      this.CreatedDate = order.CreatedDate
      this.CurrencyCode = order.CurrencyCode ?? this.OrderPriceGroup.CurrencyCode
      this.CustomerReference = order.CustomerReference ?? ''
      this.GFRQuantity = order.GFRQuantity || 0
      this.GFRValue = order.GFRValue || 0
      this.Id = order.Id
      this.InactiveQuantity = order.InactiveQuantity || 0
      this.LocationCode = order.LocationCode
      this.LocationId = order.LocationId
      this.LocationName = order.LocationName
      this.OrderProcessStatus = order.OrderProcessStatus
      this.OrderProcessStatusId = order.OrderProcessStatusId
      this.OrderReference = order.OrderReference
      this.OrderType = order.OrderType
      this.OrderTypeId = order.OrderTypeId
      this.PriceGroupName = order.PriceGroupName ?? this.OrderPriceGroup.Name
      this.RejectedQuantity = order.RejectedQuantity || 0
      this.RevisionNumber = order.RevisionNumber || 0
      this.SellerAccountId = order.SellerAccountid
      this.ShiftVersion = order.ShiftVersion
      this._Source = order.SourceId
      this.SourceId = order.SourceId
      this.Status = !!order.Status
      this.StockCode = order.StockCode
      this.StockDefinitionId = order.StockDefinitionId
      this.StockName = order.StockName
      this.TotalCurrentValue = order.TotalCurrentValue || 0
      this.TotalInitialValue = order.TotalInitialValue || 0
      this.TotalQuantity = order.TotalQuantity || 0
      this.UpdatedBy = order.UpdatedBy
      this.UpdatedByEmail = order.UpdatedByEmail
      this.UpdatedDate = order.UpdatedDate
    }
    else { // new order
      const dateString = new Date().toISOString()
      // for new orders location code is mandatory
      this.Alias = newOrderFormData!.alias
      this.Comment = ''
      this.CreatedBy = userProfile.Id
      this.CreatedByEmail = userProfile.Email
      this.CreatedDate = dateString
      this.CurrencyCode = this.OrderPriceGroup.CurrencyCode
      this.CustomerReference = newOrderFormData!.customerReference
      this.GFRQuantity = 0
      this.GFRValue = 0
      this.Id = -1
      this.InactiveQuantity = 0
      this.LocationCode = newOrderFormData!.locationCode
      this.LocationId = newOrderFormData!.locationId
      this.LocationName = newOrderFormData!.locationName
      this.OrderProcessStatus = 'draft'
      this.OrderProcessStatusId = null
      this.OrderReference = this.generateOrderReference(customer.CustomerNumber, newOrderFormData!.locationCode)
      this.OrderType = ''
      this.OrderTypeId = 0
      this.PriceGroupName = this.OrderPriceGroup.Name
      this.RejectedQuantity = 0
      this.RevisionNumber = 0
      this.SellerAccountId = activeCatalog.AccountId
      this.ShiftVersion = null
      this.SourceId = 3
      this._Source = this.SourceId
      this.Status = true
      this.StockCode = ''
      this.StockDefinitionId = null
      this.StockName = ''
      this.TotalCurrentValue = 0
      this.TotalInitialValue = 0
      this.TotalQuantity = 0
      this.UpdatedBy = userProfile.Id
      this.UpdatedByEmail = userProfile.Email
      this.UpdatedDate = dateString
    }
  }

  get Source() {
    let source = ''
    switch (this._Source) {
      case 1:
        source = 'eCatalog'
        break
      case 2:
        source = 'T1'
        break
      case 3:
        source = 'T1 Studio'
        break
      case 4:
        source = 'T1 Studio Web'
        break
    }
    return source
  }

  set Source(source) {
    this._Source = Number.isNaN(source)
      ? (source === 'eCatalog'
          ? 1
          : source === 'T1'
            ? 2
            : source === 'T1 Studio'
              ? 3
              : source === 'T1 Studio Web'
                ? 4
                : 0)
      : Number.parseInt(source)
  }

  loadOrderlines(order: IOrderDetailsModel, indexedArticles: Record<number, MyArticle>, indexedVAS: Record<number, ISellerVASModel>, customerSegmentations: Segmentation[]) {
    const userStore = useUserStore()
    // reset current order calculated data
    this.IndexedOrderlines = {}

    // initialize current order data from server
    this.Alias = order.Alias
    this.Comment = order.Comment
    this.CustomerReference = order.CustomerReference
    this.InactiveQuantity = order.InactiveQuantity
    this.LocationCode = order.LocationCode
    this.LocationId = order.LocationId
    this.LocationName = order.LocationName
    this.OrderProcessStatus = order.OrderProcessStatus
    this.OrderProcessStatusId = order.OrderProcessStatusId
    this.OrderReference = order.OrderReference
    this.OrderType = order.OrderType
    this.OrderTypeId = order.OrderTypeId
    this.RejectedQuantity = order.RejectedQuantity
    this.RevisionNumber = order.RevisionNumber
    this._Source = order.SourceId
    this.SourceId = order.SourceId
    this.StockCode = order.StockCode
    this.StockDefinitionId = order.StockDefinitionId
    this.StockName = order.StockName
    this.TotalCurrentValue = order.TotalCurrentValue
    this.TotalInitialValue = order.TotalInitialValue
    this.TotalQuantity = order.TotalQuantity

    let orderlines: Array<IOrderlineResponse | IOrderlineContent> = []
    if (order.OrderProcessStatusId != null && order.OrderProcessStatusId !== ordersConstants.orderProcessStatus.draft) { // get orderlines for non draft orders
      orderlines = order.orderlineResponse as Array<IOrderlineResponse>
    }
    else { // get orderlines for draft orders
      try {
        orderlines = order.OrderContent != null ? JSON.parse(order.OrderContent).Orderlines || [] : [] as Array<IOrderlineContent>
      }
      catch (error) {
        console.warn(`unable to load orderlines! \n ${error}`)
      }
    }

    const isCustomerRequiredDateAllowed = this.isCustomerRequiredDateAllowed()
    let customerRequiredDateValidation = {} as { relation: string }
    if (isCustomerRequiredDateAllowed && utils.isDefined(this.ActiveCatalog.CustomerRequiredDateValidation)) {
      try {
        customerRequiredDateValidation = JSON.parse(this.ActiveCatalog.CustomerRequiredDateValidation)
      }
      catch (error) {
        console.warn(error)
      }
    }

    // load orderline and update calculated data
    orderlines.forEach((orderline) => {
      const myArticle = indexedArticles[orderline.ArticleId]
      if (!utils.isDefined(myArticle)) {
        console.warn(`Invalid articleId: ${orderline.ArticleId}`)
        return
      }
      const isArticleSegmented = appConfig.DB!.isArticleSegmented(myArticle._Segmentations, customerSegmentations, userStore.activeCatalog!._IndexedCatalogSegmentation)
      if (!this.IndexedOrderlines!.hasOwnProperty(orderline.ArticleId)) {
        this.IndexedOrderlines![orderline.ArticleId] = new Orderline(this, myArticle, isArticleSegmented)
      }

      const oldOrderlineTotalQuantity = this.IndexedOrderlines![orderline.ArticleId].TotalQuantity
      const oldOrderlineActiveTotalQuantity = this.IndexedOrderlines![orderline.ArticleId].ActiveTotalQuantity
      const currentPrice = myArticle._Prices[this.OrderPriceGroup.Id] != null && myArticle._Prices[this.OrderPriceGroup.Id].Price ? myArticle._Prices[this.OrderPriceGroup.Id].Price : 0
      // load orderline
      if (order.OrderProcessStatusId != null && order.OrderProcessStatusId !== ordersConstants.orderProcessStatus.draft) {
        this.IndexedOrderlines![orderline.ArticleId].loadFromAPIResponse(this, orderline as IOrderlineResponse, indexedVAS, this.ActiveCatalog, isCustomerRequiredDateAllowed, customerRequiredDateValidation)
      }
      else {
        this.IndexedOrderlines![orderline.ArticleId].loadFromAPIResponseForDraft(this, orderline as IOrderlineContent, indexedVAS, this.ActiveCatalog, isCustomerRequiredDateAllowed, customerRequiredDateValidation, isArticleSegmented)
      }

      const currentOrderlineTotalQuantity = this.IndexedOrderlines![orderline.ArticleId].TotalQuantity - oldOrderlineTotalQuantity
      const currentOrderlineActiveTotalQuantity = this.IndexedOrderlines![orderline.ArticleId].ActiveTotalQuantity - oldOrderlineActiveTotalQuantity

      this.setTotalQuantity(this.getTotalQuantity() + currentOrderlineTotalQuantity)
      this.setActiveTotalQuantity(this.getActiveTotalQuantity() + currentOrderlineActiveTotalQuantity)
      this.setTotalInitialValue(this.CalculatedTotalInitialValue + (currentOrderlineTotalQuantity * this.IndexedOrderlines![orderline.ArticleId].InitialPrice))
      this.setTotalCurrentValue(this.CalculatedTotalCurrentValue + (currentOrderlineActiveTotalQuantity * currentPrice))
    })
  }

  fillOrder(articles: Array<MyArticle>) {
    const newOrderlines: Orderline[] = []
    // for newly create orders
    if (this.IndexedOrderlines == null) {
      this.IndexedOrderlines = {}
    }
    articles.forEach((article) => {
      if (!this.IndexedOrderlines!.hasOwnProperty(article.Id)) {
        const newOrderline = new Orderline(this, article)
        this.IndexedOrderlines![article.Id] = newOrderline
        newOrderlines.push(newOrderline)
      }
    })
    if (newOrderlines.length) {
      this.setIsOrderlinesDirty(true)
    }
    return newOrderlines
  }

  resetOrderlines() {
    this.IndexedOrderlines = {}
    this.resetCalculatedData()
  }

  resetCalculatedData() {
    this.IndexedTotalQuantityPerCRD = {}
    this.IndexedTotalQuantityPerCRDForActiveLines = {}
    this.CalculatedTotalQuantity = 0
    this.CalculatedActiveTotalQuantity = 0
    this.CalculatedTotalInitialValue = 0
    this.CalculatedTotalCurrentValue = 0
    this.setIsDirty(false)
    this.setIsOrderlinesDirty(false)
  }

  setIsDirty(isDirty: boolean) {
    this.IsDirty = isDirty
  }

  setIsOrderlinesDirty(isOrderlinesDirty: boolean) {
    this.IsOrderlinesDirty = isOrderlinesDirty
  }

  setCRDTotalQuantity(CRDId: number, quantity: number) {
    this.IndexedTotalQuantityPerCRD[CRDId] = quantity
  }

  getCRDTotalQuantity(CRDId: number) {
    return this.IndexedTotalQuantityPerCRD.hasOwnProperty(CRDId) ? this.IndexedTotalQuantityPerCRD[CRDId] : 0
  }

  setCRDTotalQuantityForActiveLine(CRDId: number, quantity: number) {
    this.IndexedTotalQuantityPerCRDForActiveLines[CRDId] = quantity
  }

  getCRDTotalQuantityForActiveLine(CRDId: number) {
    return this.IndexedTotalQuantityPerCRDForActiveLines.hasOwnProperty(CRDId) ? this.IndexedTotalQuantityPerCRDForActiveLines[CRDId] : 0
  }

  setTotalQuantity(totalQuantity: number) {
    this.CalculatedTotalQuantity = totalQuantity
  }

  getTotalQuantity() {
    return this.CalculatedTotalQuantity
  }

  setActiveTotalQuantity(totalQuantity: number) {
    this.CalculatedActiveTotalQuantity = totalQuantity
  }

  getActiveTotalQuantity() {
    return this.CalculatedActiveTotalQuantity
  }

  setTotalInitialValue(value: number) {
    this.CalculatedTotalInitialValue = value
  }

  getTotalInitialValue(getActualValue = false) {
    if (getActualValue) {
      return this.CalculatedTotalInitialValue
    }
    else {
      return Math.round(this.CalculatedTotalInitialValue * 100) / 100
    }
  }

  setTotalCurrentValue(value: number) {
    this.CalculatedTotalCurrentValue = value
  }

  getTotalCurrentValue(getActualValue = false) {
    if (getActualValue) {
      return this.CalculatedTotalCurrentValue
    }
    else {
      return Math.round(this.CalculatedTotalCurrentValue * 100) / 100
    }
  }

  generateOrderReference(customerNumber: string, locationCode: string) {
    return `${customerNumber}-${locationCode}-${Math.floor(Math.random() * 899999) + 100000}`
  }

  isCustomerRequiredDateAllowed() {
    let isCustomerRequiredDateAllowed = false
    if ((this.OrderProcessStatusId === null || this.OrderProcessStatusId === ordersConstants.orderProcessStatus.draft || this.OrderProcessStatusId === ordersConstants.orderProcessStatus.reopened)
      && Order.isCustomerRequiredDateValidatorAvailable(this.ActiveCatalog)) {
      isCustomerRequiredDateAllowed = true
    }
    return isCustomerRequiredDateAllowed
  }

  validateDeliveryDate(deliveryDate: string | null, articleCrd: ArticleCrd) {
    let customerRequiredDateValidation = {} as { relation: string }
    try {
      if (utils.isDefined(this.ActiveCatalog.CustomerRequiredDateValidation)) {
        customerRequiredDateValidation = JSON.parse(this.ActiveCatalog.CustomerRequiredDateValidation)
      }
    }
    catch (e) {
      console.warn('Invalid json for CustomerRequiredDateValidation')
    }
    if (!this.ActiveCatalog.IsStockApply && utils.isDefined(articleCrd) && deliveryDate !== null) {
      const currentCrdDateObject = new Date(this.ActiveCatalog._IndexedCatalogCRD[articleCrd.CrdId].CustomerRequiredDate)
      const deliveryDateObject = new Date(deliveryDate)
      currentCrdDateObject.setHours(0, 0, 0)
      deliveryDateObject.setHours(0, 0, 0)
      if (utils.validateCondition(deliveryDateObject.getTime(), currentCrdDateObject.getTime(), customerRequiredDateValidation.relation)) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return false
    }
  }

  updateOrderDetails(orderUpdateAbleProps: IUpdateOrderPayload) {
    return updateOrderDetails(this.ActiveCatalog.CatalogCode, this.Id!, orderUpdateAbleProps)
      .then((response) => {
        this!.setIsDirty(false)
        return response
      })
  }

  async saveDraft(orderDetailsToUpdate: Partial<Record<'Alias' | 'CustomerReference | CustomerId | LocationId', any>> | null) {
    const orderContent = {} as Record<'Orderlines', Array<IOrderlinePayload>>
    const orderlines: Array<IOrderlinePayload> = []
    const indexedOrderlines = this.IndexedOrderlines ?? {}
    for (const articleId in indexedOrderlines) {
      if (!isEmpty(this.IndexedOrderlines![articleId].IndexedCRD)) {
        for (const crdId in this.IndexedOrderlines![articleId].IndexedCRD) {
          const orderline: IOrderlinePayload = {
            ArticleId: Number(articleId),
            CrdId: Number(crdId),
            InitialPrice: this.IndexedOrderlines![articleId].InitialPrice,
            CurrentPriceGroupId: this.OrderPriceGroup.Id,
            DeliveryDate: this.IndexedOrderlines![articleId].IndexedCRD[crdId].DeliveryDate,
            OldCrdId: null, // not saving the old crdId to the server as it will be used to handle the shift delivery notification locally
            OrderlineSize: [],
            OrderlineSizeCurve: [],
            OrderlineVas: [],
          }
          for (const sizeId in this.IndexedOrderlines![articleId].IndexedCRD[crdId].IndexedSizes) {
            orderline.OrderlineSize.push({ SizeId: Number(sizeId), Quantity: this.IndexedOrderlines![articleId].IndexedCRD[crdId].IndexedSizes[sizeId].Quantity })
          }
          for (const sizeCurveId in this.IndexedOrderlines![articleId].IndexedCRD[crdId].IndexedSizeCurve) {
            orderline.OrderlineSizeCurve.push({ Id: Number(sizeCurveId), Quantity: this.IndexedOrderlines![articleId].IndexedCRD[crdId].IndexedSizeCurve[sizeCurveId].Quantity })
          }
          for (const vasId in this.IndexedOrderlines![articleId].IndexedVAS) {
            const currentVas = this.IndexedOrderlines![articleId].IndexedVAS[vasId]
            orderline.OrderlineVas.push({ VasId: Number(vasId), AdditionalInfo: currentVas.AdditionalInfo, Code: currentVas.Code })
          }
          orderlines.push(orderline)
        }
      }
      else { // when user added article without assigning quantity
        // it should be for all crds in-order to create an orderline to be available in T1
        for (const crdId in this.ActiveCatalog._IndexedCatalogCRD) {
          if (this.ActiveCatalog._IndexedCatalogCRD[crdId].Status) {
            // TODO: do index _DeliveryDates on articles
            const articleCRD = this.IndexedOrderlines![articleId].Article._DeliveryDates.find(crd => crd.CrdId === Number(crdId))
            if (articleCRD != null && articleCRD.Status) {
              const orderline: IOrderlinePayload = {
                ArticleId: Number(articleId),
                CrdId: Number(crdId),
                InitialPrice: this.IndexedOrderlines![articleId].InitialPrice,
                CurrentPriceGroupId: this.OrderPriceGroup.Id,
                DeliveryDate: null,
                OldCrdId: null,
                OrderlineSize: [] as Array<{ SizeId: number, Quantity: number }>,
                OrderlineSizeCurve: [],
                OrderlineVas: [],
              }
              this.IndexedOrderlines![articleId].Article._Sizes.forEach((size) => {
                if (size.Status) {
                  orderline.OrderlineSize.push({ SizeId: size.Id, Quantity: 0 })
                }
              })
              orderlines.push(orderline)
            }
          }
        }
      }
    }
    if (orderlines.length) {
      orderContent.Orderlines = orderlines
    }

    const payload: ISaveDraftPayload = Object.assign({
      OrderReference: this.OrderReference,
      LocationId: this.LocationId,
      CustomerId: this.CustomerId,
      CustomerReference: this.CustomerReference,
      Alias: this.Alias,
      StockDefinitionId: this.StockDefinitionId,
      SourceId: this.SourceId,
      TotalQuantity: this.CalculatedActiveTotalQuantity, // as discussed with Saad send correct(active) value to API, get list API calculate the value (without validation for draft, get details send the value as is)
      TotalCurrentValue: this.getTotalCurrentValue(true), // as discussed with Saad send correct(active) value to API, get list API calculate the value (without validation for draft, get details send the value as is)
      TotalInitialValue: this.getTotalInitialValue(true), // as discussed with Saad send correct(active) value to API, get list API calculate the value (without validation for draft, get details send the value as is)
      OrderContent: JSON.stringify(orderContent),
    }, orderDetailsToUpdate ?? {})
    try {
      const response = await saveDraft(this.ActiveCatalog.CatalogCode, payload)
      // Update the order Id, as after saving the current draft order,
      // when try to update the customer reference or alias id is required to call an API, so updating in memory order
      this.Id = response.data.Id
      // update process status as newly create order on client will have will null for OrderProcessStatusId
      this.OrderProcessStatusId = response.data.OrderProcessStatusId
      this.OrderProcessStatus = response.data.OrderProcessStatus
      this.UpdatedDate = new Date().toISOString()
      this.setIsDirty(false)
      this.setIsOrderlinesDirty(false)
      return response
    }
    catch (error) {
      console.error(error)
      throw error
    }
  }

  sendOrder(requestObject: ISendOrderPayload) {
    return sendOrderAPI(this.ActiveCatalog.CatalogCode, requestObject)
      .then((response) => {
        this.setIsDirty(false)
        this.setIsOrderlinesDirty(false)
        this.changeStatusToSubmitting()
        this.updateUpdatedDate(response.data.UpdatedDate)
        return response
      })
  }

  changeStatusToSubmitting() {
    this.OrderProcessStatusId = ordersConstants.orderProcessStatus.submitting
    this.OrderProcessStatus = 'Submitting'
  }

  updateUpdatedDate(date?: string) {
    this.UpdatedDate = date ?? new Date().toISOString()
  }

  /**
   * TODO: implement isCustomerMOQEnabled when implementing MOQ
   * TODO: sizeCurves needs to be implemented
   * TODO: articlesStocks need to be implemented
   */
  createSendOrderRequestObject(indexedArticles: Record<number, MyArticle>, indexedCustomers: Record<number, any>, sizeCurves: Array<any>, articlesStocks: Record<any, any>, customerStockThreshold: number, containsValidVas: boolean, isCustomerMOQEnabled: boolean, isCustomerMultipleOfEnabled: boolean, orderQtyMultipleOfRatio: number) {
    const userStore = useUserStore()
    // TODO: once implement the configurations use following line of codes
    // const MOQAttribute = this.ActiveCatalog.Config.MOQAttribute
    // const isMSREnabled = utils.isDefined(this.ActiveCatalog.Config.EnableMSRCriteria) &&
    //   (this.ActiveCatalog.Config.EnableMSRCriteria === true || this.ActiveCatalog.Config.EnableMSRCriteria === 'true') ? true: false
    // const articlesMustHave = this.ActiveCatalog.Config.ArticlesMustHave
    // const multipleOfAttributeSystemName = this.ActiveCatalog.Config.OrderlineQuantityMultipleOfAttributeSystemName
    const MOQAttribute = null
    const isMSREnabled = false
    const articlesMustHave = {}
    const multipleOfAttributeSystemName = null
    // const stockTracker = {}
    // const indexedOutOfStockArticleSizes = {}

    const articleQuantityTracker = {}

    const orderlines: Array<ISendOrderOrderlines> = []
    let orderline = {} as ISendOrderOrderlines
    let size = {} as ISendOrderSize
    let vas = {} as ISendOrderVAS
    // const sizeCurve = {} as ISendOrderSize
    const requestObj = (({ OrderReference, LocationId, CustomerId, CustomerReference, SourceId, OrderProcessStatusId, Alias }) => ({
      OrderReference,
      LocationId,
      CustomerId,
      CustomerReference,
      SourceId,
      ProcessingStatus: OrderProcessStatusId !== 5 && OrderProcessStatusId !== 1 ? OrderProcessStatusId : null, // as requested by API team when submitting reopened order make ProcessingStatus null
      Alias,
      Orderlines: orderlines,
    }))(this)

    // TODO: once implement the stock
    // if(this.ActiveCatalog.IsStockApply) {
    //   requestObj.StockDefinitionId = this.StockDefinitionId
    // }
    const result = {
      order: this,
      requestObj,
      validMOQ: true, // by default do not check MOQ (depend on configuration)
      validMSR: true, // by default do not check MOQ (depend on configuration)
      MSRTracker: {}, // contains articleId as key and whether it failed on DRS or FP
      MOQFailedOrderlineList: [] as Array<string>,
      validDeliveryDates: true,
      validMultipleOfQuantity: true,
      outOfStockArticleSizes: [] as Array<{ articleId: number, sizeId: number, availableQuantity: number, orderedQuantity: number }>,
      missingArticlesMustHaveList: [] as Array<string>,
      articleDroppedInSizeCurve: [] as Array<string>,
      invalidArticles: [] as Array<string>,
      containsArticleWithZeroQuantity: false,
    }
    let attributeToValidate: string | null = null
    let attributeValueToValidate: string = ''
    if (articlesMustHave && Object.keys(articlesMustHave).length) {
      attributeToValidate = Object.keys(articlesMustHave)[0]
      attributeValueToValidate = articlesMustHave[attributeToValidate] ? articlesMustHave[attributeToValidate].toString().toLowerCase() : ''
    }
    for (const articleId in this.IndexedOrderlines) {
      const article: MyArticle = this.IndexedOrderlines[articleId].Article
      article._DeliveryDates.forEach((deliveryDate) => {
        orderline = {} as ISendOrderOrderlines
        orderline.ArticleId = article.Id
        orderline.CrdId = deliveryDate.CrdId
        orderline.OrderlineSize = []
        orderline.OrderlineSizeCurve = []
        if (containsValidVas) {
          orderline.OrderlineVas = []
        }
        orderlines.push(orderline)
      })
    }
    for (let orderlineIndex = requestObj.Orderlines.length - 1; orderlineIndex >= 0; orderlineIndex--) {
      const article = indexedArticles[requestObj.Orderlines[orderlineIndex].ArticleId]
      const customerSegmentations = indexedCustomers[this.CustomerId] && indexedCustomers[this.CustomerId].Segmentations ? indexedCustomers[this.CustomerId].Segmentations : []
      if (article.Status === 1 && (!isEmpty(article._Segmentations) && customerSegmentations.length && appConfig.DB!.isArticleSegmented(article._Segmentations, customerSegmentations, userStore.activeCatalog!._IndexedCatalogSegmentation))) {
        if (!articleQuantityTracker.hasOwnProperty(article.Id)) {
          articleQuantityTracker[article.Id] = 0
        }
        if (attributeToValidate && attributeValueToValidate) {
          if (!article.hasOwnProperty(attributeToValidate) || !article[attributeToValidate] || article[attributeToValidate]!.toString().toLowerCase() !== attributeValueToValidate) {
            if (!result.missingArticlesMustHaveList.includes(article.ArticleNumber)) {
              result.missingArticlesMustHaveList.push(article.ArticleNumber)
            }
          }
        }
        let currentOrderlineMOQ: number | null = null
        if (MOQAttribute != null && isCustomerMOQEnabled) {
          currentOrderlineMOQ = utils.isValidStringValue(article[MOQAttribute]) && !Number.isNaN(Number.parseInt(article[MOQAttribute]!.toString())) ? Number.parseInt(article[MOQAttribute]!.toString()) : 0
        }

        // if crd added to a line (for new orderlines until user does not add quantity crd will not be added)
        if (this.IndexedOrderlines![requestObj.Orderlines[orderlineIndex].ArticleId].IndexedCRD[requestObj.Orderlines[orderlineIndex].CrdId]) {
          let currentOrderlineTotalQuantity = 0
          // TODO: OrderBySizeCurve does not exist on catalog details, add once implement size curve
          // if (!this.ActiveCatalog.IsStockApply && this.ActiveCatalog.OrderBySizeCurve) {
          //   const articleSizes = article._Sizes
          //   sizeCurves.forEach(catalogSizeCurve => {
          //     if (this.IndexedOrderlines![requestObj.Orderlines[orderlineIndex]['ArticleId']].IndexedCRD[requestObj.Orderlines[orderlineIndex]['CrdId']].IndexedSizeCurve[catalogSizeCurve.Id] != null &&
          //       this.IndexedOrderlines![requestObj.Orderlines[orderlineIndex]['ArticleId']].IndexedCRD[requestObj.Orderlines[orderlineIndex]['CrdId']].IndexedSizeCurve[catalogSizeCurve.Id].q > 0) {
          //       let invalidSizes: Array<string> = []
          //       if (catalogSizeCurve.Sizes.length) {
          //         for (let index = 0; index < catalogSizeCurve.Sizes.length; index++) {
          //           let sizeObject = catalogSizeCurve.Sizes[index]
          //           let articleSize = articleSizes.find(articleSize => articleSize.SizeName.toLowerCase() === sizeObject.Size.toLowerCase())
          //           //find the sizes dropped at article level so that you can reset the size curve
          //           if (!articleSize!.Status && !invalidSizes.includes(articleSize!.SizeName)) {
          //             invalidSizes.push(articleSize!.SizeName)
          //             if (!result.articleDroppedInSizeCurve.includes(articleSize!.SizeName)) {
          //               result.articleDroppedInSizeCurve.push(articleSize!.SizeName)
          //             }
          //           }
          //         }
          //       }
          //       if (invalidSizes.length == 0) {
          //         sizeCurve = {} as ISendOrderSize
          //         sizeCurve['Id'] = catalogSizeCurve.Id
          //         sizeCurve['Quantity'] = this.IndexedOrderlines![requestObj.Orderlines[orderlineIndex]['ArticleId']].IndexedCRD[requestObj.Orderlines[orderlineIndex]['CrdId']].sc[catalogSizeCurve.Id].q
          //         requestObj.Orderlines[orderlineIndex]['OrderlineSizeCurve'].push(sizeCurve)
          //       }
          //     }
          //   })
          // }
          article._Sizes.forEach((articleSize) => {
            if (this.IndexedOrderlines![requestObj.Orderlines[orderlineIndex].ArticleId].IndexedCRD[requestObj.Orderlines[orderlineIndex].CrdId].IndexedSizes[articleSize.Id] != null
              && this.IndexedOrderlines![requestObj.Orderlines[orderlineIndex].ArticleId].IndexedCRD[requestObj.Orderlines[orderlineIndex].CrdId].IndexedSizes[articleSize.Id].Quantity > 0) {
              size = {} as ISendOrderSize
              size.SizeId = articleSize.Id
              size.Quantity = this.IndexedOrderlines![requestObj.Orderlines[orderlineIndex].ArticleId].IndexedCRD[requestObj.Orderlines[orderlineIndex].CrdId].IndexedSizes[articleSize.Id].Quantity
              requestObj.Orderlines[orderlineIndex].OrderlineSize.push(size)
              currentOrderlineTotalQuantity += size.Quantity

              // TODO: add when implement stock catalog
              // valid stocks for stock catalog
              // if (this.ActiveCatalog.IsStockApply && articlesStocks != null && this.StockDefinitionId != null) {
              //   // if article or size is missing that means stock is 0 for that article or article size
              //   const articleSizeAvailableQuantity = articlesStocks.hasOwnProperty(article.Id) && articlesStocks[article.Id].hasOwnProperty(this.StockDefinitionId) &&
              //     articlesStocks[article.Id][this.StockDefinitionId].stockPerSize.hasOwnProperty(articleSize.Id) ? articlesStocks[article.Id][this.StockDefinitionId].stockPerSize[articleSize.Id] : 0
              //   if (!stockTracker.hasOwnProperty(`${article.Id}//${this.StockDefinitionId}//${articleSize.Id}`)) {
              //     stockTracker[`${article.Id}//${this.StockDefinitionId}//${articleSize.Id}`] = 0
              //   }
              //   stockTracker[`${article.Id}//${this.StockDefinitionId}//${articleSize.Id}`] += size.Quantity

              //   if (stockTracker[`${article.Id}//${this.StockDefinitionId}//${articleSize.Id}`] > articleSizeAvailableQuantity) {
              //     if (!indexedOutOfStockArticleSizes.hasOwnProperty(`${article.Id}//${this.StockDefinitionId}//${articleSize.Id}`)) {
              //       indexedOutOfStockArticleSizes[`${article.Id}//${this.StockDefinitionId}//${articleSize.Id}`] = result.outOfStockArticleSizes.length
              //       let stockValueToDisplay = articleSizeAvailableQuantity
              //       if (customerStockThreshold && articleSizeAvailableQuantity > customerStockThreshold) {
              //         stockValueToDisplay = `${customerStockThreshold}+`
              //       }
              //       result.outOfStockArticleSizes.push({ articleId: article.Id, sizeId: articleSize.Id, availableQuantity: stockValueToDisplay, orderedQuantity: stockTracker[`${article.Id}//${this.StockDefinitionId}//${articleSize.Id}`] })
              //     }
              //     result.outOfStockArticleSizes[indexedOutOfStockArticleSizes[`${article.Id}//${this.StockDefinitionId}//${articleSize.Id}`]].orderedQuantity = stockTracker[`${article.Id}//${this.StockDefinitionId}//${articleSize.Id}`]
              //   }
              // }

              // validate quantity based on multipleOf attribute
              if (multipleOfAttributeSystemName != null && isCustomerMultipleOfEnabled && article.hasOwnProperty(multipleOfAttributeSystemName) && result.validMultipleOfQuantity) {
                const currentOrderlineQuantityMultiple = utils.isDefined(article[multipleOfAttributeSystemName]) && !Number.isNaN(Number.parseInt(article[multipleOfAttributeSystemName]!.toString()))
                  ? Math.round(((Number.parseInt(article[multipleOfAttributeSystemName]!.toString())) * orderQtyMultipleOfRatio))
                  : 0
                if (currentOrderlineQuantityMultiple > 0 && size.Quantity % currentOrderlineQuantityMultiple !== 0) {
                  result.validMultipleOfQuantity = false
                }
              }
            }
          })
          if (containsValidVas) {
            for (const vasId in this.IndexedOrderlines![requestObj.Orderlines[orderlineIndex].ArticleId].IndexedVAS) {
              if (this.IndexedOrderlines![requestObj.Orderlines[orderlineIndex].ArticleId].IndexedVAS[vasId].Status && this.IndexedOrderlines![requestObj.Orderlines[orderlineIndex].ArticleId].IndexedVAS[vasId].SellerVASStatus) {
                vas = {} as ISendOrderVAS
                vas.VasId = Number.parseInt(vasId)
                vas.AdditionalInfo = this.IndexedOrderlines![requestObj.Orderlines[orderlineIndex].ArticleId].IndexedVAS[vasId].AdditionalInfo
                requestObj.Orderlines[orderlineIndex].OrderlineVas!.push(vas)
              }
            }
          }

          articleQuantityTracker[article.Id] += currentOrderlineTotalQuantity

          if (currentOrderlineMOQ != null && currentOrderlineMOQ > 0 && currentOrderlineTotalQuantity !== 0 && currentOrderlineTotalQuantity < currentOrderlineMOQ) {
            if (!result.MOQFailedOrderlineList.includes(article.ArticleNumber)) {
              result.MOQFailedOrderlineList.push(article.ArticleNumber)
            }
            result.validMOQ = false
          }

          // validate MSR (do not validate lines that has no quantity)
          if (isMSREnabled && currentOrderlineTotalQuantity !== 0 && utils.isValidStringValue(article.Division)) {
            const articleDivisionValueLower = article.Division!.toString().trim().toLowerCase()
            const equipmentCriteriaValueLower = article.EquipmentCriteria != null ? article.EquipmentCriteria.toString().trim().toLowerCase() : ''
            if (articleDivisionValueLower === 'footwear') {
              if (currentOrderlineTotalQuantity >= 120 && requestObj.Orderlines[orderlineIndex].OrderlineSize.some(size => size.Quantity % 6 !== 0)) {
                result.validMSR = false
                // DRS failed for footwear for current orderline
                if (!result.MSRTracker.hasOwnProperty(requestObj.Orderlines[orderlineIndex].ArticleId)) {
                  result.MSRTracker[requestObj.Orderlines[orderlineIndex].ArticleId] = {}
                }
                if (!result.MSRTracker[requestObj.Orderlines[orderlineIndex].ArticleId].hasOwnProperty(requestObj.Orderlines[orderlineIndex].CrdId)) {
                  result.MSRTracker[requestObj.Orderlines[orderlineIndex].ArticleId][requestObj.Orderlines[orderlineIndex].CrdId] = {
                    isDRSFailed: true,
                    isFPFailed: false,
                  }
                }
              }
              else if (currentOrderlineTotalQuantity < 120 && requestObj.Orderlines[orderlineIndex].OrderlineSize.some(size => size.Quantity % 6 !== 0 && size.Quantity !== 2 && size.Quantity !== 4)) {
                result.validMSR = false
                // FP failed for footwear for current orderline
                if (!result.MSRTracker.hasOwnProperty(requestObj.Orderlines[orderlineIndex].ArticleId)) {
                  result.MSRTracker[requestObj.Orderlines[orderlineIndex].ArticleId] = {}
                }
                if (!result.MSRTracker[requestObj.Orderlines[orderlineIndex].ArticleId].hasOwnProperty(requestObj.Orderlines[orderlineIndex].CrdId)) {
                  result.MSRTracker[requestObj.Orderlines[orderlineIndex].ArticleId][requestObj.Orderlines[orderlineIndex].CrdId] = {
                    isDRSFailed: false,
                    isFPFailed: true,
                  }
                }
              }
            }
            else if (articleDivisionValueLower === 'equipment' && equipmentCriteriaValueLower !== '') {
              if (equipmentCriteriaValueLower === 'socks' && requestObj.Orderlines[orderlineIndex].OrderlineSize.some(size => size.Quantity % 6 !== 0)) {
                result.validMSR = false
                // DRS and FP failed for equipment for current orderline
                if (!result.MSRTracker.hasOwnProperty(requestObj.Orderlines[orderlineIndex].ArticleId)) {
                  result.MSRTracker[requestObj.Orderlines[orderlineIndex].ArticleId] = {}
                }
                if (!result.MSRTracker[requestObj.Orderlines[orderlineIndex].ArticleId].hasOwnProperty(requestObj.Orderlines[orderlineIndex].CrdId)) {
                  result.MSRTracker[requestObj.Orderlines[orderlineIndex].ArticleId][requestObj.Orderlines[orderlineIndex].CrdId] = {
                    isDRSFailed: true,
                    isFPFailed: true,
                  }
                }
              }
              else if (
                (
                  equipmentCriteriaValueLower === 'balls' || equipmentCriteriaValueLower === 'gloves' || equipmentCriteriaValueLower === 'protective/shinguards'
                  || equipmentCriteriaValueLower === 'bags' || equipmentCriteriaValueLower === 'sleeves' || equipmentCriteriaValueLower === 'scarves'
                )
                && requestObj.Orderlines[orderlineIndex].OrderlineSize.some(size => size.Quantity % 2 !== 0)
              ) {
                result.validMSR = false
                // DRS and FP failed for equipment for current orderline
                if (!result.MSRTracker.hasOwnProperty(requestObj.Orderlines[orderlineIndex].ArticleId)) {
                  result.MSRTracker[requestObj.Orderlines[orderlineIndex].ArticleId] = {}
                }
                if (!result.MSRTracker[requestObj.Orderlines[orderlineIndex].ArticleId].hasOwnProperty(requestObj.Orderlines[orderlineIndex].CrdId)) {
                  result.MSRTracker[requestObj.Orderlines[orderlineIndex].ArticleId][requestObj.Orderlines[orderlineIndex].CrdId] = {
                    isDRSFailed: true,
                    isFPFailed: true,
                  }
                }
              }
            }
          }

          const deliveryDate = this.IndexedOrderlines![requestObj.Orderlines[orderlineIndex].ArticleId].IndexedCRD[requestObj.Orderlines[orderlineIndex].CrdId].DeliveryDate
          if (Order.isCustomerRequiredDateValidatorAvailable(this.ActiveCatalog!)) {
            // validate the deliveryDate
            if (deliveryDate !== null) {
              if (this.validateDeliveryDate(deliveryDate, article._DeliveryDates.find(articleCrd => articleCrd.CrdId === requestObj.Orderlines[orderlineIndex].CrdId)!)) {
                result.validDeliveryDates = true
                requestObj.Orderlines[orderlineIndex].DeliveryDate = new Date(deliveryDate)
              }
              else {
                result.validDeliveryDates = false
              }
            }
            else {
              result.validDeliveryDates = false
            }
          }
        }

        if (requestObj.Orderlines[orderlineIndex].OrderlineSize.length === 0) {
          requestObj.Orderlines.splice(orderlineIndex, 1)
        }
      }
      else {
        result.invalidArticles.push(article.ArticleNumber)
      }
    }

    if (Object.values(articleQuantityTracker).includes(0)) {
      result.containsArticleWithZeroQuantity = true
    }
    return result
  }

  static updateOrderProperties(order: Order, propertyValue: IUpdateOrderPayload | Partial<IOrderModel> | Partial<Order>) {
    for (const property in propertyValue) {
      if (order.hasOwnProperty(property)) {
        order[property] = propertyValue[property]
      }
    }
  }

  static isCustomerRequiredDateValidatorAvailable(catalogDetails: CatalogDetails) {
    const operators = ['<=', '>=', '>', '<', '==', '===', '!==', '!=']
    let customerRequiredDateValidation = {} as { relation: string }
    try {
      if (utils.isDefined(catalogDetails.CustomerRequiredDateValidation)) {
        customerRequiredDateValidation = JSON.parse(catalogDetails.CustomerRequiredDateValidation)
      }
    }
    catch (e) {
      console.warn('Invalid json for CustomerRequiredDateValidation')
    }
    if (!catalogDetails.IsStockApply && utils.isDefined(customerRequiredDateValidation)
      && customerRequiredDateValidation.hasOwnProperty('relation') && customerRequiredDateValidation.relation.toString().trim().length && operators.includes(customerRequiredDateValidation.relation)) {
      return true
    }
    else {
      return false
    }
  }
}
