export default class CustomerLocations {
  AccountId: number
  Id: number
  CustomerId: number
  CustomerNumber: string
  Name: string
  Code: string
  Address: string
  City: string
  State: string
  Zip: string
  CountryCode: string
  CountryName: string
  LocationReference: string
  Phone: string
  CreateDate: string
  UpdateDate: string
  Status: number

  constructor(accountId: number, customersLocations: any) {
    this.AccountId = accountId
    this.Id = customersLocations.Id
    this.CustomerId = customersLocations.CustomerId
    this.CustomerNumber = customersLocations.CustomerNumber
    this.Name = customersLocations.Name
    this.Code = customersLocations.Code
    this.Address = customersLocations.Address
    this.City = customersLocations.City
    this.State = customersLocations.State
    this.Zip = customersLocations.Zip
    this.CountryCode = customersLocations.CountryCode
    this.CountryName = customersLocations.CountryName
    this.LocationReference = customersLocations.LocationReference
    this.Phone = customersLocations.Phone
    this.CreateDate = customersLocations.CreateDate
    this.UpdateDate = customersLocations.UpdateDate
    this.Status = customersLocations.Status
  }
}
