import type { CreateUpdateFavoriteModel } from '@/api/t1/model/favoriteModel'

export default class FavoriteTag {
  Id: number
  CatalogCode: number
  Tag: string
  Color: string
  Articles: number[]
  SharedUsers?: number[]
  SharedUsersGroups?: number[]
  CreatedByEmail?: string
  CreatedByName?: string
  CreatedByUserName: string
  Status: number
  CreatedBy?: number
  UpdatedBy?: number
  CreatedDate?: Date
  UpdatedDate?: Date
  LocalUpdatedDate: Date

  constructor(CatalogCode: number, obj: any) {
    this.Id = obj.Id
    this.CatalogCode = CatalogCode
    this.Tag = obj.Tag
    this.Color = obj.Color
    this.Articles = obj.Articles || []
    this.SharedUsers = obj.SharedUsers
    this.SharedUsersGroups = obj.SharedUsersGroups
    this.CreatedByEmail = obj.CreatedByEmail
    this.CreatedByName = obj.CreatedByName
    this.CreatedByUserName = obj.CreatedByUserName
    this.Status = obj.Status
    this.CreatedBy = obj.CreatedBy
    this.UpdatedBy = obj.UpdatedBy
    this.CreatedDate = obj.CreatedDate
    this.UpdatedDate = obj.UpdatedDate
    this.LocalUpdatedDate = obj.LocalUpdatedDate || new Date()
  }

  addArticles(articlesId: number[]) {
    this.Articles.push(...articlesId)
    this.LocalUpdatedDate = new Date()
  }

  toCreateUpdate() {
    return {
      Tag: this.Tag,
      Articles: this.Articles,
      SharedUsers: this.SharedUsers,
      SharedUsersGroups: this.SharedUsersGroups,
      FavoriteId: this.Id,
      Color: this.Color,
    } as CreateUpdateFavoriteModel
  }
}
