<template>
  <div class="text-default">
    <div v-if="label.length > 0" class="flex">
      <label v-if="label.length > 0" class="text-xs tracking-wide uppercase label" :class="{ required }" v-text="label" />
    </div>
    <div class="relative" :class="props.class">
      <div :class="{ 'border-red-300 bg-red-50 placeholder-red-200 text-red-900': hasError }">
        <div v-if="hasIcon" class="absolute top-1.5 left-1 w-6 h-6 text-dark-grey" v-html="icon" />
        <font-awesome-icon v-else-if="hasFaIcon" class="absolute top-2.5 left-1 w-4 h-4 pl-1 text-dark-grey" :icon="faicon" />
        <input
          v-if="type !== 'textarea'" ref="refInput" :type="type" v-bind="$attrs" :value="modelValue" :min="min"
          :class="[textStyle ? textStyle : 'text-base pr-2 border bg-card h-9 border-form shadow-input', { 'pl-8': hasIcon || hasFaIcon, 'pl-2': !hasIcon && !hasFaIcon, 'bg-gray-200': disabled, 'rounded-full': rounded, 'rounded': !rounded, 'pr-7': clearable, 'pr-2': !clearable }]"
          :placeholder="placeholder" :disabled="disabled" :required="required" :pattern="pattern" @input="doInput" @keydown="handleKeyDown"
        >
        <textarea
          v-else ref="refInput" v-bind="$attrs" :value="modelValue === null ? undefined : modelValue"
          class="text-base border bg-card border-form shadow-input"
          :class="{ 'pl-7': hasIcon || hasFaIcon, 'bg-gray-200': disabled, 'rounded-full': rounded, 'rounded': !rounded, 'pr-7': clearable, 'pr-2': !clearable }"
          :placeholder="placeholder" :disabled="disabled" :required="required" @input="doInput" @keydown="handleKeyDown"
        />
        <font-awesome-icon
          v-if="clearable && !disabled && modelValue && modelValue.toString().length > 0"
          class="absolute top-2.5 right-2 w-4 h-4 cursor-pointer" icon="fa-light fa-xmark" @click="doClear"
        />
      </div>
      <div v-if="hasError">
        <p v-for="error in errors" :key="error.$uid" class="text-xs italic text-red-500">
          {{ error.$message }}
        </p>
      </div>
      <div v-else-if="externalMessage && externalMessage.length !== 0" class="text-primary-500">
        <p>{{ externalMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core'
import { computed, ref } from 'vue'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<IProps>(), { type: 'text', label: '', errors: () => [] as ErrorObject[], placeholder: '', clearable: false, disabled: false, required: false, icon: '', faicon: '', class: '', rounded: false })

const emit = defineEmits<{
  (e: 'update:modelValue', val: string | number | null): void
  (e: 'change', val: string | number | null): void
}>()

interface IProps {
  modelValue?: string | number | null
  type?: TxInputType
  textAreaRows?: number
  label?: string
  errors?: ErrorObject[]
  placeholder?: string
  clearable?: boolean
  disabled?: boolean
  required?: boolean
  icon?: string
  faicon?: string
  class?: string
  rounded?: boolean
  textStyle?: string
  min?: number
  pattern?: string
  externalMessage?: string
}
const refInput = ref<HTMLInputElement>()

function doClear() {
  refInput.value!.value = ''
  emit('update:modelValue', props.type === 'number' ? null : '')
  emit('change', props.type === 'number' ? null : '')
}

const hasIcon = computed(() => {
  return props.icon.length > 0
})

const hasFaIcon = computed(() => {
  return props.faicon.length > 0
})

const hasError = computed(() => props.errors.length)

function doInput(e: Event) {
  const inputValue = (e?.target as HTMLInputElement).value
  const finalValue = props.type !== 'number' ? inputValue : (inputValue != null && inputValue.toString().trim() !== '' && !Number.isNaN(+inputValue) ? +inputValue : null)
  emit('update:modelValue', finalValue)
  emit('change', finalValue)
}

function handleKeyDown(e: Event) {
  e.stopPropagation()
}

function focus() {
  refInput.value?.focus()
  refInput.value?.select()
}

defineExpose({
  focus,
  el: refInput,
})
</script>
