import type { SharedUserGroupModel, SharedUserModel } from '@/api/t1/model/whiteboardModel'

export default class Whiteboard {
  Id: number
  CatalogCode: number
  Name: string
  FluidRelayId: string
  SharedUsers: SharedUserModel[]
  SharedUsersGroups: SharedUserGroupModel[]
  CreatedByEmail: string
  CreatedByName: string
  CreatedByUserName: string
  Status: number
  CreatedBy: number
  UpdatedBy: number
  CreatedDate: Date
  UpdatedDate: Date

  constructor(CatalogCode: number, obj: any) {
    this.Id = obj.Id
    this.CatalogCode = CatalogCode
    this.Name = obj.Name
    this.FluidRelayId = obj.FluidRelayId
    this.SharedUsers = obj.SharedUsers
    this.SharedUsersGroups = obj.SharedUsersGroups
    this.CreatedByEmail = obj.CreatedByEmail
    this.CreatedByName = obj.CreatedByName
    this.CreatedByUserName = obj.CreatedByUserName
    this.Status = obj.Status
    this.CreatedBy = obj.CreatedBy
    this.UpdatedBy = obj.UpdatedBy
    this.CreatedDate = new Date(obj.CreatedDate)
    this.UpdatedDate = new Date(obj.UpdatedDate)
  }
}
