import type { RelationTypeModel } from '@/api/t1/model/objectRelationModel'

export default class RelationType implements RelationTypeModel {
  CatalogCode: number
  Id: number
  Name: string
  ReversedName: string
  Status: number
  CreatedBy: number
  UpdatedBy: number
  CreatedDate: Date
  UpdatedDate: Date

  constructor(catalogCode: number, relationType: RelationTypeModel) {
    this.CatalogCode = catalogCode
    this.Id = relationType.Id
    this.Name = relationType.Name
    this.ReversedName = relationType.ReversedName
    this.Status = relationType.Status
    this.CreatedBy = relationType.CreatedBy
    this.UpdatedBy = relationType.UpdatedBy
    this.CreatedDate = new Date(relationType.CreatedDate)
    this.UpdatedDate = new Date(relationType.UpdatedDate)
  }
}
