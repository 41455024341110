import type { Ref } from 'vue'
import { computed, onUnmounted, ref, shallowRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import * as sBar from '@/store/status'
import type BucketsList from '@/shared/components/buckets/BucketsList.vue'
import type MyArticle from '@/models/myArticle'
import usePriceGroupsLabel from '@/shared/composables/priceGroupsLabel'
import { FilterCriteria, FilterCriteriaMode } from '@/models/filterCriteria'
import { getBrowseByList, getIndexedBucketValues } from '@/services/browseByFactory'
import { useBrowseByStore } from '@/store/browseBy'
import { useUserStore } from '@/store/userData'

export default function useBrowseBy(filterCriteria: Ref<FilterCriteria[]>) {
  // COMPOSABLES
  const userStore = useUserStore()
  const browseByStore = useBrowseByStore()
  const { t } = useI18n()
  const { priceGroupsLabel } = usePriceGroupsLabel()
  let resetBucketValuesTimeoutTracker: number

  // VARIABLES
  const browseByDetailsCriteria = ref<Array<FilterCriteria>>([])
  const browseByDetailsLabel = ref('')
  const selectedBuckets = ref<Array<IAttributeBucket>>([])
  const selectedArticlesBucketsListView = ref<Array<MyArticle>>([])
  const bucketsListRef = ref<typeof BucketsList>()
  const visibleBucketsCount = ref(0)
  const browseByDataTable = shallowRef<IBrowseByDataTable>()
  const bucketArticles = shallowRef<MyArticle[]>([])

  // COMPUTED
  const activeBucketAttributes = computed(() => {
    return userStore.activeCatalog?.BucketAttributeList.filter(attribute => attribute.Status) || []
  })

  const browseBy = computed(() => browseByStore.browseBy)

  const isBrowseByAttribute = computed(() => browseByStore.isBrowseByAttribute)

  const isShowingBucketsListView = computed({
    get: () => browseByStore.isShowingBucketsListView,
    set: (isShowingBucketsListView) => {
      browseByStore.isShowingBucketsListView = isShowingBucketsListView
    },
  })

  // FUNCTIONS

  function exitBrowseByDetails() {
    browseByDetailsCriteria.value = []
  }

  async function getAndInitBucketsAttributesValue(catalogCode: number, filterCriteria: Array<FilterCriteria>, getGroupByColumAttribute: string | undefined, getGroupByRowAttribute: string | undefined) {
    let result: Record<string, object> | null = null
    if (browseByStore.indexedBucketAttributesValue == null) {
      browseByStore.isLoadingBucketAttributesValue = true
      result = await getIndexedBucketValues(catalogCode, filterCriteria, userStore.activeCatalog!, userStore.linkedCatalogDetails!, getGroupByColumAttribute, getGroupByRowAttribute, browseBy.value ? browseBy.value.key : '')
    }
    browseByStore.indexedBucketAttributesValue = result
    browseByStore.isLoadingBucketAttributesValue = false
  }

  function onBucketClick(bucket: IAttributeBucket) {
    browseByDetailsLabel.value = bucket.value
    // add filter criteria for bucket value
    const filterCriteria = [new FilterCriteria({ attribute: bucket.propertySystemName, multipleVals: [bucket.bucketFilterValue], mode: FilterCriteriaMode.multiString, exclude: false })]
    if (bucket.rowProperty != null) {
      // TODO: check for special cases like segmentations, favorites, deliveryDate etc
      // add filter criteria for row value
      filterCriteria.push(new FilterCriteria({ attribute: bucket.rowProperty.SystemName, multipleVals: [bucket.rowPropertyFilterValue], mode: FilterCriteriaMode.multiString, exclude: false }))
    }
    if (bucket.columnProperty != null) {
      // TODO: check for special cases like segmentations, favorites, deliveryDate etc
      // add filter criteria for column value
      filterCriteria.push(new FilterCriteria({ attribute: bucket.columnProperty.SystemName, multipleVals: [bucket.columnPropertyFilterValue], mode: FilterCriteriaMode.multiString, exclude: false }))
    }

    // if any of the criteria has seasons, then set browseByDetailsMatchCurrentCatalogOnlyFromCriteria to true to avoid getting data from active catalog
    if (bucket.propertySystemName === '_Seasons' || (bucket.rowProperty != null && bucket.rowProperty.SystemName === '_Seasons') || (bucket.columnProperty != null && bucket.columnProperty.SystemName === '_Seasons')) {
      // to avoid fetching current catalog article when go to a bucket details where either bucket, row divider or column divider is based on _Seasons, reset this value when going out of details view (browseByDetailsCriteria is empty)
      browseByStore.browseByDetailsMatchCurrentCatalogOnlyFromCriteria = true
    }
    browseByDetailsCriteria.value = filterCriteria
  }

  function onBucketsListDataChanged(articles: MyArticle[], bucketCount: number, browseByDataTables: IBrowseByDataTable) {
    browseByDataTable.value = browseByDataTables
    bucketArticles.value = articles
    visibleBucketsCount.value = bucketCount
    sBar.setItemValue('listedArts', articles.length.toLocaleString())
    sBar.setItemValue('listedGroups', bucketCount.toLocaleString())
  }

  function setBrowseByUsingPropertyName(browseBy: string) {
    const indexedBrowseByList: { [property: string]: IBrowseBy<string> } = getBrowseByList(userStore.activeCatalog!.Config.BrowseByAttribute, userStore.activeCatalog!.Config.EnableBrowseByModel, userStore.myAttributes!, priceGroupsLabel.value, t).reduce((acu, cur) => (acu[cur.key.trim().toLowerCase()] = cur) && acu, {})
    const browseByLower = browseBy.trim().toLowerCase()
    if (indexedBrowseByList.hasOwnProperty(browseByLower)) {
      browseByStore.browseBy = indexedBrowseByList[browseByLower]
    }
    else {
      if (indexedBrowseByList.hasOwnProperty('browseByArticle'.toLowerCase())) {
        browseByStore.browseBy = indexedBrowseByList['browseByArticle'.toLowerCase()]
      }
      else {
        browseByStore.browseBy = undefined
      }
    }
  }

  // WATCHERS
  watch(() => browseBy.value, () => {
    // when changes the browse by, reset browseByDetailsCriteria to move out of bucket details view
    exitBrowseByDetails()
  })

  watch(browseByDetailsCriteria, (browseByDetailsCriteria) => {
    // reset browseByDetailsLabel when user is not in bucket details
    if (!browseByDetailsCriteria.length) {
      browseByDetailsLabel.value = ''
      // set BrowseByDetailsMatchCurrentCatalogOnlyFromCriteria false which will remove restriction on filtering data only from catalog mentioned in criteria in live article
      browseByStore.browseByDetailsMatchCurrentCatalogOnlyFromCriteria = false
    }
  })

  watch([() => userStore.groupByCol, () => userStore.groupByRow, () => browseBy.value, () => filterCriteria.value], () => {
    // reset bucket attributes if there are changes in any of the, column divider, row divider, browse by or filter
    browseByStore.isLoadingBucketAttributesValue = true
    clearTimeout(resetBucketValuesTimeoutTracker)
    resetBucketValuesTimeoutTracker = setTimeout(() => {
      browseByStore.indexedBucketAttributesValue = null
      browseByStore.isLoadingBucketAttributesValue = false
    }, 50)
  })

  watch([() => isBrowseByAttribute.value, () => browseByDetailsCriteria.value], ([isBrowseByAttribute, browseByDetailsCriteria]) => {
    // set value for isShowingBucketsListView on browseByStore
    isShowingBucketsListView.value = isBrowseByAttribute && !browseByDetailsCriteria.length
  })

  onUnmounted(() => {
    clearTimeout(resetBucketValuesTimeoutTracker)
  })

  return {
    activeBucketAttributes,
    browseBy,
    browseByDetailsCriteria,
    browseByDetailsLabel,
    bucketsListRef,
    exitBrowseByDetails,
    getAndInitBucketsAttributesValue,
    isBrowseByAttribute,
    isShowingBucketsListView,
    onBucketClick,
    onBucketsListDataChanged,
    selectedArticlesBucketsListView,
    selectedBuckets,
    setBrowseByUsingPropertyName,
    visibleBucketsCount,
    browseByDataTable,
    bucketArticles,
  }
}
