import { defineStore } from 'pinia'
import type MyArticle from '@/models/myArticle'

export interface IDataObject {
  isBrowseByAttribute: boolean
  articles: MyArticle[] | null
  rowDivider: string | null
  columnDivider: string | null
  thumbnailSize: string
  numberOfColumns: number
  rows: string[]
  viewName: string | null
  browseByAttribute: string | null
  columns: string[]
  bucketData: any | null
  title: string | null
}
export const useBrowseStore = defineStore({
  id: 'browseStore',
  state: () => ({
    dataObjectForMerch: null as IDataObject | null,
  }),
  actions: {
    setDataObjectForMerch(value: IDataObject | null) {
      this.dataObjectForMerch = value
    },
  },
})
