<template>
  <tx-dialog
    v-model="dialogVisible"
    :title="t('dataTable.export.title')"
    width="400px"
    :loading="loading"
    show-ok-cancel
    :cancel-state="loading ? 'disabled' : 'enabled'"
    :ok-state="loading ? 'loading' : 'enabled'"
    confirm-text="general.ok"
    @ok="doOk"
  >
    <div class="w-full h-full">
      <div class="text-md my-2">
        {{ t('dataTable.export.desc') }}:
      </div>
      <div class="my-2 mx-auto text-center">
        <tx-tabs v-model="form.dataSource" :tabs="dataSourceTabs" />
      </div>
      <div class="my-2 mx-auto text-center">
        <tx-tabs v-model="form.columnSelection" :tabs="columnSelectionTabs" />
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { nextTick, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import TxDialog from '../TxDialog.vue'
import TxTabs from '../TxTabs.vue'

type dataSourceType = 'all' | 'filtered' | 'selected'
type columnSelectionType = 'all' | 'visible'

export interface IExportForm {
  dataSource: dataSourceType
  columnSelection: columnSelectionType
}

interface IProps {
  selectionOption: number
}

const props = withDefaults(defineProps<IProps>(), {
  selectionOption: 0,
})

const emits = defineEmits<{
  (e: 'ok', form: IExportForm): void
}>()

const { t } = useI18n()

const dataSourceTabs: ITabItem<dataSourceType>[] = [
  { key: 'all', label: 'dataTable.export.allData', visible: true },
  { key: 'filtered', label: 'dataTable.export.filteredData', visible: true },
  { key: 'selected', label: 'dataTable.export.selectedRecords', visible: true },
]

const columnSelectionTabs: ITabItem<columnSelectionType>[] = [
  { key: 'all', label: 'dataTable.export.allColumns', visible: true },
  { key: 'visible', label: 'dataTable.export.visibleColumns', visible: true },
]

const dialogVisible = ref(false)
const loading = ref(false)

const form = reactive<IExportForm>({
  dataSource: 'all',
  columnSelection: 'visible',
})

const selectionEnums = {
  0: 'all',
  1: 'selected',
  2: 'filtered',
}

watch(
  () => props.selectionOption,
  (newVal) => {
    form.dataSource = selectionEnums[newVal] as dataSourceType
  },
  { immediate: true },
)

function show() {
  // set default value
  form.dataSource = selectionEnums[props.selectionOption] as dataSourceType
  form.columnSelection = 'visible'
  loading.value = false

  dialogVisible.value = true
}

function close() {
  dialogVisible.value = false
}

function doOk() {
  loading.value = true
  nextTick(() => {
    emits('ok', form)
  })
}

defineExpose({
  show,
  close,
})
</script>
