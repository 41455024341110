<template>
  <div class="h-full">
    <div class="w-full mt-2 mb-2 text-base">
      {{ t('exportDialog.excel.title.selectLocations') }}
    </div>
    <div class="w-full mt-2">
      <div>
        <tx-list-transfer
          v-model:selected-items="form.selectedLocations" :errors="v$.selectedLocations?.$errors"
          :available-items-label="t('exportDialog.order.availableLocations')" :selected-items-label="t('exportDialog.order.selectedLocations')" :items="availableLocations"
          item-key-prop="customerLocationKey" item-display-prop="customerLocationKey" class="max-h-96" @update:selected-items="onChange"
        >
          <template #item="{ item }">
            <div class="border-zinc-400 border rounded-md bg-zinc-300 p-1.25 text-sm text-center whitespace-nowrap text-ellipsis overflow-x-hidden">
              <div class="flex flex-row ">
                <div class="flex-1 flex flex-col">
                  <div class="ml-1 text-xs truncate">
                    {{ t('exportDialog.order.customer') }}
                  </div>
                  <div class="ml-1 text-sm truncate">
                    {{ item.customerName }}
                  </div>
                  <div class="ml-1 text-sm truncate">
                    {{ item.customerNumber }}
                  </div>
                </div>
                <div class="flex-1 flex flex-col">
                  <div
                    class="ml-1 truncate pt-px mr-1 text-xs"
                  >
                    {{ t('exportDialog.order.location') }}
                  </div>
                  <div class="ml-1 text-sm truncate">
                    {{ item.locationName }}
                  </div>
                  <div class="ml-1 text-sm truncate">
                    {{ item.locationCode }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </tx-list-transfer>
        <div class="mt-8 mb-2 text-base">
          {{ t('exportDialog.excel.title.selectColumns') }}
        </div>
        <tx-list-transfer
          v-model:selected-items="form.selectedColumns"
          :available-items-label="t('exportDialog.order.availableColumns')" :selected-items-label="t('exportDialog.order.selectedColumns')" :items="availableColumns"
          item-key-prop="SystemName" item-display-prop="DisplayName" class="max-h-96" @update:selected-items="onChange"
        />
      </div>
      <div v-if="showIncludeImages" class="mt-5 w-full">
        <tx-switch
          v-if="userStore.activeCatalog" v-model="form.includeImages" :label="t('exportDialog.excel.includeImages')"
          :sub-label="t('exportDialog.excel.includeImagesLimit', { limit: userStore.activeCatalog.Config.ExportExcelArticlesWithImageLimit })" :disabled="false" @change="onChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onMounted, reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, required } from '@vuelidate/validators'
import { useUserStore } from '@/store/userData'
import TxListTransfer from '@/shared/components/txListTransfer'
import TxSwitch from '@/shared/components/TxSwitch.vue'
import { AttributeType } from '@/models/catalogAttribute'
import { appConstants } from '@/models/constants'
import utils from '@/services/utils'
import Order from '@/models/order'
import type LinkedCustomer from '@/models/linkedCustomer'
import type CustomerLocations from '@/models/CustomerLocation'
import type { ISellerVASModel } from '@/api/t1/model/orderModel'

interface ICustomerLocation {
  customerName: string
  customerId: number
  customerNumber: string
  locationName: string
  locationId: number
  locationCode: string
  customerLocationKey: string
  Editable: boolean
}

interface IProps {
  modelValue: Record<string, any>
  currentStep?: number
  validationStep?: number
  showIncludeImages?: boolean
  showIncludeInactiveArticles?: boolean
  showFavoriteTagsInSeparateRow?: boolean
  linkedCustomers: Array<LinkedCustomer>
  linkedLocations: Array<CustomerLocations>
  vasData: Array<ISellerVASModel>
}

const props = withDefaults(defineProps<IProps>(), {
  showIncludeImages: true,
  showIncludeInactiveArticles: true,
  showFavoriteTagsInSeparateRow: true,
})

const emit = defineEmits(['update:modelValue'])
const { t } = useI18n()
const userStore = useUserStore()
const withI18nMessage = createI18nMessage({ t })
const preSelectedDisabledFields: string[] = ['SizeName', 'CustomerRequiredDateDescription', 'ArticleNumber']
const form = reactive({
  selectedColumns: [] as any[],
  selectedLocations: [] as any[],
  includeImages: false,
})
const isCustomerRequiredDateValidatorAvailable = computed(() => Order.isCustomerRequiredDateValidatorAvailable(userStore.activeCatalog!))

const availableColumns = computed(() => {
  let exportableFields: IMyAttribute[] = []
  if (userStore.activeCatalog && userStore.myAttributes) {
    let exportableArticleProperties: IMyAttribute[] = Object.values(userStore.myAttributes) // filterout unwanted fields
    const priceGroupsFields: IMyAttribute[] = []
    const favoriteTagsFields: IMyAttribute[] = []
    const sizesField: IMyAttribute[] = []
    const crdFields: IMyAttribute[] = []
    // Remove fields starting with underscore from exportableArticleProperties
    exportableArticleProperties = exportableArticleProperties.filter(attribute => !attribute.SystemName.startsWith('_'))

    // my prices
    if (userStore.currentCustomer) {
      if (userStore.currentCustomer.RetailPriceGroupId) {
        priceGroupsFields.push(userStore.myAttributes._RetailPrice)
      }
      else if (userStore.currentCustomer.WholesalePriceGroupId) {
        priceGroupsFields.push(userStore.myAttributes._WholesalePrice)
      }
      else if (userStore.currentCustomer.OrderPriceGroupId) {
        priceGroupsFields.push(userStore.myAttributes._OutletPrice)
      }
    }

    // favorite tags & allow it to use in lineplan catalog
    favoriteTagsFields.push(userStore.myAttributes._FavoriteTags)
    // stock fields
    // size Fields
    sizesField.push(Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'SizeName',
      DisplayName: t('exportDialog.order.field.size'),
      Creatable: true,
      AttributeType: AttributeType.Nvarchar,
    }))
    sizesField.push(Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'SizeIndex',
      DisplayName: t('exportDialog.order.field.sizeIndex'),
      Creatable: true,
      AttributeType: AttributeType.Nvarchar,
    }))
    // sizeCUrve field
    // const sizeCurveField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    //   SystemName: 'SizeCurve',
    //   DisplayName: t('export.sizeCurve'),
    //   Creatable: true,
    //   AttributeType: AttributeType.Nvarchar
    // })
    // crd fields
    crdFields.push(Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'CustomerRequiredDate',
      DisplayName: t('exportDialog.order.field.deliveryDate'),
      Creatable: true,
      AttributeType: AttributeType.Date,
    }))
    crdFields.push(Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'CustomerRequiredDateDescription',
      DisplayName: t('exportDialog.order.field.deliveryDateDescription'),
      Creatable: true,
      AttributeType: AttributeType.Nvarchar,
    }))
    crdFields.push(Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'CustomerRequiredDateAvailabilityFrom',
      DisplayName: t('exportDialog.order.field.deliveryDateAvailabilityFrom'),
      Creatable: true,
      AttributeType: AttributeType.Date,
    }))
    crdFields.push(Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'CustomerRequiredDateAvailabilityTo',
      DisplayName: t('exportDialog.order.field.deliveryDateAvailabilityTo'),
      Creatable: true,
      AttributeType: AttributeType.Date,
    }))
    if (isCustomerRequiredDateValidatorAvailable.value) {
      crdFields.push(Object.assign({}, appConstants.staticFieldTemplate, {
        SystemName: 'DeliveryDate',
        DisplayName: t('exportDialog.order.field.customerRequiredDate'),
        Creatable: true,
        AttributeType: AttributeType.Date,
      }))
    }
    exportableFields = exportableArticleProperties.concat(favoriteTagsFields, sizesField, crdFields)
    exportableFields = utils.sort(exportableFields, ['DisplayName'])
    exportableFields = exportableFields.concat(priceGroupsFields)
  }
  const vasFields: IMyAttribute[] = []
  if (props.vasData && props.vasData.length) {
    let counter = 0
    props.vasData.forEach((vas) => {
      if (vas.Status) {
        counter++
        vasFields.push(Object.assign({}, appConstants.staticFieldTemplate, {
          SystemName: `Vas${(counter).toString().padStart(2, '0')}`,
          DisplayName: `Vas${(counter).toString().padStart(2, '0')}`,
          Creatable: true,
          AttributeType: AttributeType.Int,
        }))
        vasFields.push(Object.assign({}, appConstants.staticFieldTemplate, {
          SystemName: `Vas${(counter).toString().padStart(2, '0')}Info`,
          DisplayName: `Vas${(counter).toString().padStart(2, '0')} Info`,
          Creatable: true,
          AttributeType: AttributeType.Int,
        }))
      }
    })
  }
  exportableFields = exportableFields.concat(vasFields)
  return exportableFields
})
const availableLocations = computed(() => {
  const available: ICustomerLocation[] = []
  props.linkedCustomers.forEach((customer) => {
    props.linkedLocations.forEach((location) => {
      if (location.CustomerId === customer.CustomerId) {
        available.push({ customerLocationKey: customer.CustomerNumber.toString() + customer.Name + location.Name + location.Code.toString(), customerId: customer.CustomerId, customerNumber: customer.CustomerNumber, customerName: customer.Name, locationId: location.Id, locationName: location.Name, locationCode: location.Code, Editable: true })
      }
    })
  })
  return available
})
const rules = computed(() => {
  const result: Record<string, any> = {}
  if (props.currentStep === props.validationStep) {
    return {
      selectedLocations: {
        required: withI18nMessage(required),
      },
    }
  }
  return result
})

const v$ = useVuelidate(rules, form)

function onChange() {
  emit('update:modelValue', form)
}

onMounted(() => {
  if (preSelectedDisabledFields.length) {
    preSelectedDisabledFields.forEach((value) => {
      const attribute = availableColumns.value.find(field => field.SystemName.toLowerCase() === value.toLowerCase())
      if (attribute) {
        attribute.disabled = true
        form.selectedColumns.push(attribute)
      }
    })
  }
  if (userStore.activeCatalog && userStore.activeCatalog.Config.PreSelectedOrderFormColumns && userStore.activeCatalog.Config.PreSelectedOrderFormColumns.length) {
    userStore.activeCatalog.Config.PreSelectedOrderFormColumns.forEach((value) => {
      if (!preSelectedDisabledFields.includes(value)) {
        const attribute = availableColumns.value.find(field => field.SystemName.toLowerCase() === value.toLowerCase())
        if (attribute) {
          form.selectedColumns.push(attribute)
        }
      }
    })
  }
})
</script>
