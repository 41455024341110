<template>
  <div
    v-on-visible="{ fct: doVisible, once: true }"
    class="box-border relative overflow-hidden transition-shadow duration-200 ease-in-out bg-white border border-solid rounded-md cursor-pointer group shadow-card hover:shadow-xl" :class="[{ 'border-primary-500': selected }, `t-${size}`, { 'absolute top-0 left-0 w-full h-full bg-white opacity-60': article.Status !== 1 }]"
    @click="doClick"
  >
    <!-- No impact on UI <div class="absolute top-0 left-0 w-full h-full bg-white opacity-75" v-if="!article._IsAvailable" /> -->
    <div class="absolute top-0 left-0 z-20 easy-check" @click.stop="doSelectionChanged" />
    <div
      v-if="allowSelection"
      class="box-border z-10 w-3 h-3 bg-white border rounded-sm border-dark-50 group-hover:block check"
      :class="[selectionMode || selected ? 'block' : 'hidden']"
    >
      <font-awesome-icon v-show="selected" class="w-4 h-4 -ml-0.5 -mt-1 text-primary-500" icon="fa-light fa-check" />
    </div>
    <!-- <dx-check-box class="check" v-model="isSelected" /> -->
    <fav-ribbon
      class="absolute z-10 h-2 mt-0.5" :class="getRibbonClasses" :tags="article._FavoriteTags"
      :max="[6, 8, 10][size - 1]"
    />
    <!-- <image-loader class="image" :image-size="imgSize" :image="article._thumbnailImage" /> -->
    <div class="image" :data-img="getImage">
      <div v-if="!visible || getImage === ''" class="text-dark-25" v-html="svgNoImage" />
      <div v-else-if="errorLoadingImg" class="text-red-500" v-html="svgNoImage" />
      <!-- <el-image
        v-else
        :src="getImage"
        :style="{ width: getImageSize + 'px', height: getImageSize + 'px' }"
        lazy
      >
        <template #placeholder>
          <div class="flex h-full">
            <div class="m-auto animate-pulse" v-t="'general.loading'" />
          </div>
        </template>
        <template #error>
          <div class="text-red-500" v-html="svgNoImage" />
        </template>
      </el-image> -->
      <!-- TODO: Implement lazy image -->
      <img
        v-else-if="loadPlaceholderImage" :src="getPlaceHolderImage" :style="{ width: `${getImageSize}px`, height: `${getImageSize}px` }"
        @error="errorLoadingImg = true"
      >
      <img
        v-else :src="getImage" :style="{ width: `${getImageSize}px`, height: `${getImageSize}px` }"
        @error="loadPlaceholderImage = true"
      >
      <span v-if="showWatermark && watermarkText.length" class="watermark" style="color: #0d5ebb6e">
        {{ watermarkText }}
      </span>
      <span v-else-if="requestWatermark && requestWatermark.length" class="watermark" :style="requestWatermarkStyles">
        {{ requestWatermark }}
      </span>
    </div>
    <div class="absolute flex flex-row flex-wrap details">
      <div v-for="(itm, index) in getThumbnailDetails" :key="index" :style="{ width: `${itm.w}px`, height: `${itm.h}px` }">
        <div v-if="itm.label" v-text="itm.label" />
        <div v-if="itm.value === '$retail'" :class="itm.style" v-text="getRetailPrice" />
        <div v-else-if="itm.value === '$wholesale'" :class="itm.style" v-text="getWholesale" />
        <div v-else-if="itm.value === '$retail.nc'" :class="itm.style" v-text="getRetailPriceNc" />
        <div v-else-if="itm.value === '$wholesale.nc'" :class="itm.style" v-text="getWholesaleNc" />
        <div v-else-if="itm.value === '$datecol'" :class="itm.style" v-text="getDate" />
        <div v-else-if="itm.value === '$sku'" :class="itm.style" v-text="'SKU'" />
        <!-- show first three characters -->
        <div v-else-if="itm.value === 'RetailIntroMonth'" :class="itm.style" v-text="article[itm.value]?.toString().trim().substring(0, 3)" />
        <div v-else-if="itm.value === 'RetailExitMonth'" :class="itm.style" v-text="article[itm.value]?.toString().trim().substring(0, 3)" />
        <!-- show ColoredUpDate in date format -->
        <div v-else-if="itm.value === 'ColoredUpDate'" :class="itm.style" v-text="utils.formatDate(article[itm.value])" />
        <!-- TODO: Implement SKU -->
        <flags-band
          v-else-if="itm.value === '$flags'" :article="article" :class="itm.style"
          :flags="getFlags" :mini="true" :max="getMaxFlags"
        />
        <div v-else-if="utils.isValidStringValue(itm.value)" :class="itm.style" v-text="article[itm.value!]" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import svgNoImage from '@/assets/svg/no-image.svg?raw'
import FlagsBand from '@/shared/components/FlagsBand.vue'
import FavRibbon from '@/shared/components/favorites/FavRibbon.vue'
import type MyArticle from '@/models/myArticle'
import appConfig from '@/services/appConfig'
import utils from '@/services/utils'
import { useUserStore } from '@/store/userData'
import { useBrowseByStore } from '@/store/browseBy'
import { requestConstants } from '@/models/constants'

interface IProps {
  article: MyArticle
  selected: boolean
  size?: number
  selectionMode?: boolean
  showWatermark?: boolean
  allowSelection?: boolean
  styleCreateAction?: StyleCreateActionType
  isPreviewForm?: boolean
  watermarkText?: string
}

const props = withDefaults(defineProps<IProps>(), { size: 3, selectionMode: false, showWatermark: false, allowSelection: true, watermarkText: '' })

const emit = defineEmits<{
  (e: 'click', ev: MouseEvent): void
  (e: 'update:selected', value: boolean): void
  (e: 'selectionChanged', value: boolean): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const browseByStore = useBrowseByStore()
const visible = ref(false)
const errorLoadingImg = ref(false)
const loadPlaceholderImage = ref(false)

const requestWatermarkStyles = computed(() => {
  const result = { color: '#0d5ebb6e' }
  if (props.article._RequestState === requestConstants.requestStates.approve) {
    result.color = '#46d147c7'
  }
  else if (props.article._RequestState === requestConstants.requestStates.reject) {
    result.color = '#f45c5bad'
  }
  else if (props.article._RequestState === requestConstants.requestStates.confirm) {
    result.color = '#44eb7b'
  }
  return result
})

const requestWatermark = computed(() => {
  let result = ''
  if (props.article._IsRequestArticle) {
    const prefix = props.article._RequestState === requestConstants.requestStates.approve
      ? t('requests.approved')
      : props.article._RequestState === requestConstants.requestStates.reject
        ? t('requests.rejected')
        : props.article._RequestState === requestConstants.requestStates.confirm
          ? t('requests.confirmed')
          : ''

    result = prefix && prefix.length ? `${prefix} ${t('general.request')}` : t('general.request')

    if (props.article._RequestSource === requestConstants.requestSources.new) {
      result = props.article._RequestState === requestConstants.requestStates.approve
        ? t('requests.approveArticleRequest')
        : props.article._RequestState === requestConstants.requestStates.reject
          ? t('requests.rejectArticleRequest')
          : props.article._RequestState === requestConstants.requestStates.confirm
            ? t('requests.confirmArticleRequest')
            : t('requests.newArticleRequest')
    }
    else if (props.article._RequestSource === requestConstants.requestSources.carryover) {
      result = prefix && prefix.length ? `${prefix} ${t('requests.carryoverArticleRequest')}` : t('requests.carryoverArticleRequest')
    }
    else if (props.article._RequestSource === requestConstants.requestSources.similarStyle) {
      result = prefix && prefix.length ? `${prefix} ${t('requests.similarModelRequest')}` : t('requests.similarModelRequest')
    }
  }
  return result
})

function doClick(ev: MouseEvent) {
  if (props.selectionMode) {
    doSelectionChanged()
  }
  else {
    emit('click', ev)
  }
}

function doSelectionChanged() {
  const newVal = !props.selected
  emit('update:selected', newVal)
  emit('selectionChanged', newVal)
}

function doVisible() {
  visible.value = true
}

const getFlags = computed(() => {
  if (userStore.activeCatalog?.Config.ArticleThumbnailFlags) {
    return userStore.activeCatalog?.Config.ArticleThumbnailFlags
  }
  else {
    return [] as IFlagsBandFlag[]
  }
})

const getImageSize = computed(() => {
  switch (props.size) {
    case 3:
      return 120
    case 2:
      return 105
    default:
      return 60
  }
})

const getMaxFlags = computed(() => {
  switch (props.size) {
    case 3:
      return 4
    case 2:
      return 3
    default:
      return 0
  }
})

const getRetailPrice = computed(() => {
  return utils.formatPrice(userStore.priceGroups.retail, props.article._RetailPrice, userStore.activeCatalog?.Config.ShowPriceThousandsSeparated)
})

const getWholesale = computed(() => {
  return utils.formatPrice(userStore.priceGroups.wholesale, props.article._WholesalePrice, userStore.activeCatalog?.Config.ShowPriceThousandsSeparated)
})

const getRetailPriceNc = computed(() => {
  return props.article?._RetailPrice.toString()
})

const getWholesaleNc = computed(() => {
  return props.article?._WholesalePrice.toString()
})

const getDate = computed(() => {
  let date = props.article._ShipDateRange
  if (userStore.activeCatalog && userStore.activeCatalog.Config.ArticleThumbnailShowFirstDeliveryDate) {
    date = utils.formatDate(props.article.getFirstDeliveryDate(userStore.activeCatalog))
  }
  return date || '-'
})

const getThumbnailDetails = computed(() => {
  if (userStore.activeCatalog) {
    if (props.styleCreateAction === 'carryoverModel') {
      const res = userStore.activeCatalog.Config.CarryoverArticleThumbnailDetailsWeb.article
      return res.large
    }
    else {
      const res = (props.isPreviewForm && browseByStore.isBrowseByModel) ? userStore.activeCatalog.Config.ThumbnailDetails.article : (!browseByStore.isBrowseByModel ? userStore.activeCatalog.Config.ThumbnailDetails.article : userStore.activeCatalog.Config.ThumbnailDetails.model)
      return props.size === 3 ? res.large : props.size === 2 ? res.medium : res.small
    }
  }
  return []
})

const getRibbonClasses = computed(() => {
  switch (props.size) {
    case 1:
      return ['top-1', 'right-1']
    case 2:
    case 3:
      return ['top-3', 'right-3']
    default:
      return []
  }
})

const getImage = computed(() => {
  const catalog = props.article.CatalogCode === userStore.activeCatalog?.CatalogCode ? userStore.activeCatalog : userStore.linkedCatalogDetails[props.article.CatalogCode]
  if (utils.isValidStringValue(catalog?.DuneContext) && utils.isValidStringValue(catalog?.ContextKey)) {
    const params = new URLSearchParams()
    params.set('Context', catalog.DuneContext)
    params.set('ContextKey', catalog.ContextKey)
    params.set('ImageSet', props.article.ArticleNumber)
    params.set('w', '120')
    params.set('h', '120')
    params.set('trim', 'false')
    params.set('f', 'webp')
    if (userStore.activeCatalog && userStore.activeCatalog.Config.NewestImageAssetKeyList.length) {
      params.set('Key', userStore.activeCatalog.Config.NewestImageAssetKeyList.toString())
    }
    return `${appConfig.AssetsUrl}/assets/r?${params.toString()}`
  }
  return ''
})

const getPlaceHolderImage = computed(() => {
  if (userStore && userStore.indexedSilhouetteImages && userStore.activeCatalog?.Config.SilhouetteImagesCriteria) {
    const imageKey = utils.getPlaceHolderImageKey(userStore.activeCatalog?.Config.SilhouetteImagesCriteria, props.article, userStore.indexedSilhouetteImages)
    if (utils.isValidStringValue(imageKey) && userStore.indexedSilhouetteImages[imageKey] && userStore.activeCatalog.ContextKey && userStore.activeCatalog.DuneContext) {
      return `${appConfig.AssetsUrl}/assets/content/${userStore.indexedSilhouetteImages[imageKey].StorageFile}?ContextKey=${encodeURIComponent(userStore.activeCatalog.ContextKey)}&f=webp&w=120&h=120&trim=true`
    }
  }
  return ''
})
</script>

<style lang="scss" scoped>
.t-1 {
  width: 95px;
  height: 126px;

  .easy-check {
    width: 25px;
    height: 25px;
  }

  .check {
    position: absolute;
    top: 5px;
    left: 5px;
  }

  .image {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 10px;
    left: 18px;
  }

  .fav {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    top: 5px;
    right: 5px;
    width: 72px;
    height: 10px;
    z-index: 1;
  }

  .details {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 95px;
    height: 46px;
    padding: 0 5px 5px 5px;
  }

  .watermark {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    transform: rotate(-45deg);
    text-align: center;
    font-weight: 1000;
    font-size: 10px;
    z-index: 1;
  }
}

.t-2 {
  width: 125px;
  height: 178px;

  .easy-check {
    width: 35px;
    height: 35px;
  }

  .check {
    position: absolute;
    top: 12px;
    left: 10px;
  }

  .image {
    position: absolute;
    width: 105px;
    height: 105px;
    top: 10px;
    left: 10px;
  }

  .fav {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    top: 10px;
    right: 10px;
    width: 80px;
    height: 10px;
    z-index: 1;
  }

  .details {
    position: absolute;
    top: 126px;
    left: 0px;
    width: 125px;
    height: 50px;
    padding: 0 5px 5px 5px;
  }

  .watermark {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    transform: rotate(-45deg);
    text-align: center;
    font-weight: 1000;
    font-size: 15px;
    z-index: 1;
  }
}

.t-3 {
  width: 150px;
  height: 205px;

  .easy-check {
    width: 35px;
    height: 35px;
  }

  .check {
    position: absolute;
    top: 12px;
    left: 10px;
  }

  .image {
    position: absolute;
    top: 5px;
    left: 15px;
    width: 120px;
    height: 120px;
  }

  .fav {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    top: 10px;
    right: 10px;
    width: 95px;
    height: 10px;
    z-index: 1;
  }

  .details {
    position: absolute;
    top: 130px;
    left: 0px;
    width: 148px;
    height: 75px;
    padding: 0 5px 5px 5px;
  }

  .watermark {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    transform: rotate(-45deg);
    text-align: center;
    font-weight: 1000;
    font-size: 18px;
    z-index: 1;
  }
}
</style>
