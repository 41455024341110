import { fabric } from 'fabric'
import { useDebounceFn } from '@vueuse/core'
import { v4 as guid } from 'uuid'
import Utility from './utility'
import { whiteboardConstants } from '@/models/constants'

export default class WbConnector extends fabric.Path implements IWbObject {
  public id: string
  public type = whiteboardConstants.objectTypes.connector
  public startPoint: IPoint
  public rStartPoint: IPoint
  public startCorner: FabricObjectCorners
  public startObjectId: string
  public endPoint: IPoint | null
  public rEndPoint: IPoint | null
  public endCorner: FabricObjectCorners | null
  public endObjectId: string | null
  public c1: IPoint | null
  public rC1: IPoint | null
  public c2: IPoint | null
  public rC2: IPoint | null
  public lock: boolean
  public preventUnlock: boolean
  public connectable: boolean
  public editableProps: Record<string, IWbObjectProp> = {
    color: { name: 'color', type: 'color' },
  }

  public actions: Record<string, IWObjectActions> = {
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  public controlDistanceConstant: number
  private debouncedFireObjectModified: Function

  constructor(path?: string | fabric.Point[] | undefined, options?: IWbConnectorOptions) {
    super(
      ((path) => {
        if (path == null && options?.hasOwnProperty('startPoint')) {
          if (options.startPoint == null) {
            console.error('startPoint to start connector is missing! to create a connector option needs to contain startPoint')
          }
          else {
            const point = options.startPoint
            path = ['M', point.x, point.y, 'C', point.x, point.y, point.x, point.y, point.x, point.y].toString()
          }
        }
        return path
      })(path),
      ((options) => {
        return Object.assign({ stroke: 'gray', strokeWidth: 1 }, options, {
          fill: '',
          perPixelTargetFind: true,
          targetFindTolerance: 10,
          hasControls: false,
          hasBorders: false,
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: true,
          lockRotation: true,
          lockScalingFlip: true,
          lockSkewingX: true,
          lockSkewingY: true,
          hoverCursor: 'pointer',
        })
      })(options),
    )
    const startPoint: IPoint = { x: 0, y: 0 } // just for compiler
    this.id = options?.id || guid()
    this.startPoint = options?.startPoint || startPoint
    this.rStartPoint = options?.rStartPoint || startPoint
    this.startCorner = options?.startCorner || 'mt'
    this.startObjectId = options?.startObjectId || ''
    this.endPoint = options?.endPoint || null
    this.rEndPoint = options?.rEndPoint || null
    this.endCorner = options?.endCorner || null
    this.endObjectId = options?.endObjectId || null
    this.c1 = options?.c1 || null
    this.c2 = options?.c2 || null
    this.rC1 = options?.rC1 || null
    this.rC2 = options?.rC2 || null
    this.lock = false
    this.connectable = false // they will not attached to another connecters
    this.selectable = true
    this.controlDistanceConstant = 100
    this.preventUnlock = options?.preventUnlock || false

    this.debouncedFireObjectModified = useDebounceFn(() => {
      this.canvas?.fire('object:modified', { target: this })
    }, whiteboardConstants.debounce.APICall, { maxWait: 2000 })
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'color':
        this.set('stroke', value.backgroundColor)
        break
      case 'startPoint': {
        const point: IPoint = value
        this.set('startPoint', point)
        this.setPathStartPoint(value)
        break
      }
      case 'rStartPoint': {
        this.set('rStartPoint', value)
        break
      }
      case 'startCorner':
        this.set('startCorner', value)
        break
      case 'startObjectId':
        this.set('startObjectId', value)
        break
      case 'endPoint': {
        const point: IPoint = value
        this.set('endPoint', point)
        this.setPathEndPoint(point)
        break
      }
      case 'rEndPoint': {
        this.set('rEndPoint', value)
        break
      }
      case 'endCorner':
        this.set('endCorner', value)
        break
      case 'endObjectId':
        this.set('endObjectId', value)
        break
      case 'c1': {
        const point: IPoint = value
        this.set('c1', point)
        this.setC1(point)
        break
      }
      case 'c2': {
        const point: IPoint = value
        this.set('c2', point)
        this.setC2(point)
        break
      }
      default:
        console.warn('Attempting to set unsupported IWbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.debouncedFireObjectModified()
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'color':
        result.color = this.stroke
        break
      case 'startPoint':
        result.startPoint = this.startPoint
        break
      case 'rStartPoint':
        result.rStartPoint = this.rStartPoint
        break
      case 'startCorner':
        result.startCorner = this.startCorner
        break
      case 'startObjectId':
        result.startObjectId = this.startObjectId
        break
      case 'endPoint':
        result.endPoint = this.endPoint
        break
      case 'rEndPoint':
        result.rEndPoint = this.rEndPoint
        break
      case 'endCorner':
        result.endCorner = this.endCorner
        break
      case 'endObjectId':
        result.endObjectId = this.endObjectId
        break
      case 'c1':
        result.c1 = this.c1
        break
      case 'c2':
        result.c2 = this.c2
        break

      default:
        console.warn('Attempting to get unsupported IWbObjectProp', prop)
    }
    return result
  }

  drawConnectorPathWithMouseMove(opt: fabric.IEvent<MouseEvent | Event>, connectorOrigin: fabric.Object, canvas) {
    const hoveredObject: IWbObject | null = opt.target as IWbObject
    const connectableObjects = canvas.getObjects().filter(object => object.connectable)
    let point = canvas.getPointer(opt.e, false)
    let c2 = point as IPoint
    let endObjectId: string | null = null
    let endCorner: FabricObjectCorners | null = null

    // initialize c1 if not yet assigned (one time initialization, on creating connector instance we do not initialize c1, c2 and endPoint)
    if (this.c1 == null) {
      const originTargetCenter = connectorOrigin?.getCenterPoint()
      const c1 = this.getControlCoordBasedOnObjectCorner(point, this.startCorner)
      const rC1 = { x: c1.x - originTargetCenter.x, y: c1.y - originTargetCenter.y }
      // set c1 and rC1
      this.setProp('c1', c1)
      this.set('rC1', rC1)
    }

    if (!hoveredObject?.connectable) { // either null or non connectable
      for (let i = 0; i < connectableObjects.length; i++) {
        const closestPoint = Utility.getClosestObjectCornerToCurrentPointerByDistance(opt.e, canvas, connectableObjects[i], 'connectable', ['mt', 'mr', 'mb', 'ml'], 20)
        if (closestPoint != null) {
          point = closestPoint
          c2 = this.getControlCoordBasedOnObjectCorner(point, point.corner)
          endObjectId = connectableObjects[i].id
          endCorner = point.corner
          break
        }
      }
    }
    else { // if connectable
      point = Utility.getClosestObjectCornerToCurrentPointer(opt.e, canvas, hoveredObject, 'connectable', ['mt', 'mr', 'mb', 'ml'])
      c2 = this.getControlCoordBasedOnObjectCorner(point, point.corner)
      endObjectId = hoveredObject.id!
      endCorner = point.corner
    }
    // set c2 (rC2 will be set when line connected at addConnectorEndPoint method)
    this.setProp('endObjectId', endObjectId)
    this.setProp('endCorner', endCorner)

    this.setProp('c2', c2)
    // set end point
    this.setProp('endPoint', point)
  }

  addConnectorEndPoint(point: IObjectCornerCoords, target: IWbObject) {
    const targetCenter = target.getCenterPoint()
    let c2 = { x: point.x, y: point.y } as IPoint
    if (point.corner) {
      c2 = this.getControlCoordBasedOnObjectCorner(point, point.corner)
    }
    // set end point object id
    this.setProp('endObjectId', target.id)

    // set end corer
    this.setProp('endCorner', point.corner!)

    // set connector's c2 and rC2
    this.setProp('c2', c2)
    const rC2 = { x: c2.x - targetCenter.x, y: c2.y - targetCenter.y }
    this.set('rC2', rC2)

    // set relative end point
    this.setProp('rEndPoint', { x: point.x - targetCenter.x, y: point.y - targetCenter.y })

    // set connector's final end point
    this.setProp('endPoint', point)
  }

  // PRIVATE METHODS
  private setPathStartPoint(point: IPoint) {
    if (this.path && this.path[0]) {
      this.path[0][1] = point.x
      this.path[0][2] = point.y
      // eslint-disable-next-line ts/ban-ts-comment
      // @ts-expect-error
      this._setPath(this.path)
      this.setCoords()
    }
  }

  private setPathEndPoint(point: IPoint) {
    if (this.path && this.path[1]) {
      const arr = this.path[1]
      // eslint-disable-next-line ts/ban-ts-comment
      // @ts-expect-error
      this.path[1][arr.length - 2] = point.x
      // eslint-disable-next-line ts/ban-ts-comment
      // @ts-expect-error
      this.path[1][arr.length - 1] = point.y
      // eslint-disable-next-line ts/ban-ts-comment
      // @ts-expect-error
      this._setPath(this.path)
      this.setCoords()
    }
  }

  private setC1(point: IPoint) {
    if (this.path && this.path[1]) {
      this.path[1][1] = point.x
      this.path[1][2] = point.y
      // eslint-disable-next-line ts/ban-ts-comment
      // @ts-expect-error
      this._setPath(this.path)
      this.setCoords()
    }
  }

  private setC2(point: IPoint) {
    if (this.path && this.path[1]) {
      this.path[1][3] = point.x
      this.path[1][4] = point.y
      // eslint-disable-next-line ts/ban-ts-comment
      // @ts-expect-error
      this._setPath(this.path)
      this.setCoords()
    }
  }

  /**
   * @description return a coordinate that can be used for a control (c1 & c2)
   * @param point
   * @param corner
   * @returns {IPoint} coordinate for a control based on object corner
   */
  private getControlCoordBasedOnObjectCorner(point: IPoint, corner: FabricObjectCorners | null): IPoint {
    const control = { x: point.x, y: point.y }
    if (corner != null) {
      if (corner === 'mt') {
        control.y -= this.controlDistanceConstant
      }
      else if (corner === 'mb') {
        control.y += this.controlDistanceConstant
      }
      else if (corner === 'mr') {
        control.x += this.controlDistanceConstant
      }
      else if (corner === 'ml') {
        control.x -= this.controlDistanceConstant
      }
    }
    return control
  }

  override toObject(propertiesToInclude?: string[]) {
    const props = propertiesToInclude || []
    props.push('id', 'startPoint', 'rStartPoint', 'startCorner', 'startObjectId', 'endPoint', 'rEndPoint', 'endCorner', 'endObjectId', 'c1', 'c2', 'rC1', 'rC2', 'connectable', 'preventUnlock')
    return super.toObject(props)
  }

  static fromObject(object: fabric.Object, callback?: Function) {
    return fabric.Object._fromObject(whiteboardConstants.objectTypes.connector, object, callback, 'path') as WbConnector
  }

  override _render(ctx: CanvasRenderingContext2D): void {
    super._render(ctx)
    // ctx.beginPath()
    // ctx.moveTo(this.startPoint.x, this.endPoint.y)
    // ctx.bezierCurveTo()
  }
}

const f: any = fabric
f.WbConnector = WbConnector
