<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <!-- HEADER -->
    <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="t('orders.sendOrder.sendMultipleOrderTitle')" :show-header="true" />

    <!-- BODY -->
    <div class="flex-col px-6 mt-10 grow">
      <tx-progress v-model="currentProgress" :color="progressColor" :title="progressTitle" :show-percentage="true" />
      <div v-show="!isCatalogReceivingOrders" class="my-2 text-error">
        {{ t('orders.sendOrder.alerts.receiveOrderClosed') }}
      </div>

      <!-- STATUS TABLE -->
      <div class="flex-col mt-8">
        <!-- HEADER -->
        <div class="flex text-lg font-bold grow">
          <div class="pr-2 capitalize w-60 shrink-0">
            {{ t('orders.misc.orderReferences') }}
          </div>
          <div class="w-20 pr-2 capitalize shrink-0">
            {{ t('general.status') }}
          </div>
          <div class="grow" />
        </div>
        <!-- DATA -->
        <div class="flex-col">
          <div v-for="(trackerValue, orderReference) in sendOrderTracker" :key="orderReference" class="flex mt-2 overflow-hidden grow text-ellipsis whitespace-nowrap">
            <div class="flex items-center pr-2 w-60 shrink-0">
              <div
                class="w-3 h-3 mr-1 rounded-full" :class="{
                  'bg-warn-400': trackerValue.status === 'failed' || trackerValue.status === 'canceled',
                  'bg-highlight': trackerValue.status === 'pending' || trackerValue.status === 'processing',
                  'bg-green-400': trackerValue.status === 'sent',
                }"
              />
              <div class="truncate" :title="orderReference">
                {{ orderReference }}
              </div>
            </div>
            <div class="w-20 pr-2 capitalize truncate shrink-0">
              {{ trackerValue.status }}
            </div>
            <div class="truncate grow" :title="trackerValue.message">
              {{ trackerValue.message }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <tx-form-footer
      class="flex flex-row justify-end flex-shrink-0 flex-nowrap" :primary-text="t('general.close')" :secondary-text="t('general.cancel')"
      secondary-type="danger" @primary-click="close" @secondary-click="cancel"
    />
  </div>

  <!-- PROMPT DIALOG -->
  <tx-dialog
    v-model="showPromptDialog" :show-ok-cancel="true" :title="promptDialogTitle" :confirm-text="t('general.ok')" width="450px" height="250px"
    :loading="promptDialogLoading" @ok="promptDialogUserConfirm" @cancel="promptDialogUserCancel"
  >
    <div class="whitespace-pre-line">
      {{ promptDialogDescription }}
    </div>
  </tx-dialog>
</template>

<script lang='ts' setup>
import pick from 'lodash-es/pick'
import { inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type CustomerLocation from '@/models/CustomerLocation'
import type MyArticle from '@/models/myArticle'
import type Order from '@/models/order'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'
import TxProgress from '@/shared/components/TxProgress.vue'
import { checkIfOrderIsEditable } from '@/modules/orders/services/orderFactory'
import { fetchOrderlines } from '@/api/t1/order'
import type { IOrderModel, ISellerVASModel, ISendOrderOrderlines, IUpdateOrderPayload } from '@/api/t1/model/orderModel'
import { ordersConstants } from '@/models/constants'
import { useUserStore } from '@/store/userData'
import { getCatalogDetails } from '@/api/t1/catalog'

const props = defineProps<{
  orders: Array<Order>
  indexedArticles: Record<number, MyArticle>
  indexedLocations: Record<number, CustomerLocation>
  indexedVAS: Record<number, ISellerVASModel>
  indexedCustomers: Record<number, any>
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'failed', error: Error): void
}>()

const userStore = useUserStore()
const { t } = useI18n()
const { updateOrderInOrdersList, addOrderToSubmittingOrderReferenceList } = inject('ordersProvide', {
  updateOrderInOrdersList: (_currentOrder: Order, _updateOrderPayload: IUpdateOrderPayload | IOrderModel | Partial<Order>) => null,
  addOrderToSubmittingOrderReferenceList: (_orders: Array<Order>) => null,
})

const isCatalogReceivingOrders = ref(true)

const currentProgress = ref(0)
const progressColor = ref<undefined | string>(undefined)
const progressTitle = ref<undefined | string>(undefined)

const sendOrderTracker = ref<Record<string, { status: 'pending' | 'processing' | 'sent' | 'failed' | 'canceled', message: string }>>({})

const showPromptDialog = ref(false)
const promptDialogLoading = ref(false)
const promptDialogTitle = ref('')
const promptDialogDescription = ref('')
let deferredPromptDialogClosureResolve: null | ((value: void | PromiseLike<void>) => void) = null
let deferredPromptDialogClosureReject: null | ((value: void | any) => void) = null
let promptAction: null | 'sendOrders' = null

let isSendOrdersCanceled = false

async function sendOrders() {
  progressTitle.value = t('orders.sendOrder.alerts.checkDataPackageUpdate')
  const intervalId = setInterval(() => {
    const progress = currentProgress.value + 3
    currentProgress.value = progress > 99 ? 99 : progress
  }, 200)
  try {
    const catalogDetailsResponse = await getCatalogDetails(userStore.activeCatalog!.CatalogCode)
    if (!(catalogDetailsResponse.data.ReceiveOrders === 1 || catalogDetailsResponse.data.ReceiveOrders === true || catalogDetailsResponse.data.ReceiveOrders === 'true')) {
      isCatalogReceivingOrders.value = false
      return
    }
  }
  catch (error) {
    console.warn(error)
    progressColor.value = '#f56c6c'
    emit('failed', error as Error)
    return
  }
  finally {
    clearInterval(intervalId)
    progressTitle.value = ''
    currentProgress.value = 100
  }
  sendOrderTracker.value = {}
  const validOrdersData: Array<{
    order: Order
    requestObj: {
      OrderReference: string
      LocationId: number
      CustomerId: number
      CustomerReference: string
      SourceId: number
      ProcessingStatus: number | null
      Alias: string
      Orderlines: Array<ISendOrderOrderlines>
    }
    validMOQ: boolean
    validMSR: boolean
    MSRTracker: Record<string, any>
    MOQFailedOrderlineList: Array<string>
    validDeliveryDates: boolean
    validMultipleOfQuantity: boolean
    outOfStockArticleSizes: Array<{ articleId: number, sizeId: number, availableQuantity: number, orderedQuantity: number }>
    missingArticlesMustHaveList: Array<string>
    articleDroppedInSizeCurve: Array<string>
    invalidArticles: Array<string>
  }> = []
  const invalidMOQArticlesOrdersMap: Record<string, Array<string>> = {}
  const invalidArticlesMustHaveOrdersMap: Record<string, Array<string>> = {}
  const containsValidVas = Object.values(props.indexedVAS).some(vas => vas.Status)

  for (let i = 0; i < props.orders.length; i++) {
    sendOrderTracker.value[props.orders[i].OrderReference] = {
      status: 'pending',
      message: '',
    }
  }

  for (let i = 0; i < props.orders.length; i++) {
    const selectedOrder: Order = props.orders[i]
    if (isSendOrdersCanceled) {
      sendOrderTracker.value[selectedOrder.OrderReference].status = 'canceled'
      continue
    }
    let orderDetailsResponse
    try {
      // we will not have unsaved draft order in orders page as we enforce users to save a draft order after creating one, so on orders page all the draft orders are saved on server and have Id assigned
      orderDetailsResponse = await fetchOrderlines(userStore.activeCatalog!.CatalogCode, userStore.currentCustomer?.CustomerId, selectedOrder.Id!)
    }
    catch (error) {
      console.warn(`unable to fetch orderlines!, \n ${error}`)
      // fetch order failed set status to failed
      sendOrderTracker.value[selectedOrder.OrderReference].status = 'failed'
      sendOrderTracker.value[selectedOrder.OrderReference].message = t('orders.alerts.orderCanNotBeUpdated.getOrderApiFailed')
      continue
    }

    // load orderlines
    selectedOrder.loadOrderlines(orderDetailsResponse.data, props.indexedArticles, props.indexedVAS, props.indexedCustomers[selectedOrder.CustomerId].Segmentations || [])

    if (selectedOrder.OrderProcessStatusId === ordersConstants.orderProcessStatus.submitting) {
      sendOrderTracker.value[selectedOrder.OrderReference].status = 'sent'
      sendOrderTracker.value[selectedOrder.OrderReference].message = t('orders.alerts.orderCanNotBeUpdated.submitting')
      continue
    }

    const orderStatus = checkIfOrderIsEditable(selectedOrder, userStore.userProfile, props.indexedLocations)
    if (!orderStatus.isOrderEditable) {
      sendOrderTracker.value[selectedOrder.OrderReference].status = 'failed'
      if (!orderStatus.doesCustomerLocationExist) {
        sendOrderTracker.value[selectedOrder.OrderReference].message = t('orders.alerts.orderCanNotBeUpdated.inactiveLocationForSendOrders')
      }
      else if (!orderStatus.haveOrderOwnership) {
        sendOrderTracker.value[selectedOrder.OrderReference].message = t('orders.alerts.orderCanNotBeUpdated.doesNotHaveOwnershipForSendOrders')
      }
      else if (orderStatus.isOrderInactive) {
        sendOrderTracker.value[selectedOrder.OrderReference].message = t('orders.alerts.orderCanNotBeUpdated.inactiveOrderForSendOrders')
      }
      else if (orderStatus.noneEditableProcessStatus && selectedOrder.OrderProcessStatusId !== ordersConstants.orderProcessStatus.submitting) {
        sendOrderTracker.value[selectedOrder.OrderReference].message = t('orders.alerts.orderCanNotBeUpdated.orderCannotBeSend')
      }
      continue
    }

    // TODO: once work on implementing configurations update the createSendOrderRequestObject arguments
    const result = selectedOrder.createSendOrderRequestObject(props.indexedArticles, props.indexedCustomers, [], {}, 0, containsValidVas, false, false, 0)
    if (!result.requestObj.Orderlines.length) {
      sendOrderTracker.value[selectedOrder.OrderReference].status = 'failed'
      sendOrderTracker.value[selectedOrder.OrderReference].message = t('orders.sendOrder.failedAlerts.noOrderlineMessage')
      continue
    }
    else if (result.invalidArticles.length) {
      sendOrderTracker.value[selectedOrder.OrderReference].status = 'failed'
      sendOrderTracker.value[selectedOrder.OrderReference].message = t('orders.sendOrder.failedAlerts.invalidArticlesMessage')
      continue
    }
    else if (result.outOfStockArticleSizes.length) {
      // TODO: show more info button and on click stock failure dialog, add when implement stock catalog
      sendOrderTracker.value[selectedOrder.OrderReference].status = 'failed'
      sendOrderTracker.value[selectedOrder.OrderReference].message = t('orders.sendOrder.failedAlerts.outOfStockMessage')
      continue
    }
    else if (!result.validMOQ) {
      sendOrderTracker.value[selectedOrder.OrderReference].status = 'failed'
      sendOrderTracker.value[selectedOrder.OrderReference].message = t('orders.sendOrder.failedAlerts.MOQCriteriaFailedMessage')
      continue
    }
    else if (!result.validMSR) {
      sendOrderTracker.value[selectedOrder.OrderReference].status = 'failed'
      sendOrderTracker.value[selectedOrder.OrderReference].message = t('orders.sendOrder.failedAlerts.MSRCriteriaFailedMessage')
      continue
    }
    else if (!result.validDeliveryDates) {
      sendOrderTracker.value[selectedOrder.OrderReference].status = 'failed'
      sendOrderTracker.value[selectedOrder.OrderReference].message = t('orders.sendOrder.failedAlerts.deliveryDateCriteriaFailedMessage')
      continue
    }
    else if (!result.validMultipleOfQuantity) {
      sendOrderTracker.value[selectedOrder.OrderReference].status = 'failed'
      sendOrderTracker.value[selectedOrder.OrderReference].message = t('orders.sendOrder.failedAlerts.multipleOfQuantityFailedMessage')
      continue
    }
    else if (!result.articleDroppedInSizeCurve) {
      sendOrderTracker.value[selectedOrder.OrderReference].status = 'failed'
      sendOrderTracker.value[selectedOrder.OrderReference].message = t('orders.sendOrder.failedAlerts.articleSizeDroppedMessage', { sizeList: result.articleDroppedInSizeCurve })
      continue
    }

    if (!result.validMOQ && userStore.activeCatalog!.Config.PassiveMOQ) {
      invalidMOQArticlesOrdersMap[selectedOrder.OrderReference] = result.MOQFailedOrderlineList
    }
    if (userStore.activeCatalog!.Config.ArticlesMustHave && Object.keys(userStore.activeCatalog!.Config.ArticlesMustHave).length && result.missingArticlesMustHaveList.length) {
      invalidArticlesMustHaveOrdersMap[selectedOrder.OrderReference] = result.missingArticlesMustHaveList
    }
    validOrdersData.push(result)
  }

  let promise = Promise.resolve()
  if (Object.keys(invalidMOQArticlesOrdersMap).length || Object.keys(invalidArticlesMustHaveOrdersMap).length) {
    promptAction = 'sendOrders'
    promptDialogTitle.value = t('general.alert')
    promptDialogDescription.value = ''
    if (Object.keys(invalidMOQArticlesOrdersMap).length) {
      let MOQFailedOrderlineList = '\n'
      Object.keys(invalidMOQArticlesOrdersMap).forEach((orderReference) => {
        MOQFailedOrderlineList += `${orderReference}: ${invalidMOQArticlesOrdersMap[orderReference].join(', ')}\n`
      })
      promptDialogDescription.value += t('orders.sendOrder.alerts.invalidMOQWarning', { MOQFailedOrderlineList })
    }
    if (Object.keys(invalidArticlesMustHaveOrdersMap).length) {
      let missingArticlesMustHaveList = ''
      Object.keys(invalidArticlesMustHaveOrdersMap).forEach((orderReference) => {
        missingArticlesMustHaveList += `${orderReference}: ${invalidArticlesMustHaveOrdersMap[orderReference].join(', ')}\n`
      })
      promptDialogDescription.value += `\n${t('orders.sendOrder.alerts.invalidArticlesMustHaveWarning', { missingArticlesMustHaveList })}`
    }
    showPromptDialog.value = true
    promise = new Promise((resolve, reject) => {
      deferredPromptDialogClosureResolve = resolve
      deferredPromptDialogClosureReject = reject
    })
  }

  try {
    await promise
    for (let i = 0; i < validOrdersData.length; i++) {
      sendOrderTracker.value[validOrdersData[i].order.OrderReference].status = 'processing'
      try {
        await validOrdersData[i].order.sendOrder(validOrdersData[i].requestObj)
        updateOrderInOrdersList(validOrdersData[i].order, pick(validOrdersData[i].order, ['OrderProcessStatusId', 'OrderProcessStatus']))
        addOrderToSubmittingOrderReferenceList([validOrdersData[i].order])
        sendOrderTracker.value[validOrdersData[i].order.OrderReference].status = 'sent'
      }
      catch (error) {
        sendOrderTracker.value[validOrdersData[i].order.OrderReference].status = 'failed'
        sendOrderTracker.value[validOrdersData[i].order.OrderReference].message = t('orders.alerts.orderCanNotBeUpdated.unexpectedError')
      }
    }
  }
  catch (error) {
    close()
  }
  finally {
    resetPromptDialogValues()
  }
}

function close() {
  emit('close')
}

function cancel() {
  isSendOrdersCanceled = true
}

function promptDialogUserConfirm() {
  if (promptAction === 'sendOrders') {
    deferredPromptDialogClosureResolve!()
  }
}

function promptDialogUserCancel() {
  if (promptAction === 'sendOrders') {
    deferredPromptDialogClosureReject!(new Error('Operation canceled by user'))
  }
}

function resetPromptDialogValues() {
  showPromptDialog.value = false
  promptDialogTitle.value = ''
  promptDialogDescription.value = ''
  promptAction = null
}

onMounted(() => {
  sendOrders()
})
</script>
