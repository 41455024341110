<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100">
    <div class="text-center">
      <h1 class="text-9xl font-bold text-gray-800">
        403
      </h1>
      <p class="text-2xl mt-4 text-gray-600">
        Forbidden
      </p>
      <p class="mt-2 text-gray-500">
        You don't have permission to access this resource.
      </p>
      <router-link :to="{ name: 'Admin' }" class="mt-6 inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
        Go Home
      </router-link>
    </div>
  </div>
</template>
