import { isObject } from 'lodash-es'
import Article from './article'
import type Resource from './resource'
import type DuneAsset from './duneAsset'
import type SimpleFavoriteTag from './simpleFavoriteTag'
import type CatalogPriceGroup from './catalogPriceGroup'
import type CatalogDetails from './catalogDetails'
import type Allocation from './allocation'
import type CatalogAttribute from './catalogAttribute'
import utils from '@/services/utils'
import { appConstants } from '@/models/constants'
import type { MasterSizeScaleModel } from '@/api/t1/model/sizeModel'
import appConfig from '@/services/appConfig'

export default class MyArticle extends Article {
  _RetailPrice: number = 0
  _WholesalePrice: number = 0
  _OutletPrice: number = 0
  _IsAvailable: boolean = false
  _Resources: Resource[] = []
  _FavoriteTags: SimpleFavoriteTag[] = []
  _Assets: DuneAsset[] = []
  _Allocations: Allocation[] = []
  _Seasons: string = ''
  _IsRequestArticle: boolean = false
  _RequestNumber: string = ''
  _RequestSource: number = 0
  _RequestState: number = 0
  _SourceModelId: number | null = null
  _SourceModelNumber: string | null = null
  _SourceArticleId: number | null = null
  _SourceArticleNumber: string | null = null
  _ConfirmArticleNumber: string | null = null
  _Comment: string = ''
  _Reason: string = ''
  _SortOrder: string = ''
  _IsNonSegmented: boolean = false

  get _ShipDateRange(): string {
    const shipmentStartDateMonth = utils.isDefined(this.ShipmentStartDate) && typeof this.ShipmentStartDate === 'string'
      ? new Date(this.ShipmentStartDate).toLocaleString('default', { month: 'short' })
      : this.ShipmentStartDate?.toLocaleString('default', { month: 'short' })
    const shipmentEndDateMonth = utils.isDefined(this.ShipmentEndDate) && typeof this.ShipmentEndDate === 'string'
      ? new Date(this.ShipmentEndDate).toLocaleString('default', { month: 'short' })
      : this.ShipmentEndDate?.toLocaleString('default', { month: 'short' })
    return utils.isDefined(shipmentStartDateMonth) ? `${shipmentStartDateMonth} - ${shipmentEndDateMonth}` : ''
  }

  constructor(article: Article, attributes: CatalogAttribute[], retailPrice: number, wholesalePrice: number, outletPrice: number, resources: Resource[], isAvailable: boolean, assets: DuneAsset[], favoriteTags: SimpleFavoriteTag[], allocations: Allocation[], season: string, dataSourceTypeId: number, articlesCustomSortOrder: { sortOrder: Record<string, number>, orderedAttributeSystemName: string[] } | undefined) {
    super(article.CatalogCode, attributes, article, dataSourceTypeId)
    this.init(article, retailPrice, wholesalePrice, outletPrice, resources, isAvailable, assets, favoriteTags, allocations, season, articlesCustomSortOrder)
  }

  init(article: Article, retailPrice: number, wholesalePrice: number, outletPrice: number, resources: Resource[], isAvailable: boolean, assets: DuneAsset[], favoriteTags: SimpleFavoriteTag[], allocations: Allocation[], season: string, articlesCustomSortOrder: { sortOrder: Record<string, number>, orderedAttributeSystemName: string[] } | undefined) {
    this._RetailPrice = retailPrice
    this._WholesalePrice = wholesalePrice
    this._OutletPrice = outletPrice
    this._IsAvailable = isAvailable
    this._Resources = resources || []
    this._Assets = assets || []
    this._FavoriteTags = favoriteTags || []
    this._Allocations = allocations || []
    this._Seasons = appConstants.staticAttributes._Seasons.FilterLookup.has(this.CatalogCode) ? appConstants.staticAttributes._Seasons.FilterLookup.get(this.CatalogCode) : season
    this._IsRequestArticle = Boolean(article._IsRequestArticle)
    this._RequestNumber = article._RequestNumber?.toString() || ''
    this._RequestSource = Number(article._RequestSource)
    this._RequestState = Number(article._RequestState)
    this._SourceModelId = article._SourceModelId ? Number(article._SourceModelId) : null
    this._SourceModelNumber = article._SourceModelNumber?.toString() || null
    this._SourceArticleId = article._SourceArticleId ? Number(article._SourceArticleId) : null
    this._SourceArticleNumber = article._SourceArticleNumber?.toString() || null
    this._ConfirmArticleNumber = article._ConfirmArticleNumber?.toString() || null
    this._Comment = article._Comment?.toString() || ''
    this._Reason = article._Reason?.toString() || ''
    if (utils.isDefined(article.RequestAttributes) && isObject(article.RequestAttributes)) {
      for (const key in article.RequestAttributes) {
        this[key] = article.RequestAttributes[key]
      }
    }
    // generate article custom sortorder
    this._SortOrder = utils.generateArticlesSortOrder(this, articlesCustomSortOrder)
    this._IsNonSegmented = Boolean(article._IsNonSegmented) || false
    // this._ShipDateRange =
    //   [this.ShipmentStartDate?.toLocaleString('default', { month: 'short' }),
    //   this.ShipmentEndDate?.toLocaleString('default', { month: 'short' })]
    //     .filter(Boolean).join(' - ')
  }

  async translateToken(token: string, myAttributes: Record<string, IMyAttribute>, activeCatalog: CatalogDetails, masterSizeScales: Record<number, MasterSizeScaleModel>, priceGroups: { retail: CatalogPriceGroup | undefined, wholesale: CatalogPriceGroup | undefined, outlet: CatalogPriceGroup | undefined }) {
    if (!token.startsWith('$')) {
      if (this.hasOwnProperty(token) && myAttributes.hasOwnProperty(token) && utils.isDefined(this[token])) {
        return utils.ifUndefined(await utils.getAttributeTypeSpecificValue(myAttributes[token], this, appConfig.DB, activeCatalog, priceGroups), null)
      }
      else {
        if (masterSizeScales && token === 'MasterSizeScaleId' && this.MasterSizeScaleId && masterSizeScales[this.MasterSizeScaleId]) {
          return masterSizeScales[this.MasterSizeScaleId].SizeScale
        }
        else {
          return null
        }
      }
    }
    else {
      switch (token) {
        case '$retail':
          return utils.formatPrice(priceGroups.retail, this._RetailPrice, activeCatalog.Config.ShowPriceThousandsSeparated)

        case '$wholesale':
          return utils.formatPrice(priceGroups.retail, this._WholesalePrice, activeCatalog.Config.ShowPriceThousandsSeparated)

        case '$retail.nc':
          return this._RetailPrice.toString()

        case '$wholesale.nc':
          return this._WholesalePrice.toString()

        case '$datecol':
          if (activeCatalog.Config.ArticleThumbnailShowFirstDeliveryDate) {
            return utils.formatDate(this.getFirstDeliveryDate(activeCatalog))
          }
          return this._ShipDateRange || '-'

        case '$seg':
          return Object.values(this._Segmentations)
            .map(s => activeCatalog._IndexedCatalogSegmentation[s.Id] && activeCatalog._IndexedCatalogSegmentation[s.Id].Status === 1 ? activeCatalog._IndexedCatalogSegmentation[s.Id].Name : null)
            .filter(seg => utils.isValidStringValue(seg))
            .join(', ')

        default:
          break
      }
    }
  }

  getFirstDeliveryDate(activeCatalog: CatalogDetails) {
    const activeDeliveryDates = this._DeliveryDates.filter(x => x.Status > 0)
    if (activeDeliveryDates.length) {
      const first = activeDeliveryDates.reduce((prev, curr) => activeCatalog._IndexedCatalogCRD[prev.CrdId].CustomerRequiredDate < activeCatalog!._IndexedCatalogCRD[curr.CrdId].CustomerRequiredDate ? prev : curr)
      return activeCatalog._IndexedCatalogCRD[first.CrdId]?.CustomerRequiredDate
    }
    return null
  }
}
