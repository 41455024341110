<template>
  <div class="relative w-full">
    <div class="relative">
      <div
        ref="refInput" :contenteditable="!disabled" tabindex="0" aria-multiline="true" role="textbox"
        class="p-2 overflow-y-auto text-base border rounded select-text h-28 focus:outline-none focus:ring-0 focus:border-primary bg-card border-form shadow-input"
        :class="{ 'bg-gray-200': disabled, 'pr-4': clearable, 'pr-2': !clearable }"
      />
      <div v-show="showPlaceholder" class="absolute text-gray-500 pointer-events-none top-2 left-2">
        {{ placeholder }}
      </div>
      <font-awesome-icon
        v-if="clearable && !disabled && modelValue && modelValue.length > 0"
        class="absolute top-2.5 right-2 w-4 h-4 cursor-pointer" icon="fa-light fa-xmark" @click="doClear"
      />
    </div>
    <div
      v-show="!!currentKey" ref="popper"
      :style="caretPosition ? { top: `${caretPosition.top}px`, left: `${caretPosition.left}px` } : {}"
      class="absolute block max-w-xs overflow-x-hidden overflow-y-scroll text-base bg-white rounded max-h-36 shadow-dropdown z-dropdown focus:outline-none"
    >
      <div v-if="!displayedItems.length">
        <slot name="no-result">
          {{ t('general.noResult') }}
        </slot>
      </div>
      <template v-else>
        <div
          v-for="(item, index) of displayedItems" :key="index"
          class="p-1 text-base text-gray-900 cursor-pointer select-none hover:bg-grey-light focus:outline-none focus:bg-grey-light max-h-15"
          :class="{ 'text-primary-500': selectedIndex === index }" @mousedown="applyMention(index)"
        >
          <slot :name="`item-${currentKey || oldKey}`" :item="item" :index="index">
            <slot name="item" :item="item" :index="index">
              <div class="p-1 border rounded-lg border-grey">
                <div>{{ item.label || item.value }}</div>
                <div v-if="item.subTitle && item.subTitle.length" class="text-sm truncate">
                  {{ item.subTitle }}
                </div>
              </div>
            </slot>
          </slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
// https://github.com/Akryum/vue-mention

import { useI18n } from 'vue-i18n'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import utils from '@/services/utils'

interface IProps {
  modelValue?: string
  placeholder?: string
  clearable?: boolean
  disabled?: boolean
  keys?: string[]
  items?: IMentionItem[]
  omitKey?: boolean
  filteringDisabled?: boolean
  insertSpace?: boolean
  mapInsert?: Function
  limit?: number
}

const props = withDefaults(defineProps<IProps>(), { placeholder: '', clearable: false, disabled: false, keys: () => ['@'] as string[], items: () => [] as IMentionItem[], omitKey: false, filteringDisabled: false, insertSpace: false, limit: 12 })

const emit = defineEmits<{
  (e: 'update:modelValue', val: string | null): void
  (e: 'search', value: string, oldValue?: string): void
  (e: 'open', value?: string, index?: number): void
  (e: 'close', value?: string): void
  (e: 'apply', item: IMentionItem, key?: string, value?: string): void
}>()

const { t } = useI18n()

const currentKey = ref<string>()
let currentKeyIndex: number
const oldKey = ref<string>()
const showPlaceholder = ref(!!props.placeholder)

// Items
const searchText = ref<string>()

watch(searchText, (value, oldValue) => {
  if (value) {
    emit('search', value, oldValue)
  }
})

const filteredItems = computed(() => {
  if (!searchText.value || props.filteringDisabled) {
    return props.items
  }

  const finalSearchText = searchText.value.toLowerCase()

  return props.items.filter((item) => {
    let text: string
    if (item.searchText) {
      text = item.searchText
    }
    else if (item.label) {
      text = item.label
    }
    else {
      text = ''
      for (const key in item) {
        text += item[key]
      }
    }
    return text.toLowerCase().includes(finalSearchText)
  })
})

const displayedItems = computed(() => filteredItems.value.slice(0, props.limit))

// Selection
const selectedIndex = ref(0)

watch(displayedItems, () => {
  selectedIndex.value = 0
}, { deep: true })

// Input element management
const refInput = ref<HTMLElement>()
const popper = ref<HTMLElement>()

function doClear() {
  if (refInput.value) {
    refInput.value.textContent = ''
  }
  emitInputEvent('input')
}

onMounted(() => {
  attach()
})

onUnmounted(() => {
  detach()
})

// Events
function attach() {
  if (refInput.value) {
    refInput.value.addEventListener('input', onInput)
    refInput.value.addEventListener('keydown', onKeyDown)
    refInput.value.addEventListener('keyup', onKeyUp)
    refInput.value.addEventListener('scroll', onScroll)
    refInput.value.addEventListener('blur', onBlur)
  }
}

function detach() {
  if (refInput.value) {
    refInput.value.removeEventListener('input', onInput)
    refInput.value.removeEventListener('keydown', onKeyDown)
    refInput.value.removeEventListener('keyup', onKeyUp)
    refInput.value.removeEventListener('scroll', onScroll)
    refInput.value.removeEventListener('blur', onBlur)
  }
}
function onInput() {
  checkKey()
  const content = refInput.value?.innerHTML || ''
  emit('update:modelValue', content)
  showPlaceholder.value = !utils.isValidStringValue(refInput.value?.textContent?.trim())
}

function onBlur() {
  closeMenu()
}

function onKeyDown(e: KeyboardEvent) {
  if (currentKey.value) {
    if (e.key === 'ArrowDown') {
      selectedIndex.value++
      if (popper.value) {
        popper.value.scrollTop += 20
      }
      if (selectedIndex.value >= displayedItems.value.length) {
        selectedIndex.value = 0
        if (popper.value) {
          popper.value.scrollTop = 0
        }
      }

      cancelEvent(e)
    }
    if (e.key === 'ArrowUp') {
      selectedIndex.value--
      if (popper.value) {
        popper.value.scrollTop -= 20
      }
      if (selectedIndex.value < 0) {
        if (popper.value) {
          popper.value.scrollTop = displayedItems.value.length * 20
        }
        selectedIndex.value = displayedItems.value.length - 1
      }
      cancelEvent(e)
    }
    if ((e.key === 'Enter' || e.key === 'Tab')
      && displayedItems.value.length > 0) {
      applyMention(selectedIndex.value)
      cancelEvent(e)
    }
    if (e.key === 'Escape') {
      closeMenu()
      cancelEvent(e)
    }
  }
  e.stopPropagation()
}

let cancelKeyUp: string | null = null

function onKeyUp(e: KeyboardEvent) {
  if (cancelKeyUp && e.key === cancelKeyUp) {
    cancelEvent(e)
  }
  cancelKeyUp = null

  if (refInput.value && refInput.value.childNodes.length) {
    const nodes = refInput.value.childNodes
    if (nodes[nodes.length - 1].nodeType !== Node.TEXT_NODE && (e.key === 'Backspace' || e.key === 'Delete')) {
      refInput.value.appendChild(document.createTextNode('\uFEFF'))
    }

    if (nodes[0].nodeType !== Node.TEXT_NODE) {
      refInput.value.prepend(document.createTextNode('\uFEFF'))
    }
    if (e.key === 'Backspace' || e.key === 'Delete') {
      const selection = window.getSelection()
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)
        const commonAncestor = range.commonAncestorContainer
        const isSpanElement = commonAncestor.parentNode instanceof HTMLSpanElement
        if (isSpanElement && commonAncestor.parentNode && commonAncestor.parentNode.parentNode) {
          commonAncestor.parentNode.parentNode.removeChild(commonAncestor.parentNode)
        }
      }
    }
  }
}

function cancelEvent(e: KeyboardEvent) {
  e.preventDefault()
  e.stopPropagation()
  cancelKeyUp = e.key
}

function onScroll() {
  updateCaretPosition()
}

function getSelectionStart() {
  return window.getSelection()?.anchorOffset
}

// function setCaretPosition (index: number) {
//   if (!refInput.value) return
//   nextTick(() => {
//     refInput.value!.selectionEnd = index
//   })
// }

function getValue() {
  return window.getSelection()?.anchorNode?.textContent || ''
}

// function setValue (value) {
//   if (refInput.value) {
//     (refInput.value as HTMLInputElement).value = value
//   }
//   emitInputEvent('input')
// }

function emitInputEvent(type: string) {
  refInput.value?.dispatchEvent(new Event(type))
}

let lastSearchText: string | null = null

function checkKey() {
  const index = getSelectionStart()
  if (index && index >= 0) {
    const { key, keyIndex } = getLastKeyBeforeCaret(index)
    const text = lastSearchText = getLastSearchText(index, keyIndex)
    if (!(keyIndex < 1 || /\s/.test(getValue()[keyIndex - 1]))) {
      return false
    }
    if (text != null) {
      openMenu(key, keyIndex)
      searchText.value = text
      return true
    }
  }
  closeMenu()
  return false
}

function getLastKeyBeforeCaret(caretIndex: number) {
  const [keyData] = props.keys.map(key => ({
    key,
    keyIndex: getValue().lastIndexOf(key, caretIndex - 1),
  })).sort((a, b) => b.keyIndex - a.keyIndex)
  return keyData
}

function getLastSearchText(caretIndex: number, keyIndex: number) {
  if (keyIndex !== -1) {
    const text = getValue().substring(keyIndex + 1, caretIndex)
    // If there is a space we close the menu
    if (!/\s/.test(text)) {
      return text
    }
  }
  return null
}

// Position of the popper
const caretPosition = ref<{ top: number, left: number, height: number }>()

function updateCaretPosition() {
  if (currentKey.value && refInput.value) {
    const rect = window.getSelection()?.getRangeAt(0).getBoundingClientRect()
    if (rect) {
      const inputRect = refInput.value.getBoundingClientRect()
      caretPosition.value = {
        left: rect.left - inputRect.left,
        top: rect.top - inputRect.top,
        height: rect.height,
      }
      caretPosition.value.top -= refInput.value.scrollTop - 22
    }
  }
}

// Open/close
function openMenu(key: string, keyIndex: number) {
  if (currentKey.value !== key) {
    currentKey.value = key
    currentKeyIndex = keyIndex
    updateCaretPosition()
    selectedIndex.value = 0
    emit('open', currentKey.value, currentKeyIndex)
  }
}

function closeMenu() {
  if (currentKey.value != null) {
    oldKey.value = currentKey.value
    currentKey.value = undefined
    emit('close', oldKey.value)
  }
}

// Apply
function applyMention(itemIndex: number) {
  const item = displayedItems.value[itemIndex]
  const value = (props.omitKey ? '' : currentKey.value) + String(props.mapInsert ? props.mapInsert(item, currentKey.value) : item.value) + (props.insertSpace ? ' ' : '')
  const sel = window.getSelection()
  if (sel && currentKey.value) {
    const range = sel.getRangeAt(0)
    range.setStart(range.startContainer, range.startOffset - currentKey.value.length - (lastSearchText ? lastSearchText.length : 0))
    range.deleteContents()

    const mentionEl = document.createElement('span')
    mentionEl.setAttribute('data-value', item.value)
    if (item.type) {
      mentionEl.setAttribute('data-type', item.type)
    }
    mentionEl.className = 'text-primary'
    // const finalValue = value[value.length - 1] === ' ' ? value.substring(0, value.length - 1) : value
    mentionEl.appendChild(document.createTextNode(value))
    range.insertNode(mentionEl)
    range.setStart(range.endContainer, range.endOffset)

    // range.collapse(false)
    const emptyNode = document.createTextNode('\uFEFF\u00A0')
    range.insertNode(emptyNode)
    range.setStartAfter(emptyNode)

    sel.removeAllRanges()
    sel.addRange(range)

    emitInputEvent('input')
    emit('apply', item, currentKey.value, value)
    closeMenu()
    refInput.value?.focus()
  }
}

function getMentionedItems() {
  const result: IMentionItem[] = []
  if (refInput.value) {
    const spans = refInput.value.getElementsByTagName('span')
    if (spans.length) {
      Array.from(spans).forEach((span) => {
        const value = span.getAttribute('data-value')
        const type = span.getAttribute('data-type')
        const index = props.items.findIndex(i => i.value?.toString() === value && i.type?.toString() === type)
        if (index >= 0) {
          result.push(props.items[index])
        }
      })
    }
  }
  return result
}

defineExpose({
  getMentionedItems,
  clear: doClear,
})
</script>
