import utils from '@/services/utils'

export default class DuneAsset {
  Context: string
  Id: string
  Key: string
  ImageSet: string
  FileName: string
  SortOrder: number
  Status: number
  StorageFile: string
  UpdatedOn: Date

  get imageSet(): string {
    return this.ImageSet
  }

  get fileName(): string {
    return this.FileName
  }

  get ImageName(): string {
    return this.Key
  }

  get imageName(): string {
    return this.Key
  }

  get Is3DModel(): boolean {
    const validKeys = ['glb', 'gltf']
    return utils.isDefined(this.Key) && validKeys.includes(this.Key.trim().toLowerCase())
  }

  constructor(context: string, obj: any) {
    this.Context = context
    this.Id = obj.Id // database table id
    this.Key = obj.Key // image type (front, back, sideview, etc)
    this.ImageSet = obj.ImageSet // article number
    this.FileName = obj.FileName // file name
    this.SortOrder = obj.SortOrder
    this.Status = obj.Status ? 1 : 0
    this.StorageFile = obj.StorageFile // storage id
    this.UpdatedOn = new Date(obj.UpdatedOn)
  }
}
