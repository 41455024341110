<template>
  <div v-if="currentOrder != null" class="h-full overflow-auto">
    <!-- HEADER -->
    <div class="flex items-center justify-between h-8">
      <div v-tooltip="formattedUpdatedDateTooltip" class="text-xs text-secondary">
        {{ formattedUpdatedDate }}
      </div>
      <!-- SAVE BUTTON -->
      <tx-button
        v-show="v$.$dirty && (currentOrder.OrderProcessStatusId === ordersConstants.orderProcessStatus.draft || currentOrder.OrderProcessStatusId === ordersConstants.orderProcessStatus.reopened)"
        class="font-bold capitalize bg-transparent text-primary-500 outline-1 outline-primary outline"
        height="30px" width="60px" :text="t('general.save').toUpperCase()"
        :disabled="isFormDisabled"
        @click="doUpdateOrderDetails"
      />
    </div>

    <div class="my-5 font-semibold text-[14px] select-all">
      {{ currentOrder?.OrderReference }}
    </div>
    <input-label class="my-5" :label="t('orders.field.customer')" label-class-string="text-secondary pb-1 text-xs">
      <span class="select-all">{{ currentOrder?.CustomerName }}</span>
    </input-label>
    <input-label class="my-5" :label="t('orders.field.location')" label-class-string="text-secondary pb-1 text-xs">
      <span class="select-all">{{ currentOrder?.LocationName }}</span>
    </input-label>

    <!-- ORDER DETAILS FORM -->
    <input-label v-for="editableField in orderDetailsEditableFields" :key="editableField.SystemName" class="my-5" :label="editableField.DisplayName" label-class-string="text-secondary pb-1 text-xs">
      <form-editor
        v-model="orderDetailsFormModel[editableField.SystemName]"
        :attribute="editableField"
        :disabled="isFormDisabled"
        :form="orderDetailsFormModel"
        :show-label="false"
        @change="onFormValueChanged"
      />
    </input-label>

    <input-label class="my-5" :label="t('orders.field.createdBy')" label-class-string="text-secondary pb-1 text-xs">
      <span class="select-all">{{ currentOrder?.CreatedByEmail }}</span>
    </input-label>

    <input-label class="my-5" :label="t('orders.field.processStatus')" label-class-string="text-secondary pb-1 text-xs">
      <span class="select-all">{{ currentOrder?.OrderProcessStatus }}</span>
    </input-label>

    <input-label class="my-5" :label="t('orders.field.customerCodeName')" label-class-string="text-secondary pb-1 text-xs">
      <span class="select-text">{{ `${currentOrder?.CustomerNumber} - ${currentOrder?.CustomerName}` }}</span>
    </input-label>

    <input-label class="my-5" :label="t('orders.field.locationCodeName')" label-class-string="text-secondary pb-1 text-xs">
      <span class="select-text">{{ `${currentOrder?.LocationCode} - ${currentOrder?.LocationName}` }}</span>
    </input-label>

    <input-label class="my-5" :label="t('orders.field.totalInitialValue')" label-class-string="text-secondary pb-1 text-xs">
      <span class="select-all">{{ thousandsFormattedTotalInitialValue }}</span>
    </input-label>

    <input-label class="my-5" :label="t('orders.field.totalCurrentValue')" label-class-string="text-secondary pb-1 text-xs">
      <span class="select-all">{{ thousandsFormattedTotalCurrentValue }}</span>
    </input-label>

    <input-label class="my-5" :label="t('orders.field.totalCurrentQuantity')" label-class-string="text-secondary pb-1 text-xs">
      <span class="select-all">{{ thousandsFormattedActiveTotalQuantity }}</span>
    </input-label>
  </div>
</template>

<script lang='ts' setup>
import moment from 'moment'
import useVuelidate from '@vuelidate/core'
import { computed, inject, reactive, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'
import type CustomerLocation from '@/models/CustomerLocation'
import FormEditor from '@/shared/components/FormEditor.vue'
import InputLabel from '@/shared/components/InputLabel.vue'
import type Order from '@/models/order'
import TxButton from '@/shared/components/TxButton.vue'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import type { IOrderModel, IUpdateOrderPayload } from '@/api/t1/model/orderModel'
import { appConstants, ordersConstants } from '@/models/constants'

import { useNotificationStore } from '@/store/notification'
import { useOrdersStore } from '@/store/orders'
import { useUserStore } from '@/store/userData'

const props = defineProps<{
  indexedLocations: Record<number, CustomerLocation>
}>()

const emit = defineEmits<{
  (e: 'updated', orderDetailsFormModel: Record<'Alias' | 'CustomerReference', any>): void
}>()

const { t } = useI18n()
const notificationsStore = useNotificationStore()
const userStore = useUserStore()
const ordersStore = useOrdersStore()
const { updateOrderInOrdersList } = inject('ordersProvide', {
  updateOrderInOrdersList: (_currentOrder: Order, _updateOrderPayload: IUpdateOrderPayload | IOrderModel | Partial<Order>) => null,
})

const isSavingOrderDetails = ref(false)
const currentOrder = toRef(ordersStore, 'currentOrder') // currentOrder will always be defined in this page
const orderDetailsFormModel: Record<'Alias' | 'CustomerReference', any> = reactive({}) as Record<'Alias' | 'CustomerReference', any>
const orderDetailsEditableFields: Array<IMyAttribute> = [
  Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'Alias',
    DisplayName: t('orders.field.alias'),
    Creatable: true,
    AttributeType: AttributeType.Nvarchar,
  }),
  Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'CustomerReference',
    DisplayName: t('orders.field.customerReference'),
    Creatable: true,
    AttributeType: AttributeType.Nvarchar,
  }),
]

const formattedUpdatedDate = computed(() => moment(currentOrder.value!.UpdatedDate).fromNow())
const formattedUpdatedDateTooltip = computed(() => moment(currentOrder.value!.UpdatedDate).format('MMMM Do YYYY, h:mm:ss a'))
const thousandsFormattedTotalInitialValue = computed(() => utils.thousandsSeparator(currentOrder.value!.getTotalInitialValue(true).toFixed(2)))
const thousandsFormattedTotalCurrentValue = computed(() => utils.thousandsSeparator(currentOrder.value!.getTotalCurrentValue(true).toFixed(2)))
const thousandsFormattedActiveTotalQuantity = computed(() => utils.thousandsSeparator(currentOrder.value!.getActiveTotalQuantity()))
const isOrderEditable = computed(() => currentOrder.value!.Status && (currentOrder.value!.OrderProcessStatusId == null || !ordersConstants.noneEditableProcessStatuses.includes(currentOrder.value!.OrderProcessStatusId))
  && currentOrder.value!.CreatedBy === userStore.userProfile.Id && props.indexedLocations.hasOwnProperty(currentOrder.value!.LocationId) && props.indexedLocations[currentOrder.value!.LocationId].Status,
)
const isFormDisabled = computed(() => {
  return (
    isSavingOrderDetails.value || !isOrderEditable.value
  )
})
const validations = computed(() => {
  const result: Record<string, any> = {}
  orderDetailsEditableFields.forEach((field) => {
    result[field.SystemName] = {}
  })
  return result
})
const v$ = useVuelidate(validations, orderDetailsFormModel)

function init() {
  orderDetailsEditableFields.forEach((orderDetailsEditableField) => {
    orderDetailsFormModel[orderDetailsEditableField.SystemName] = currentOrder.value![orderDetailsEditableField.SystemName] ?? ''
  })
}

function onFormValueChanged() {
  v$.value.$touch()
  currentOrder.value!.setIsDirty(true)
  emit('updated', orderDetailsFormModel)
}

async function doUpdateOrderDetails() {
  // do not include draft not synced to server (OrderProcessStatusId === null)
  if (currentOrder.value!.OrderProcessStatusId === ordersConstants.orderProcessStatus.draft || currentOrder.value!.OrderProcessStatusId === ordersConstants.orderProcessStatus.reopened) {
    isSavingOrderDetails.value = true
    const orderUpdateAbleProps: IUpdateOrderPayload = (({ CustomerId, LocationId, SourceId, CustomerReference, Alias }) =>
      ({ CustomerId, LocationId, SourceId, CustomerReference, Alias }))(currentOrder.value!)
    for (const key in orderDetailsFormModel) {
      orderUpdateAbleProps[key] = orderDetailsFormModel[key]
    }
    try {
      const response = await currentOrder.value!.updateOrderDetails(orderUpdateAbleProps)
      updateOrderInOrdersList(currentOrder.value!, response.data)
      resetOrderInfoForm()
      notificationsStore.addNotification({ message: t('orderlines.updateOrder.success'), type: 'Success' })
    }
    catch (error) {
      console.error(error)
      notificationsStore.addNotification({ message: t('orderlines.updateOrder.failed'), type: 'Alert' })
    }
    finally {
      isSavingOrderDetails.value = false
    }
  }
}

function resetOrderInfoForm() {
  v$.value.$reset()
}

init()

defineExpose({
  doUpdateOrderDetails,
  resetOrderInfoForm,
})
</script>
