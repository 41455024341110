<template>
  <div class="flex w-full h-full overflow-hidden">
    <!-- IMAGES & REQUESTS -->
    <div class="flex flex-col w-1/2 h-full px-7 py-7">
      <!-- TOOLBAR (TABS AND RESOURCES) -->
      <div class="flex w-full h-8">
        <tx-tabs v-model="activeTab" class="w-1/2 grow" :tabs="Object.values(tabs)" />
        <div class="w-1/2">
          <resource-icons-list v-if="isResourcesModuleEnabled" :resources="article._Resources" />
        </div>
      </div>

      <transition
        enter-from-class="opacity-0" enter-active-class="ease-out transition-medium"
        enter-to-class="opacity-100" leave-from-class="opacity-100" leave-active-class="ease-out transition-medium"
        leave-to-class="opacity-0"
      >
        <audit-trails v-if="activeTab === tabs.history.key" :article="article" />

        <manage-object-relation v-else-if="activeTab === tabs.related.key" :article="article" />

        <div v-else class="flex flex-col h-full">
          <!-- HEADER -->
          <div class="flex flex-col w-full">
            <flags-band :article="article" :flags="getFlags" />
            <div class="w-full pt-2">
              <span class="inline-block w-5/6 text-2xl font-semibold select-all whitespace-nowrap text-ellipsis">{{ article.ArticleName }}</span>
              <span class="inline-block w-1/6 text-sm font-semibold text-right select-all text-primary-500">{{ getRetailPrice }}</span>
            </div>
            <div class="w-full pt-1">
              <span class="inline-block w-5/6">
                <span class="text-sm select-all text-grey-icon whitespace-nowrap text-ellipsis">{{ article.ArticleNumber }}</span>
                <span v-if="article.Status === 0" class="px-1 ml-1 text-xs text-white align-middle bg-red-500 rounded-lg">{{ t('general.dropped') }}</span>
                <span v-if="article.Status === 2" class="px-1 ml-1 text-xs text-white align-middle bg-blue-500 rounded-lg">{{ t('general.notAssorted') }}</span>
                <span v-if="article.Status === 3" class="px-1 ml-1 text-xs text-white align-middle bg-red-500 rounded-lg">{{ t('general.globallyDropped') }}</span>
              </span>
              <span class="inline-block w-1/6 text-sm text-right select-all text-grey-icon">{{ getDate }}</span>
            </div>
            <!-- TODO: Work on request article header -->
          </div>

          <!-- Assets -->
          <div class="flex flex-1 w-full h-full pt-1">
            <article-assets-viewer :article="article" />
          </div>

          <!-- Other articles for same model -->
          <tx-horizontal-scroll class="relative h-28 -mx-7">
            <div
              v-for="art in otherColorArticles" v-show="art.CatalogArticleId !== article.CatalogArticleId"
              :key="art.CatalogArticleId" class="inline-block m-1 cursor-pointer h-25 w-25"
              @click="onOtherColorArticles(art)"
            >
              <image-loader :context="activeCatalog.DuneContext" :context-key="activeCatalog.ContextKey" :article-number="art.ArticleNumber" :size="95" />
              <div class="h-4 text-xs text-center truncate" style="width: 95px">
                {{ art.Colorway && art.Colorway.toString().length > 0 ? art.Colorway : '-' }}
              </div>
            </div>
          </tx-horizontal-scroll>
        </div>
      </transition>
    </div>
    <!-- TODO: REQUESTS -->

    <!-- ARTICLE DETAILS AND FORMS -->
    <div class="w-1/2 h-full px-2 py-4 bg-default">
      <div class="flex h-10 p-2 mr-24">
        <tx-button v-show="showBackArrow" type="icon" faicon="fa-light fa-chevron-left" @click="onBackClicked" />

        <!-- ACTIONS HEADER -->
        <div class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header">
          <!-- TITLE -->
          <div class="flex-1 text-xl font-semibold text-center text-tx-form-title" v-text="infoHeaderTitle" />
          <span v-if="selectedArticleAction === allArticleActions.assignColor.key" class="mt-3 text-base text-center lex-1 text-tx-form-title">{{ t('assignColor.header') }}</span>
        </div>

        <!-- ARTICLE ACTIONS DROPDOWN -->
        <tx-dropdown
          v-if="isArticleActionsEnabled && !showBackArrow && !readOnly" v-model="selectedArticleAction"
          :items="getArticleActions" value-prop="key" display-prop="label"
        >
          <template #button>
            <icon-button
              type="inline" faicon="fa-light fa-angle-down" :label="t('general.actions', getArticleActions.length)"
              :right-icon="true" :width="100"
            />
          </template>
        </tx-dropdown>
      </div>

      <!-- BODY -->
      <div class="w-full h-full pb-4 mb-2">
        <!-- TODO: local forecast and notes -->
        <update-article
          v-if="selectedArticleAction === allArticleActions.edit.key" :articles="[article]"
          :show-header="false" @cancel="onBackClicked" @updated="onArticleUpdated"
        />

        <active-or-deactive-article
          v-else-if="selectedArticleAction === allArticleActions.activate.key || selectedArticleAction === allArticleActions.deactivate.key"
          :show-header="false" :type="selectedArticleAction === allArticleActions.activate.key ? 'activate' : 'deactivate'"
          :articles="[article]" @cancel="onBackClicked" @updated="onArticleUpdated"
        />
        <lock-or-unlock-article
          v-else-if="selectedArticleAction === allArticleActions.lock.key || selectedArticleAction === allArticleActions.unlock.key"
          :type="selectedArticleAction === allArticleActions.lock.key ? 'lock' : 'unlock'" :show-header="false"
          :articles="[article]" @cancel="onBackClicked" @updated="onArticleUpdated"
        />

        <update-article-segmentation
          v-else-if="selectedArticleAction === allArticleActions.editSegmentations.key"
          :show-header="false" :articles="[article]" @cancel="onBackClicked" @updated="onArticleUpdated"
        />

        <update-article-retail-window
          v-else-if="selectedArticleAction === allArticleActions.editRetailWindow.key"
          :show-header="false" :articles="[article]" @cancel="onBackClicked" @updated="onArticleUpdated"
        />

        <update-article-forecast
          v-else-if="selectedArticleAction === allArticleActions.editForecasts.key"
          :article="article" @cancel="onBackClicked" @updated="onArticleUpdated"
        />

        <assign-article-resource
          v-else-if="selectedArticleAction === allArticleActions.assignResources.key"
          :show-header="false" :articles="[article]" @cancel="onBackClicked" @updated="onArticleUpdated"
        />

        <manage-object-relation
          v-else-if="selectedArticleAction === allArticleActions.editRelations.key"
          :article="article" @cancel="onBackClicked"
        />

        <update-size-scale
          v-else-if="selectedArticleAction === allArticleActions.editSizes.key" :articles="[article]"
          :show-header="false" @cancel="onBackClicked" @updated="onArticleUpdated"
        />

        <update-prices
          v-else-if="selectedArticleAction === allArticleActions.editPrices.key" :articles="[article]"
          :show-header="false" @cancel="onBackClicked" @updated="onArticleUpdated"
        />

        <assign-color
          v-else-if="selectedArticleAction === allArticleActions.assignColor.key" :context-article="article"
          :show-header="false" @cancel="onBackClicked" @updated="onArticleUpdated"
        />

        <update-period
          v-else-if="selectedArticleAction === allArticleActions.editPeriod.key" :article="article"
          :show-header="false" @cancel="onBackClicked" @updated="onArticleUpdated"
        />

        <manage-article-state
          v-else-if="selectedArticleAction === allArticleActions.manageArticleState.key"
          :show-header="false" :articles="[article]" @cancel="onBackClicked" @updated="onArticleUpdated"
        />

        <update-parent-model
          v-else-if="selectedArticleAction === allArticleActions.editParentModel.key"
          :show-header="false" :articles="[article]" @cancel="onBackClicked" @updated="onArticleUpdated"
        />

        <assort-articles
          v-if="selectedArticleAction === allArticleActions.assortArticles.key" :show-header="false"
          :articles="[article]" @cancel="onBackClicked" @updated="onArticleUpdated"
        />

        <div v-else class="w-full h-full overflow-y-auto">
          <!-- ALERT -->
          <tx-alert :show="infoMessageList.length > 0" type="info" :text="infoMessageList.join(', ')" />

          <!-- FAVORITES -->
          <article-favorites v-if="!readOnly" :article="article" @on-edit-fav="onEditFav" />

          <!-- ATTRIBUTES -->
          <div class="flex flex-row flex-wrap p-4 attributes">
            <article-info-cell
              v-for="(itm, index) in articleDetails" :key="index" :label="itm.label" :value="itm.value"
              :width="itm.width" :style="itm.style"
            />
          </div>

          <!-- STOCKS -->
          <stock-table v-if="userStore.activeCatalog?.IsStockApply" :article="article" />

          <!-- ATTACHMENTS -->

          <!-- FORECAST -->
          <update-article-forecast
            v-if="isUpdateArticleForecastEnabled" class="h-auto my-2" :article="article"
            readonly
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onMounted, ref, watch } from 'vue'
import { computedAsync } from '@vueuse/core'
import LockOrUnlockArticle from '../articleDetails/components/LockOrUnlockArticle.vue'
import ArticleAssetsViewer from './components/ArticleAssetsViewer.vue'
import ArticleFavorites from './components/ArticleFavorites.vue'
import ArticleInfoCell from './components/ArticleInfoCell.vue'
import AuditTrails from './components/AuditTrails.vue'
import UpdateArticle from './components/UpdateArticle.vue'
import ActiveOrDeactiveArticle from './components/ActiveOrDeactiveArticle.vue'
import UpdateArticleSegmentation from './components/UpdateArticleSegmentation.vue'
import UpdateArticleForecast from './components/UpdateArticleForecast.vue'
import AssignArticleResource from './components/AssignArticleResource.vue'
import ManageArticleState from './components/ManageArticleState.vue'
import updatePrices from './components/UpdatePrices.vue'
import UpdatePeriod from './components/UpdatePeriod.vue'
import AssignColor from './components/AssignColor.vue'
import ManageObjectRelation from './components/ManageObjectRelation.vue'
import UpdateSizeScale from './components/UpdateSizeScale.vue'
import UpdateParentModel from './components/UpdateParentModel.vue'
import StockTable from './components/StockTable.vue'
import AssortArticles from './components/AssortArticles.vue'
import useArticleActions from './composables/articleActions'
import type MyArticle from '@/models/myArticle'
import { privileges } from '@/models/constants'
import utils, { CancelToken } from '@/services/utils'
import appConfig from '@/services/appConfig'
import UpdateArticleRetailWindow from '@/shared/components/UpdateArticleRetailWindow.vue'
import ImageLoader from '@/shared/components/ImageLoader.vue'
import TxHorizontalScroll from '@/shared/components/TxHorizontalScroll.vue'
import FlagsBand from '@/shared/components/FlagsBand.vue'
import ResourceIconsList from '@/shared/components/resources/ResourceIconsList.vue'
import IconButton from '@/shared/components/IconButton.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxDropdown from '@/shared/components/TxDropdown.vue'
import TxTabs from '@/shared/components/TxTabs.vue'
import TxButton from '@/shared/components/TxButton.vue'
import useDisableModule from '@/shared/composables/disableModule'
import { useNotificationStore } from '@/store/notification'
import { useUserStore } from '@/store/userData'
import { getArticleAssets } from '@/api/t1/article'
import type Article from '@/models/article'

interface IProps {
  article: MyArticle
  readOnly?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  readOnly: false,
})

const emit = defineEmits<{
  (e: 'updated', key: string, value: any): void
  (e: 'change', article: MyArticle): void
  (e: 'onEditFav', article: MyArticle): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const notificationStore = useNotificationStore()
const currentArticle = computed(() => {
  return props.article
})
const { isResourcesModuleEnabled, isOrderModuleEnabled, isForecastModuleEnabled } = useDisableModule()
const { selectedArticleAction, allArticleActions, getArticleActions, getUpdatedMessage } = useArticleActions(currentArticle)

const tabs: Record<string, ITabItem> = {
  images: { key: 'images', label: 'general.images', visible: true },
  history: { key: 'history', label: 'general.history', visible: userStore.userProfile.isValidPrivilege(privileges.article.getHistory) },
  related: { key: 'related', label: 'general.related', visible: userStore.userProfile.isValidPrivilege(privileges.objectRelation.get) },
}

const activeTab = ref(tabs.images.key)
const otherColorArticles = ref<MyArticle[]>([])

const getFlags = computed(() => {
  if (userStore.activeCatalog?.Config.ArticleThumbnailFlags) { return userStore.activeCatalog?.Config.ArticleThumbnailFlags }

  else { return [] as IFlagsBandFlag[] }
})

const getRetailPrice = computed(() => {
  return userStore.activeCatalog?.IsLinePlan !== 1
    ? utils.formatPrice(userStore.priceGroups.retail, props.article._RetailPrice, userStore.activeCatalog?.Config.ShowPriceThousandsSeparated)
    : ''
})

const getDate = computed(() => {
  let date = props.article._ShipDateRange
  if (userStore.activeCatalog && userStore.activeCatalog.Config.ArticleThumbnailShowFirstDeliveryDate) { date = utils.formatDate(props.article.getFirstDeliveryDate(userStore.activeCatalog)) }

  return date
})

const showBackArrow = computed(() => utils.isDefined(selectedArticleAction.value))

const isArticleActionsEnabled = computed(() => getArticleActions.value.length > 0 && userStore.activeCatalog?.CatalogCode === props.article.CatalogCode)
  && (!utils.isDefined(userStore.currentCustomer) || !props.article._IsNonSegmented)

const infoHeaderTitle = computed(() => {
  return utils.isDefined(selectedArticleAction.value) ? allArticleActions[selectedArticleAction.value].label : ''
})

const activeCatalog = computed(() => props.article.CatalogCode === userStore.activeCatalog?.CatalogCode ? userStore.activeCatalog : userStore.linkedCatalogDetails[props.article.CatalogCode])

const isUpdateArticleForecastEnabled = computed(() => isForecastModuleEnabled.value && userStore.userProfile.isValidPrivilege(privileges.article.getForecasts))

function onBackClicked() {
  selectedArticleAction.value = null
}

function onArticleUpdated(articles: MyArticle[] | Article[], type: string = '') {
  const message = getUpdatedMessage(articles, type)

  notificationStore.addNotification({ message, type: 'Success' })
  onBackClicked()
}

function onOtherColorArticles(article: MyArticle) {
  emit('change', article)
}

const infoMessageList = computed(() => {
  const infoMessages: string[] = []
  if (props.article._Allocations.length && utils.isDefined(userStore.currentCustomer) && userStore.currentCustomer.AllocationGroups.length) {
    // Each article has one allocation group assigned
    const allocation = userStore.currentCustomer.AllocationGroups.find(a => a.AllocationGroupId === props.article._Allocations[0].Id)
    if (utils.isDefined(allocation)) {
      if (utils.isDefined(allocation.MinQuantity) && allocation.MinQuantity !== 0
        && utils.isDefined(allocation.MaxQuantity) && allocation.MaxQuantity !== 0) { infoMessages.push(t('articleDetails.alerts.minAndMaxQuantity', { maxQuantity: allocation.MaxQuantity, minQuantity: allocation.MinQuantity })) }

      else if (utils.isDefined(allocation.MinQuantity) && allocation.MinQuantity !== 0) { infoMessages.push(t('articleDetails.alerts.minQuantityOnly', { minQuantity: allocation.MinQuantity })) }

      else if (utils.isDefined(allocation.MaxQuantity) && allocation.MaxQuantity !== 0) { infoMessages.push(t('articleDetails.alerts.maxQuantityOnly', { maxQuantity: allocation.MaxQuantity })) }
    }
  }
  // show info for weather article have any valid crd and size
  if (isOrderModuleEnabled.value) {
    let validCrd = false
    for (const crd of props.article._DeliveryDates) {
      if (crd.Status > 0 && userStore.activeCatalog && utils.isDefined(userStore.activeCatalog._IndexedCatalogCRD[crd.CrdId])
        && userStore.activeCatalog._IndexedCatalogCRD[crd.CrdId].Status > 0 && userStore.activeCatalog._IndexedCatalogCRD[crd.CrdId].Availability) {
        const nowInMilliseconds = Date.now()
        if ((crd.AvailabilityFrom == null && crd.AvailabilityTo == null)
          || (((crd.AvailabilityFrom != null && nowInMilliseconds > crd.AvailabilityFrom.getTime()) || (crd.AvailabilityFrom == null))
          && ((crd.AvailabilityTo != null && nowInMilliseconds < crd.AvailabilityTo.getTime()) || (crd.AvailabilityTo == null)))) {
          validCrd = true
          break
        }
      }
    }
    if (!validCrd) { infoMessages.push(t('articleDetails.alerts.unavailableCRD')) }

    const articleSizes = props.article._Sizes
    if (articleSizes.filter(size => !size.Status).length === articleSizes.length) { infoMessages.push(t('articleDetails.alerts.orderlineWithoutSize')) }
  }
  return infoMessages
})

const articleDetails = computedAsync(
  async () => {
    const result: { label: string, value: any, width: number, style?: string[] }[] = []
    if (userStore.activeCatalog?.Config.ArticleDetailsTemplate2 && utils.isDefined(userStore.myAttributes)) {
      for (const itm of userStore.activeCatalog.Config.ArticleDetailsTemplate2) {
        result.push({
          label: itm.label,
          value: utils.ifUndefined(await props.article.translateToken(itm.value, userStore.myAttributes!, userStore.activeCatalog!, userStore.masterSizeScales, userStore.priceGroups), ''),
          width: itm.width,
          style: itm.style,
        })
      }
    }
    return result
  },
  [],
)

function onEditFav() {
  emit('onEditFav', props.article)
}

// TODO: Handle it separately for line plan
async function loadOtherColorArticles() {
  otherColorArticles.value = []
  const catalogDetails = props.article.CatalogCode === userStore.activeCatalog?.CatalogCode
    ? userStore.activeCatalog
    : userStore.linkedCatalogDetails[props.article.CatalogCode]
  if (catalogDetails && props.article && props.article.ModelNumber && props.article.ModelNumber !== '') {
    const articles = await appConfig.DB!.getMyArticlesByModelNumber(catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, props.article.ModelNumber, true, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
    otherColorArticles.value = (!(articles instanceof CancelToken) && articles) ? articles : []
  }
}

async function loadAssets() {
  if (!props.article) { return }
  const cat = props.article.CatalogCode === userStore.activeCatalog?.CatalogCode ? userStore.activeCatalog : userStore.linkedCatalogDetails[props.article.CatalogCode]
  const res = await utils.tryAsync(getArticleAssets(cat.DuneContext, cat.ContextKey, props.article.ArticleNumber, userStore.activeCatalog?.Config.NewestImageAssetKeyList))
  if (res.success) {
    emit('updated', '_Assets', res.result)
  }
}

// first call
onMounted(() => {
  loadOtherColorArticles()
  loadAssets()
})

watch(() => props.article, async () => {
  loadOtherColorArticles()
  loadAssets()
})
</script>
