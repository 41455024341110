import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import { pick } from 'lodash-es'
import { merchConstants } from '@/models/constants'

interface IMbRectangleOptions extends fabric.ITriangleOptions {
  id?: string
  locked?: boolean
  templateObject?: boolean
}
const editableProps: Record<string, IMbObjectProp> = {
  border: { name: 'border', type: 'border' },
  backgroundColor: { name: 'backgroundColor', type: 'color' },
  locked: { name: 'lock', type: 'lock' },
  // addDiscussion: { name: 'add comment', type: 'addDiscussion' },
}
export default class MbTriangle extends fabric.Triangle implements IMbObject {
  public id: string
  public type = merchConstants.objectTypes.triangle
  public locked: boolean
  public editableProps: Record<string, IMbObjectProp> = editableProps

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    copy: { action: 'copy', label: 'Copy Selection', faicon: 'fa-light fa-copy', showInSubMenu: true },
    align: { action: 'align', label: 'Align', faicon: 'fa-light fa-align-justify', showInSubMenu: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  constructor(opt?: IMbRectangleOptions) {
    super(opt)
    this.id = opt?.id || guid()
    this.locked = opt?.locked || false
    this.originX = 'center'
    this.originY = 'center'
    this.fill = opt?.fill || '#ffffff'
    this.stroke = opt?.stroke || '#000000'
    this.strokeWidth = Number(opt?.strokeWidth) || 1
    this.setLock(this.locked)
    this.stateProperties = this.stateProperties?.concat(['locked'])
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'border':
        this.set('stroke', value.borderColor)
        this.set('strokeWidth', value.strokeWidth)
        break
      case 'backgroundColor':
        this.set('fill', value.backgroundColor)
        break
      case 'locked':
        this.set('locked', value.locked)
        this.setLock(value.locked)
        break
      case 'lockSkewingX':
        this.set('lockSkewingX', value)
        break
      case 'lockSkewingY':
        this.set('lockSkewingY', value)
        break
      case 'lockScalingX':
        this.set('lockScalingX', value)
        break
      case 'lockScalingY':
        this.set('lockScalingY', value)
        break
      default:
        console.warn('Attempting to set unsupported MbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'border':
        result.borderColor = this.stroke
        result.strokeWidth = this.strokeWidth
        break
      case 'backgroundColor':
        result.backgroundColor = this.fill
        break
      case 'locked':
        result.lock = this.locked
        break
      default:
        console.warn('Attempting to get unsupported MbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject() {
    const propsToPluck = [
      'id',
      'type',
      'top',
      'left',
      'angle',
      'width',
      'height',
      'scaleX',
      'scaleY',
      'flipX',
      'flipY',
      'fill',
      'stroke',
      'strokeWidth',
      'locked',
      'templateObject',
    ]
    return pick(this, propsToPluck)
  }

  static fromObject(object: fabric.Object, callback?: Function) {
    return fabric.Object._fromObject(merchConstants.objectTypes.triangle, object, callback) as MbTriangle
  }

  static getEditableProps() {
    return editableProps
  }
}

const f: any = fabric
f.MbTriangle = MbTriangle
