import type { CreateDiscussionModel, DiscussionDetailsModel, EndDiscussionModel, UpdateDiscussionModel } from './model/discussionModel'
import Net from '@/services/net'

export function createDiscussion(catalogCode: number, requestObject: CreateDiscussionModel) {
  return Net.t1.post<DiscussionDetailsModel>(`/catalogs/${catalogCode}/discussions`, requestObject)
}

export function updateDiscussion(catalogCode: number, discussionId: number, requestObject: UpdateDiscussionModel) {
  return Net.t1.put(`/catalogs/${catalogCode}/discussions/${discussionId}`, requestObject)
}

export function addDiscussionComment(catalogCode: number, discussionId: number, comment: string) {
  return Net.t1.post<DiscussionDetailsModel>(`/catalogs/${catalogCode}/discussions/${discussionId}/comments`, { Comment: comment })
}

export function getDiscussionDetails(catalogCode: number, discussionId: number) {
  return Net.t1.get<DiscussionDetailsModel>(`/catalogs/${catalogCode}/discussions/${discussionId}`)
}

export function endDiscussions(catalogCode: number, requestObject: EndDiscussionModel[]) {
  return Net.t1.put(`/catalogs/${catalogCode}/discussions/end`, requestObject)
}
