<template>
  <div class="w-full h-full overflow-x-hidden overflow-y-auto">
    <tx-collapse v-model="activeGroupList" class="mb-4">
      <tx-collapse-item v-for="(group, groupIndex) in refGroupedAttributes" :key="groupIndex" :title="group.label" :value="group.label" class="pb-4">
        <div class="flex flex-wrap w-full h-full">
          <!-- <div v-for="attribute in group.attributes" class="flex-grow flex-shrink" :class="[`basis-[${100 / columns}%] p-2`]"> -->
          <div v-for="attribute in group.attributes" :key="groupIndex + attribute.SystemName" class="p-2 mb-3 last-of-type:mb-2" :style="{ flex: `${100 / columns}%` }">
            <attribute-editor
              v-model="form[attribute.SystemName]"
              v-model:checked="checkedAttributes[attribute.SystemName].checked"
              :articles="[article]"
              :attribute="attribute"
              :form="form"
              :disabled="haveUpdatePrivilege || attribute.ReadOnly || !attribute.Editable || readOnly"
              :required="attribute.IsRequired"
              :show-checkbox="true"
              :show-external-change-management-info="attribute.ExternalChangeManagementURL != null"
              :show-obsolete-master-sizescale-warning="showObsoleteMasterSizescaleWarning"
              :errors="v$[attribute.SystemName].$errors"
              @blur="v$[attribute.SystemName].$touch"
              @check-changed="v$[attribute.SystemName].$touch"
              @change="handleChange(attribute)"
            />
          </div>
        </div>
      </tx-collapse-item>
    </tx-collapse>
    <div class="flex flex-wrap w-full">
      <!-- <div v-for="attribute in  nonGroupedAttributes" class="flex-grow flex-shrink" :class="[`basis-[${100 / columns}%] p-2`]"> -->
      <div v-for="attribute in refNonGroupedAttributes" :key="attribute.SystemName" class="p-2 mb-3 last-of-type:mb-2" :style="{ flex: `${100 / columns}%` }">
        <attribute-editor
          v-model="form[attribute.SystemName]"
          v-model:checked="checkedAttributes[attribute.SystemName].checked"
          :articles="[article]"
          :attribute="attribute"
          :form="form"
          :disabled="haveUpdatePrivilege || attribute.ReadOnly || !attribute.Editable || readOnly"
          :required="attribute.IsRequired"
          :show-checkbox="true"
          :show-external-change-management-info="attribute.ExternalChangeManagementURL != null"
          :show-obsolete-master-sizescale-warning="showObsoleteMasterSizescaleWarning"
          :errors="v$[attribute.SystemName].$errors"
          @blur="v$[attribute.SystemName].$touch"
          @check-changed="v$[attribute.SystemName].$touch"
          @change="handleChange(attribute)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import useAttributeValidator from '../composables/attributeValidator'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import type MyArticle from '@/models/myArticle'
import TxCollapse from '@/shared/components/txCollapse/txCollapse.vue'
import TxCollapseItem from '@/shared/components/txCollapse/txCollapseItem.vue'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import { privileges } from '@/models/constants'
import { useUserStore } from '@/store/userData'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'

interface IProps {
  article: MyArticle
  activeGroups?: Array<string>
  attributes: IMyAttribute[]
  columns: number
  groupedAttributes?: Array<{ label: string, attributes: Array<IMyAttribute> }>
  nonGroupedAttributes?: Array<IMyAttribute>
  readOnly?: boolean
  showObsoleteMasterSizescaleWarning?: boolean
}
const props = withDefaults(defineProps<IProps>(), {
  activeGroups: () => [] as Array<string>,
  attributes: () => [] as Array<IMyAttribute>,
  nonGroupedAttributes: () => ([]) as Array<IMyAttribute>,
  groupedAttributes: () => ([]) as Array<{ label: string, attributes: Array<IMyAttribute> }>,
  columns: 1,
  readOnly: false,
})

const emit = defineEmits<{
  (e: 'formDirtyStateChanged', isFormDirty: boolean): void
  (e: 'changed', form: Record<string, any>): void
}>()

const userStore = useUserStore()

const form = reactive<Record<string, any>>({})
const checkedAttributes = reactive<Record<string, { checked: boolean, isDirty: boolean }>>({})
const activeGroupList = ref(props.activeGroups)
const refGroupedAttributes = ref(props.groupedAttributes)
const refNonGroupedAttributes = ref(props.nonGroupedAttributes)
const refAttributes = ref(props.attributes)

const { updateCriteriaAttributeAllowedValues, updateLookupAttributeValues } = useArticleFormHelper()
const { v$ } = useAttributeValidator(ref([props.article]), props.attributes, form, checkedAttributes)

const haveUpdatePrivilege = computed(() => !userStore.userProfile.isValidPrivilege(privileges.article.update))

// the will consider dirty if any of the checkboxes is selected
const isDirty = computed(() => {
  return Object.values(checkedAttributes).some(attributeOptionsValue => attributeOptionsValue.checked)
})

watchEffect(() => {
  emit('formDirtyStateChanged', isDirty.value)
})

watch(checkedAttributes, () => {
  emitCheckedValues()
})

watch(() => props.attributes, (attributes) => {
  refAttributes.value = attributes
})

watch(() => props.groupedAttributes, (groupedAttributes) => {
  refGroupedAttributes.value = groupedAttributes
})

watch(() => props.nonGroupedAttributes, (nonGroupedAttributes) => {
  refNonGroupedAttributes.value = nonGroupedAttributes
})

initForm()

function handleChange(attribute: IMyAttribute) {
  if (checkedAttributes.hasOwnProperty(attribute.SystemName)) {
    v$.value[attribute.SystemName].$touch()
    checkedAttributes[attribute.SystemName].checked = !(attribute.SystemName === 'MasterSizeScaleId' && attribute.ReadOnly)
    checkedAttributes[attribute.SystemName].isDirty = !(attribute.SystemName === 'MasterSizeScaleId' && attribute.ReadOnly)

    updateCriteriaAttributeAllowedValues(refAttributes, form, form)
    const updatedAttributes = []
    updateLookupAttributeValues(refAttributes, form, form, updatedAttributes)
    updatedAttributes.forEach((lookUpAttribute) => {
      checkedAttributes[lookUpAttribute].checked = true
      checkedAttributes[lookUpAttribute].isDirty = true
    })
    emitCheckedValues()
  }
}

function emitCheckedValues() {
  const checkedValues: Record<string, any> = {}
  props.attributes.forEach((attribute) => {
    if (checkedAttributes[attribute.SystemName].checked && checkedAttributes[attribute.SystemName].isDirty) {
      checkedValues[attribute.SystemName] = form[attribute.SystemName]
    }
  })
  emit('changed', checkedValues)
}

function initForm() {
  for (const key in form) {
    delete form[key]
  }
  for (const key in checkedAttributes) {
    delete checkedAttributes[key]
  }

  props.attributes.forEach((attribute) => {
    let articleAttributeValue = utils.getArticleAttributeValue(attribute, props.article)
    const sourceFields: Record<string, any> = {}
    if (utils.isDefined(attribute.parsedValidationExpression) && attribute.parsedValidationExpression.length) {
      attribute.parsedValidationExpression.forEach((itm) => {
        if (utils.isDefined(itm.SourceField) && props.article.hasOwnProperty(itm.SourceField)) {
          sourceFields[itm.SourceField] = props.article[itm.SourceField]
        }
      })
    }
    if (!utils.isDefined(articleAttributeValue) && attribute.AttributeType === AttributeType.MultiValue && attribute.VettingList && attribute.VettingList.length > 0) {
      articleAttributeValue = []
    }
    form[attribute.SystemName] = articleAttributeValue
    checkedAttributes[attribute.SystemName] = { checked: false, isDirty: false }
  })
  updateCriteriaAttributeAllowedValues(refAttributes, form, form)
  const updatedAttributes = []
  updateLookupAttributeValues(refAttributes, form, form, updatedAttributes)
  updatedAttributes.forEach((lookUpAttribute) => {
    checkedAttributes[lookUpAttribute].checked = true
    checkedAttributes[lookUpAttribute].isDirty = true
  })
}

watch(() => [props.article, props.attributes], initForm)

defineExpose({ v$ })
</script>
