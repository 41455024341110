import type { IBucketsModel, ICreateBucketsModel, IGetBucketValuePayload, IRolesBasedRestrictedBucketAttributes } from './model/buckets'
import Net from '@/services/net'

export function getBucketsValue(catalogCode: number, payload: IGetBucketValuePayload) {
  return Net.t1.post<IBucketsModel[]>(`/catalogs/${catalogCode}/buckets`, payload)
    .then((response) => {
      return response.data
    })
}

export function getRestrictedBucketAttributes(catalogCode: number) {
  return Net.t1.get<IRolesBasedRestrictedBucketAttributes>(`catalogs/${catalogCode}/role/bucketattributes`)
}

export function updateBucketsValue(catalogCode, requestObjects) {
  return Net.t1.put<ICreateBucketsModel>(`/catalogs/${catalogCode}/buckets`, requestObjects)
}
