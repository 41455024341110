import type { fabric } from 'fabric'
import type { Ref, ShallowRef } from 'vue'
import { ref, watch } from 'vue'
import { isArray } from 'lodash-es'
import type MerchSlide from '../services/merchSlide'
import utils from '@/services/utils'
import type Merch from '@/modules/merch/services/merch'
import { imageConstants } from '@/models/constants'
import { useEventListener } from '@/shared/composables/event'
import MbTextBox from '@/modules/merch/services/textBox'
import MbImage from '@/modules/merch/services/image'

export default function useWindowEvents(merch: ShallowRef<Merch | undefined>, activeSlide: Ref<MerchSlide | undefined>) {
  let mb: Merch
  const isFullScreen = ref(false)
  const isArticleOrModelDetailsVisible = ref(false)

  watch(merch, () => {
    if (merch.value) {
      init()
    }
  }, { immediate: true })

  useEventListener(window, 'keydown', handleKeyDown)

  // keep this event since navigator.clipboard have compatibilities issues (read method is not supported in firefox and partially supported by chromium based browsers)
  useEventListener(window, 'paste', handlePaste)

  function init() {
    mb = merch.value!
  }

  function handleKeyDown(event: KeyboardEvent) {
    const disabledKeyEvent = event.target !== document.body || isFullScreen.value
    if (mb && !disabledKeyEvent) {
      switch (event.key) {
        case 'Delete':
        case 'Backspace': {
          const objs = mb.canvas.getActiveObjects() as Array<IMbObject>
          mb.removeObjects(objs, true)
          break
        }
        case 'A':
        case 'a':
          if (event.metaKey || event.ctrlKey) {
            mb.selectAll(mb.templateObjectsSelectable)
          }
          break
        case 'C':
        case 'c':
          if ((event.metaKey || event.ctrlKey) && !isArticleOrModelDetailsVisible.value) {
            mb.copySelectedObjects()
          }
          break
        // case 'V':
        // case 'v':
        //   if(event.metaKey || event.ctrlKey) {
        //     _debounce(() => mb.pasteClipboardObjects(), 0)()
        //   }
        //   break
        // case 'F':
        // case 'f':
        //   if (event.metaKey || event.ctrlKey) {
        //     findReplaceDialogVisible.value = true
        //     event.preventDefault()
        //   }
        //   break
        case 'Z':
        case 'z':
          if (event.metaKey || event.ctrlKey) {
            if (event.shiftKey) {
              mb.redo()
            }
            else {
              mb.undo()
            }
          }
          break
        // case 'PageUp':
        //   mb.bringSelectedObjectsToFront()
        //   break
        // case 'PageDown':
        //   mb.sendSelectedObjectsToBack()
        //   break
        // case 'ArrowUp' /* Up arrow */:
        //   mb.moveObject(event, 'up')
        //   break
        // case 'ArrowDown' /* Down Arrow */:
        //   mb.moveObject(event, 'down')
        //   break
        // case 'ArrowLeft' /* Left Arrow */:
        //   mb.moveObject(event, 'left')
        //   break
        // case 'ArrowRight' /* Right Arrow */:
        //   mb.moveObject(event, 'right')
        //   break
      }
    }
  }

  // TODO there is issue when copying and pasting mutiple object its getting pasted outside canvas bounderies
  async function handlePaste(event: Event | ClipboardEvent) {
    const disabledPasting = event.target !== document.body || isFullScreen.value
    if (merch.value && activeSlide.value && !disabledPasting && event instanceof ClipboardEvent && event.clipboardData) {
      let newObjs: fabric.Object[] = []
      for (const index in event.clipboardData.items) {
        const item: DataTransferItem = event.clipboardData.items[index]
        if (item.kind === 'file' && imageConstants.validImageFormats.has(item.type)) {
          const file = item.getAsFile()
          if (file && file.size <= imageConstants.maxImageSize) {
            function getMbImage(file: File): Promise<MbImage> {
              return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = (e) => {
                  const image = new Image()
                  image.src = (e.target!.result as string)
                  image.onload = function () {
                    const obj = new MbImage(image)
                    obj.left = (((obj.width || 0) * (obj.scaleX || 0)) / 2) + 10
                    obj.top = (((obj.height || 0) * (obj.scaleY || 0)) / 2) + 10
                    resolve(obj)
                  }
                }
                reader.readAsDataURL(file)
              })
            }
            newObjs.push(await getMbImage(file))
          }
        }
        else if (item.kind === 'string') {
          function getData(dataItem: DataTransferItem) {
            return new Promise<string>((resolve) => {
              dataItem.getAsString((data) => {
                resolve(data)
              })
            })
          }
          const data = await getData(item)
          const copiedObjects = utils.tryParse(data)
          if (copiedObjects && Array.isArray(copiedObjects) && copiedObjects.length > 0) {
            const newObjects: any[] = []
            copiedObjects.forEach((objData) => {
              const copiedObject = objData.object
              copiedObject.left = 10 + objData.relativePosition.left
              copiedObject.top = 10 + objData.relativePosition.top
              const resetId = function (o) {
                if (o.hasOwnProperty('id')) {
                  delete o.id
                }
                if (o.hasOwnProperty('objects') && isArray(o.objects)) {
                  o.objects.forEach(object => resetId(object))
                }
              }
              resetId(copiedObject)
              newObjects.push(copiedObject)
            })

            if (newObjects.length) {
              newObjs = await activeSlide.value?.createObjects(newObjects, mb.showFavorites.value)
            }
          }
          else if (utils.isDefined(data) && utils.isValidStringValue(data)) {
            newObjs.push(new MbTextBox(data))
          }
        }
      }
      if (newObjs.length) {
        merch.value.addObjects(newObjs, true, true)
      }
    }
  }

  return {
    isFullScreen,
    isArticleOrModelDetailsVisible,
  }
}
