<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <!-- HEADER -->
    <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="t('overwriteColorCode.title')" :subtitle="t('overwriteColorCode.header')" :show-header="showHeader" />
    <!-- BODY -->
    <div class="px-10 mt-4 alerts">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
    </div>
    <!-- FORM -->
    <div class="relative flex-auto mt-4 body">
      <!-- LOADER -->
      <div v-show="loading">
        <loader style="z-index: 1801;" />
        <div class="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-transparent opacity-50" />
      </div>
      <div class="w-full px-10">
        <attribute-editor
          v-model="formModel[colorCode.SystemName]"
          :articles="[contextArticle]"
          :form="formModel"
          :attribute="colorCode"
          :required="colorCode.IsRequired"
          :errors="v$[colorCode.SystemName]?.$errors"
          @blur="v$[colorCode.SystemName]?.$touch"
          @change="v$[colorCode.SystemName]?.$touch"
        />
      </div>
    </div>
    <!-- FOOTER -->
    <tx-form-footer
      class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-text="t('general.update')" :primary-disabled="v$.$invalid" :secondary-disabled="loading"
      @primary-click="onUpdate" @secondary-click="onCancel"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onMounted, reactive, ref } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import useArticleLocalDataUpdater from '../composables/articleLocalDataUpdater'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import Loader from '@/shared/components/Loader.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import type MyArticle from '@/models/myArticle'
import { assignArticleColors } from '@/api/t1/article'
import { appConstants } from '@/models/constants'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'
import utils from '@/services/utils'

interface IProps {
  showColorway?: boolean
  showHeader?: boolean
  contextArticle: MyArticle
}

const props = withDefaults(defineProps<IProps>(), { showColorway: false, showHeader: true })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[]): void
  (e: 'back'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { refreshLocalArticlesData } = useArticleLocalDataUpdater()
const { errorMessage, hasError } = useErrorMessage()

const loading = ref<boolean>(false)
const formModel = reactive<Record<string, any>>({})
const colorCode: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
  DisplayName: t('overwriteColorCode.colorCode'),
  IsRequired: true,
  SystemName: 'ColorCode',
  ReadOnly: false,
  // eslint-disable-next-line regexp/no-dupe-disjunctions
  ValidationExpression: /^(0*[1-9]\d{0,2}|100|999)$/,
})

const rules = computed(() => {
  const result: Record<string, any> = {}
  result[colorCode.SystemName] = {}
  if (colorCode.IsRequired) {
    result[colorCode.SystemName].required = helpers.withMessage(t('validations.required', { property: colorCode.DisplayName }), required)
  }
  const pattern = helpers.regex(new RegExp(colorCode.ValidationExpression))
  result[colorCode.SystemName].pattern = helpers.withMessage(t('overwriteColorCode.invalidPattern'), pattern)
  return result
})
const v$ = useVuelidate(rules, formModel)

function init() {
  formModel.ColorCode = props.contextArticle.ColorCode ? props.contextArticle.ColorCode : props.contextArticle.ArticleNumber ? (props.contextArticle.ArticleNumber.substring(props.contextArticle.ArticleNumber.length - 3)).toString() : ''
}

function onCancel() {
  emit('cancel')
}

async function onUpdate() {
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
    return
  }
  const article = props.contextArticle
  loading.value = true
  const requestObj: Record<string, any> = {}
  let isArticleColorCode = false
  requestObj.ColorCode = formModel.ColorCode
  await assignArticleColors(userStore.activeCatalog!.CatalogCode, article.Id, requestObj).then(() => {
    isArticleColorCode = true
  }).catch((error) => {
    errorMessage.value = t('general.unexpectedError')
    if (utils.isDefined(error.response) && utils.isDefined(error.response.data) && Array.isArray(error.response.data) && error.response.data.length) {
      errorMessage.value = error.response.data.map(e => e.ErrorMessage).join(', ')
    }
    loading.value = false
  })
  if (isArticleColorCode) {
    try {
      await refreshLocalArticlesData(false, article.ModelId, article.Id)
      emit('updated', [props.contextArticle])
    }
    catch (error) {
      errorMessage.value = t('general.unexpectedError')
    }
    finally {
      loading.value = false
    }
  }
}

onMounted(() => {
  init()
})
</script>
