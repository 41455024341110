import type { EventBusListener, UseEventBusReturn } from '@vueuse/core'
import { useEventBus } from '@vueuse/core'

type AppEventName = 'catalogDataUpdated' | 'browseByChanged' | 'requestDataUpdated'

type AppEventType<T> = T extends 'catalogDataUpdated' ? IDataUpdatedEvent :
  T extends 'browseByChanged' ? IBrowseByChangedEvent :
    T extends 'requestDataUpdated' ? IRequestsUpdatedEvent : never

export default function useEventsBus() {
  const eventBus: { [event in AppEventName]: UseEventBusReturn<AppEventType<event>, any> } = {
    catalogDataUpdated: useEventBus<IDataUpdatedEvent>('catalogDataUpdated'),
    browseByChanged: useEventBus<IBrowseByChangedEvent>('browseByChanged'),
    requestDataUpdated: useEventBus<IRequestsUpdatedEvent>('requestDataUpdated'),
  }

  function onAppEvent<T extends AppEventName>(event: T, f: EventBusListener<AppEventType<T>>) {
    eventBus[event].on(f as EventBusListener<AppEventType<AppEventName>>)
  }

  function offAppEvent<T extends AppEventName>(event: T, f: EventBusListener<AppEventType<T>>) {
    eventBus[event].off(f as EventBusListener<AppEventType<AppEventName>>)
  }

  function emitter<T extends AppEventName>(event: T, payload: AppEventType<T>) {
    eventBus[event].emit(payload)
  }

  return {
    onAppEvent,
    offAppEvent,
    emitter,
  }
}
