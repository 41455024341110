<template>
  <div class="flex flex-col grow overflow-hidden">
    <div class="border-b bg-gray-50 shrink grow-0">
      <tx-input
        v-model="filter" rounded faicon="fa-light fa-magnifying-glass" clearable :placeholder="t('general.typeToFilter')"
        class="my-4 mx-2"
      />
    </div>
    <div class="overflow-y-auto">
      <tx-tree ref="tree" class="tree" :data="getCurrentTree" :filter="filter" @node-click="onTreeItemClick" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxTree from '@/shared/components/TxTree.vue'
import { useUserStore } from '@/store/userData'

const emit = defineEmits<{
  (e: 'treeItemClick', evt?: ITreeNode)
}>()

const { t } = useI18n()
const userStore = useUserStore()

const filter = ref('')
const tree = ref<InstanceType<typeof TxTree>>()

const getCurrentTree = computed(() => userStore.currentTree || [])

async function onTreeItemClick(e?: ITreeNode) {
  emit('treeItemClick', e)
}

defineExpose({
  tree,
})
</script>
