<template>
  <div
    class="flex items-center"
    :class="[{ 'cursor-pointer': enabled, 'opacity-40 cursor-not-allowed': !enabled, 'flex-row': type === 'inline', 'hover:text-primary-500': type !== 'static', 'active:text-primary-500': type !== 'static' }, textColor]"
    :style="{ width: `${width}px`, height: `${height}px` }" @click="onClick"
  >
    <template v-if="!rightIcon">
      <div
        v-if="icon" :style="{ width: `${iconSize}px`, height: `${iconSize}px`, maxWidth: iconSize, maxHeight: iconSize }"
        v-html="icon"
      />
      <font-awesome-icon
        v-else-if="faicon" :icon="faicon"
        :style="{ width: `${iconSize - faiconSizeMargin}px`, height: `${iconSize - faiconSizeMargin}px`, maxWidth: (iconSize - faiconSizeMargin), maxHeight: (iconSize - faiconSizeMargin) }"
      />
    </template>
    <span v-if="label && label.length > 0" class="my-auto text-sm" :class="{ 'font-semibold': labelBold }" v-text="label" />
    <template v-if="rightIcon">
      <div
        v-if="icon" :style="{ width: `${iconSize}px`, height: `${iconSize}px`, maxWidth: iconSize, maxHeight: iconSize }"
        v-html="icon"
      />
      <font-awesome-icon
        v-else-if="faicon" :icon="faicon"
        :style="{ width: `${iconSize - faiconSizeMargin}px`, height: `${iconSize - faiconSizeMargin}px`, maxWidth: (iconSize - faiconSizeMargin), maxHeight: (iconSize - faiconSizeMargin) }"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  icon: { type: String, required: false },
  faicon: { type: String, required: false },
  enabled: { type: Boolean, default: true },
  width: { type: Number, default: 24 },
  iconSize: { type: Number, default: 24 },
  faiconSizeMargin: { type: Number, default: 6 },
  height: { type: Number, default: 24 },
  label: { type: String, default: '' },
  type: { default: 'default', validator: (t: string) => ['default', 'inline', 'nav', 'static'].includes(t) },
  active: { type: Boolean, default: false },
  rightIcon: { type: Boolean, required: false },
  labelBold: { type: Boolean, required: false },
})

const emit = defineEmits(['click'])

function onClick(ev: any) {
  if (props.enabled) {
    emit('click', ev)
  }
}

const textColor = computed(() => {
  if (props.active) {
    return 'text-primary-500'
  }
  else if (props.type === 'nav') {
    return 'text-white'
  }
  else {
    return 'text-dark'
  }
})
</script>
