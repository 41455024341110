<template>
  <div class="flex flex-row-reverse items-center flex-1 overflow-x-auto overflow-y-hidden flex-nowrap">
    <div
      v-for="resource in resources" :key="resource.Id" v-tooltip="resource.ResourceName"
      class="m-0.5 w-6 h-6 cursor-pointer" @click="onResourceClick(resource)"
    >
      <resource-item type="icon" :resource="resource" />
    </div>
  </div>
  <!-- TODO: Need to work on resource preview -->
</template>

<script setup lang="ts">
import ResourceItem from './ResourceItem.vue'
import type Resource from '@/models/resource'

interface IProps {
  resources?: Resource[]
}
withDefaults(defineProps<IProps>(), { resources: () => [] as Resource[] })

function onResourceClick(resource: Resource) {
  window.open(resource.ResourceUrl)
  // var link = document.createElement("a")
  // const fileExtension = resource.ResourceUrl.split('.').pop()
  // link.download = `${resource.ResourceName}.${fileExtension}`
  // link.target = "_blank";
  // link.href = resource.ResourceUrl
  // document.body.appendChild(link)
  // link.click()
  // document.body.removeChild(link)
  // delete link
}
</script>
