import type { Subscription } from 'dexie'
import { liveQuery } from 'dexie'
import { isArray } from 'lodash-es'
import { useRoute, useRouter } from 'vue-router'
import { onBeforeUnmount, ref } from 'vue'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import type MyArticle from '@/models/myArticle'
import utils from '@/services/utils'

export default function useArticleDetails(moduleName = 'Catalog') {
  const userStore = useUserStore()
  const route = useRoute()
  const router = useRouter()

  let clickedArticleSubscription: Subscription | null = null
  const articleDetailsOpen = ref(false)
  const clickedArticle = ref<MyArticle | null>(null)
  let reLoad = false

  function onArticleUpdated(key: string, value: any) {
    if (clickedArticle.value && clickedArticle.value.hasOwnProperty(key)) {
      clickedArticle.value[key] = value
    }
  }
  function onChangeArticle(art: MyArticle) {
    if (art) {
      clickedArticle.value = art
    }
  }

  function onArticleClick(art: MyArticle, reload = false) {
    reLoad = reload
    if (reload) {
      articleDetailsOpen.value = false
    }

    const newParams: { articleId: string, objectId?: string, linkedCatalogCode?: number, reload?: number } = { articleId: art.Id.toString(), objectId: undefined, linkedCatalogCode: undefined, reload: undefined }
    if (art._IsRequestArticle) {
      newParams.objectId = art.CatalogArticleId
    }
    if (userStore.activeCatalog && art.CatalogCode !== userStore.activeCatalog.CatalogCode) {
      newParams.linkedCatalogCode = art.CatalogCode
    }
    const mergedParams = { ...router.currentRoute.value.params, ...newParams }
    router.push({ name: `${moduleName}ArticleDetails`, params: mergedParams, query: { ...router.currentRoute.value.query } })
  }

  function openArticleDetails() {
    if (userStore.activeCatalog && route.params.articleId) {
      // TODO: Add loading
      let catalogDetails = userStore.activeCatalog
      const articleId = Number(route.params.articleId)
      const linkedCatalogCode = Number(route.params.linkedCatalogCode)
      if (linkedCatalogCode && userStore.linkedCatalogDetails[linkedCatalogCode]) {
        catalogDetails = userStore.linkedCatalogDetails[linkedCatalogCode]
      }
      if (clickedArticleSubscription) {
        clickedArticleSubscription.unsubscribe()
        clickedArticleSubscription = null
      }
      const objectId = route.params.objectId?.toString()
      if (utils.isValidStringValue(objectId)) {
        if (catalogDetails.CatalogCode === userStore.activeCatalog.CatalogCode) {
          const obs = liveQuery(async () => await appConfig.DB!.getRequestArticleById(catalogDetails, articleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet))
          clickedArticleSubscription = obs.subscribe({
            next: async (requestArticle) => {
              if (requestArticle) {
                clickedArticle.value = requestArticle
                articleDetailsOpen.value = true
              }
            },
            error: err => console.error('Error while retrieving requests', err),
          })
        }
        else {
          console.warn('this should never happen')
        }
      }
      else {
        const obs = liveQuery(async () => await appConfig.DB!.getMyArticles(catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, [articleId], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, false, userStore.currentCustomer, userStore.currentCustomerSegmentations, true))
        clickedArticleSubscription = obs.subscribe({
          next: (res) => {
            if (res && isArray(res) && res[0]) {
              articleDetailsOpen.value = true
              clickedArticle.value = res[0]
            }
          },
          error: err => console.error('Error while retrieving articles', err),
        })
      }
    }
  }

  function closeArticleDetails() {
    articleDetailsOpen.value = false
    clickedArticle.value = null
    if (clickedArticleSubscription) {
      clickedArticleSubscription.unsubscribe()
      clickedArticleSubscription = null
    }
    if (route.name !== moduleName && !reLoad) {
      const newParams = { ...router.currentRoute.value.params }
      delete newParams.articleId
      router.push({ name: moduleName, params: newParams, query: { ...router.currentRoute.value.query } })
    }
    if (reLoad) {
      reLoad = false
    }
  }

  onBeforeUnmount(() => {
    if (clickedArticleSubscription) {
      clickedArticleSubscription.unsubscribe()
      clickedArticleSubscription = null
    }
  })

  return {
    articleDetailsOpen,
    clickedArticle,
    onArticleUpdated,
    onArticleClick,
    openArticleDetails,
    closeArticleDetails,
    onChangeArticle,
  }
}
