<template>
  <tx-dialog
    v-model="dialogVisible" :title="t('dataTable.columnChooser.title')" width="500px"
    @after-leave="onAfterLeave"
  >
    <div v-if="itemsRendered" class="overflow-hidden">
      <tx-list-transfer
        v-model:selected-items="selectedCols" class="max-h-96" :available-items-label="t('dataTable.columnChooser.available')"
        :selected-items-label="t('dataTable.columnChooser.selected')" :items="cols" item-key-prop="property" item-display-prop="title"
      />
      <div class="flex justify-between mt-4">
        <tx-button type="cancel" :text="t('dataTable.columnChooser.addAll')" @click="addAll" />
        <tx-button type="cancel" :text="t('dataTable.columnChooser.removeAll')" @click="removeAll" />
      </div>
    </div>

    <template #footer>
      <div class="flex items-center justify-center px-6 py-4 space-x-3 sm:justify-end bg-gray-50">
        <tx-button type="confirm" :text="t('general.save')" width="100px" @click="doOk()" />
        <tx-button type="cancel" :text="t('general.cancel')" width="100px" @click="doCancel()" />
      </div>
    </template>
  </tx-dialog>
</template>

<script setup lang="ts">
import { nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import TxListTransfer from '../txListTransfer'
import TxDialog from '../TxDialog.vue'
import type { ITxDataTableColumn } from './TxDataTable.types'
import TxButton from '@/shared/components/TxButton.vue'

const emits = defineEmits<{
  (e: 'ok', selectedColumns: any[]): void
  (e: 'cancel'): void
}>()

defineExpose({ show })

const { t } = useI18n()
const dialogVisible = ref(false)
const itemsRendered = ref(false)

const selectedCols = ref<any[]>([])
const cols = ref<ITxDataTableColumn[]>([])

async function show(columns: ITxDataTableColumn[], selectedColumns: any[]) {
  cols.value = columns
  selectedCols.value = [...selectedColumns]

  itemsRendered.value = true
  await nextTick()

  dialogVisible.value = true
}

function doOk() {
  dialogVisible.value = false
  emits('ok', selectedCols.value)
  itemsRendered.value = false
}

function doCancel() {
  dialogVisible.value = false
  emits('cancel')
}

function onAfterLeave() {
  if (!dialogVisible.value) {
    itemsRendered.value = false
  }
}

function addAll() {
  selectedCols.value = [...cols.value]
}

function removeAll() {
  selectedCols.value = []
}
</script>
