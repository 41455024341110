import Net from '@/services/net'
import type { SellerLookupDefinitionModel } from '@/api/t1/model/sellerModel'

export function getSellerLookup(accountId: number, attributeGroupId: number, catalogAttributes) {
  const lookupAttributeDefinitions: Record<string, SellerLookupDefinitionModel> = {}
  return Net.t1.get(`/sellers/${accountId}/lookups`)
    .then((response) => {
      const validDestinationDefinitions = {}
      response.data.forEach((data) => {
        if (data.AttributeGroupId === attributeGroupId) {
          const sourceAttributes = data.SourceAttributes.split('|')
          const destinationAttributes = data.DestinationAttributes.split('|')
          if (!sourceAttributes.find(attribute => !catalogAttributes[attribute]) && !destinationAttributes.find(attribute => !catalogAttributes[attribute])) {
            destinationAttributes.forEach((attribute) => {
              if (!validDestinationDefinitions[attribute]) {
                validDestinationDefinitions[attribute] = data.Id
              }
            })
          }
        }
      })
      const definitionIds = new Set(Object.values(validDestinationDefinitions))
      const promises: Promise<void | number>[] = []
      const lookupDetails = {}
      definitionIds.forEach((id) => {
        promises.push(Net.t1.get(`/sellers/${accountId}/lookups/${id}`).then((detailsResponse) => {
          const response = detailsResponse.data
          response.SourceAttributes = response.SourceAttributes.split('|')
          response.DestinationAttributes = response.DestinationAttributes.split('|')
          lookupDetails[detailsResponse.data.Id] = response
        }),
        )
      })
      return Promise.all(promises).then(() => {
        Object.keys(validDestinationDefinitions).forEach((destinationAttribute) => {
          if (lookupDetails[validDestinationDefinitions[destinationAttribute]]) {
            lookupAttributeDefinitions[destinationAttribute] = lookupDetails[validDestinationDefinitions[destinationAttribute]]
          }
        })
        return lookupAttributeDefinitions
      })
    }).catch(e => console.warn('Could not load Lookup Definitions', e))
}
