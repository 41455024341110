import { v4 as guid } from 'uuid'
import type { ExportRequest, GetStatusModel } from './model/generateModel'
import Net from '@/services/net'
import type { PDFJsonSchema } from '@/api/fileServer/model/generateModel'

export function getStatus(id: string, type: string) {
  if (type === 'Articles' || type === 'editSizes' || type === 'assortArticles' || type === 'updateViaImport' || type === 'updateViaImportModel' || type === 'importByLicensee' || type === 'setSizesByLicenseeModelNumber' || type === 'setPricesByLicenseeModelNumber') {
    return Net.t1.post(`/backgroundjob/${id}`, { JobTypeId: 1 })
  }
  else {
    return Net.fileServer.get<GetStatusModel>(`/generate?id=${id}`)
  }
}
export function getArticlesJobDetails(id: string) {
  return Net.t1.get(`/backgroundjobs/json/${id}`)
}
export function generate(requestObject: ExportRequest | PDFJsonSchema, outputFormat: 'ppt' | 'pdf') {
  const json = JSON.stringify(requestObject)
  const blob = new Blob([json], { type: 'application/json' })
  const formData = new FormData()
  const fileName = `${guid()}.json`
  formData.append('file', blob, fileName)
  formData.append('outputFormat', outputFormat)
  return Net.fileServer.post<{ Id: string }>(`/generate`, formData)
}
