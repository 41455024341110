import { useUserStore } from '@/store/userData'

export function useConfirmDialog() {
  const userStore = useUserStore()

  if (!userStore.confirmDialogEl) {
    throw new Error('No confirm dialog provided!')
  }

  return userStore.confirmDialogEl.show
}
