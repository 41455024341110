import { fabric } from 'fabric'
import { isArray } from 'lodash-es'
import type { Point } from 'fabric/fabric-impl'
import type WbConnector from './connector'

/**
 * A utility for canvas
 */
export default class Utility {
  /**
   * @description find closest corner from provided corners to given target object with respect to current mouse pointer
   * @param event
   * @param canvas
   * @param target target object
   * @param criteriaProp a property which is use as filter criteria, when passed it will check if the target object
   * contains truthy value for that property, if not it will not consider the target object and it will return the current mouse pointer
   * @param corners list of target object corders default to mid corners
   * @returns { IObjectCornerCoords } passed object's closest corner or current mouse pointer
   */
  static getClosestObjectCornerToCurrentPointer(event: MouseEvent | Event, canvas: fabric.Canvas, target: fabric.Object | undefined, criteriaProp?: string | null | undefined, corners: Array<FabricObjectCorners> = ['mt', 'mr', 'mb', 'ml']): IObjectCornerCoords {
    const currentMousePoint = canvas.getPointer(event, false)
    let closestConnecter: IObjectCornerCoords = { x: currentMousePoint.x, y: currentMousePoint.y, corner: null }
    let cornerPoints: Array<IObjectCornerCoords> = []
    let closestDistance
    // TODO: criteriaProp can be later replace with evaluate function
    if (target && (!criteriaProp || target[criteriaProp])) {
      cornerPoints = Object.entries(Utility.getAbsoluteCoords(target)).reduce((acu: Array<IObjectCornerCoords>, cur) => {
        if (corners?.includes(cur[0] as FabricObjectCorners)) {
          acu.push({ x: cur[1].x, y: cur[1].y, corner: cur[0] } as IObjectCornerCoords)
        }
        return acu
      }, [])
      for (let i = 0; i < cornerPoints.length; i++) {
        const cornerPoint = cornerPoints[i]
        const currentDistance = Math.hypot(cornerPoint.x - currentMousePoint.x, cornerPoint.y - currentMousePoint.y)
        if (closestDistance == null || closestDistance > currentDistance) {
          closestDistance = currentDistance
          closestConnecter = cornerPoint
        }
      }
    }
    return closestConnecter
  }

  /**
   * @description a method that return first closest coordinates of a canvas object's corner that its distance to mouse pointer is less than distance argument
   * if target is undefined it will return mouse coordinate
   * @param {MouseEvent | Event} event
   * @param {fabric.Canvas} canvas
   * @param {fabric.Object} target
   * @param {string | undefined} criteriaProp a property which is use as filter criteria, when passed it will check if the target object
   * contains truthy value for that property, if not it will not consider the target object and it will return the current mouse pointer
   * @param {Array<FabricObjectCorners>} corners
   * @param {number} distance minimum distance for a coord to be consider
   * @returns {IObjectCornerCoords | null} coordinate of current mouse position if target is null or any corner of a fabric object
   */
  static getClosestObjectCornerToCurrentPointerByDistance(event: MouseEvent | Event, canvas: fabric.Canvas, target: fabric.Object | undefined, criteriaProp?: string | null | undefined, corners: Array<FabricObjectCorners> = ['mt', 'mr', 'mb', 'ml'], distance = 15): IObjectCornerCoords | null {
    const currentMousePoint = canvas.getPointer(event, false)
    let closestConnecter: IObjectCornerCoords | null = null // { x: currentMousePoint.x, y: currentMousePoint.y, corner: null }
    let cornerCoords: Array<IObjectCornerCoords> = []
    if (target && (!criteriaProp || target[criteriaProp])) {
      cornerCoords = Object.entries(Utility.getAbsoluteCoords(target)).reduce((acu: Array<IObjectCornerCoords>, cur) => {
        if (corners.includes(cur[0] as FabricObjectCorners)) {
          acu.push({ x: cur[1].x, y: cur[1].y, corner: cur[0] as FabricObjectCorners })
        }
        return acu
      }, [])
      for (let i = 0; i < cornerCoords.length; i++) {
        const cornerCoord = cornerCoords[i]
        if (Math.hypot(cornerCoord.x - currentMousePoint.x, cornerCoord.y - currentMousePoint.y) < distance) {
          closestConnecter = cornerCoord
          break
        }
      }
    }
    return closestConnecter
  }

  static getAbsoluteCoords(target: fabric.Object): Record<FabricObjectCorners, IPoint> {
    let absoluteCoord: Record<FabricObjectCorners, IPoint> = {} as Record<FabricObjectCorners, IPoint>
    const defaultPoint: IPoint = { x: 0, y: 0 }
    const targetACoords = target.aCoords || {
      tl: defaultPoint,
      tr: defaultPoint,
      bl: defaultPoint,
      br: defaultPoint,
    }
    absoluteCoord = Object.assign(absoluteCoord, target.aCoords)
    absoluteCoord.mt = { x: (targetACoords.tl.x + targetACoords.tr.x) / 2, y: targetACoords.tl.y }
    absoluteCoord.mr = { x: targetACoords.tr.x, y: (targetACoords.tr.y + targetACoords.br.y) / 2 }
    absoluteCoord.mb = { x: (targetACoords.bl.x + targetACoords.br.x) / 2, y: targetACoords.bl.y }
    absoluteCoord.ml = { x: targetACoords.tl.x, y: (targetACoords.tl.y + targetACoords.bl.y) / 2 }
    return absoluteCoord
  }

  static drawArc(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object, x: number, y: number, r: number, start: number, end: number, fillStyle: string, strokeStyle: string) {
    ctx.save()
    ctx.translate(left, top)
    ctx.beginPath()
    ctx.arc(x, y, r, start, end)
    ctx.fillStyle = fillStyle
    ctx.strokeStyle = strokeStyle
    ctx.fill()
    ctx.stroke()
    ctx.restore()
  }

  static addWbConnectorsConnectedObjectListeners(canvas: fabric.Canvas, connector: InstanceType<typeof WbConnector>, startObject?: IWbObject, endObject?: IWbObject) {
    const startObjectMovingListener = function () {
      if (connector.startObjectId === startObject?.id) { // safe guard
        connector.setProp('startPoint', fabric.util.transformPoint(connector.rStartPoint as Point, startObject.calcTransformMatrix()))
        connector.setProp('c1', fabric.util.transformPoint(connector.rC1 as Point, startObject.calcTransformMatrix()))
      }
    }

    const startObjectModifiedListener = function () {
      // handle skew and scale on modified (we can add condition base on action property to update based on specific event and to skip moving or any other update that is not a transform)
      connector.setProp('startPoint', fabric.util.transformPoint(connector.rStartPoint as Point, startObject!.calcTransformMatrix()))
      connector.setProp('c1', fabric.util.transformPoint(connector.rC1 as Point, startObject!.calcTransformMatrix()))
    }

    const endObjectMovingListener = function () {
      if (connector.endObjectId === endObject?.id) { // safe guard
        connector.setProp('endPoint', fabric.util.transformPoint(connector.rEndPoint as Point, endObject.calcTransformMatrix()))
        connector.setProp('c2', fabric.util.transformPoint(connector.rC2 as Point, endObject.calcTransformMatrix()))
      }
    }

    const endObjectModifiedListener = function () {
      // handle skew and scale on modified (we can add condition base on action property to update based on specific event and to skip moving or any other update that is not a transform)
      connector.setProp('endPoint', fabric.util.transformPoint(connector.rEndPoint as Point, endObject!.calcTransformMatrix()))
      connector.setProp('c2', fabric.util.transformPoint(connector.rC2 as Point, endObject!.calcTransformMatrix()))
    }

    const startObjectRemovedListener = function () {
      canvas.remove(connector) // TODO: will connector clear the object instance? I think the object instance need to be disposed as well
      const endObject = (canvas.getObjects() as Array<IWbObject>).filter(object => object.id === connector.endObjectId)[0]
      if (endObject) {
        endObject.off('moving', endObjectMovingListener)
        endObject.off('modified', endObjectModifiedListener)
        // eslint-disable-next-line ts/no-use-before-define
        endObject.off('moving', endObjectRemovedListener)
      }
      // connector = null
    }

    const endObjectRemovedListener = function () {
      canvas.remove(connector) // TODO: will connector clear the object instance? I think the object instance need to be disposed as well
      const startObject = (canvas.getObjects() as Array<IWbObject>).filter(object => object.id === connector.startObjectId)[0]
      if (startObject) {
        startObject.off('moving', startObjectMovingListener)
        startObject.off('modified', startObjectModifiedListener)
        startObject.off('moving', startObjectRemovedListener)
      }
      // connector = null
    }

    if (startObject) {
      startObject.on('moving', startObjectMovingListener)
      startObject.on('modified', startObjectModifiedListener)
      startObject.on('removed', startObjectRemovedListener)
    }

    if (endObject) {
      endObject.on('moving', endObjectMovingListener)
      endObject.on('modified', endObjectModifiedListener)
      endObject.on('removed', endObjectRemovedListener)
    }
  }

  /**
   * @description a canvas utility that return similar objects
   * @param canvas
   * @param targetedObjects a fabric object or list of fabric objects
   * @return {Array<fabric.Object>}
   */
  static getSimilarObjects(canvas: fabric.Canvas, targetedObjects: Array<fabric.Object> | fabric.Object) {
    const canvasObjects = canvas.getObjects()
    if (!canvasObjects.length || !targetedObjects) {
      return []
    }

    if (!isArray(targetedObjects)) {
      targetedObjects = [targetedObjects]
    }

    const matchingObjects = canvasObjects.filter(canvasObject => (targetedObjects as Array<fabric.Object>).find(targetedObject => targetedObject.type === canvasObject.type))
    if (matchingObjects.length > 0) {
      return matchingObjects
    }
    return []
  }
}
