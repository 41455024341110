import { v4 as guid } from 'uuid'
import { fabric } from 'fabric'
import { getDiscussionDetails } from '@/api/t1/discussion'
import type UserProfile from '@/models/userProfile'
import { whiteboardConstants } from '@/models/constants'
import { useUserStore } from '@/store/userData'

interface IWbDiscussionIconOptions extends fabric.IPathOptions {
  id?: string
  discussionId?: number
  title?: string
  resolved?: boolean
  comments?: IDiscussionComment[]
  preventUnlock?: boolean
}
export default class WbDiscussionIcon extends fabric.Path implements IWbObject {
  public id: string
  public discussionId?: number
  public title?: string
  public resolved: boolean
  public comments: IDiscussionComment[]
  public type = whiteboardConstants.objectTypes.discussion
  public lock: boolean
  public connectable: boolean
  public editableProps: Record<string, IWbObjectProp> = {}
  public actions: Record<string, IWObjectActions> = {}
  public preventUnlock: boolean

  constructor(opt?: IWbDiscussionIconOptions) {
    super('M15.5 4a6.5 6.5 0 1 1 0 13H13l-3.073 3.073a.25.25 0 0 1-.427-.177V17a6.5 6.5 0 1 1 0-13h6zm0 1h-6a5.5 5.5 0 0 0-.221 10.996L9.5 16h1v2.085L12.586 16H15.5a5.5 5.5 0 0 0 .221-10.996L15.5 5zm-6 4.5a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z', opt)
    this.id = opt?.id || guid()
    this.discussionId = opt?.discussionId
    this.title = opt?.title
    this.resolved = opt?.resolved || false
    this.comments = opt?.comments || []
    this.fill = opt?.fill || 'red'
    this.opacity = opt?.opacity || 1
    this.hasControls = opt?.hasControls || false
    this.lock = false
    this.connectable = true
    this.preventUnlock = opt?.preventUnlock || false
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'title':
        this.set('title', value.title)
        break
      case 'resolved':
        this.set('resolved', value.resolved)
        break
      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'title':
        result.title = this.title
        break
      case 'resolved':
        result.resolved = this.resolved
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  addComment(content: string, mentioned: IMentionItem[], userProfile: UserProfile) {
    this.comments.push({
      id: guid(),
      content,
      date: new Date(),
      userId: userProfile.Id,
      username: userProfile.UserName,
      firstName: userProfile.FirstName,
      lastName: userProfile.LastName,
      mentioned: mentioned.map(x => ({ value: x.value, type: x.type })),
    })
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  migrateFromT1(catalogCode: number, discussionId: number) {
    return getDiscussionDetails(catalogCode, discussionId)
      .then((resp) => {
        if (resp.data) {
          this.title = resp.data.Title
          this.resolved = !!resp.data.Ended
          this.comments = []
          if (resp.data.Comments.length) {
            for (const comment of resp.data.Comments) {
              this.comments.push({
                id: comment.Id.toString(),
                content: comment.Comment,
                date: comment.CreatedDate,
                userId: comment.CreatedBy,
                username: comment.CreatedByUsername,
                firstName: comment.CreatedByFirstname,
                lastName: comment.CreatedByLastname,
                mentioned: [],
              })
            }
          }
          this.discussionId = undefined
          this.dirty = true
          this.canvas?.requestRenderAll()
          this.canvas?.fire('object:modified', { target: this })
        }
      })
      .catch(e => console.error(e))
  }

  setLockObject(locked: boolean) {
    this.set('lockMovementX', locked)
    this.set('lockMovementY', locked)
    this.set('lockRotation', locked)
    this.set('lockScalingFlip', locked)
    this.set('lockScalingX', locked)
    this.set('lockScalingY', locked)
    this.set('hasControls', !locked)
  }

  override toObject(propertiesToInclude?: string[]) {
    const props = propertiesToInclude || []
    props.push('id', 'title', 'resolved', 'comments', 'preventUnlock')
    return super.toObject(props)
  }

  static fromObject(opt: IWbDiscussionIconOptions, callback?: Function) {
    const object = new WbDiscussionIcon(opt)
    if (object.discussionId) {
      const userStore = useUserStore()
      if (userStore.activeCatalog) {
        object.migrateFromT1(userStore.activeCatalog.CatalogCode, object.discussionId)
          .then(() => console.log('migrated', object))
      }
    }
    return callback && callback(object)
    // return fabric.Object._fromObject(whiteboardConstants.objectTypes.discussion, object, callback) as WbDiscussionIcon
  }
}

const f: any = fabric
f.WbDiscussionIcon = WbDiscussionIcon
