<template>
  <tx-button
    v-bind="$attrs" ref="buttonRef" :faicon="faicon" :type="type" :disabled="disabled" :loading="loading" :ping="ping" :text="text" :width="width"
    :height="height" :icon-size="iconSize" @click.stop="(ev) => onItemActionsIconClick()"
  />
  <tx-menu
    ref="menuRef"
    :options="items"
    @click="onActionClick"
    @close="onClose()"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import TxButton from './TxButton.vue'
import TxMenu from './TxMenu.vue'

interface IProps {
  type?: 'confirm' | 'danger' | 'default' | 'cancel' | 'text' | 'icon'
  disabled?: boolean
  text?: string
  width?: string
  height?: string
  loading?: boolean
  faicon?: string
  icon?: string
  iconSize?: string
  ping?: boolean
  items: ITxActionItem[]
}

withDefaults(defineProps<IProps>(), { type: 'icon', disabled: false, text: '', loading: false, width: '32px', height: '32px', faicon: 'fa-light fa-ellipsis-vertical' })
const emit = defineEmits<{
  (e: 'stateChanged', state: boolean): void
}>()

const menuRef = ref<InstanceType<typeof TxMenu>>()
const buttonRef = ref<InstanceType<typeof TxButton>>()

function onItemActionsIconClick() {
  if (!buttonRef.value) { return }
  const pos = buttonRef.value.$el.getBoundingClientRect() // WARN: This only works for buttons positioned relative to viewport
  menuRef.value?.openMenu({ pageX: pos.left, pageY: pos.bottom }, {})
  emit('stateChanged', true)
}

function onActionClick(action: IContextMenuItem) {
  if (!action.disabled) {
    action.onClick && action.onClick(action)
    menuRef.value?.doClose()
  }
}

function onClose() {
  emit('stateChanged', false)
}
</script>
