<template>
  <div class="tx-collapse" role="tablist">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { provide, toRef } from 'vue'

interface IProps {
  modelValue: Array<string | number>
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: () => [] as Array<string | number>,
})

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: Array<string | number>): void
  (e: 'change', modelValue: Array<string | number>): void
}>()

// METHODS
function updateActiveItems(value) {
  const activeItems = props.modelValue.slice(0)
  const clickedCollapseIndex = activeItems.indexOf(value)
  if (clickedCollapseIndex === -1) {
    activeItems.push(value)
  }
  else {
    activeItems.splice(clickedCollapseIndex, 1)
  }
  setActiveItems(activeItems)
}

function setActiveItems(activeItems) {
  emit('update:modelValue', activeItems)
  emit('change', activeItems)
}
// METHODS - END

provide('txCollapse', {
  activeItems: toRef(props, 'modelValue'),
  updateActiveItems,
})
</script>
