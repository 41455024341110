<template>
  <div>
    <ul>
      <tx-tree-item
        v-for="item in filteredData" :key="item.key" class="item" :selected-key="selectedKey" :item="item" :draggable="draggable" :checked-nodes-limit="props.checkedNodesLimit"
        :current-selection-count="currentSelectionCount" :default-is-open="defaultExpandAll" :show-checkbox="checkboxVisible" :highlight-text="filter" :show-slide-index="showSlideIndex" :allow-strict-check="allowStrictCheck"
        @node-click="nodeClick" @toggle-node="nodeToggle" @check-change="nodeCheckChange" @action-click="onActionClick"
        @context="(data) => showContextMenu(data.evt, data.key, data.item)" @drop="onDrop"
      />
    </ul>
    <tx-menu
      v-if="props.contextMenu && props.contextMenu.length !== 0"
      ref="menuRef"
      :options="props.contextMenu"
      @click="onContextMenuOptionClick"
      @close="onContextMenuOptionClose"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue'
import TxTreeItem from './TxTreeItem.vue'
import TxMenu from '@/shared/components/TxMenu.vue'
import utils from '@/services/utils'

interface IProps {
  data?: ITreeNode[]
  defaultExpandAll?: boolean
  showCheckbox?: 'never' | 'always' | 'onHoverOrOtherChecked'
  filter?: string
  showSlideIndex?: boolean
  allowToggleOnNodeClick?: boolean
  allowStrictCheck?: boolean
  contextMenu?: IContextMenuItem[]
  draggable?: boolean
  checkedNodesLimit?: number
}
const props = withDefaults(defineProps<IProps>(), { data: () => [] as ITreeNode[], defaultExpandAll: false, showCheckbox: 'never', filter: '', showSlideIndex: false, allowToggleOnNodeClick: false, allowStrictCheck: false, draggable: false })

const emit = defineEmits<{
  (e: 'nodeClick', node: ITreeNode): void
  (e: 'checkChange', node: ITreeNode, checked: boolean): void
  (e: 'actionClick', node: ITreeNode, action: TreeNodeAction): void
  (e: 'contextMenuNode', item: ITreeNode)
  (e: 'contextMenuItemClick', option: IContextMenuItem, targetItem: ITreeNode)
  (e: 'drop', draggedNodeKey: string | number, targetNode: ITreeNode, dropPosition: string): void
}>()

const selectedKey = ref<number | string>()
const selectedNode = ref<ITreeNode>()
const menuRef = ref<InstanceType<typeof TxMenu>>()
const contextMenuNode = ref<ITreeNode>()
const checkboxVisible = ref<'never' | 'always' | 'onHoverOrOtherChecked'>('never')

onMounted(() => {
  checkboxVisible.value = props.showCheckbox
})

function nodeClick(node: ITreeNode) {
  selectedKey.value = node.key
  selectedNode.value = node
  if (props.allowToggleOnNodeClick) {
    nodeToggle(node)
  }
  emit('nodeClick', node)
}

function nodeToggle(node: ITreeNode) {
  node.expanded = !node.expanded
}
const currentSelectionCount = computed(() => {
  const checkedNodes = getCheckedNodes(true)
  return checkedNodes.length
})

function findKeyInChildren(node: ITreeNode, key: string | number): ITreeNode | undefined {
  let foundNode = node.children.find(child => child.key === key)
  if (foundNode) {
    return foundNode
  }
  for (let index = 0; index < node.children.length; index++) {
    const child = node.children[index]
    foundNode = findKeyInChildren(child, key)
    if (foundNode) { break }
  }
  return foundNode
}

function nodeCheckChange(node: ITreeNode, checked: boolean) {
  emit('checkChange', node, checked)
  if (props.showCheckbox === 'onHoverOrOtherChecked' && getCheckedNodes().length > 0) {
    checkboxVisible.value = 'always'
  }
  else {
    checkboxVisible.value = props.showCheckbox
  }
}

function onActionClick(node: ITreeNode, action: TreeNodeAction) {
  emit('actionClick', node, action)
}
function onDrop(event: MouseEvent, targetNode: ITreeNode, draggedNodeKey: string | number, dropPosition: string) {
  emit('drop', draggedNodeKey, targetNode, dropPosition)
}
function getChildCheckedNodes(checkedNodes: ITreeNode[], nodes: ITreeNode[], leafOnly: boolean = false) {
  if (nodes.length > 0) {
    nodes.forEach((node) => {
      if (node.checked && (!leafOnly || (leafOnly && node.children.length === 0))) {
        checkedNodes.push(node)
      }
      getChildCheckedNodes(checkedNodes, node.children)
    })
  }
}

function getCurrentKey() {
  return selectedKey.value
}

function getCurrentNode() {
  return selectedNode.value
}

function setCurrentKey(key: number | string) {
  // Find the node
  const node = findKeyInChildren({ label: '', key: '', path: [], checked: false, expanded: false, sortOrder: 0, children: props.data, actions: [] }, key)
  if (node) {
    selectedKey.value = key
    selectedNode.value = node
    return true
  }
  return false
}
function showContextMenu(event: MouseEvent, key: string | number, item?: ITreeNode) {
  event.preventDefault()
  if (!item) {
    contextMenuNode.value = undefined
    menuRef.value?.doClose()
    return
  }
  emit('contextMenuNode', item)
  contextMenuNode.value = item
  nextTick(() => {
    menuRef.value?.openMenu(event, item)
  })
}
const filteredData = computed(() => {
  const getNodes = (result: ITreeNode[], object: ITreeNode) => {
    if (object.label.toLowerCase().includes(props.filter.toLocaleLowerCase())) {
      object.expanded = true
      result.push(object)
      return result
    }
    if (Array.isArray(object.children)) {
      const children = object.children.reduce(getNodes, [])
      if (children.length) {
        result.push({ ...object, children })
        object.expanded = true
      }
    }
    return result
  }
  if (props.filter && props.filter.trim().length > 0) {
    return props.data.reduce(getNodes, [])
  }
  else {
    return props.data
  }
})

function getCheckedNodes(leafOnly: boolean = false) {
  const checkedNodes: ITreeNode[] = []
  props.data.forEach((node) => {
    if (node.checked && (!leafOnly || (leafOnly && node.children.length === 0))) {
      checkedNodes.push(node)
    }
    getChildCheckedNodes(checkedNodes, node.children, leafOnly)
  })
  return checkedNodes
}
function onContextMenuOptionClick(option: IContextMenuItem, targetItem: ITreeNode) {
  emit('contextMenuItemClick', option, targetItem)
}

function onContextMenuOptionClose() {
  contextMenuNode.value = undefined
}
function getPreviousSibling(node: ITreeNode) {
  let previousSibling: ITreeNode | null = null
  if (utils.isDefined(node.parent)) {
    const parentNode = node.parent
    const nodeIndex = parentNode.children.findIndex(childNode => childNode.key === node.key)
    if (nodeIndex !== -1 && nodeIndex !== 0) {
      previousSibling = parentNode.children[nodeIndex - 1]
    }
  }
  else {
    const nodeIndex = props.data.findIndex(childNode => childNode.key === node.key)
    if (nodeIndex !== -1 && nodeIndex !== 0) {
      previousSibling = props.data[nodeIndex - 1]
    }
  }
  return previousSibling
}
function getNextSibling(node: ITreeNode) {
  let nextSibling: ITreeNode | null = null
  if (utils.isDefined(node.parent)) {
    const parentNode = node.parent
    const nodeIndex = parentNode.children.findIndex(childNode => childNode.key === node.key)
    if (nodeIndex !== -1 && parentNode.children.length !== nodeIndex + 1) {
      nextSibling = parentNode.children[nodeIndex + 1]
    }
  }
  else {
    const nodeIndex = props.data.findIndex(childNode => childNode.key === node.key)
    if (nodeIndex !== -1 && props.data.length !== nodeIndex + 1) {
      nextSibling = props.data[nodeIndex + 1]
    }
  }
  return nextSibling
}
defineExpose({
  getCurrentKey,
  getCurrentNode,
  setCurrentKey,
  getCheckedNodes,
  getPreviousSibling,
  getNextSibling,
})
</script>
