<template>
  <div :style="{ width }" class="box-border flex flex-col content-center justify-center p-2 text-sm" :title="valueTitle">
    <div
      v-if="isNull(visibleValue) || isUndefined(visibleValue)"
      class="w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-disabled"
      v-text="t('general.blank')"
    />
    <div
      v-else-if="type === AttributeType.Bool || type === AttributeType.Status"
      class="box-border flex flex-row justify-start my-auto whitespace-nowrap"
    >
      <div class="flex flex-row min-w-[50px] py-[3px] px-2 content-center items-center rounded-sm bg-grey-white">
        <div class="box-border w-2 h-2 mr-2 rounded-full whitespace-nowrap" :style="{ backgroundColor: value ? 'green' : 'red' }" />
        <div class="box-border">
          {{ visibleValue }}
        </div>
      </div>
    </div>
    <div v-else-if="column.property === 'OrderProcessStatus'" class="box-border min-w[80px] max-w-[100px]">
      <div class="flex flex-row items-center py-[3px] px-2 bg-[#d6e0fa94] border-[1px] border-solid border-[#b2b2b2] rounded-2xl">
        <div class="box-border w-2 h-2 mr-2 rounded-full" :style="{ backgroundColor: orderProcessStatusBadgeColorStyle }" />
        <div class="box-border truncate">
          {{ visibleValue }}
        </div>
      </div>
    </div>
    <div v-else-if="type === AttributeType.TimeAgo" class="w-full overflow-hidden whitespace-nowrap">
      <use-time-ago v-slot="{ timeAgo }" :time="visibleValue">
        {{ timeAgo }}
      </use-time-ago>
    </div>
    <div v-else class="w-full overflow-hidden overflow-ellipsis whitespace-nowrap">
      {{ visibleValue }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { isNull, isUndefined } from 'lodash-es'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { UseTimeAgo } from '@vueuse/components'
import type { ITxDataTableVisibleColumn } from '@/shared/components/txDataTable/TxDataTable.types'
import { AttributeType } from '@/models/catalogAttribute'
import utils from '@/services/utils'

const props = defineProps<{
  column: ITxDataTableVisibleColumn
  type: AttributeType
  width: string
  value: any
}>()

const { t } = useI18n()

const visibleValue = computed(() => {
  if (isNull(props.value) || isUndefined(props.value)) { return props.value }
  switch (props.type) {
    case AttributeType.ArticleNumber:
    case AttributeType.Calc:
    case AttributeType.LinkedCatalogArticleNumber:
    case AttributeType.Nvarchar:
    case AttributeType.SellerModel:
    case AttributeType.SizeScale:
    case AttributeType.MasterSizeScale:
      return props.value
    case AttributeType.Int:
      return props.value.toLocaleString()
    case AttributeType.Decimal:
      return props.value.toLocaleString(undefined, { minimumFractionDigits: 2 })
    case AttributeType.Date:
    case AttributeType.DateOption:
      return utils.formatDate(props.value)
    case AttributeType.DateTime:
      return utils.formatDateTime(props.value)
    case AttributeType.MultiValue:
      return props.value.join('|')
    case AttributeType.Status:
      return props.value ? t('general.active') : t('general.inactive')
    case AttributeType.Bool:
      return props.value ? t('general.yes') : t('general.no')
    case AttributeType.ColorPalette:
      return props.value.toString()
    case AttributeType.TimeAgo:
      return new Date(props.value)
    default:
      return null
  }
})

const valueTitle = computed(() => {
  if (utils.isDefined(visibleValue.value)) {
    if (props.type === AttributeType.TimeAgo) {
      return utils.formatDateTime(visibleValue.value)
    }
  }
  return visibleValue.value
})

const orderProcessStatusBadgeColorStyle = computed(() => {
  const valueLower = visibleValue.value ? visibleValue.value.toString().toLowerCase() : ''
  switch (valueLower) {
    case 'draft':
    case 'reopened':
      return '#5093e1'
    case 'submitted':
    case 'disapproved':
    case 'approved':
    case 'locked':
    case 'submitting':
    case 'confirmed':
      return '#1bb934'
    default:
      return '#000000'
  }
})
</script>
