<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <div class="header" />
    <!-- BODY -->
    <div class="px-10 mt-4 alerts">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
    </div>

    <!-- FORM -->
    <div class="w-full h-full px-10 overflow-auto gap-x-6">
      <div class="w-full mt-4">
        <div class="flex flex-row-reverse w-full pr-4 my-2">
          <icon-button
            v-if="!showAddRelationForm && userStore.userProfile.isValidPrivilege(privileges.objectRelation.create)"
            type="inline" faicon="fa-light fa-plus" :label="t('manageObjectRelation.addRelation')" :width="100"
            @click="onAddRelation"
          />
        </div>
        <div v-if="showAddRelationForm">
          <div class="w-full my-2">
            <tx-select
              v-model="form.RelationTypeId" :label="t('manageObjectRelation.relationshipType')"
              :data="relationTypes" :errors="v$.RelationTypeId.$errors" value-prop="Id" display-prop="Name"
              required @blur="v$.RelationTypeId.$touch"
            />
          </div>
          <div class="w-full my-2">
            <article-number-autocomplete
              v-model="form.RelatedObjectId" :label="t('general.article')"
              :errors="v$.RelatedObjectId.$errors" value-prop="Id" :skipped-article-ids="objectRelations.filter(x => x.RelationTypeId === form.RelationTypeId).map(x => x.RelatedArticleId)"
              :disabled="!form.RelationTypeId"
              clearable required @blur="v$.RelatedObjectId.$touch"
            />
          </div>
        </div>
        <!-- Table -->
        <div v-else class="relative w-full h-full">
          <loader v-if="loading" />
          <div v-for="(objRelations, relationName) in objectRelationsByRelationType" v-else-if="objectRelationsByRelationType.length" :key="relationName" class="w-full my-2">
            <div class="block bg-white rounded-lg shadow">
              <div class="py-1 text-xl font-medium text-center border-b border-gray-300" v-text="relationName" />
              <div class="w-full p-2">
                <table class="w-full text-center">
                  <thead class="border-b bg-default">
                    <tr>
                      <th scope="col" class="py-2 text-sm font-semibold" v-text="t('manageObjectRelation.related')" />
                      <th scope="col" class="py-2 text-sm font-semibold" v-text="t('manageObjectRelation.number')" />
                      <th scope="col" class="py-2 text-sm font-semibold" v-text="t('manageObjectRelation.name')" />
                      <th scope="col" class="py-2 text-sm font-semibold" v-text="t('manageObjectRelation.actions')" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="objectRelation in objRelations" :key="objectRelation.RelatedArticleId" class="bg-white border-b">
                      <td class="py-2 text-sm font-light whitespace-nowrap">
                        {{ objectRelation.IsRequestArticle > 0 ? t('manageObjectRelation.request') : t('general.article') }}
                      </td>
                      <td class="py-2 text-sm font-light select-text whitespace-nowrap" v-text="objectRelation.RelatedArticleNumber" />
                      <td class="py-2 text-sm font-light select-text whitespace-nowrap" v-text="objectRelation.RelatedArticleName" />
                      <td class="py-2 text-sm font-light whitespace-nowrap">
                        <tx-button
                          v-if="userStore.userProfile.isValidPrivilege(privileges.objectRelation.delete)"
                          type="icon" faicon="fa-light fa-trash-can" @click="confirmDeleteRelation(objectRelation)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-else class="flex items-center justify-center col-start-1 col-end-12">
            <p>{{ t('general.emptyList') }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <tx-form-footer
      v-if="showAddRelationForm" class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-text="t('general.add')" :primary-loading="adding" :primary-disabled="v$.$invalid" :secondary-text="t('general.close')"
      :secondary-disabled="adding" @primary-click="onSave" @secondary-click="onClose"
    />

    <tx-dialog
      v-model="deleteDialogVisible" :title="t('manageObjectRelation.removeRelation')" :loading="deleting"
      :cancel-state="loading ? 'disabled' : 'enabled'" :ok-state="loading ? 'loading' : 'enabled'"
      confirm-text="general.remove" show-ok-cancel @click="deleteDialogVisible = false"
      @ok="deleteRelation(objectRelationToDelete!)"
    >
      <div class="text-xl">
        {{ t('manageObjectRelation.removeRelationConfirmation', { label: objectRelationToDelete?.RelatedArticleNumber }) }}
      </div>
    </tx-dialog>
  </div>
</template>

<script setup lang="ts">
// TODO: Handle request linking
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import IconButton from '@/shared/components/IconButton.vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import ArticleNumberAutocomplete from '@/shared/components/ArticleNumberAutocomplete.vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import Loader from '@/shared/components/Loader.vue'
import type MyArticle from '@/models/myArticle'
import ObjectRelation from '@/models/objectRelation/objectRelation'
import type RelationType from '@/models/objectRelation/relationType'
import type { CreateObjectRelationModel } from '@/api/t1/model/objectRelationModel'
import { createObjectRelation, deleteObjectRelation } from '@/api/t1/objectRelation'
import { privileges } from '@/models/constants'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useNotificationStore } from '@/store/notification'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'

const props = defineProps<{
  article: MyArticle
}>()

defineEmits<{
  (e: 'cancel'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const notificationStore = useNotificationStore()
const { errorMessage, hasError } = useErrorMessage()
const withI18nMessage = createI18nMessage({ t })

const entityId = {
  article: 1,
}

const loading = ref(false)

const deleteDialogVisible = ref(false)
const objectRelationToDelete = ref<ObjectRelation | null>(null)
const deleting = ref(false)

const objectRelations = ref<ObjectRelation[]>([])
async function loadObjectRelations() {
  loading.value = true
  objectRelations.value = await appConfig.DB!.getObjectRelations(props.article.CatalogCode, props.article.Id, entityId.article, (props.article._IsRequestArticle ? 1 : 0))
  loading.value = false
}

const objectRelationsByRelationType = computed(() => objectRelations.value.reduce((acc, cur) => {
  acc[cur.RelationTypeName] = acc[cur.RelationTypeName] || []
  acc[cur.RelationTypeName].push(cur)
  return acc
}, {} as Record<string, ObjectRelation[]>))

function confirmDeleteRelation(objectRelation: ObjectRelation) {
  objectRelationToDelete.value = objectRelation
  deleteDialogVisible.value = true
}

function deleteRelation(objectRelation: ObjectRelation) {
  deleting.value = true
  deleteObjectRelation(props.article.CatalogCode, objectRelation.Id)
    .then(async () => {
      await appConfig.DB!.objectRelations.delete(objectRelation.Id)
      const index = objectRelations.value.findIndex(x => x.Id === objectRelation.Id)
      if (index >= 0) {
        objectRelations.value.splice(index, 1)
      }
      notificationStore.addNotification({ message: t('manageObjectRelation.deleteSuccessfully'), type: 'Success' })
    }).catch((error) => {
      notificationStore.addNotification({ message: t('manageObjectRelation.deleteFailed'), type: 'Alert', actions: ['Support', 'ShowDetails'], details: utils.getErrorMessage(error) })
    }).then(() => {
      deleting.value = false
      objectRelationToDelete.value = null
      deleteDialogVisible.value = false
    })
}

// form
const showAddRelationForm = ref(false)
const adding = ref(false)

const form = reactive<CreateObjectRelationModel>({
  EntityId: entityId.article,
  RelationTypeId: 0,
  RelatedObjectId: null,
  IsRequestArticle: props.article._IsRequestArticle ? 1 : 0,
  RequestArticleName: '',
  RequestArticleNumber: '',
})

const rules = computed(() => ({
  RelationTypeId: { required: withI18nMessage(required) },
  RelatedObjectId: { required: withI18nMessage(required) },
}))
const v$ = useVuelidate(rules, form)

const relationTypes = ref<RelationType[]>([])
async function loadRelationTypes() {
  loading.value = true
  relationTypes.value = await appConfig.DB!.getRelationTypes(props.article.CatalogCode)
  loading.value = false
}

function onAddRelation() {
  loadRelationTypes()
  showAddRelationForm.value = true
}

function onClose() {
  showAddRelationForm.value = false
  errorMessage.value = ''
}

async function onSave() {
  errorMessage.value = ''
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
    return
  }
  if (utils.isDefined(props.article) && form.RelationTypeId > 0 && utils.isDefined(form.RelatedObjectId)) {
    const catalogCode = props.article.CatalogCode
    adding.value = true
    form.IsRequestArticle = props.article._IsRequestArticle ? 1 : 0
    createObjectRelation(catalogCode, props.article.Id, form)
      .then(async (res) => {
        const newObjectRelation = new ObjectRelation(catalogCode, res.data.ObjectId, res.data.EntityId, res.data)
        await appConfig.DB!.objectRelations.add(newObjectRelation)
        notificationStore.addNotification({ message: t('manageObjectRelation.addSuccessfully'), type: 'Success' })
        loadObjectRelations()
      })
      .catch((e) => {
        console.error(e)
        if (e.response && e.response.data) {
          errorMessage.value = e.response.data.map(x => x.ErrorMessage).join(', ')
        }
        else {
          errorMessage.value = t('general.unexpectedError')
        }
      })
      .finally(() => adding.value = false)
  }
}

function reset() {
  loading.value = false
  errorMessage.value = ''

  deleteDialogVisible.value = false
  objectRelationToDelete.value = null
  deleting.value = false

  showAddRelationForm.value = false
  adding.value = false
  form.RelationTypeId = 0
  form.RelatedObjectId = null
}

watch(() => props.article, reset)

onMounted(async () => {
  reset()
  loadObjectRelations()
})
</script>
