<template>
  <div class="flex items-center flex-nowrap">
    <!-- LEFT -->
    <div class="flex items-center flex-auto h-full mx-8">
      <div v-for="action in leftSideActions" v-show="action.visible" :key="action.id" class="flex items-center ml-4 cursor-pointer flex-nowrap" @click="onClick(action)">
        <tx-button type="icon" class="w-6 h-6" :faicon="action.icon" :disabled="action.disabled" />
        <div v-if="action.label" class="text-xs" :class="{ 'opacity-40 cursor-not-allowed': action.disabled }">
          {{ action.label }}
        </div>
      </div>
    </div>
    <!-- RIGHT -->
    <div class="flex flex-row-reverse items-center flex-auto h-full mx-8">
      <div v-for="action in rightSideActions" :key="action.id" class="flex items-center ml-4 cursor-pointer flex-nowrap" @click="onClick(action)">
        <tx-button type="icon" class="w-6 h-6" :faicon="action.icon" :disabled="action.disabled" />
        <div v-if="action.label" class="text-xs" :class="{ 'opacity-40 cursor-not-allowed': action.disabled }">
          {{ action.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts' setup>
import { computed } from 'vue'
import { isEmpty } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import type { OrderlineToolbarActions } from '../../Orders.types'
import type Order from '@/models/order'
import type Orderline from '@/models/orderline'
import TxButton from '@/shared/components/TxButton.vue'
import { ordersConstants } from '@/models/constants'

const props = defineProps<{
  currentOrder: Order
  isLoading: boolean
  isLoadingData: boolean
  isOrderEditable: boolean
  isOrderEditableExcludingProcessStatus: boolean
  selectedOrderlines: Array<Orderline>
}>()

const emit = defineEmits<{
  (e: 'actionClick', actionObject: IToolbarActionButton<OrderlineToolbarActions>): void
}>()

const { t } = useI18n()

const leftSideActions = computed(() => ([
  {
    id: 'save',
    label: t('orderlines.actions.save'),
    icon: 'fa-light fa-floppy-disk',
    visible: true,
    disabled: !props.isOrderEditable || !props.currentOrder.IsOrderlinesDirty || props.isLoading || props.isLoadingData,
  },
  {
    id: 'send',
    label: t('orderlines.actions.send'),
    icon: 'fa-light fa-paper-plane',
    visible: true,
    disabled: !props.isOrderEditableExcludingProcessStatus || props.currentOrder.IndexedOrderlines == null || isEmpty(props.currentOrder.IndexedOrderlines)
    || (props.currentOrder.OrderProcessStatusId != null && props.currentOrder.OrderProcessStatusId !== ordersConstants.orderProcessStatus.draft
    && props.currentOrder.OrderProcessStatusId !== ordersConstants.orderProcessStatus.reopened
    ) || props.isLoading || props.isLoadingData,
  },
  {
    id: 'reopen',
    label: t('orderlines.actions.reopen'),
    icon: 'fa-light fa-rotate-right',
    visible: true,
    disabled: !props.isOrderEditableExcludingProcessStatus || props.currentOrder.OrderProcessStatusId !== ordersConstants.orderProcessStatus.submitted || props.isLoading || props.isLoadingData,
  },
  {
    id: 'fillOrder',
    label: t('orderlines.actions.fill'),
    icon: 'fa-light fa-plus',
    visible: true,
    disabled: !props.isOrderEditable || props.isLoading || props.isLoadingData,
  },
  {
    id: 'columnPicker',
    label: t('orderlines.actions.columnPicker'),
    icon: 'fa-light fa-table',
    visible: true,
  },
  {
    id: 'filter',
    label: t('orderlines.actions.filterOrderlines'),
    icon: 'fa-light fa-filter',
    visible: true,
  },
]) as Array<IToolbarActionButton<OrderlineToolbarActions>>)

const rightSideActions = computed(() => ([
  {
    id: 'close',
    label: '',
    icon: 'fa-light fa-xmark',
    visible: true,
  },
  {
    id: 'refresh',
    label: t('orderlines.actions.refresh'),
    icon: 'fa-light fa-rotate-right',
    visible: true,
  },
  {
    id: 'removeOrderlines',
    label: t('orderlines.actions.removeOrderlines'),
    icon: 'fa-light fa-trash-can',
    visible: true,
    disabled: !props.isOrderEditable || !props.selectedOrderlines.length || props.isLoading || props.isLoadingData,
  },
]) as Array<IToolbarActionButton<OrderlineToolbarActions>>)

function onClick(action: IToolbarActionButton<OrderlineToolbarActions>) {
  if (!action.disabled) {
    emit('actionClick', action)
  }
}
</script>
