<template>
  <div class="h-16 min-h-[64px] text-center border-b bg-gray-50 flex flex-col items-center justify-between">
    <tx-tabs v-model="activeTab" :tabs="tabs" class="m-auto" />
    <!-- <tx-select required placeholder="Columns" v-model="cols" :data="[1, 2, 3, 4, 5, 6]"
      class="pb-1 pr-3 my-auto w-18" /> -->
  </div>

  <div v-show="activeTab === 'tree'" style="height: calc(100vh - 200px)" class="h-full overflow-auto">
    <tx-tree class="tree" :data="getCurrentTree" @node-click="onTreeItemClick" />
  </div>
  <articles-list
    v-show="activeTab === 'articles'" :thumbnail-size="1" :category-criteria="categoryCriteria" :allow-selection="allowSelectionArticle"
    :click-to-select="clickToSelectArticle" @article-click="onArticleClick" @selection-changed="onArticleSelectionChange"
  />
  <div v-show="activeTab === 'filter'" class="h-full p-4">
    <articles-filter @cancel="activeTab = 'articles'" @confirm="onFilterConfirm" />
  </div>
</template>

<script setup lang="ts">
import { computed, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import usePriceGroupsLabel from '../composables/priceGroupsLabel'
import ArticlesFilter from './ArticlesFilter.vue'
import ArticlesList from './ArticlesList.vue'
import TxTabs from './TxTabs.vue'
import TxTree from './TxTree.vue'
import type MyArticle from '@/models/myArticle'
import utils from '@/services/utils'
import { FilterCriteria, FilterCriteriaMode } from '@/models/filterCriteria'
import { useUserStore } from '@/store/userData'
import { useBrowseByStore } from '@/store/browseBy'
import { getBrowseByList } from '@/services/browseByFactory'

type ArticlesFinderTabs = 'articles' | 'tree' | 'filter'

interface IProps {
  browseByModel?: boolean
  columns?: number
  visible?: boolean
  allowSelectionArticle?: boolean
  clickToSelectArticle?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  browseByModel: false,
  columns: 3,
  visible: false,
  allowSelectionArticle: true,
  clickToSelectArticle: true,
})

const emits = defineEmits<{
  (e: 'articleClick', art: MyArticle): void
  (e: 'selectionChanged', arts: MyArticle[]): void
  (e: 'update:columns', columns: number)
}>()

// DATA
const activeTab = ref<ArticlesFinderTabs>('tree')
const categoryCriteria = ref<FilterCriteria[]>([])

const tabs: ITabItem<ArticlesFinderTabs>[] = [
  { key: 'articles', label: !props.browseByModel ? 'general.articles' : 'general.models', visible: true },
  { key: 'tree', label: 'general.tree', visible: true },
  // { key: 'favorites', label: 'Favorites' },
  { key: 'filter', label: 'general.filter', visible: true },
]
let initialized = false
let indexedBrowseByList: Record<string, IBrowseBy> | null
// DATA - END

const userStore = useUserStore()
const browseByStore = useBrowseByStore()
const { t } = useI18n()
const { priceGroupsLabel } = usePriceGroupsLabel()

// COMPUTED
const getCurrentTree = computed(() => userStore.currentTree || [])

// const cols = computed({
//   get: () => props.columns,
//   set: (v: number) => {
//     emits('update:columns', v)
//   },
// })
// COMPUTED - END

// WATCHERS
watch(() => props.visible, (visible) => {
  if (visible) {
    init()
  }
  else {
    browseByStore.browseBy = undefined
  }
})
// WATCHERS - END

init()

// METHODS
function init() {
  if (!initialized) { // this is kept intentionally and intentionally added watch on visible(which is as of writing this code this watch is not required)
    if (props.browseByModel) {
      if (indexedBrowseByList == null) {
        indexedBrowseByList = getBrowseByList(userStore.activeCatalog!.Config.BrowseByAttribute, userStore.activeCatalog!.Config.EnableBrowseByModel, userStore.myAttributes!, priceGroupsLabel.value, t)
          .reduce((acu, cur) => (acu[cur.key.trim().toLowerCase()] = cur) && acu, {})
      }
      if (indexedBrowseByList.hasOwnProperty('browseByModel'.toLowerCase())) {
        browseByStore.browseBy = indexedBrowseByList['browseByModel'.toLowerCase()]
      }
    }
    else {
      browseByStore.browseBy = undefined
    }
    initialized = true
  }
}

function onTreeItemClick(e?: ITreeNode) {
  if (utils.isDefined(e)) {
    categoryCriteria.value = e.path.map(p => new FilterCriteria({ attribute: p.a, multipleVals: [(utils.isDefined(p.v) ? p.v.toLowerCase() : null)], mode: FilterCriteriaMode.multiString, exclude: false }))
    activeTab.value = 'articles'
  }
}

async function onFilterConfirm(e: FilterCriteria[]) {
  categoryCriteria.value = e.map(itm => new FilterCriteria(itm))
  activeTab.value = 'articles'
}

function onArticleClick(art: MyArticle) {
  emits('articleClick', art)
}

function onArticleSelectionChange(arts: MyArticle[]) {
  emits('selectionChanged', arts)
}

onUnmounted(() => {
  browseByStore.browseBy = undefined
})
</script>
