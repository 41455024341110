import { savedViewConstants } from '@/models/constants'
import type { SavedViewItemModel } from '@/api/t1/model/savedViewModel'

export interface ISavedViewFilter {
  value: any
  type: string
  exclude: boolean
}

export default class SavedView {
  CatalogCode: number
  Id: number
  Name: string
  IsCatalogLevel: number
  RowDivider?: string
  ColumnDivider?: string
  ImageSize: string
  ColumnWidth?: string | null
  ImageType: string
  MasterCatalogCode: number
  CreatedByUserAccountId: number
  BrowseByAttribute?: string | null
  SeasonFilterApplied: boolean
  SortOrderList: number[]
  CustomSort?: string | null
  FavoriteTags: string[]
  Filter: Record<string, ISavedViewFilter>
  Json?: string | null
  Version: number
  Status: number
  CreatedBy: number
  UpdatedBy: number
  CreatedDate: Date
  UpdatedDate: Date

  get FolderName(): string {
    let folderName = ''
    const indexOfLastSeparator = this.Name.lastIndexOf(savedViewConstants.folderPathSeparator)
    if (indexOfLastSeparator >= 0) {
      folderName = this.Name.slice(0, indexOfLastSeparator)
    }
    return folderName
  }

  get DisplayName(): string {
    const indexOfLastSeparator = this.Name.lastIndexOf(savedViewConstants.folderPathSeparator)
    if (indexOfLastSeparator >= 0) {
      return this.Name.substring(indexOfLastSeparator + 1)
    }
    return this.Name
  }

  get FullDisplayName(): string {
    return this.Name.replace(savedViewConstants.folderPathSeparator, ' > ')
  }

  get Type(): string {
    return this.IsCatalogLevel > 0 ? 'Predefined View' : 'My View'
  }

  constructor(catalogCode: number, savedView: SavedViewItemModel) {
    this.CatalogCode = catalogCode
    this.Id = savedView.Id
    this.Name = savedView.Name
    this.IsCatalogLevel = savedView.IsCatalogLevel ? 1 : 0
    this.RowDivider = savedView.RowDivider
    this.ColumnDivider = savedView.ColumnDivider
    this.ImageSize = savedView.ImageSize
    this.ColumnWidth = savedView.ColumnWidth
    this.ImageType = savedView.ImageType
    this.MasterCatalogCode = savedView.MasterCatalogCode
    this.CreatedByUserAccountId = savedView.CreatedByUserAccountId
    this.BrowseByAttribute = savedView.BrowseByAttribute
    this.SeasonFilterApplied = savedView.SeasonFilterApplied
    try {
      this.SortOrderList = savedView.SortOrderList ? JSON.parse(savedView.SortOrderList) : []
    }
    catch (error) {
      console.error(`Invalid SortOrderList for saved view ${savedView.Name} -> ${savedView.SortOrderList}`, error)
      this.SortOrderList = []
    }
    this.CustomSort = savedView.CustomSort
    try {
      this.FavoriteTags = savedView.FavoriteTags ? JSON.parse(savedView.FavoriteTags) : []
    }
    catch (error) {
      console.error(`Invalid FavoriteTags for saved view ${savedView.Name} -> ${savedView.FavoriteTags}`, error)
      this.FavoriteTags = []
    }
    try {
      this.Filter = savedView.Filter ? JSON.parse(savedView.Filter) : {}
    }
    catch (error) {
      console.error(`Invalid Filter for saved view ${savedView.Name} -> ${savedView.Filter}`, error)
      this.Filter = {}
    }
    this.Json = savedView.Json
    this.Version = savedView.Version
    this.Status = savedView.Status
    this.CreatedBy = savedView.CreatedBy
    this.UpdatedBy = savedView.UpdatedBy
    this.CreatedDate = savedView.CreatedDate
    this.UpdatedDate = savedView.UpdatedDate
  }
}
