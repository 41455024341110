export default class ArticlePrice {
  CatalogCode: number
  Id: number
  Prices: Price[]

  constructor(catalogCode: number, articlePrice: any) {
    this.CatalogCode = catalogCode
    this.Id = articlePrice.Id
    this.Prices = articlePrice.Prices.map((price: any) => new Price(price))
  }
}

export class Price {
  Id: number
  PriceGroupId: number
  Price: number

  constructor(price: any) {
    this.Id = price.Id
    this.PriceGroupId = price.PriceGroupId
    this.Price = price.Price || price.price
  }
}
