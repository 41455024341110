import type { AttributeType } from './catalogAttribute'

export interface ICatalogBucketAttribute {
  AllowedValues?: Array<string>
  AttributeDisplayName: string
  AttributeId: number
  AttributeSystemName: string
  AttributeTypeId: AttributeType
  Criteria: string
  Status: number
  ValidationExpression: string
  ValidationMessage: string
  Editable: boolean
  Visible: boolean
}

export interface ICatalogBucketAttributeParsed extends ICatalogBucketAttribute {
  parsedValidationExpressionObject?: {
    calculatedRegexExpression: {
      calculatedFormula: any
    }
    aggregateBucketArticlesAttributeSystemName: string
    includeInactiveArticles: boolean
  }
  evaluateFunction?: Function
  aggregateBucketArticlesAttributeSystemName?: string
  includeInactiveArticles?: boolean
}

export default class CatalogBucketAttribute implements ICatalogBucketAttribute {
  AllowedValues?: string[]
  AttributeDisplayName: string
  AttributeId: number
  AttributeSystemName: string
  AttributeTypeId: AttributeType
  Criteria: string
  Status: number
  ValidationExpression: string
  ValidationMessage: string
  Editable: boolean
  Visible: boolean

  constructor({ AllowedValues, AttributeDisplayName, AttributeSystemName, AttributeTypeId, AttributeId, Criteria, Status, ValidationExpression, ValidationMessage }: CatalogBucketAttribute) {
    this.AllowedValues = AllowedValues
    this.AttributeDisplayName = AttributeDisplayName
    this.AttributeId = AttributeId
    this.AttributeSystemName = AttributeSystemName
    this.AttributeTypeId = AttributeTypeId
    this.Criteria = Criteria
    this.Status = Status
    this.ValidationExpression = ValidationExpression
    this.ValidationMessage = ValidationMessage
    this.Editable = true
    this.Visible = true
  }
}
