<template>
  <div class="flex flex-col h-full overflow-x-hidden overflow-y-auto">
    <slot />
    <div class="border-b bg-gray-50">
      <tx-input
        v-model="filter" rounded clearable :placeholder="t('general.typeToFilter')"
        faicon="fa-light fa-magnifying-glass" class="mx-2 my-4"
      />
    </div>
    <div v-for="(grp, index) in groups" :key="index" class="flex flex-col px-3">
      <div class="flex flex-col px-4 py-2.5">
        <span class="uppercase text-md text-primary-500 font-medium">{{ grp.title }}</span>
        <span v-if="grp.subTitle" class="text-sm text-disabled font-medium">{{ grp.subTitle }}</span>
      </div>
      <tx-nav-tree-node
        v-for="node in grp.nodes.filter(n => n.visible)" :key="node.id" :node="node" :expanded="node.expanded"
        :selected-node="selectedNode" @click="doNodeClick" @update:expanded="updateNodeExpanded"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import TxInput from '../TxInput.vue'
import type { ITxNavTreeGroup, ITxNavTreeNode } from './TxNavTree.types'
import TxNavTreeNode from './TxNavTreeNode.vue'

defineProps<{
  groups: ITxNavTreeGroup[]
  selectedNode?: ITxNavTreeNode
}>()

const emits = defineEmits<{
  (e: 'nodeClick', node: ITxNavTreeNode): void
  (e: 'update:selected-node', node: ITxNavTreeNode): void
}>()

const filter = ref('')
const { t } = useI18n()

function doNodeClick(node: ITxNavTreeNode) {
  if (node.children && node.children.length > 0) {
    node.expanded = !node.expanded
  }
  else {
    emits('nodeClick', node)
    emits('update:selected-node', node)
  }
}

function updateNodeExpanded(node: ITxNavTreeNode, expanded: boolean) {
  node.expanded = expanded
}
</script>
