<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <!-- HEADER -->
    <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="t('articleDetails.actions.editSegmentations')" :show-header="showHeader" />

    <!-- BODY -->
    <div class="px-10 mt-4 alerts">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
    </div>
    <!-- FORM -->
    <div class="overflow-hidden body h-full">
      <div class=" h-full w-full mt-2">
        <div class="w-full px-10 my-2 mb-6">
          <tx-checkbox
            v-model="checkAll" :label="t('general.checkAll')" :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
          />
        </div>
        <div class="grid w-full h-[calc(100%_-_60px)] grid-cols-2 px-10 gap-x-6 overflow-scroll">
          <div v-for="segmentation in availableSegmentations" :key="segmentation.Id" class="grid items-center w-full grid-cols-2 h-14">
            <label class="flex items-center">
              <input
                v-model="checkedSegmentations" type="checkbox" :value="segmentation.Id"
                :indeterminate="indeterminateSegmentationIds.has(segmentation.Id)"
                @change="handleCheckedSegmentationChange(segmentation.Id)"
              >
              <span class="pl-1 text-base" v-text="segmentation.Name" />
            </label>
            <form-editor
              v-if="userStore.activeCatalog?.SegmentationPlanningEnable && checkedSegmentations.indexOf(segmentation.Id) >= 0"
              v-model="planningValues[segmentation.Id]"
              :attribute="planningValueField"
              :form="planningValues"
              :required="true"
              :show-label="false"
              :clearable="false"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <tx-form-footer
      class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-text="t('general.update')" :primary-loading="loading" :secondary-disabled="loading"
      @primary-click="onUpdate" @secondary-click="onCancel"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onMounted, ref, watch } from 'vue'
import { isArray } from 'lodash-es'
import TxCheckbox from '@/shared/components/TxCheckbox.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import type MyArticle from '@/models/myArticle'
import ArticleSegmentation, { Segmentation } from '@/models/articleSegmentation'
import type { UpdateArticleSegmentationsModel } from '@/api/t1/model/articleModel'
import { updateArticleSegmentations } from '@/api/t1/article'
import { appConstants } from '@/models/constants'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import useEventsBus from '@/shared/composables/eventBus'
import appConfig from '@/services/appConfig'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'
import FormEditor from '@/shared/components/FormEditor.vue'
import { AttributeType } from '@/models/catalogAttribute'
import type Article from '@/models/article'

interface IProps {
  articles: MyArticle[]
  showHeader?: boolean
  isModel?: boolean
}
const props = withDefaults(defineProps<IProps>(), {
  showHeader: true,
  isModel: false,
})

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[] | Article[]): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()
const { emitter } = useEventsBus()

const loading = ref(false)
const checkAll = ref(false)
const isIndeterminate = ref(false)

const planningValues = ref<Record<number, string>>({})
const checkedSegmentations = ref<number[]>([])
const indeterminateSegmentationIds = ref(new Set<number>())
const articles = ref<MyArticle[] | Article[]>([])

const availablePlanningValues = [t('editSegmentations.assorted'), t('editSegmentations.planned')]

const availableSegmentations = computed(() => {
  if (userStore.activeCatalog) {
    return Object.values(userStore.activeCatalog._IndexedCatalogSegmentation)
      .filter(x => x.Status > 0 && !appConstants.excludeSegmentationNames.has(x.Name.toLowerCase()))
      .sort((a, b) => a.Name.localeCompare(b.Name))
  }
  return []
})

function handleCheckAllChange(val: TxBooleanish) {
  checkedSegmentations.value = val ? availableSegmentations.value.map(s => s.Id) : []
  isIndeterminate.value = false
  indeterminateSegmentationIds.value.clear()
}

const planningValueField: IFormEditorAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
  SystemName: 'PlanningValue',
  DisplayName: '',
  Creatable: true,
  AttributeType: AttributeType.Nvarchar,
  ReadOnly: false,
  IsRequired: true,
  VettingList: availablePlanningValues,
})

function handleCheckedSegmentationChange(segmentationId: number) {
  const checkedCount = checkedSegmentations.value.length
  checkAll.value = checkedCount === availableSegmentations.value.length
  isIndeterminate.value = checkedCount > 0 && checkedCount < availableSegmentations.value.length
  indeterminateSegmentationIds.value.delete(segmentationId)
}

function onCancel() {
  emit('cancel')
}

async function onUpdate() {
  if (userStore.activeCatalog && props.articles.length > 0) {
    loading.value = true
    const catalogCode = userStore.activeCatalog.CatalogCode
    const promises: Promise<any>[] = []
    const updatedArticles: (Article | MyArticle)[] = []
    const failedArticles: string[] = []

    articles.value.forEach((article) => {
      const requestObj: UpdateArticleSegmentationsModel[] = []
      Object.values(article._Segmentations).forEach((articleSegmentation) => {
        if (userStore.activeCatalog?._IndexedCatalogSegmentation[articleSegmentation.Id]
          && appConstants.excludeSegmentationNames.has(userStore.activeCatalog?._IndexedCatalogSegmentation[articleSegmentation.Id].Name.toLowerCase())) {
          requestObj.push({ SegmentationId: articleSegmentation.Id, PlanningValue: articleSegmentation.PlanningValue })
        }
        else if (indeterminateSegmentationIds.value.has(articleSegmentation.Id)) {
          requestObj.push({ SegmentationId: articleSegmentation.Id, PlanningValue: articleSegmentation.PlanningValue })
        }
      })
      checkedSegmentations.value.forEach((segmentationId) => {
        requestObj.push({
          SegmentationId: segmentationId,
          PlanningValue: planningValues.value[segmentationId],
        })
      })
      promises.push(updateArticleSegmentations(catalogCode, article.Id, requestObj)
        .then(() => {
          article._Segmentations = requestObj.map(itm => new Segmentation({ Id: itm.SegmentationId, PlanningValue: itm.PlanningValue }))
          updatedArticles.push(article)
        })
        .catch(() => failedArticles.push(article.ArticleNumber)),
      )
    })
    Promise.all(promises)
      .then(async () => {
        if (updatedArticles.length) {
          const articleSegmentationData = updatedArticles.map(art => new ArticleSegmentation(userStore.activeCatalog!.CatalogCode, { Id: art.Id, Segmentations: art._Segmentations }))
          await appConfig.DB!.bulkUpdateArticles(userStore.activeCatalog!.CatalogCode, 'segmentations', articleSegmentationData, false)
          emitter('catalogDataUpdated', { source: 'Segmentation' })
          emit('updated', updatedArticles)
        }
        if (failedArticles.length) {
          errorMessage.value = t('editSegmentations.failedUpdateSegmentations', { articles: failedArticles.join(', ') })
        }
      })
      .catch((e) => {
        console.error(e)
        errorMessage.value = t('general.unexpectedError')
      })
      .finally(() => loading.value = false)
  }
}

async function reset() {
  loading.value = false
  errorMessage.value = ''
  checkAll.value = false
  isIndeterminate.value = false
  planningValues.value = {}
  checkedSegmentations.value = []
  indeterminateSegmentationIds.value.clear()
  articles.value = props.articles

  if (props.isModel) {
    const queryCriterion: Array<[number, number, string]> = [[userStore.activeCatalog!.CatalogCode, 1, props.articles[0].ModelNumber]]
    for (let i = 1; i < props.articles.length; i++) {
      queryCriterion.push([+userStore.activeCatalog!.CatalogCode, 1, props.articles[i].ModelNumber])
    }
    const res = await appConfig.DB!.getArticlesByCatalogAndModelCriterion(queryCriterion)
    if (res && isArray(res) && res[0]) {
      articles.value = res
    }
  }

  availableSegmentations.value.forEach((segmentation) => {
    let checked: null | boolean = null
    articles.value.forEach((article) => {
      if (article._Segmentations[segmentation.Id]) {
        if (checked === null) {
          checked = true
        }

        const planningValue = article._Segmentations[segmentation.Id].PlanningValue?.toLowerCase() === 'planned' ? availablePlanningValues[1] : availablePlanningValues[0]

        if (planningValues.value[segmentation.Id]) {
          if (planningValues.value[segmentation.Id] !== planningValue) {
            planningValues.value[segmentation.Id] = availablePlanningValues[0]
          }
        }
        else {
          planningValues.value[segmentation.Id] = planningValue
        }

        indeterminateSegmentationIds.value.add(segmentation.Id)
      }
      else {
        checked = false
        if (!planningValues.value[segmentation.Id]) {
          planningValues.value[segmentation.Id] = availablePlanningValues[0]
        }
      }
    })
    if (checked) {
      checkedSegmentations.value.push(segmentation.Id)
      indeterminateSegmentationIds.value.delete(segmentation.Id)
    }
  })
}

watch(() => props.articles, reset)

onMounted(() => reset())
</script>
