import type { StockDefinitionModel, StockModel } from './model/stockModel'
import Net from '@/services/net'

export function getStockDefinitions(catalogCode: number) {
  return Net.t1.get<StockDefinitionModel[]>(`/catalogs/${catalogCode}/stockdefinitions`)
}

export function getStocks(catalogCode: number, articleId: number | null = null, customerId: number | null = null, u: string | null = null) {
  const params = new URLSearchParams()
  if (articleId !== null) {
    params.set('articleId', articleId.toString())
  }
  if (customerId !== null) {
    params.set('customerId', customerId.toString())
  }
  if (u !== null) {
    params.set('u', u)
  }
  return Net.t1.get<StockModel[]>(`/catalogs/${catalogCode}/stocks?${params.toString()}`)
}
