import { computed, ref } from 'vue'

const memUsed = ref(0)
const memAvailable = ref(0)
const storageUsed = ref(0)
const storageAvailable = ref(0)
const items = ref([] as IStatusBarItem[])
const itemsSet = new Set<string>()

async function perfCheck() {
  const storage = await navigator.storage.estimate()
  storageAvailable.value = storage.quota || 0
  storageUsed.value = storage.usage || 0

  if ((window.performance as any).memory) {
    const timingInfo = (window.performance as any).memory
    memUsed.value = timingInfo.usedJSHeapSize
    memAvailable.value = timingInfo.jsHeapSizeLimit
  }
  else {
    memUsed.value = 0
    memAvailable.value = 0
  }

  setTimeout(perfCheck, 30000)
}

setTimeout(perfCheck, 1000)

interface IStatusBarItem {
  id: string
  value: string
  label: string
  icon: string
}

export function setItems(newItems: IStatusBarItem[]) {
  items.value.splice(0, items.value.length)
  newItems.forEach((itm) => {
    items.value.push(itm)
    itemsSet.add(itm.id)
  })
}

export function setItemValue(itemId: string, value: string) {
  const item = items.value.find(itm => itm.id === itemId)
  if (!item) {
    console.warn('Unable to find status bar item:', itemId)
    return
  }
  item.value = value
}

export function updateItemLabel(itemId: string, value: string) {
  const item = items.value.find(itm => itm.id === itemId)
  if (!item) {
    console.warn('Unable to find status bar item:', itemId)
    return
  }
  item.label = value
}

export function addItem(item: IStatusBarItem, pos?: number) {
  if (pos != null) {
    items.value.splice(pos, 0, item)
  }
  else {
    items.value.push(item)
  }
  itemsSet.add(item.id)
}

export function removeItem(itemId: string) {
  const index = items.value.findIndex(itm => itm.id === itemId)
  if (index >= 0) {
    itemsSet.delete(items.value[index].id)
    items.value.splice(index, 1)
  }
}

export function hasItem(itemId: string) {
  return itemsSet.has(itemId)
}

export const getItems = computed(() => items.value)
export const getMemUsed = computed(() => memUsed.value)
export const getMemAvailable = computed(() => memAvailable.value)
export const getStorageUsed = computed(() => storageUsed.value)
export const getStorageAvailable = computed(() => storageAvailable.value)
