import type { Ref } from 'vue'
import { isRef, onBeforeUnmount, onMounted, unref, watch } from 'vue'

export function useEventListener(target: Ref<EventTarget> | EventTarget, eventName: string, callback: (e: any) => any) {
  if (isRef(target)) {
    watch(target, (value, oldValue) => {
      oldValue?.removeEventListener(eventName, callback)
      value?.addEventListener(eventName, callback)
    })
  }
  else {
    onMounted(() => {
      target.addEventListener(eventName, callback)
    })
  }
  onBeforeUnmount(() => {
    unref(target)?.removeEventListener(eventName, callback)
  })
}
