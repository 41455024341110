<template>
  <div class="mx-auto mt-16 text-2xl">
    {{ t('general.hi') }} {{ firstName }}
  </div>

  <div class="w-full mt-2 text-sm" v-text="t('login.loginUsersHeading')" />

  <div class="w-full mt-10">
    <tx-input
      v-model="filterText" clearable faicon="fa-light fa-magnifying-glass" :placeholder="t('general.search')"
      :disabled="loading"
    />
  </div>

  <div class="w-full h-56 mt-2">
    <ul tabindex="-1" role="listbox" class="w-full h-48 overflow-auto leading-10">
      <li
        v-for="user in filterUsers" :key="user.id" tabindex="0" class="relative flex items-center h-10 pl-2 cursor-pointer hover:bg-primary hover:text-on-primary"
        :class="user.username === userName ? 'bg-sky-300' : ''"
        @click="onUserSelected(user)"
      >
        <div class="pl-2 text-base truncate border-b grow border-grey">
          <span class="inline-block w-8 mr-2 leading-8 text-center rounded-2xl bg-grey">{{ user.initials }}</span>
          <span>{{ user.username }}</span>
        </div>
      </li>
    </ul>
  </div>

  <div class="w-full">
    <tx-button type="cancel" width="180px" height="40px" :disabled="loading" :text="t('general.cancel')" @click="onCancel" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxInput from '@/shared/components/TxInput.vue'
import type { AuthUser } from '@/models/auth'

interface IProps {
  loading?: boolean
  firstName?: string
  userName?: string
  users?: AuthUser[]
}
const props = withDefaults(defineProps<IProps>(), { loading: false, firstName: '', userName: '', users: () => [] as AuthUser[] })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'userSelected', selectedUser: AuthUser): void
}>()

const { t } = useI18n()

const filterText = ref('')

function onCancel() {
  emit('cancel')
}

const filterUsers = computed(() => {
  const users = props.users.filter((user) => {
    return user.username.toLowerCase().includes(filterText.value.toLowerCase())
  })
  return users
})

function onUserSelected(selectedUser: AuthUser) {
  if (!props.loading) {
    emit('userSelected', selectedUser)
  }
}
</script>
