<template>
  <tx-dialog
    v-model="visible" :title="t('merch.dialog.addFolder.title')" width="450px" show-ok-cancel
    :ok-state="v$.$invalid ? 'disabled' : 'enabled'" :loading="loading" confirm-text="merch.addFolder"
    @ok="doCreate()" @cancel="doCancel()"
  >
    <div class="w-full h-full">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      <div class="w-full h-full">
        <div class="w-full my-2">
          <tx-select
            v-model="form.parentFolder" :label="t('merch.dialog.addFolder.fields.parentFolder')" :data="parentFolders"
          />
        </div>
        <div class="w-full my-2">
          <tx-input
            v-model.trim="form.name" :label="t('merch.dialog.addFolder.fields.folderName')" required
          />
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, helpers, maxLength, minLength, required } from '@vuelidate/validators'
import { computed, reactive, ref } from 'vue'
import type Merch from '../services/merch'
import { getAllPathsWithParents } from '../utils'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import { merchConstants } from '@/models/constants'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'

const props = defineProps<{
  slideTree: ITreeNode[] | undefined
  merch: Merch | undefined
}>()

const emit = defineEmits<{
  (e: 'create', folderId: string): void
}>()

const { t } = useI18n()
const { errorMessage, hasError } = useErrorMessage()
const withI18nMessage = createI18nMessage({ t })

const visible = ref(false)
const loading = ref(false)
const form = reactive({
  name: '',
  parentFolder: '',
})
// const parentFolders = ref <string[]> ([])
const parentFolders = computed(() => {
  let folders: any[] = []
  if (props.slideTree && props.slideTree.length !== 0) {
    folders = getAllPathsWithParents(props.slideTree)
    folders = folders.map(folder => folder.path)
  }
  return folders
})
const rules = computed(() => {
  const result: Record<string, any> = {}
  if (visible.value) {
    result.name = {}
    result.name.required = helpers.withMessage(t('validations.required', { property: t('merch.dialog.addFolder.fields.folderName') }), required)
    result.name.minLength = withI18nMessage(minLength(1))
    result.name.maxLength = withI18nMessage(maxLength(250))
    if (utils.isValidStringValue(form.name)) {
      const isFolderNameExist = () => {
        for (let i = 0; i < parentFolders.value.length; i++) {
          const currentFolderName = parentFolders.value[i]
          const nameList = currentFolderName.split(merchConstants.folderPathSeparator).map(namePath => namePath.trim())
          if (nameList.includes(form.name.trim())) {
            return false
          }
        }
        return true
      }
      // eslint-disable-next-line dot-notation
      result.name['isFolderNameExist'] = helpers.withMessage(t('merch.dialog.addFolder.validations.duplicateFolderName'), isFolderNameExist)
    }
  }
  return result
})
const v$ = useVuelidate(rules, form)

async function showDialog() {
  reset()
}
async function doCreate() {
  errorMessage.value = ''
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
    return
  }
  if (props.merch) {
    const folderId = utils.randomId()
    props.merch.addFolder(form.name, form.parentFolder, folderId)
    emit('create', folderId)
  }
  visible.value = false
}
function doCancel() {
  visible.value = false
}
function reset() {
  loading.value = false
  visible.value = true
  form.name = ''
  form.parentFolder = ''
}
defineExpose({
  showDialog,
  loading,
  visible,
  errorMessage,
})
</script>
