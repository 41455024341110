<template>
  <div v-show="active" id="actionBar" class="w-full transition-all duration-500 ease-in-out pointer-events-none h-15 z-action-bar">
    <div class="w-full h-1 opacity-50 bg-gradient-easy-to-top" />
    <div class="py-1.5 px-2.5 h-13 w-full bg-white pointer-events-auto overflow-x-auto overflow-y-hidden">
      <div class="flex flex-row items-center justify-center w-max mx-auto my-0">
        <div
          v-for="btn in buttons" :key="btn.id" class="flex flex-col items-center justify-center mx-3 my-0 cursor-pointer button w-17 text-dark active:text-primary-500 hover:text-primary-500"
          :class="{ 'opacity-50 cursor-not-allowed': btn.disabled }" @click="onButtonClicked(btn, $event)"
        >
          <font-awesome-icon class="w-5 h-5 mb-1" :icon="btn.icon" />
          <div class="text-xs" v-text="t(btn.label)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

defineProps<{ active: boolean, buttons: IActionBarButton[] }>()
const { t } = useI18n()

function onButtonClicked(button: IActionBarButton, event: MouseEvent) {
  if (button.disabled) {
    return
  }
  button.onClick(event)
}
</script>

<style scoped>
.cursor-not-allowed {
  cursor: not-allowed;
}
</style>
